import { LinearProgress, Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import {
  getCurrentProgress,
  getTotalProgress,
  getUploading,
  getUploadingText,
} from "../../store/uploadingSlice";

const UploadingProgress = () => {
  const currentProgress = useAppSelector(getCurrentProgress);
  const totalProgress = useAppSelector(getTotalProgress);
  const loading = useAppSelector(getUploading);
  const uploadingText = useAppSelector(getUploadingText);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.90)",
        backdropFilter: "blur(7px)",
        zIndex: 10000,
        justifyContent: "center",
        alignItems: "center",
        opacity: loading ? 1 : 0,
        pointerEvents: loading ? "all" : "none",
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Typography
        sx={{
          color: "#3050F5",
          marginBottom: "24px",
          fontFamily: "'Ubuntu', sans-serif",
        }}
      >
        {currentProgress} of {totalProgress} {uploadingText}...
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(currentProgress / totalProgress) * 100}
        sx={{
          borderRadius: "8px",
          width: "264px",
          height: "10px",
          background: "white",
          border: "2px solid #4261FF",
          ".MuiLinearProgress-bar1Determinate": {
            backgroundColor: "#C0CAFF",
          },
        }}
      />
    </div>
  );
};

export default UploadingProgress;
