import CreateModelView from "../controls/CreateModelView";
import CreateVideoView from "../controls/CreateVideoView";

const HomeView = () => {
  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        position: "absolute",
        top: "64px",
        left: 0,
        right: "16px",
        backgroundColor: "#f1f3f4",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <div
        style={{
          height: "50%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: "16px",
        }}
      >
        <CreateModelView />
      </div>
      <div
        style={{
          height: "50%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: "16px",
        }}
      >
        <CreateVideoView />
      </div>
    </div>
  );
};

export default HomeView;
