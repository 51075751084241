import { createSlice } from "@reduxjs/toolkit";
import { AIModel } from "./types";
import { RootState } from "./store";

const initialState: AIModel = {
  generatingShot: false,
  generatingAudio: false,
  generatingText: "",
};

export const aiSlice = createSlice({
  name: "ai",
  initialState,
  reducers: {
    setGeneratingShot: (state, action) => {
      state.generatingShot = action.payload;
    },
    setGeneratingAudio: (state, action) => {
      state.generatingAudio = action.payload;
    },
    setGeneratingText: (state, action) => {
      state.generatingText = action.payload;
    },
  },
});

export const getGeneratingShot = (state: RootState) => state.ai.generatingShot;
export const getGeneratingAudio = (state: RootState) =>
  state.ai.generatingAudio;
export const getGeneratingText = (state: RootState) => state.ai.generatingText;

export const { setGeneratingShot, setGeneratingAudio, setGeneratingText } =
  aiSlice.actions;
export default aiSlice.reducer;
