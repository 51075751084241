import { Button, CircularProgress, Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IOSSwitch } from "../ShareModelDialog";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getEditorViewVisible,
  setEditorViewVisible,
} from "../../../store/editorSlice";
import { saveEditorViewVisibleToLocalStorage } from "../../../managers/storage/LocalStorageManager";
import DownArrow from "../../../icons/DownArrow";
import { getUserSubscriptionLevel } from "../../../store/userSlice";
import {
  capitalizeFirstLetter,
  replaceUnderscoresWithSpaces,
} from "../../../helpers/Helper";
import { setSubscriptionAsync } from "../../../managers/ClientManager";

function EditorSettings() {
  const dispatch = useAppDispatch();
  const editorViewVisible = useAppSelector(getEditorViewVisible);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const [switchTypographyText, setSwitchTypographyText] =
    useState("Link Disabled");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (editorViewVisible) {
      setSwitchTypographyText("Editor View Visible");
    } else {
      setSwitchTypographyText("Editor View Hidden");
    }
  }, [editorViewVisible]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <div style={{ display: "flex" }}>
        <IOSSwitch
          sx={{ mr: "8px" }}
          onChange={(e) => {
            dispatch(setEditorViewVisible(e.target.checked));
            saveEditorViewVisibleToLocalStorage(e.target.checked);
          }}
          checked={editorViewVisible}
        />
        <Typography>{switchTypographyText}</Typography>
      </div>
      <Button
        onClick={handleOpenPopover}
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          padding: "0px 12px",
          height: "36px",
          width: "280px",
          gap: "4px",
          justifyContent: "space-between",
          boxShadow: popoverOpen
            ? "0px 10px 10px 0px rgba(0, 0, 0, 0.08)"
            : "none",
          ":hover": {
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Subscription Level:{" "}
          {loading ? (
            <CircularProgress size={"16px"} sx={{ marginLeft: "8px" }} />
          ) : (
            replaceUnderscoresWithSpaces(
              capitalizeFirstLetter(subscriptionLevel)
            )
          )}
        </Typography>
        <DownArrow />
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          marginTop: "5px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "8px",
          }}
        >
          <Button
            sx={{
              borderRadius: "12px",
              justifyContent: "start",

              background: "#FFF",
              ":hover": {
                background: "#E3E5E5",
              },
              ":active": {
                background: "#F1F3F4",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={async () => {
              setPopoverOpen(false);
              setLoading(true);
              await setSubscriptionAsync(dispatch, "demo");
              setLoading(false);
            }}
          >
            Demo
          </Button>
          <Button
            sx={{
              borderRadius: "12px",
              justifyContent: "start",

              background: "#FFF",
              ":hover": {
                background: "#E3E5E5",
              },
              ":active": {
                background: "#F1F3F4",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={async () => {
              setPopoverOpen(false);
              setLoading(true);
              await setSubscriptionAsync(dispatch, "starter");
              setLoading(false);
            }}
          >
            Starter
          </Button>
          <Button
            sx={{
              borderRadius: "12px",
              justifyContent: "start",

              background: "#FFF",
              ":hover": {
                background: "#E3E5E5",
              },
              ":active": {
                background: "#F1F3F4",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={async () => {
              setPopoverOpen(false);
              setLoading(true);
              await setSubscriptionAsync(dispatch, "pro");
              setLoading(false);
            }}
          >
            Pro
          </Button>
          <Button
            sx={{
              borderRadius: "12px",
              justifyContent: "start",

              background: "#FFF",
              ":hover": {
                background: "#E3E5E5",
              },
              ":active": {
                background: "#F1F3F4",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={async () => {
              setPopoverOpen(false);
              setLoading(true);
              await setSubscriptionAsync(dispatch, "test");
              setLoading(false);
            }}
          >
            Test
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export default EditorSettings;
