import { StoreItemModel } from "../../store/types";
import {
  getSharedBlobAsync,
  getSharedJsonAsync,
  getUserBlobAsync,
  getUserJsonAsync,
} from "./AzureStorageManager";
import { getBlobFromIDBAsync, saveBlobToIDBAsync } from "./DbManager";

export async function getPreviewWebpAsync(model: StoreItemModel) {
  const previewUrl = `previews/${model.url}.webp`;
  let blob = await getBlobFromIDBAsync(previewUrl);
  if (!blob || blob.size < 1) {
    const newModel = JSON.parse(JSON.stringify(model));
    newModel.url = previewUrl;
    blob = await getUserBlobAsync(newModel.url);
    if (blob && blob.size > 1) await saveBlobToIDBAsync(blob, previewUrl);
  }
  return blob ? URL.createObjectURL(blob) : undefined;
}

export async function getPreviewMp4Async(model: StoreItemModel) {
  const previewUrl = `previews/${model.url}.mp4`;
  let blob = await getBlobFromIDBAsync(previewUrl);
  if (!blob) {
    const newModel = JSON.parse(JSON.stringify(model));
    newModel.url = previewUrl;
    blob = await getUserBlobAsync(newModel.url);
    if (blob) await saveBlobToIDBAsync(blob, previewUrl);
  }
  return blob ? URL.createObjectURL(blob) : undefined;
}

export async function getPreviewStringAsync(model: StoreItemModel) {
  const previewUrl = `previews/${model.url}.json`;
  let blob = await getBlobFromIDBAsync(previewUrl);
  if (!blob || blob.size < 3000) {
    const newModel = JSON.parse(JSON.stringify(model));
    newModel.url = previewUrl;
    const preview = await getUserBlobAsync(newModel);
    if (preview) await saveBlobToIDBAsync(new Blob([preview]), previewUrl);
    return preview;
  }

  const data = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsText(blob!);
  });

  return data;
}

export async function getBlobAsync(model: StoreItemModel) {
  let blob = await getBlobFromIDBAsync(model.url);
  if (!blob) {
    blob =
      model.accessType === "shared"
        ? await getSharedBlobAsync(model.url)
        : await getUserBlobAsync(model.url);
    if (blob) await saveBlobToIDBAsync(blob, model.url);
  }
  return blob;
}

export async function getJsonAsync(model: StoreItemModel) {
  const idbBlob = await getBlobFromIDBAsync(model.url);
  if (idbBlob) {
    const idbJson: any = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(JSON.parse(reader.result as string));
      };
      reader.readAsText(idbBlob);
    });
    if (idbJson.createdOn === Date.parse(model.properties.lastModified)) {
      return idbJson.data;
    }
  }

  const json =
    model.accessType === "shared"
      ? await getSharedJsonAsync(model.url)
      : await getUserJsonAsync(model.url);
  if (json) {
    const newIdbJson = {
      data: json,
      createdOn: Date.parse(model.properties.lastModified),
    };
    await saveBlobToIDBAsync(
      new Blob([JSON.stringify(newIdbJson)], { type: "application/json" }),
      model.url
    );
  }
  return json;
}
