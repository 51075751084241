import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getProject } from "../../store/projectSlice";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import { getSaving, getTabMode, switchTabTo } from "../../store/navigatorSlice";
import {
  Box,
  CircularProgress,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { tabModes } from "../../store/types";
import { ReactNode, useEffect, useRef } from "react";
import {
  getAnimatableName,
  getLoaderState,
  getSceneLoading,
} from "../../store/sceneSlice";
import { getTimelineSelectedItem } from "../../store/timelineSlice";
import { getLumiereSceneById } from "../scene/LumiereScene";
import { Inspector } from "babylonjs-inspector";
import LogoIcon from "../../icons/LogoIcon";
import { selectScriberLoading } from "../../store/scriberSlice";
import { hideCustomAlert } from "../../store/dialogSlice";
import { appInsights } from "../../managers/AppInsights";
import Styles from "./styles/Styles";
import SelectedElementsIcon from "../../icons/SelectedElementsIcon";
import ElementsIcon from "../../icons/ElementsIcon";
import Elements from "./elements/Elements";
import { getEditorViewVisible } from "../../store/editorSlice";
import TextAnimations from "./textAnimations/TextAnimations";
import TabsLoadingView from "./TabsLoadingView";
import VideosIcon from "../../icons/VideosIcon";
import SelectedVideosIcon from "../../icons/SelectedVideosIcon";
import SelectedModelsIcon from "../../icons/SelectedModelsIcon";
import ModelsIcon from "../../icons/ModelsIcon";
import LogoIconColor from "../../icons/LogoIconColor";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ height: "100%" }}
    >
      {value === index && children}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const inspectorRef = useRef();
  const tabMode = useAppSelector(getTabMode);
  const saving = useAppSelector(getSaving);
  const loading = useAppSelector(getLoaderState);
  const sceneLoading = useAppSelector(getSceneLoading);
  const scriberLoading = useAppSelector(selectScriberLoading);
  const animatableName = useAppSelector(getAnimatableName);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const editorViewVisible = useAppSelector(getEditorViewVisible);

  useEffect(() => {
    if (!selectedItem?.id) return;
    const lumiereScene = getLumiereSceneById(selectedItem.id);
    if (!lumiereScene) return;
    if (tabMode === tabModes.developer) {
      Inspector.Show(lumiereScene.scene, {
        embedMode: true,
        enableClose: false,
        globalRoot: inspectorRef.current,
        handleResize: false,
      });
    } else {
      Inspector.Hide();
      lumiereScene.controlledCamera.attachControl();
    }
  }, [tabMode, selectedItem?.id]);

  const handleChange = (event: React.SyntheticEvent, value: tabModes) => {
    if (loading) return;

    const trackableElement = (event.target as HTMLElement).closest(
      "[data-track]"
    );
    if (trackableElement) {
      const eventName = trackableElement.getAttribute("data-track");
      if (eventName) {
        appInsights.trackEvent({ name: eventName });
      }
    }
    dispatch(switchTabTo(value));
    dispatch(hideCustomAlert());
  };

  const tabsTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#3050F5",
            },
            color: "#454E63",
            fontSize: 10,
            fontWeight: 700,
            textTransform: "none",
            fontFamily: "Red Hat Display",
            gap: 8,
            ":disabled": { opacity: "0.6" },
          },
        },
      },
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        margin: "10px 0px",
        pointerEvents:
          loading || scriberLoading || sceneLoading ? "none" : "all",
        opacity: loading || scriberLoading || sceneLoading ? 0.75 : 1,
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Box
        sx={{
          width: "72px",
          height: "100%",
          zIndex: 100,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ThemeProvider theme={tabsTheme}>
            <Tabs
              value={tabMode}
              orientation="vertical"
              onChange={(e, v) => handleChange(e, v)}
              aria-label="basic tabs example"
            >
              <Tab
                data-track="HomeTabClicked"
                {...a11yProps(tabModes.home)}
                icon={
                  tabMode === tabModes.home ? <LogoIconColor /> : <LogoIcon />
                }
                disableRipple
                sx={{
                  marginLeft: "2px",
                  padding: 0,
                  minWidth: 0,
                }}
              />
              <Tab
                data-track="ModelsTabClicked"
                {...a11yProps(tabModes.models)}
                disableRipple
                icon={
                  tabMode === tabModes.models ? (
                    <SelectedModelsIcon />
                  ) : (
                    <ModelsIcon />
                  )
                }
                label="Models"
                sx={{
                  padding: 0,
                  minWidth: 0,
                  marginTop: "12px",
                }}
              />
              <Tab
                data-track="VideosTabClicked"
                {...a11yProps(tabModes.videos)}
                disableRipple
                icon={
                  tabMode === tabModes.videos ? (
                    <SelectedVideosIcon />
                  ) : (
                    <VideosIcon />
                  )
                }
                label="Videos"
                sx={{
                  padding: 0,
                  minWidth: 0,
                  marginTop: "8px",
                }}
              />
              <Tab
                {...a11yProps(tabModes.elements)}
                disableRipple
                icon={
                  tabMode === tabModes.elements ? (
                    <SelectedElementsIcon />
                  ) : (
                    <ElementsIcon />
                  )
                }
                label="Elements"
                sx={{
                  padding: 0,
                  minWidth: 0,
                  marginTop: "8px",
                  display: editorViewVisible ? "flex" : "none",
                }}
              />
              <Tab
                {...a11yProps(tabModes.developer)}
                disabled={!project.sequences || project.sequences.length === 0}
                icon={<SettingsSuggestOutlinedIcon />}
                label="Developer"
                disableRipple
                sx={{
                  display: editorViewVisible ? "inherit" : "none",
                  padding: 0,
                  minWidth: 0,
                  marginTop: "8px",
                }}
              />
              <Tab
                {...a11yProps(tabModes.styles)}
                disableRipple
                sx={{ minHeight: 0, padding: 0 }}
              />
              <Tab
                {...a11yProps(tabModes.textAnimations)}
                disableRipple
                sx={{ minHeight: 0, padding: 0 }}
              />
            </Tabs>
          </ThemeProvider>
          {!loading && (
            <CircularProgress
              size={24}
              sx={{
                marginTop: "24px",
                marginBottom: "16px",
                display: saving ? "block" : "none",
                color: "#3050F5",
              }}
            />
          )}
        </Box>
      </Box>
      {editorViewVisible && (
        <Box
          sx={{
            padding: "4px 0px",
          }}
        >
          <Box
            sx={{
              display:
                tabMode === tabModes.home ||
                tabMode === tabModes.models ||
                tabMode === tabModes.videos
                  ? "none"
                  : "block",
              backgroundColor:
                tabMode === tabModes.styles ||
                tabMode === tabModes.textAnimations
                  ? "#F1F3F4"
                  : "#FFF",
              position: "relative",
              minWidth: "360px",
              maxWidth: "360px",
              height: "100%",
              borderRadius: "16px",
              border: "solid #D0D4D9",
              boxSizing: "border-box",
              borderWidth:
                tabMode === tabModes.styles ||
                tabMode === tabModes.textAnimations
                  ? "1px"
                  : "0px",
            }}
          >
            <TabsLoadingView />
            <TabPanel value={tabMode} index={tabModes.home} />
            <TabPanel value={tabMode} index={tabModes.models} />
            <TabPanel value={tabMode} index={tabModes.videos} />
            <TabPanel value={tabMode} index={tabModes.elements}>
              <Box sx={{ height: "100%" }}>
                <Elements />
              </Box>
            </TabPanel>
            <TabPanel value={tabMode} index={tabModes.developer}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    overflowY: "auto",
                    padding: "16px",
                  }}
                >
                  <Typography>{animatableName}</Typography>
                  <Box ref={inspectorRef} />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={tabMode} index={tabModes.styles}>
              <Box sx={{ height: "100%" }}>
                <Styles />
              </Box>
            </TabPanel>
            <TabPanel value={tabMode} index={tabModes.textAnimations}>
              <Box sx={{ height: "100%" }}>
                <TextAnimations />
              </Box>
            </TabPanel>
          </Box>
        </Box>
      )}
    </Box>
  );
}
