import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { MainObject, Project } from "./types";

const initialState = {} as Project;

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProject: (_, action) => {
      return action.payload;
    },
    applyTemplate: (state, action) => {
      Object.assign(state, action.payload);
    },
    setProjectName: (state, action) => {
      state.name = action.payload;
    },
    setProjectRenderUrl: (state, action) => {
      state.renderUrl = action.payload;
    },
    setProjectAudio: (state, action) => {
      state.audio = action.payload;
    },
    setProjectAudioTrims: (state, action) => {
      state.audio!.trims = action.payload;
    },
    setProjectAudioOffset: (state, action) => {
      state.audio!.offset = action.payload;
    },
    addSequence: (state, action) => {
      state.sequences.push(action.payload);
    },
    removeSequence: (state, action) => {
      state.sequences = state.sequences.filter((s) => s.id !== action.payload);
    },
    reorderSequences: (state, action) => {
      state.sequences.splice(
        action.payload.endIndex,
        0,
        state.sequences.splice(action.payload.startIndex, 1)[0]
      );
    },
    setSequenceDuration: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      sequence.duration = Math.floor(action.payload.duration);
      if (!sequence.trims) {
        sequence.trims = [0, 0];
      }
    },
    setSequenceTrims: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      sequence.trims = action.payload.trims;
    },
    setSequenceMainObject: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      if (!sequence.mainObject) {
        sequence.mainObject = { model: action.payload.model } as MainObject;
      } else {
        sequence.mainObject.model = action.payload.model;
      }
    },
    setSequenceSkybox: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      sequence.skybox!.model = action.payload.model;
    },
    setSequenceShot: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      sequence.shot = action.payload.model;
      sequence.trims = [0, 0];
    },
    setSequenceRelativeBox: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      sequence.mainObject!.relativeBox = action.payload.relativeBox;
    },
    setAnimatableTransform: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      if (sequence.camera.id === action.payload.animatableId) {
        sequence.camera.transform = action.payload.transform;
      } else if (
        sequence.mainObject?.model.id === action.payload.animatableId
      ) {
        sequence.mainObject!.transform = action.payload.transform;
      } else {
        const light = sequence.lights?.find(
          (l) => l.id === action.payload.animatableId
        );
        if (light) {
          light.transform = action.payload.transform;
        }
      }
    },
    setAnimatableAnimations: (state, action) => {
      const sequence = getSelectedSequence(state, action.payload.sequenceId);
      if (sequence.camera.id === action.payload.animatableId) {
        sequence.camera.animations = action.payload.animations;
      } else if (
        sequence.mainObject?.model.id === action.payload.animatableId
      ) {
        sequence.mainObject!.animations = action.payload.animations;
      } else {
        const light = sequence.lights?.find(
          (l) => l.id === action.payload.animatableId
        );
        if (light) {
          light.animations = action.payload.animations;
        }
      }
    },
    addText: (state, action) => {
      if (!state.texts) state.texts = [];
      state.texts.push(action.payload);
    },
    removeText: (state, action) => {
      state.texts = state.texts!.filter((t) => t.id !== action.payload);
    },
    setTextTemplate: (state, action) => {
      const selectedText = getSelectedText(state, action.payload.id);
      selectedText.template = action.payload.template;
    },
    setTextTimelineProps: (state, action) => {
      const selectedText = getSelectedText(state, action.payload.id);
      selectedText.timeline = {
        ...selectedText.timeline,
        ...action.payload.data,
      };
    },
    setTextAppearanceProps: (state, action) => {
      const selectedText = getSelectedText(state, action.payload.id);
      selectedText.appearance = {
        ...selectedText.appearance,
        ...action.payload.data,
      };
    },
    setTextAnimationTemplate: (state, action) => {
      const selectedText = getSelectedText(state, action.payload.id);
      if (!selectedText.animation) {
        selectedText.animation = {
          duration: 1,
          template: action.payload.template,
        };
      } else {
        selectedText.animation.template = action.payload.template;
      }
    },
    setTextAnimationProps: (state, action) => {
      const selectedText = getSelectedText(state, action.payload.id);
      selectedText.animation = {
        ...selectedText.animation,
        ...action.payload.data,
      };
    },
  },
});

export const getProject = (state: RootState) => state.project;

export const {
  setProject,
  applyTemplate,
  setProjectAudio,
  setProjectAudioTrims,
  setProjectAudioOffset,
  setProjectName,
  setProjectRenderUrl,
  addSequence,
  removeSequence,
  reorderSequences,
  setSequenceDuration,
  setSequenceMainObject,
  setSequenceSkybox,
  setSequenceShot,
  setSequenceRelativeBox,
  setAnimatableTransform,
  setAnimatableAnimations,
  setSequenceTrims,
  addText,
  removeText,
  setTextTemplate,
  setTextTimelineProps,
  setTextAppearanceProps,
  setTextAnimationTemplate,
  setTextAnimationProps,
} = projectSlice.actions;

export default projectSlice.reducer;

function getSelectedSequence(project: Project, selectedSequenceId: string) {
  return project.sequences.find((s) => s.id === selectedSequenceId)!;
}

function getSelectedText(project: Project, selectedTextId: string) {
  return project.texts!.find((t) => t.id === selectedTextId)!;
}
