import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DialogModel, createVideoDialogModes } from "./types";

const initialState: DialogModel = {
  earlyAccessDialogOpen: false,
  settingsDialogOpen: false,
  shareModelDialogOpen: false,
  createModelDialogOpen: false,
  createVideoDialogOpen: false,
  createVideoDialogMode: createVideoDialogModes.createVideo,
  customAlert: "",
  imageDialog: undefined,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    showEarlyAccessDialog: (state) => {
      state.earlyAccessDialogOpen = true;
    },
    hideEarlyAccessDialog: (state) => {
      state.earlyAccessDialogOpen = false;
    },
    showSettingsDialog: (state) => {
      state.settingsDialogOpen = true;
    },
    hideSettingsDialog: (state) => {
      state.settingsDialogOpen = false;
    },
    showShareModelDialog: (state) => {
      state.shareModelDialogOpen = true;
    },
    hideShareModelDialog: (state) => {
      state.shareModelDialogOpen = false;
    },
    showCreateModelDialog: (state) => {
      state.createModelDialogOpen = true;
    },
    hideCreateModelDialog: (state) => {
      state.createModelDialogOpen = false;
    },
    showCreateVideoDialog: (state) => {
      state.createVideoDialogOpen = true;
    },
    hideCreateVideoDialog: (state) => {
      state.createVideoDialogOpen = false;
    },
    setCreateVideoDialogMode: (state, action) => {
      state.createVideoDialogMode = action.payload;
    },
    showCustomAlert: (state, action) => {
      state.customAlert = action.payload;
    },
    hideCustomAlert: (state) => {
      state.customAlert = "";
    },
    showImageDialog: (state, action) => {
      state.imageDialog = action.payload;
    },
    hideImageDialog: (state) => {
      state.imageDialog = undefined;
    },
  },
});

export const getEarlyAccessDialogOpen = (state: RootState) =>
  state.dialog.earlyAccessDialogOpen;
export const getCreateModelDialogOpen = (state: RootState) =>
  state.dialog.createModelDialogOpen;
export const getCreateVideoDialogOpen = (state: RootState) =>
  state.dialog.createVideoDialogOpen;
export const getCreateVideoDialogMode = (state: RootState) =>
  state.dialog.createVideoDialogMode;
export const getSettingsDialogOpen = (state: RootState) =>
  state.dialog.settingsDialogOpen;
export const getShareModelDialogOpen = (state: RootState) =>
  state.dialog.shareModelDialogOpen;
export const getCustomAlertText = (state: RootState) =>
  state.dialog.customAlert;
export const getImageDialogItem = (state: RootState) =>
  state.dialog.imageDialog;

export const {
  showEarlyAccessDialog,
  hideEarlyAccessDialog,
  showSettingsDialog,
  hideSettingsDialog,
  showShareModelDialog,
  hideShareModelDialog,
  showCustomAlert,
  hideCustomAlert,
  showCreateModelDialog,
  hideCreateModelDialog,
  showCreateVideoDialog,
  hideCreateVideoDialog,
  setCreateVideoDialogMode,
  showImageDialog,
  hideImageDialog,
} = dialogSlice.actions;

export default dialogSlice.reducer;
