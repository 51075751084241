import { Box, Button } from "@mui/material";
import AudioTrackItem from "./AudioTrackItem";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { switchTabTo } from "../../../../store/navigatorSlice";
import {
  getProject,
  setProjectAudio,
  setProjectAudioOffset,
} from "../../../../store/projectSlice";
import {
  getTimelineScale,
  setTimelinePlaying,
} from "../../../../store/timelineSlice";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import {
  getStorageItemFromSharedStorageData,
  getProjectDuration,
  timeoutAsync,
} from "../../../../helpers/Helper";
import { getSharedStorageDataAsync } from "../../../../managers/storage/AzureStorageManager";
import { animationsFps } from "../../../../settings/GlobalSettings";
import { setSceneLoading } from "../../../../store/sceneSlice";
import {
  setGeneratingAudio,
  setGeneratingText,
} from "../../../../store/aiSlice";

type AudioTrackProps = { duration?: number };

function AudioTrack({ duration }: AudioTrackProps) {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const scaleRatio = useAppSelector(getTimelineScale);
  const [itemToDelete, setItemToDelete] = useState(false);

  const handleCloseDeleteDialog = () => {
    setItemToDelete(false);
  };

  const handleShowDeleteDialog = () => {
    setItemToDelete(true);
  };

  const handleDelete = () => {
    dispatch(setProjectAudio(undefined));
  };

  const handleAddAudio = async () => {
    dispatch(setGeneratingAudio(true));
    dispatch(setTimelinePlaying(false));
    dispatch(setSceneLoading(true));
    dispatch(setGeneratingText("Generating Audio"));

    const audioStorageData = await getSharedStorageDataAsync("ai-audios");
    const audios = getStorageItemFromSharedStorageData(audioStorageData);
    const i = Math.floor(Math.random() * audios.length);
    const projectDuration = getProjectDuration(project) / animationsFps;
    dispatch(
      setProjectAudio({
        source: audios[i],
        trims: [0, 30.0 - projectDuration >= 0.0 ? 30.0 - projectDuration : 0],
        duration: 30,
        offset: 0,
      })
    );

    await timeoutAsync(600);
    dispatch(setSceneLoading(false));
  };

  const handleDragStart = (e: DraggableEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragStop = (e: DraggableEvent, d: DraggableData) => {
    e.preventDefault();
    e.stopPropagation();
    const x = Math.max(d.x / scaleRatio, 0);
    dispatch(setProjectAudioOffset(x));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        height: "32px",
      }}
    >
      <Box sx={{ minWidth: "32px", height: "100%" }}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {project.audio ? (
          <Draggable
            axis="x"
            bounds={{ left: 0, right: duration! * scaleRatio }}
            onStart={handleDragStart}
            onStop={(e, d) => handleDragStop(e, d)}
            position={{
              x: project.audio.offset * scaleRatio,
              y: 0,
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "grab",
              }}
            >
              <AudioTrackItem onDelete={handleShowDeleteDialog} />
            </Box>
          </Draggable>
        ) : (
          <Button
            onClick={handleAddAudio}
            sx={{
              backgroundColor: "#FFF",
              marginTop: "8px",
              borderRadius: "8px",
              gap: "4px",
              ":hover": {
                backgroundColor: "#e6e6e6",
              },
              ":active": {
                backgroundColor: "#cfcfcf",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            Add Music
            <img src="assets/svg/Union.svg" width="12px" height="12px" />
          </Button>
        )}
      </Box>
      <Box sx={{ minWidth: "32px", height: "100%" }}></Box>
      {itemToDelete && (
        <ConfirmationDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete it?"
          confirmButtonText="Delete"
          item="audio"
          onConfirm={handleDelete}
          onCancel={handleCloseDeleteDialog}
        />
      )}
    </Box>
  );
}

export default AudioTrack;
