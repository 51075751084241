import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../../store/hooks";
import { getOccupiedStorageSize } from "../../../store/storageSlice";
import {
  getUserStorageLimit,
  getUserSubscriptionLevel,
} from "../../../store/userSlice";
import { useEffect, useState } from "react";
import { Popover, Typography } from "@mui/material";

const ColorLinearProgress = styled(LinearProgress)(({ theme, value = 0 }) => ({
  height: "12px",
  borderRadius: "6px",
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: "6px",
    transition: "transform .5s ease, background-color .5s ease", // Add transform transition for smooth width change
    backgroundColor: value <= 50 ? "#4261FF" : value <= 99 ? "#4261FF" : "Red",
  },
}));

const StorageProgressBar = () => {
  const [storagePercentage, setStoragePercentage] = useState(0);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const occupiedStorageSize = useAppSelector(getOccupiedStorageSize);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !occupiedStorageSize) return;

      setStoragePercentage(
        occupiedStorageSize < storageLimit
          ? (occupiedStorageSize / storageLimit) * 100
          : 100
      );
    };

    asyncWraper();
  }, [occupiedStorageSize, subscriptionLevel, storageLimit]);

  return (
    <div
      style={{
        margin: "16px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "4px",
        opacity: storagePercentage ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
      }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography style={{ fontWeight: 700, fontSize: "14px" }}>
        {occupiedStorageSize.toString().endsWith(".0")
          ? occupiedStorageSize.toFixed(0)
          : occupiedStorageSize.toFixed(1)}{" "}
        of {storageLimit} Gb Used
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <img src="assets/images/DatabaseIcon.png" width="24px" height="24px" />
        <ColorLinearProgress variant="determinate" value={storagePercentage} />
      </div>
      <Popover
        sx={{
          pointerEvents: "none",
          marginTop: "8px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            width: "280px",
            justifyContent: "center",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography align="center" style={{ fontSize: "14px" }}>
            Storage is used for storing your models, videos and images.
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default StorageProgressBar;
