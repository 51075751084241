import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getCreateModelDialogOpen,
  hideCreateModelDialog,
  hideCustomAlert,
} from "../../store/dialogSlice";
import CreateModelView from "../controls/CreateModelView";
import CustomAlert from "./CustomAlert";
import { useEffect } from "react";

const CreateModelDialog = () => {
  const dispatch = useAppDispatch();
  const dialogOpen = useAppSelector(getCreateModelDialogOpen);

  const handleCloseDialog = () => {
    dispatch(hideCreateModelDialog());
  };

  useEffect(() => {
    dispatch(hideCustomAlert());
  }, [dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth={true}
      sx={{
        overflow: "visible",
        ".MuiDialog-paper": {
          backgroundColor: "#FFF",
          borderRadius: "16px",
          overflow: "visible",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        <div
          style={{
            height: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
            borderRadius: "16px",
          }}
        >
          <CreateModelView />
        </div>

        <CustomAlert positionTop="24px" positionRight="24px" />

        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            top: "0px",
            right: "-42px",
            background: "#F1F3F4",
            ":hover": {
              background: "#F1F3F4",
              boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModelDialog;
