import { Popover, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUserCredits, getUserLeftCredits } from "../../store/userSlice";
import { useEffect, useState } from "react";
import {
  getCreditsAvailability,
  getUserSettingsAsync,
} from "../../managers/ClientManager";

const CreditsView = () => {
  const dispatch = useAppDispatch();
  const leftCredits = useAppSelector(getUserLeftCredits);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const asyncWrapper = async () => {
      const result = await getCreditsAvailability();
      if (result) {
        await getUserSettingsAsync(dispatch);
      }
    };
    asyncWrapper();
  }, []);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "2px",
      }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <img
        src="assets/images/CreditCardIconBlack.png"
        width={"24px"}
        height={"24px"}
      />
      <Typography>{leftCredits}</Typography>
      <Popover
        sx={{
          pointerEvents: "none",
          marginTop: "12px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            width: "200px",
            justifyContent: "center",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography align="center" style={{ fontSize: "14px" }}>
            You have {leftCredits} Creative Credits left. They are replenished
            every day.
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default CreditsView;
