import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ScriberState } from "./types";

const initialState: ScriberState = {
  loading: false,
  inited: false,
  scribersSelectable: true,
  selectedScriberProjectId: "",
};

export const scriberSlice = createSlice({
  name: "scriber",
  initialState,
  reducers: {
    setScriberLoading: (state, action) => {
      state.loading = action.payload;
    },
    setScriberItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setScriberInited: (state) => {
      state.inited = true;
    },
    setScribersSelectable: (state, action) => {
      state.scribersSelectable = action.payload;
    },
    setSelectedScriberProjectId: (state, action) => {
      state.selectedScriberProjectId = action.payload;
    },
  },
});

export const selectScriberLoading = (state: RootState) => state.scriber.loading;
export const selectScriberInited = (state: RootState) => state.scriber.inited;
export const selectScriberSelectedItem = (state: RootState) =>
  state.scriber.selectedItem;
export const selectScribersSelectable = (state: RootState) =>
  state.scriber.scribersSelectable;
export const getSelectedScriberProjectId = (state: RootState) =>
  state.scriber.selectedScriberProjectId;

export const {
  setScriberLoading,
  setScriberItem,
  setScriberInited,
  setScribersSelectable,
  setSelectedScriberProjectId,
} = scriberSlice.actions;

export default scriberSlice.reducer;
