import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { UserSettings } from "./types";

const initialState: UserSettings = {
  userRole: "user",
  subscriptionLevel: "demo",
  subscriptionId: "",
  subscriptionEndDate: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserSettings: (state, action) => {
      Object.assign(state, action.payload);
    },
  },
});

export const getUserRole = (state: RootState) => state.user.userRole;
export const getUserSubscriptionLevel = (state: RootState) =>
  state.user.subscriptionLevel;
export const getUserSubscriptionId = (state: RootState) =>
  state.user.subscriptionId;
export const getUserSubscriptionEndDate = (state: RootState) =>
  state.user.subscriptionEndDate;
export const getUserSubscriptionLimits = (state: RootState) =>
  state.user.subscriptionLimits;
export const getUserCredits = (state: RootState) => state.user.credits;
export const getUserLeftCredits = (state: RootState) =>
  state.user.credits?.left;
export const getUserStorageLimit = (state: RootState) =>
  state.user.subscriptionLimits?.storageGb;
export const getUserCreatedDateTime = (state: RootState) =>
  state.user.createdDateTime;
export const getUserDisplayName = (state: RootState) => state.user.displayName;
export const getUserMail = (state: RootState) => state.user.mail;
export const getUserLeftScribes = (state: RootState) =>
  state.user.scriberAvailability?.left;
export const getUserLeftDownloads = (state: RootState) =>
  state.user.videoDownloadAvailability?.left;
export const getUserScriberLimit = (state: RootState) =>
  state.user.subscriptionLimits?.scriberProcess.count;
export const getUserProjectPhotosLimit = (state: RootState) =>
  state.user.subscriptionLimits?.scriberPhotos.count;
export const getHasUserOnboarded = (state: RootState) => true;
export const { setUserSettings } = userSlice.actions;

export default userSlice.reducer;
