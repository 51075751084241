import { Button, Typography } from "@mui/material";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../managers/AppInsights";
import {
  getStorageItemFromStorageData,
  timeoutAsync,
} from "../../helpers/Helper";
import { postUserBlobAsync } from "../../managers/storage/AzureStorageManager";
import {
  setCreateVideoDialogMode,
  showCreateVideoDialog,
  showCustomAlert,
} from "../../store/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setCurrentProgress,
  setTotalProgress,
  setUploading,
  setUploadingText,
} from "../../store/uploadingSlice";
import DropZone from "./DropZone";
import { createVideoDialogModes } from "../../store/types";
import { saveBlobToIDBAsync } from "../../managers/storage/DbManager";
import { useEffect, useState } from "react";
import {
  getOccupiedStorageSize,
  getUserStorageData,
} from "../../store/storageSlice";
import {
  demoStarterLimitReachedString,
  proTestLimitReachedString,
} from "../../settings/GlobalStrings";
import {
  getUserStorageLimit,
  getUserSubscriptionLevel,
} from "../../store/userSlice";

const CreateVideoView = () => {
  const dispatch = useAppDispatch();
  const userStorageData = useAppSelector(getUserStorageData);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const occupiedStorageSize = useAppSelector(getOccupiedStorageSize);
  const [scribersEmpty, setScribersEmpty] = useState(false);
  const [storageLimitReached, setStorageLimitReached] = useState(false);

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !occupiedStorageSize) return;
      setStorageLimitReached(occupiedStorageSize >= storageLimit);
    };

    asyncWraper();
  }, [occupiedStorageSize, storageLimit]);

  useEffect(() => {
    const configs = getStorageItemFromStorageData(
      "configs/scribers",
      userStorageData
    );
    const models = getStorageItemFromStorageData("models", userStorageData);

    if (configs.length === 0 && models.length === 0) {
      setScribersEmpty(true);
    } else setScribersEmpty(false);
  }, [userStorageData]);

  const handleSelectModels = () => {
    dispatch(showCreateVideoDialog());
    if (scribersEmpty) {
      dispatch(setCreateVideoDialogMode(createVideoDialogModes.createModel));
      return;
    }
    dispatch(setCreateVideoDialogMode(createVideoDialogModes.modelsGallery));
  };

  const handleModelUpload = async (files: File[]) => {
    const file = files?.[0];
    if (!file) return;

    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );
      return;
    }

    if (!file.name.endsWith(".glb")) {
      dispatch(showCustomAlert("Model must have .glb format"));
      return;
    }
    dispatch(setCurrentProgress(0));
    dispatch(setTotalProgress(100));
    dispatch(setUploadingText("% Uploaded"));
    await timeoutAsync(300);

    dispatch(setUploading(true));

    const uploadUrl = "models/" + file.name;
    await saveBlobToIDBAsync(file, uploadUrl);
    await postUserBlobAsync(file, uploadUrl, true, (p) => {
      dispatch(setCurrentProgress(Math.round(p)));
    });
    dispatch(showCreateVideoDialog());
    dispatch(setCreateVideoDialogMode(createVideoDialogModes.modelsGallery));
    dispatch(setUploading(false));
  };

  return (
    <DropZone onModelsReceived={(files) => handleModelUpload(files)}>
      <div
        style={{
          height: "calc(100% - 32px)",
          width: "calc(100% - 32px)",
          marginTop: "16px",
          marginLeft: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: "8px",
          border: "1px dashed rgba(55, 62, 78, 0.20)",
        }}
      >
        <img src="assets/svg/VideoIcon.svg" width="48px" height="48px" />
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: 900,
            marginTop: "22px",
            color: "#373E4E",
          }}
        >
          Create Video
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            marginTop: "12px",
            color: "#5F6A84",
          }}
        >
          Select 3D model from Library or drag and drop *.glb file here to begin
        </Typography>
        <Button
          sx={{
            background: "#FFF",
            borderRadius: "12px",
            border: "2px solid #4261FF",
            padding: "10px 18px",
            height: "48px",
            width: "156px",
            marginTop: "40px",
            ":hover": {
              background: "#FFF",
              boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
            },
            ":active": {
              background: "#FFF",
              boxShadow: "none",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
          onClick={handleSelectModels}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "#3050F5",
              fontWeight: 800,
            }}
          >
            Select Model
          </Typography>
        </Button>
      </div>
    </DropZone>
  );
};

export default withAITracking(
  reactPlugin,
  CreateVideoView,
  "CreateVideo",
  "flex"
);
