import { Box, Typography, Input } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUserDisplayName } from "../../store/userSlice";
import {
  getUserSettingsAsync,
  setDisplayNameAsync,
} from "../../managers/ClientManager";

const INPUT_OFFSET = 2;

const Username = () => {
  const dispatch = useAppDispatch();
  const displayName = useAppSelector(getUserDisplayName);
  const [username, setUsername] = useState("");
  const [inputWidth, setInputWidth] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const typographyRef = useRef<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (displayName) {
      setUsername(displayName);
    }
  }, [displayName]);

  useEffect(() => {
    setInputWidth(typographyRef.current?.offsetWidth + INPUT_OFFSET);
  }, [username]);

  const handleInputChange = (e: any) => {
    setUsername(e.target.value);
    setInputWidth(typographyRef.current?.offsetWidth + INPUT_OFFSET);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleInputUnfocus = () => {
    setInputWidth(typographyRef.current?.offsetWidth + INPUT_OFFSET);
    setIsFocused(false);
    renameAsync();
  };

  const renameAsync = async () => {
    if (displayName && username === "") {
      setUsername(displayName);
      return;
    }
    if (displayName === username) {
      return;
    }
    await setDisplayNameAsync(username);
    await getUserSettingsAsync(dispatch);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current?.blur();
    }
    if (event.key === "Escape") {
      inputRef.current?.blur();
    }
  };

  return (
    <Box
      sx={{
        height: "28px",
        display: "flex",
        alignItems: "center",
        borderRadius: "12px",
        padding: "0px 6px 0px 8px",
        outlineOffset: "-1px",
        width: "auto",
        outline: isFocused ? "1px solid #4261FF" : "none",
        ":hover": {
          outline: isFocused ? "1px solid #4261FF" : "1px solid #D0D4D9",
        },
      }}
    >
      <Typography
        ref={typographyRef}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -100,
          whiteSpace: "pre",
        }}
      >
        {username}
      </Typography>
      <Input
        inputRef={inputRef}
        disableUnderline={true}
        type="text"
        onChange={handleInputChange}
        value={username}
        onFocus={handleInputFocus}
        onBlur={handleInputUnfocus}
        onKeyDown={handleKeyDown}
        sx={{
          ".MuiInput-input": {
            "&::selection": {
              backgroundColor: "#aeb4d4",
            },
            caretColor: "#4261FF",
            textOverflow: "ellipsis",
            color: "#454E63",
            width: inputWidth,
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export default Username;
