import { Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAnimationsDuration,
  getProjectDuration,
  getSequenceById,
  timeoutAsync,
} from "../../helpers/Helper";
import { getAIShotAsync } from "../../managers/AICreator";
import {
  deleteUserBlobAsync,
  getUserJsonAsync,
  postUserJsonAsync,
} from "../../managers/storage/AzureStorageManager";
import { saveBlobToIDBAsync } from "../../managers/storage/DbManager";
import { setGeneratingShot, setGeneratingText } from "../../store/aiSlice";
import {
  setSequenceShot,
  setSequenceDuration,
  getProject,
  setProjectAudioTrims,
} from "../../store/projectSlice";
import {
  setTimelinePlaying,
  setTimelineCaretTime,
  getTimelineSelectedItem,
} from "../../store/timelineSlice";
import { animationsFps } from "../../settings/GlobalSettings";
import { setSceneLoading } from "../../store/sceneSlice";
import {
  drainCreditAsync,
  getUserSettingsAsync,
} from "../../managers/ClientManager";
import {
  proTestNoCreditsString,
  demoStarterNoCreditsString,
} from "../../settings/GlobalStrings";
import { showCustomAlert } from "../../store/dialogSlice";
import {
  getUserLeftCredits,
  getUserSubscriptionLevel,
} from "../../store/userSlice";

const RegenerateShotButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const leftCredits = useAppSelector(getUserLeftCredits);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);

  const handleCreateAIShot = async () => {
    if (!leftCredits || leftCredits <= 0) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestNoCreditsString
            : demoStarterNoCreditsString
        )
      );
      return;
    }

    dispatch(setGeneratingShot(true));

    dispatch(setTimelinePlaying(false));
    dispatch(setGeneratingText("Generating Animation"));

    dispatch(setSceneLoading(true));

    drainCreditAsync(dispatch);

    const sequence = getSequenceById(project, selectedItem?.id!)!;

    if (sequence.shot?.accessType === "user") {
      deleteUserBlobAsync(sequence.shot.url);
    }

    const config = await getUserJsonAsync(
      `configs/${sequence.mainObject?.model.url.split(".")[0]}.json`
    );
    let relativeBox;

    if (config && config.relativeBox) relativeBox = config.relativeBox;
    else relativeBox = undefined;

    const { shot, duration } = await getAIShotAsync(relativeBox);

    if (project.audio) {
      const audioDuration =
        (getProjectDuration(project) -
          sequence.duration +
          getAnimationsDuration(shot.camera.animations)) /
        animationsFps;
      dispatch(
        setProjectAudioTrims([
          0,
          project.audio.duration - audioDuration >= 0
            ? project.audio.duration - audioDuration
            : 0,
        ])
      );
    }

    await postUserJsonAsync(shot, `shots/${shot.name}.json`);
    await saveBlobToIDBAsync(
      new Blob([JSON.stringify(shot)], { type: "application/json" }),
      `shots/${shot.name}.json`
    );
    dispatch(
      setSequenceShot({
        sequenceId: selectedItem?.id,
        model: {
          id: shot.name,
          name: shot.name,
          path: "shots",
          url: `shots/${shot.name}.json`,
          accessType: "user",
          properties: {},
        },
      })
    );
    dispatch(
      setSequenceDuration({ sequenceId: sequence.id, duration: duration })
    );
    let time = 0;
    for (let i = 0; i < project.sequences.length; i++) {
      if (project.sequences[i].id === sequence.id) {
        break;
      }
      time += project.sequences[i].duration;
    }
    dispatch(setTimelineCaretTime(time / 60));
    await timeoutAsync(300);
    dispatch(setGeneratingShot(false));
    dispatch(setSceneLoading(false));
  };

  return (
    <Button
      sx={{
        background: "#FFF",
        borderRadius: "12px",
        border: "2px solid #4261FF",
        padding: "10px 18px",
        height: "36px",
        width: "calc(100% - 32px)",
        ":hover": {
          background: "#FFF",
          boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
        },
        ":active": {
          background: "#FFF",
          boxShadow: "none",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      onClick={handleCreateAIShot}
    >
      <Typography
        sx={{
          color: "#3050F5",
        }}
      >
        Animation
      </Typography>
    </Button>
  );
};

export default RegenerateShotButton;
