import { Box } from "@mui/material";
import VideoTrack from "./threeD/VideoTrack";
import AudioTrack from "./audio/AudioTrack";
import DrawableScale from "./time/DrawableScale";
import { useEffect, useRef, useState } from "react";
import Caret from "./time/Caret";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getProject } from "../../../store/projectSlice";
import {
  getTimelineCaretTime,
  getTimelineScale,
  setTimelineMinRatio,
} from "../../../store/timelineSlice";
import { animationsFps } from "../../../settings/GlobalSettings";
import TextTrack from "./text/TextTrack";

type TracksViewProps = { engineContext: any };

function TracksView({ engineContext }: TracksViewProps) {
  const dispatch = useAppDispatch();
  const rootBoxRef = useRef<HTMLDivElement>();
  const scrollBoxRef = useRef<HTMLDivElement>();
  const [rootWidth, setRootWidth] = useState<number | undefined>();
  const project = useAppSelector(getProject);
  const [duration, setDuration] = useState<number | undefined>();
  const scale = useAppSelector(getTimelineScale);
  const caretTime = useAppSelector(getTimelineCaretTime);
  const caretTimeRef = useRef(caretTime);

  useEffect(() => {
    if (rootBoxRef.current) {
      new ResizeObserver(handleRootObserver).observe(rootBoxRef.current);
    }
  }, []);

  useEffect(() => {
    caretTimeRef.current = caretTime;
  }, [caretTime]);

  useEffect(() => {
    if (!rootWidth || project.sequences.length < 1) return;
    const totalDuration = project.sequences.reduce(
      (acc, s) => acc + s.duration / animationsFps,
      0
    );
    const minRatio = rootWidth / totalDuration;
    setDuration(totalDuration);
    dispatch(setTimelineMinRatio(minRatio));
  }, [rootWidth, project.sequences]);

  useEffect(() => {
    const caretPosition = caretTime * scale;
    const scrollableWidth = rootBoxRef.current!.clientWidth;
    const scrollAdjust = caretPosition - scrollableWidth / 2;
    scrollBoxRef.current!.scrollLeft = scrollAdjust;
  }, [scale]);

  const handleRootObserver = () => {
    if (rootBoxRef.current) setRootWidth(rootBoxRef.current.offsetWidth - 64);
  };

  return (
    <Box
      ref={rootBoxRef}
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        className="sequencer"
        ref={scrollBoxRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
          position: "relative",
        }}
      >
        <DrawableScale duration={duration} />
        <TextTrack duration={duration} />
        <div style={{ height: "2px" }} />
        <VideoTrack engineContext={engineContext} />
        <div style={{ height: "2px" }} />
        <AudioTrack duration={duration} />
        <Caret duration={duration} />
      </Box>
    </Box>
  );
}

export default TracksView;
