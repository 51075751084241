import { Box } from "@mui/material";
import Timer from "./header/Timer";
import Scaler from "./header/Scaler";
import TracksView from "./tracks/TracksView";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getTimelinePlaying,
  setTimelinePlaying,
} from "../../store/timelineSlice";
import Play from "./header/Play";
import { getAppMode, getTabMode } from "../../store/navigatorSlice";
import { appModes, tabModes } from "../../store/types";
import { getProject } from "../../store/projectSlice";
import { useEffect, useState } from "react";
import { getSceneLoading } from "../../store/sceneSlice";

type TimelineViewProps = {
  engineContext: any;
};

function TimelineView({ engineContext }: TimelineViewProps) {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const tabMode = useAppSelector(getTabMode);
  const appMode = useAppSelector(getAppMode);
  const sceneLoading = useAppSelector(getSceneLoading);
  const playing = useAppSelector(getTimelinePlaying);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === " " || event.code === "Space") {
      event.preventDefault();

      dispatch((dispatch, getState) => {
        const currentPlaying = getTimelinePlaying(getState());
        dispatch(setTimelinePlaying(!currentPlaying));
      });
    }
  };

  return (
    <Box
      sx={{
        visibility:
          tabMode === tabModes.home ||
          tabMode === tabModes.models ||
          tabMode === tabModes.videos ||
          appMode === appModes.scriberPreview ||
          appMode === appModes.videoPreview
            ? "hidden"
            : "visible",
        pointerEvents: tabMode === tabModes.developer ? "none" : "all",
        opacity: tabMode === tabModes.developer ? 0.3 : 1,
        display: appMode === appModes.render ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        height: "256px",
        paddingTop: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "64px",
          opacity: sceneLoading ? 0.75 : 1,
          transition: "opacity 0.3s ease-in-out",
          pointerEvents: sceneLoading ? "none" : "all",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "16px",
          }}
        >
          <Play
            playing={playing}
            onPlay={(v) => dispatch(setTimelinePlaying(v))}
          />
          <Timer />
        </Box>
        {/* <Scaler /> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          opacity: sceneLoading ? 0.04 : 1,
          transition: "opacity 0.3s ease-in-out",
          pointerEvents: sceneLoading ? "none" : "all",
        }}
      >
        <TracksView key={project.id} engineContext={engineContext} />
      </Box>
    </Box>
  );
}

export default TimelineView;
