import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { SceneState } from "./types";

const initialState: SceneState = {
  loading: 0,
  sceneLoading: 0,
  progress: 0,
  threeDView: false,
  canvasSize: { width: 0, height: 0 },
};

export const sceneSlice = createSlice({
  name: "scene",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload ? state.loading + 1 : state.loading - 1;
    },
    setSceneLoading: (state, action) => {
      state.sceneLoading = action.payload
        ? state.sceneLoading + 1
        : state.sceneLoading - 1;
    },
    setAnimatableName: (state, action) => {
      state.selectedAnimatableName = action.payload;
    },
    setScene3DView: (state, action) => {
      state.threeDView = action.payload;
    },
    setSceneCanvasSize: (state, action) => {
      state.canvasSize = action.payload;
    },
  },
});

export const getLoaderState = (state: RootState) => state.scene.loading !== 0;
export const getSceneLoading = (state: RootState) =>
  state.scene.sceneLoading !== 0;
export const getRenderProgress = (state: RootState) => state.scene.progress;
export const getAnimatableName = (state: RootState) =>
  state.scene.selectedAnimatableName;
export const getScene3DView = (state: RootState) => state.scene.threeDView;
export const getSceneCanvasSize = (state: RootState) => state.scene.canvasSize;

export const {
  setLoading,
  setSceneLoading,
  setProgress,
  setAnimatableName,
  setScene3DView,
  setSceneCanvasSize,
} = sceneSlice.actions;

export default sceneSlice.reducer;
