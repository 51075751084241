import { Box, Button, Typography } from "@mui/material";
import { useAppSelector } from "../store/hooks";
import { getProject } from "../store/projectSlice";
import { getAppMode, getTabMode } from "../store/navigatorSlice";
import { appModes, tabModes } from "../store/types";
import RenderPanel from "./scene/render/RenderPanel";
import UserIcon from "./controls/UserIcon";
import SceneHeader from "./scene/header/SceneHeader";
import ProjectRename from "./controls/ProjectRename";
import UpgradeToProButton from "./controls/UpgradeToProButton";
import {
  getUserSubscriptionId,
  getUserSubscriptionLevel,
} from "../store/userSlice";
import PlayerHeader from "./player/PlayerHeader";
import SceneSwitch from "./scene/header/SceneSwitch";
import { capitalizeFirstLetter } from "../helpers/Helper";
import { getSceneLoading } from "../store/sceneSlice";
import CreditsView from "./controls/CreditsView";
import AppleIcon from "@mui/icons-material/Apple";

const Header = () => {
  const project = useAppSelector(getProject);
  const tabMode = useAppSelector(getTabMode);
  const appMode = useAppSelector(getAppMode);
  const sceneLoading = useAppSelector(getSceneLoading);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const subscriptionId = useAppSelector(getUserSubscriptionId);

  return (
    <Box
      sx={{
        pointerEvents:
          tabMode === tabModes.developer || sceneLoading ? "none" : "all",
        opacity: tabMode === tabModes.developer || sceneLoading ? 0.75 : 1,
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        minHeight: "64px",
        maxHeight: "64px",
        zIndex: 10,
        backgroundColor: "#F1F3F4",
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
        }}
      >
        {(tabMode === tabModes.home ||
          tabMode === tabModes.models ||
          tabMode === tabModes.videos) && (
          <Typography
            sx={{
              fontSize: "18px",
            }}
          >
            Lumiere3D{" "}
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {subscriptionLevel !== "demo"
                ? capitalizeFirstLetter(subscriptionLevel)
                : ""}
            </span>
          </Typography>
        )}
        {appMode === appModes.sequencer && project.name && <ProjectRename />}
        {tabMode !== tabModes.home &&
          tabMode !== tabModes.models &&
          tabMode !== tabModes.videos &&
          appMode === appModes.sequencer &&
          project.id && <SceneHeader />}
      </div>
      {project.name && appMode !== appModes.render && <SceneSwitch />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          marginRight: "16px",
          gap: "16px",
          alignItems: "center",
          width: "100%",
        }}
      >
        {tabMode === tabModes.home && (
          <Button
            href="https://apps.apple.com/us/app/lumiere3d/id6468220159?itsct=apps_box_badge&amp;itscg=30200"
            target="_blank"
            style={{
              marginLeft: "16px",
              width: "132px",
              height: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFF",
              borderRadius: "16px",
              gap: "4px",
            }}
          >
            <AppleIcon
              sx={{
                fontSize: "24px",
                marginBottom: "4px",
              }}
            />
            <Typography>App Store</Typography>
          </Button>
        )}
        {appMode !== appModes.videoPreview &&
          subscriptionLevel !== "pro" &&
          appMode !== appModes.render &&
          subscriptionId !== "apple-subscription" &&
          subscriptionId !== "apple-subscription-cancelled" && (
            <UpgradeToProButton text="Upgrade" />
          )}
        {tabMode !== tabModes.home &&
          tabMode !== tabModes.models &&
          tabMode !== tabModes.videos && (
            <>
              <RenderPanel />
              {appMode === appModes.videoPreview && <PlayerHeader />}
              {/* {appMode !== appModes.render && project.renderUrl && (
                <EditSceneToggle />
              )} */}
            </>
          )}

        {appMode !== appModes.render && (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <CreditsView />
            <UserIcon />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Header;
