import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { scriberConfig } from "../../../scene/types";
import {
  getUserJsonAsync,
  getUserPreviewAsync,
} from "../../../../managers/storage/AzureStorageManager";
import { StoreItemModel } from "../../../../store/types";
import { useAppSelector } from "../../../../store/hooks";
import { formatTimestamp, timeoutAsync } from "../../../../helpers/Helper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { generateModelPreviewAsync } from "../../../../managers/preview/PreviewManager";

interface ScriberItemProps {
  configStore: StoreItemModel;
  item: StoreItemModel;
  onSelectHandle: (model: StoreItemModel) => void;
  onMenu: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    scriber: scriberConfig
  ) => void;
}

const ScriberItem = ({
  configStore,
  item,
  onSelectHandle,
  onMenu,
}: ScriberItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [config, setConfig] = useState<scriberConfig>();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const runAsync = async (signal: AbortSignal) => {
      if (signal.aborted) return;
      const config = await getUserJsonAsync(configStore.url);
      if (!config) return;
      setConfig(config);
      if (config.status !== "done") {
        await timeoutAsync(10_000);
        console.log("retrying", config);
        runAsync(signal);
      }
    };

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    runAsync(abortControllerRef.current.signal);

    return () => abortControllerRef.current.abort();
  }, [configStore]);

  useEffect(() => {
    if (config?.status === "done") {
      const getModelPreviewsAsync = async () => {
        const modelUrl = `scribers/${config.id}.glb`;
        const preview = await getUserPreviewAsync(modelUrl);
        if (!preview || preview.size < 1000) {
          setPreviewUrl(undefined);
          const blob = await generateModelPreviewAsync(modelUrl);
          if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            setPreviewUrl(blobUrl);
          }
        } else {
          const blobUrl = URL.createObjectURL(preview);
          setPreviewUrl(blobUrl);
        }
      };
      getModelPreviewsAsync();
    }
  }, [config?.status]);

  return (
    <Grid
      item
      style={{
        minWidth: "244px",
        minHeight: "176px",
        width: "244px",
        height: "176px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {config && (
        <div>
          {config.status === "queue" && (
            <div
              style={{
                width: "244px",
                height: "144px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
              <Typography>In queue...</Typography>
            </div>
          )}
          {config.status === "processing" && (
            <div
              style={{
                width: "244px",
                height: "144px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
              <Typography>Processing...</Typography>
            </div>
          )}
          {config.status === "done" && (
            <div>
              <div
                className="gradient"
                style={{
                  width: "244px",
                  height: "144px",
                  borderRadius: "12px",
                  position: "absolute",
                  left: 0,
                  top: 0,
                }}
              ></div>
              <Button
                style={{
                  width: "244px",
                  height: "144px",
                  borderRadius: "12px",
                  opacity: previewUrl ? 1 : 0,
                  transition: "opacity 0.3s ease-in-out",
                }}
                onClick={() => onSelectHandle(item)}
              >
                <img src={previewUrl} width="244px" height="144px" />
              </Button>
            </div>
          )}
          {config.status === "error" && (
            <div
              style={{
                width: "244px",
                height: "144px",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <ErrorOutlineIcon fontSize="large" />
                {config.errorMsg && (
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "14px",
                      margin: "0px 8px",
                      marginTop: "4px",
                    }}
                  >
                    {config.errorMsg}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          width: "244px",
          height: "144px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          opacity: isHovered ? 0.3 : 0.1,
          transition: "opacity 300ms",
          pointerEvents: "none",
        }}
      />
      {config && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px",
          }}
        >
          <img src="assets/svg/TimeIcon.svg" />
          <Typography
            style={{
              color: "#373E4E",
              maxWidth: "148px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontVariantNumeric: "lining-nums tabular-nums",
              fontFeatureSettings: '"clig" off, "liga" off',
              fontFamily: "Archivo",
              fontSize: "12px",
              fontWeight: 800,
              lineHeight: "24px",
              marginLeft: "2px",
            }}
          >
            {formatTimestamp(config?.date ?? 0)[0]}
          </Typography>
          <img
            src="assets/svg/CalendarIcon.svg"
            style={{ marginLeft: "12px" }}
          />
          <Typography
            style={{
              color: "#373E4E",
              maxWidth: "148px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontFamily: "Archivo",
              fontSize: "12px",
              fontWeight: 800,
              lineHeight: "24px",
              marginLeft: "2px",
            }}
          >
            {formatTimestamp(config?.date ?? 0)[1]}
          </Typography>
        </div>
      )}
      {config && (
        <div
          style={{
            position: "absolute",
            left: "8px",
            top: "8px",
            opacity: isHovered ? 1 : 0,
            transition: "opacity 300ms",
            pointerEvents: "none",
          }}
        >
          <img
            src="assets/svg/ScanIconWhite.svg"
            width={"32px"}
            height={"32px"}
          />
        </div>
      )}
      {!config && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-32px",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          right: "8px",
          top: 0,
        }}
      >
        {(config?.status === "done" || config?.status === "error") && (
          <IconButton
            onClick={(e) => onMenu(e, config)}
            sx={{
              transition: "opacity 300ms",
              marginTop: "8px",
              padding: "0px 0px",
              opacity: isHovered ? 1 : 0,
            }}
          >
            <img src="assets/svg/MoreIcon.svg" width="20px" height="20px" />
          </IconButton>
        )}
      </div>
    </Grid>
  );
};

export default ScriberItem;
