import { Typography, Button } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { showEarlyAccessDialog } from "../../store/dialogSlice";
import GradientTypography from "./GradientTypography";
import GradientCrown from "./GradientCrown";

type UpgradeToProButtonProps = {
  text: string;
};

const UpgradeToProButton = ({ text }: UpgradeToProButtonProps) => {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        height: "36px",
        width: "124px",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
        className="magicButton"
      />
      <Button
        variant="outlined"
        sx={{
          position: "absolute",
          top: "2px",
          left: "2px",
          height: "32px",
          width: "calc(100% - 4px)",
          borderRadius: "14px",
          borderWidth: 0,
          backgroundColor: "#FFFD",
          gap: "8px",
          ":hover": {
            borderWidth: 0,
            backgroundColor: "#FFFE",
          },
          ":active": {
            borderWidth: 0,
            backgroundColor: "#FFFE",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
        onClick={async () => {
          dispatch(showEarlyAccessDialog());
        }}
      >
        <GradientCrown size="16px" />
        <GradientTypography fontSize="14" text={text} />
      </Button>
    </div>
  );
};

export default UpgradeToProButton;
