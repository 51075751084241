import { ReactNode, useEffect, useState } from "react";
import { TextAnimationTemplate, iTextTrack } from "../../../store/types";

type InOutPreviewAnimatorProps = {
  template: TextAnimationTemplate;
  children: ReactNode;
  playing: boolean;
};

const InOutPreviewAnimator = ({
  children,
  template,
  playing,
}: InOutPreviewAnimatorProps) => {
  return (
    <>
      {playing && (
        <div
          style={{
            animationName: template.inStyleName,
            animationDuration: `1s`,
            animationPlayState: "running",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              animationName: template.outStyleName,
              animationDuration: `1s`,
              animationPlayState: "running",
              animationDelay: `2s`,
              overflow: "hidden",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      {!playing && children}
    </>
  );
};

export default InOutPreviewAnimator;
