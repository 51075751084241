import {
  Dialog,
  DialogContent,
  Box,
  List,
  ListItem,
  Typography,
  Button,
  IconButton,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getEarlyAccessDialogOpen,
  hideEarlyAccessDialog,
} from "../../store/dialogSlice";
import { useEffect, useState } from "react";
import {
  getUserSubscriptionId,
  getUserSubscriptionLevel,
} from "../../store/userSlice";
import { handleAddAndCheckout } from "../../managers/FastSpring";
import GradientTypography from "../controls/GradientTypography";
import GradientCrown from "../controls/GradientCrown";
import { getSubscriptionPlans } from "../../store/subscriptionSlice";

interface listItem {
  text: string;
  imageUrl: string;
}

const SubscriptionDialog = () => {
  const dispatch = useAppDispatch();
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const subscriptionId = useAppSelector(getUserSubscriptionId);
  const dialogOpen = useAppSelector(getEarlyAccessDialogOpen);
  const plans = useAppSelector(getSubscriptionPlans);
  const [demolimits, setDemoLimits] = useState<listItem[]>([]);
  const [starterlimits, setStarterLimits] = useState<listItem[]>([]);
  const [prolimits, setProLimits] = useState<listItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [starterYearly, setStarterYearly] = useState(false);
  const [proYearly, setProYearly] = useState(false);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (subscriptionLevel === "pro") handleCloseDialog();
  }, [subscriptionLevel]);

  useEffect(() => {
    if (dialogOpen && plans) {
      setDemoLimits([
        {
          text: `Scriber`,
          imageUrl: "",
        },
        {
          text: `${plans.demo.scriberProcess.count} Scribers per day`,
          imageUrl: "assets/images/AppleIcon.png",
        },
        {
          text: `${plans.demo.scriberPhotos.count} Photos per project`,
          imageUrl: "assets/images/PhotoStackIcon.png",
        },
        {
          text: "No Models downloading",
          imageUrl: "assets/images/DownloadIconBlue.png",
        },
        {
          text: "No Priority processing",
          imageUrl: "assets/images/RocketIcon.png",
        },
        {
          text: `Video Editor`,
          imageUrl: "",
        },
        {
          text: `${plans.demo.credits.count} Creative Credits per day`,
          imageUrl: "assets/images/CreditCardIcon.png",
        },
        {
          text: `${plans.demo.videoSettings.resolution}p Render resolution`,
          imageUrl: "assets/images/UnfullHorizontalPortraitIcon.png",
        },
        {
          text: `${plans.demo.videoSettings.videoFps} Frames per second`,
          imageUrl: "assets/images/CountdownIcon.png",
        },
        {
          text: `${plans.demo.videoDownloads.count}* Video downloads per day`,
          imageUrl: "assets/images/FilmRollIcon.png",
        },
        {
          text: `Other`,
          imageUrl: "",
        },
        {
          text: `${plans.demo.storageGb}GB Storage`,
          imageUrl: "assets/images/UnfullDatabaseIcon.png",
        },
      ]);
      setStarterLimits([
        {
          text: `Scriber`,
          imageUrl: "",
        },
        {
          text: `${plans.starter.scriberProcess.count} Scribers per day`,
          imageUrl: "assets/images/AppleIcon.png",
        },
        {
          text: `${plans.starter.scriberPhotos.count} Photos per project`,
          imageUrl: "assets/images/PhotoStackIcon.png",
        },
        {
          text: "Download models",
          imageUrl: "assets/images/DownloadIconBlue.png",
        },
        {
          text: "Priority processing",
          imageUrl: "assets/images/RocketIcon.png",
        },
        {
          text: `Video Editor`,
          imageUrl: "",
        },
        {
          text: `${plans.starter.credits.count} Creative Credits per day`,
          imageUrl: "assets/images/CreditCardIcon.png",
        },
        {
          text: `${plans.starter.videoSettings.resolution}p Render resolution`,
          imageUrl: "assets/images/HorizontalPortraitIcon.png",
        },
        {
          text: `${plans.starter.videoSettings.videoFps} Frames per second`,
          imageUrl: "assets/images/CountdownIcon.png",
        },
        {
          text: `${plans.starter.videoDownloads.count} Video downloads per day`,
          imageUrl: "assets/images/FilmRollIcon.png",
        },
        {
          text: `Other`,
          imageUrl: "",
        },
        {
          text: `${plans.starter.storageGb}GB Storage`,
          imageUrl: "assets/images/MidDatabaseIcon.png",
        },
      ]);
      setProLimits([
        {
          text: `Scriber`,
          imageUrl: "",
        },
        {
          text: `${plans.pro.scriberProcess.count} Scribers per day`,
          imageUrl: "assets/images/AppleIcon.png",
        },
        {
          text: `${plans.pro.scriberPhotos.count} Photos per project`,
          imageUrl: "assets/images/PhotoStackIcon.png",
        },
        {
          text: "Download models",
          imageUrl: "assets/images/DownloadIconBlue.png",
        },
        {
          text: "Highest Priority processing",
          imageUrl: "assets/images/RocketIcon.png",
        },
        {
          text: `Video Editor`,
          imageUrl: "",
        },
        {
          text: `${plans.pro.credits.count} Creative Credits per day`,
          imageUrl: "assets/images/CreditCardIcon.png",
        },
        {
          text: `${plans.pro.videoSettings.resolution}p Render resolution`,
          imageUrl: "assets/images/HorizontalPortraitIcon.png",
        },
        {
          text: `${plans.pro.videoSettings.videoFps} Frames per second`,
          imageUrl: "assets/images/CountdownIcon.png",
        },
        {
          text: `Unlimited Video downloads`,
          imageUrl: "assets/images/FilmRollIcon.png",
        },
        {
          text: `Other`,
          imageUrl: "",
        },
        {
          text: `${plans.pro.storageGb}GB Storage`,
          imageUrl: "assets/images/DatabaseIcon.png",
        },
      ]);
    }
  }, [dialogOpen]);

  const handleCloseDialog = () => {
    dispatch(hideEarlyAccessDialog());
  };

  const handleGetStarterPlan = () => {
    if (starterYearly) handleAddAndCheckout(dispatch, "starter-yearly");
    else handleAddAndCheckout(dispatch, "starter");
  };

  const handleGetProPlan = () => {
    if (proYearly) handleAddAndCheckout(dispatch, "pro-yearly");
    else handleAddAndCheckout(dispatch, "pro");
  };

  const infoIcon = (
    <img
      src="assets/svg/InfoIconGray.svg"
      width="20px"
      height="20px"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    />
  );

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      maxWidth="xl"
      sx={{
        overflow: "visible",
        ".MuiDialog-paper": {
          backgroundColor: "#F1F3F4",
          borderRadius: "16px",
          overflow: "visible",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            gap: "8px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                position: "relative",
                backgroundColor: "#FFF",
                minWidth: "280px",
                maxWidth: "320px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                borderRadius: "16px",
                outlineOffset: "-1px",
                outline: "1px solid rgba(69, 78, 99, 0.20)",
              }}
            >
              <div
                style={{ position: "absolute", bottom: "4px", left: "12px" }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  * - videos with watermark
                </Typography>
              </div>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "20px", lineHeight: "24px" }}>
                  Demo
                </Typography>
                {subscriptionLevel === "demo" && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#4261FF",
                    }}
                  >
                    Current Plan
                  </Typography>
                )}
              </Box>

              <Typography
                sx={{ fontSize: "40px", lineHeight: "44px", marginTop: "4px" }}
              >
                Free
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "rgba(69, 78, 99, 0.70)",
                  lineHeight: "24px",
                  marginTop: "13.5px",
                }}
              >
                Touch 3D video world
              </Typography>
              <List
                sx={{
                  marginTop: "26px",
                  padding: "0px 0px",
                }}
              >
                {demolimits.map((item) => (
                  <ListItem
                    key={item.text}
                    sx={{
                      padding: "0px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      {item.imageUrl === "" && item.text === "" && (
                        <div style={{ width: "24px", height: "24px" }}></div>
                      )}

                      {item.imageUrl !== "" && (
                        <img src={item.imageUrl} width="24px" height="24px" />
                      )}
                      <Typography
                        sx={{ fontWeight: item.imageUrl ? 600 : 800 }}
                      >
                        {item.text}
                      </Typography>
                      {item.text.includes("Credits") && infoIcon}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                backgroundColor: "#FFF",
                minWidth: "280px",
                maxWidth: "320px",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                borderRadius: "16px",
                outlineOffset: "-2px",
                outline: "2px solid #3050F5",
                height:
                  subscriptionLevel !== "starter" || !subscriptionId
                    ? "524px"
                    : "580px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "24px",
                    }}
                  >
                    Starter
                  </Typography>
                </div>
                {subscriptionLevel === "starter" ? (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#4261FF",
                    }}
                  >
                    Current Plan
                  </Typography>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#FFF",
                      display: "flex",
                      justifyContent: "center",
                      border: "1px solid #FFF",
                      borderRadius: "12px",
                      height: "24px",
                      gap: "1px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setStarterYearly(false);
                      }}
                      sx={{
                        backgroundColor: starterYearly ? "#FFF" : "#EAEDFE",
                        borderRadius: "12px",
                        ":hover": {
                          backgroundColor: starterYearly ? "" : "#EAEDFE",
                        },
                        "& .MuiTouchRipple-root": {
                          display: "none",
                        },
                      }}
                    >
                      Monthly
                    </Button>
                    <Button
                      onClick={() => {
                        setStarterYearly(true);
                      }}
                      sx={{
                        backgroundColor: !starterYearly ? "#FFF" : "#EAEDFE",
                        borderRadius: "12px",
                        ":hover": {
                          backgroundColor: !starterYearly ? "" : "#EAEDFE",
                        },
                        "& .MuiTouchRipple-root": {
                          display: "none",
                        },
                      }}
                    >
                      Yearly
                    </Button>
                  </div>
                )}
              </Box>
              <Typography
                sx={{ fontSize: "52px", lineHeight: "44px", marginTop: "4px" }}
              >
                {starterYearly ? (
                  <span>
                    <s style={{ fontSize: "32px", fontWeight: 600 }}>$144</s>
                    <span style={{ fontSize: "32px" }}>&nbsp;&nbsp;$</span>
                    119
                  </span>
                ) : (
                  <span>
                    <span style={{ fontSize: "32px" }}>$</span>11,99
                  </span>
                )}
                <span style={{ fontSize: "16px", fontWeight: 900 }}>
                  {" "}
                  per {starterYearly ? "year" : "month"}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "rgba(69, 78, 99, 0.70)",
                  lineHeight: "24px",
                }}
              >
                Pay every {starterYearly ? "year" : "month"} to access its cool
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "rgba(69, 78, 99, 0.70)",
                  lineHeight: "24px",
                }}
              >
                features
              </Typography>
              <List
                sx={{
                  padding: "0px 0px",
                  marginTop: "2px",
                }}
              >
                {starterlimits.map((item) => (
                  <ListItem
                    key={item.text}
                    sx={{
                      padding: "0px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      {item.imageUrl !== "" && (
                        <img src={item.imageUrl} width="24px" height="24px" />
                      )}
                      <Typography
                        sx={{ fontWeight: item.imageUrl ? 600 : 800 }}
                      >
                        {item.text}
                      </Typography>
                      {item.text.includes("Credits") && infoIcon}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            {(subscriptionLevel !== "starter" || !subscriptionId) && (
              <Button
                onClick={handleGetStarterPlan}
                sx={{
                  background: "#FFF",
                  borderRadius: "16px",
                  height: "48px",
                  gap: "8px",
                  marginTop: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #3050F5",

                  ":hover": {
                    background: "#FFF",
                    boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                  },
                  ":active": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
              >
                <img
                  src="assets/images/TransparentCrown.png"
                  width="20px"
                  height="18px"
                  style={{
                    marginBottom: "4px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                  }}
                >
                  Get Starter Subscription
                </Typography>
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div
              className="magicButton"
              style={{
                minWidth: "320px",
                maxWidth: "320px",
                height: "564px",
                borderWidth: "16px",
              }}
            ></div>
            <Box
              sx={{
                position: "absolute",
                top: "2px",
                left: "2px",
                backgroundColor: "#FFFD",
                width: "276px",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                borderRadius: "14px",
                height: "520px",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "24px",
                    }}
                  >
                    Pro
                  </Typography>
                </div>
                {subscriptionLevel === "pro" ? (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#4261FF",
                    }}
                  >
                    Current Plan
                  </Typography>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "12px",
                      height: "24px",
                      gap: "1px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setProYearly(false);
                      }}
                      sx={{
                        backgroundColor: proYearly ? "" : "#FFF",
                        borderRadius: "12px",
                        ":hover": {
                          backgroundColor: proYearly ? "" : "#FFF",
                        },
                        "& .MuiTouchRipple-root": {
                          display: "none",
                        },
                      }}
                    >
                      Monthly
                    </Button>
                    <Button
                      onClick={() => {
                        setProYearly(true);
                      }}
                      sx={{
                        backgroundColor: !proYearly ? "" : "#FFF",
                        borderRadius: "12px",
                        ":hover": {
                          backgroundColor: !proYearly ? "" : "#FFF",
                        },
                        "& .MuiTouchRipple-root": {
                          display: "none",
                        },
                      }}
                    >
                      Yearly
                    </Button>
                  </div>
                )}
              </Box>
              <Typography
                sx={{ fontSize: "52px", lineHeight: "44px", marginTop: "4px" }}
              >
                {proYearly ? (
                  <span>
                    <s style={{ fontSize: "32px", fontWeight: 600 }}>$300</s>
                    <span style={{ fontSize: "32px" }}>&nbsp;&nbsp;$</span>
                    229
                  </span>
                ) : (
                  <span>
                    <span style={{ fontSize: "32px" }}>$</span>24,99
                  </span>
                )}
                <span style={{ fontSize: "16px", fontWeight: 900 }}>
                  {" "}
                  per {proYearly ? "year" : "month"}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "rgba(69, 78, 99, 0.70)",
                  lineHeight: "24px",
                }}
              >
                Pay every {proYearly ? "year" : "month"} to access its cool
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "rgba(69, 78, 99, 0.70)",
                  lineHeight: "24px",
                }}
              >
                features
              </Typography>
              <List
                sx={{
                  padding: "0px 0px",
                }}
              >
                {prolimits.map((item) => (
                  <ListItem
                    key={item.text}
                    sx={{
                      padding: "0px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        marginTop: "8px",
                      }}
                    >
                      {item.imageUrl !== "" && (
                        <img src={item.imageUrl} width="24px" height="24px" />
                      )}
                      <Typography
                        sx={{ fontWeight: item.imageUrl ? 600 : 800 }}
                      >
                        {item.text}
                      </Typography>
                      {item.text.includes("Credits") && infoIcon}
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
            <div style={{ position: "relative", marginTop: "8px" }}>
              <Box
                sx={{
                  height: "48px",
                }}
                className="magicButton"
              />
              <Button
                data-track="CreateAIProjectClicked"
                variant="outlined"
                sx={{
                  position: "absolute",
                  top: "2px",
                  left: "2px",
                  height: "44px",
                  width: "calc(100% - 4px)",
                  borderRadius: "14px",
                  borderWidth: 0,
                  backgroundColor: "#FFFD",
                  gap: "8px",
                  ":hover": {
                    borderWidth: 0,
                    backgroundColor: "#FFFE",
                  },
                  ":active": {
                    borderWidth: 0,
                    backgroundColor: "#FFFE",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
                onClick={handleGetProPlan}
              >
                <GradientCrown size="24px" />
                <GradientTypography
                  fontSize="18px"
                  text="Get Pro Subscription"
                />
              </Button>
            </div>
          </Box>
        </Box>

        <Popover
          sx={{
            pointerEvents: "none",
            marginTop: "8px",
            ".MuiPopover-paper": {
              borderRadius: "12px",
            },
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div
            style={{
              width: "280px",
              justifyContent: "center",
              padding: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" style={{ fontSize: "14px" }}>
              Creative Credits are used for video creation as well as
              background, animation and music generation powered by our AI.
            </Typography>
          </div>
        </Popover>

        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            top: "0px",
            right: "-42px",
            background: "#F1F3F4",
            ":hover": {
              background: "#F1F3F4",
              boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionDialog;
