import { Typography, IconButton, Button } from "@mui/material";
import { useState, useEffect } from "react";
import {
  getJsonAsync,
  getPreviewWebpAsync,
} from "../../../managers/storage/StorageManager";
import { StoreItemModel } from "../../../store/types";
import { formatTimestamp } from "../../../helpers/Helper";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";

interface ProjectItemProps {
  item: StoreItemModel;
  onSelectHandle: () => void;
  onMenu: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const VideosItem = ({ item, onSelectHandle, onMenu }: ProjectItemProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [hasRender, setHasRender] = useState(false);

  useEffect(() => {
    const asyncWrapper = async () => {
      const json = await getJsonAsync(item);
      const preview = await getPreviewWebpAsync(item);
      if (preview) setPreviewUrl(preview);
      setHasRender(json.renderUrl);
    };
    asyncWrapper();
  }, [item.url]);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          className="gradient"
          style={{
            width: "244px",
            height: "144px",
            borderRadius: "12px",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        ></div>
        <Button
          data-track="ProjectClicked"
          style={{
            width: "244px",
            height: "144px",
            borderRadius: "12px",
            position: "relative",
            padding: 0,
            overflow: "hidden",
            opacity: previewUrl ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
          onClick={() => onSelectHandle()}
        >
          {previewUrl && (
            <img
              src={previewUrl}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </Button>
        <Typography
          style={{
            color: "#373E4E",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "24px",
            marginLeft: "2px",
            marginTop: "8px",
          }}
        >
          {item.name}
        </Typography>
      </div>
      <div
        style={{
          transition: "opacity 300ms",
          opacity: isHovered ? 0.3 : 0,
          width: "244px",
          height: "144px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          transition: "opacity 300ms",
          opacity: isHovered ? 1 : 0,
          position: "absolute",
          top: "8px",
          left: "12px",
          display: "flex",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <img src="assets/svg/TimeIconWhite.svg" />
        <Typography
          style={{
            color: "#FFF",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontVariantNumeric: "lining-nums tabular-nums",
            fontFeatureSettings: '"clig" off, "liga" off',
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties?.lastModified)[0]}
        </Typography>
        <img
          src="assets/svg/CalendarIconWhite.svg"
          style={{ marginLeft: "12px" }}
        />
        <Typography
          style={{
            color: "#FFF",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties?.lastModified)[1]}
        </Typography>
      </div>

      <div
        style={{
          position: "absolute",
          right: "8px",
          top: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <IconButton
          onClick={(e) => onMenu(e)}
          style={{
            transition: "opacity 300ms",
            padding: "0px 0px",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <img src="assets/svg/MoreIcon.svg" width="20px" height="20px" />
        </IconButton>
      </div>
      <div
        style={{
          position: "absolute",
          left: "10px",
          bottom: "38px",
          display: hasRender ? "flex" : "none",
          flexDirection: "column",
          gap: "4px",
          pointerEvents: "none",
        }}
      >
        <OndemandVideoRoundedIcon fontSize="large" style={{ color: "white" }} />
      </div>
    </div>
  );
};

export default VideosItem;
