import {
  deleteUserBlobAsync,
  getSharedBlobAsync,
  getUserBlobAsync,
  postUserJsonAsync,
} from "../managers/storage/AzureStorageManager";
import {
  Project,
  StorageData,
  StoreItemModel,
  sortingMethods,
} from "../store/types";
import {
  deleteBlobFromIDBAsync,
  existBlobInIDBAsync,
  saveBlobToIDBAsync,
} from "../managers/storage/DbManager";
import {
  increaseTotalProgress,
  resetProgress,
  setProgressText,
} from "../store/progressSlice";
import { setLoading } from "../store/sceneSlice";
import { getJsonAsync } from "../managers/storage/StorageManager";

export function timeoutAsync(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getIntWithZeros(int: number) {
  return int.toLocaleString("en-US", {
    minimumIntegerDigits: 4,
    useGrouping: false,
  });
}

export function formatBytes(a: number, b = 0) {
  if (!a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
    ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}

export function sortStoreItemModels(
  models: StoreItemModel[],
  sortingMethod: sortingMethods
): StoreItemModel[] {
  const naturalCompare = (a: string, b: string): number => {
    const chunkRegExp = /(\d+|\D+)/g;

    const getChunks = (str: string): (number | string)[] => {
      const chunks = str.match(chunkRegExp);
      return chunks
        ? chunks.map((chunk) => (isNaN(Number(chunk)) ? chunk : Number(chunk)))
        : [];
    };

    const chunksA = getChunks(a);
    const chunksB = getChunks(b);

    const minLength = Math.min(chunksA.length, chunksB.length);

    for (let i = 0; i < minLength; i++) {
      const comparison =
        chunksA[i] < chunksB[i] ? -1 : chunksA[i] > chunksB[i] ? 1 : 0;
      if (comparison !== 0) {
        return comparison;
      }
    }

    return chunksA.length - chunksB.length;
  };

  switch (sortingMethod) {
    case sortingMethods.byName:
      models = models.sort((a, b) =>
        naturalCompare(a.name.toLowerCase(), b.name.toLowerCase())
      );
      break;
    case sortingMethods.byDate:
      models = models.sort((a, b) => {
        const dateA = new Date(a.properties.createdOn).getTime();
        const dateB = new Date(b.properties.createdOn).getTime();
        return dateB - dateA;
      });
      break;
    case sortingMethods.bySize:
      models = models.sort(
        (a, b) => b.properties.contentLength - a.properties.contentLength
      );
      break;
  }
  return models;
}

export function getProjectDuration(project: Project) {
  return project.sequences
    .map((s) => s.duration - s.trims[0] - s.trims[1])
    .reduce((partialSum, a) => partialSum + a, 0);
}

export function getAnimationsDuration(animations: any) {
  return Math.max(
    ...animations.flatMap((a: any) => a.keys.map((k: any) => k.frame)),
    0
  );
}

export async function saveProjectAsync(project: Project) {
  if (!project) return;
  const sourceString = JSON.stringify(project);
  const deepClone = JSON.parse(sourceString) as Project;
  const url = "projects/" + project.name + ".json";
  await postUserJsonAsync(deepClone, url);
}

export function getStorageItemFromSharedStorageData(
  storageData: any
): StoreItemModel[] {
  if (!storageData) {
    return [];
  }
  const files = storageData.files.map(
    (f: any) =>
      ({
        id: f.name,
        name: getName(f.name),
        accessType: "shared",
        url: f.name,
        properties: f.properties,
      } as StoreItemModel)
  );
  return files;
}

export function getStorageItemFromStorageData(
  path: string,
  storageData: any
): StoreItemModel[] {
  if (!storageData) {
    return [];
  }

  if (!storageData.directories?.some((d: any) => d.path === path)) {
    return [];
  }

  const dir = storageData.directories.find((d: any) => d.path === path);
  const files = dir.files.map(
    (f: any) =>
      ({
        id: f.name,
        name: getName(f.name),
        accessType: "user",
        url: f.name,
        properties: f.properties,
      } as StoreItemModel)
  );
  return files;
}

export function getName(url: string) {
  const filename = url.split("/").pop();
  const nameWithoutExtension = filename?.split(".").slice(0, -1).join(".");
  return nameWithoutExtension;
}

export function getScenesFromStorageData(storageData: StorageData) {
  const dirs = storageData.data.directories.filter(
    (d: any) =>
      d.path.startsWith("scenes/") &&
      !d.path.includes("/skyboxes") &&
      !d.path.includes("/shots")
  );
  const m = dirs.map((d: any) =>
    getStorageItemFromStorageData(d.path, storageData)
  );
  const models: StoreItemModel[] = m.flatMap((m: any) => m);
  return models;
}

export function getTemplatesFromStorageData(storageData: StorageData) {
  const dirs = storageData.data.directories.filter((d: any) =>
    d.path.startsWith("templates/")
  );
  const m = dirs.map((d: any) =>
    getStorageItemFromStorageData(d.path, storageData)
  );
  const models: StoreItemModel[] = m.flatMap((m: any) => m);
  return models;
}

export async function preloadBlobsAsync(
  project: Project,
  dispatch: Function,
  progressCallback: (bytes: number) => void
) {
  let models = [];
  for (const sequence of project.sequences) {
    if (sequence.environment) {
      if (!(await existBlobInIDBAsync(sequence.environment.model.url))) {
        models.push(sequence.environment.model);
      }
    }
    if (sequence.mainObject) {
      if (!(await existBlobInIDBAsync(sequence.mainObject.model.url))) {
        models.push(sequence.mainObject.model);
      }
    }
    if (sequence.skybox) {
      if (!(await existBlobInIDBAsync(sequence.skybox.model.url))) {
        models.push(sequence.skybox.model);
      }
    }
  }
  if (project.audio) {
    if (!(await existBlobInIDBAsync(project.audio.source.url))) {
      models.push(project.audio.source);
    }
  }
  models = models.filter(
    (value, index, self) => index === self.findIndex((t) => t.url === value.url)
  );
  let totalBytes = 0;
  models.forEach((m) => {
    if (m.properties.contentLength) {
      totalBytes += m.properties.contentLength;
    }
  });
  if (totalBytes !== 0) {
    dispatch(resetProgress());
    dispatch(increaseTotalProgress(totalBytes));
    dispatch(setProgressText("Setting things up"));
    dispatch(setLoading(true));
  }

  const downloadPromises = models.map(
    (m) =>
      new Promise<void>(async (resolve) => {
        const blob =
          m.accessType === "shared"
            ? await getSharedBlobAsync(m.url, progressCallback)
            : await getUserBlobAsync(m.url, undefined, progressCallback);
        await saveBlobToIDBAsync(blob!, m.url);
        resolve();
      })
  );
  await Promise.all(downloadPromises);
  if (totalBytes !== 0) {
    dispatch(setLoading(false));
  }
}

export function getSequenceById(project: Project, id: string) {
  return project.sequences.find((s) => s.id === id);
}

export function getTextById(project: Project, id: string) {
  return project.texts?.find((s) => s.id === id);
}

export function getSubfoldersFromPath(path: string, storageData: StorageData) {
  return storageData.data.directories
    .filter((d: any) => d.path.startsWith(path))
    .map((d: any) => d.path);
}

export function getCurrentTimestampUTC() {
  const now = new Date();
  const timestampUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds()
  );

  return timestampUTC;
}

export const getTimeAsString = (time: number) =>
  `${time.toFixed(1).toString().padStart(4, "0")}s`;

export const getRelativePositionX = (e: React.MouseEvent<HTMLDivElement>) => {
  let { clientX, currentTarget } = e;
  const { left } = currentTarget.getBoundingClientRect();
  if (clientX > currentTarget.clientWidth + left)
    clientX = currentTarget.clientWidth + left;
  return clientX - left < 0 ? 0 : clientX - left;
};

export function validateJSON(json: string) {
  try {
    JSON.parse(json);
    return true;
  } catch (error) {
    return false;
  }
}

export const formatAudioDuration = (durationInSeconds: number): string => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

export const formatTimestamp = (timestamp: number): [string, string] => {
  const date = new Date(timestamp);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = date
    .toLocaleTimeString("en-US", timeOptions)
    .replace(/\s/g, "");

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  };
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  return [formattedTime, formattedDate];
};

export const resizeImage = (
  blob: Blob,
  maxWidth: number,
  maxHeight: number,
  callback: any
) => {
  // Step 1: Read the Blob as Data URL
  var reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = function () {
    var base64data = reader.result;

    // Step 2: Create an Image Object
    var img = new Image();
    img.src = base64data as string;
    img.onload = function () {
      // Step 3: Calculate Aspect Ratio
      const originalWidth = img.width;
      const originalHeight = img.height;
      const aspectRatio = originalWidth / originalHeight;

      let newWidth = originalWidth;
      let newHeight = originalHeight;

      // If the image is wider than the desired width, scale it down
      if (originalWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = newWidth / aspectRatio;
      }

      // If the new height is still taller than the desired height, scale it down further
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * aspectRatio;
      }

      // Step 4: Draw the Image on Canvas
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      canvas.width = newWidth; // Set new width
      canvas.height = newHeight; // Set new height

      ctx!.drawImage(img, 0, 0, newWidth, newHeight);

      // Step 5: Extract the New Image
      canvas.toBlob(
        function (newBlob) {
          callback(newBlob);
        },
        "image/jpg",
        1
      ); // Set the format and quality here
    };
  };
};

export function normalizeValue(
  value: number,
  minMax: [number, number]
): number {
  const [min, max] = minMax;
  let normalized = (value - min) / (max - min);
  normalized = Math.max(0, Math.min(normalized, 1));

  return normalized;
}

export function getOriginalValue(
  interpolator: number,
  minMax: [number, number]
): number {
  const [min, max] = minMax;
  return interpolator * (max - min) + min;
}

export function capitalizeFirstLetter(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function replaceUnderscoresWithSpaces(str: string) {
  return str.replace("_", " ");
}

export async function handleDeleteProjectAsync(model: StoreItemModel) {
  const projectToDelete = (await getJsonAsync(model)) as Project;
  let deletionTasks = [];

  projectToDelete.sequences.forEach((s) => {
    if (s.shot) {
      deletionTasks.push(deleteUserBlobAsync(`shots/${s.shot.id}.json`));
      deletionTasks.push(deleteBlobFromIDBAsync(`shots/${s.shot.id}.json`));
    }
  });

  deletionTasks.push(deleteUserBlobAsync(model.url));
  deletionTasks.push(
    deleteUserBlobAsync(`previews/projects/${model.name}.json.webp`)
  );
  deletionTasks.push(deleteBlobFromIDBAsync(model.url));
  deletionTasks.push(
    deleteBlobFromIDBAsync(`previews/projects/${model.name}.json.webp`)
  );

  await Promise.all(deletionTasks);
}

export function divideTotalInRandomNumbers(
  totalSum: number,
  min: number,
  max: number
) {
  const numbers = [];
  let currentSum = 0;

  while (currentSum < totalSum) {
    let remaining = totalSum - currentSum;

    if (remaining <= max && remaining >= min) {
      numbers.push(remaining);
      currentSum += remaining;
    } else {
      let maxVal = Math.min(max, remaining - min);
      let randomNumber = Math.floor(Math.random() * (maxVal - min + 1)) + min;
      numbers.push(randomNumber);
      currentSum += randomNumber;
    }
  }

  return numbers;
}
