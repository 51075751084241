import Videos from "./Videos";

const VideosView = () => {
  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        position: "absolute",
        top: "64px",
        left: 0,
        right: "16px",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        borderRadius: "16px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "16px",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Videos />
      </div>
    </div>
  );
};

export default VideosView;
