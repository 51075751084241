import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {
  deleteUserBlobAsync,
  getUserJsonAsync,
  getUserPreviewAsync,
} from "../../../../managers/storage/AzureStorageManager";
import { generateModelPreviewAsync } from "../../../../managers/preview/PreviewManager";
import { Project, StoreItemModel } from "../../../../store/types";
import {
  formatTimestamp,
  getStorageItemFromStorageData,
  handleDeleteProjectAsync,
} from "../../../../helpers/Helper";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getUserStorageData } from "../../../../store/storageSlice";
import { deleteBlobFromIDBAsync } from "../../../../managers/storage/DbManager";
import { setSaving } from "../../../../store/navigatorSlice";

export interface ModelItemProps {
  item: StoreItemModel;
  onSelectHandle: (model: StoreItemModel) => void;
}

const ModelItem = ({ item, onSelectHandle }: ModelItemProps) => {
  const dispatch = useAppDispatch();
  const userStorageData = useAppSelector(getUserStorageData);
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [itemToDelete, setItemToDelete] = useState<StoreItemModel | null>(null);

  useEffect(() => {
    const getModelPreviewsAsync = async () => {
      const preview = await getUserPreviewAsync(item.url);
      if (!preview || preview.size < 1000) {
        setPreviewUrl(undefined);
        const blob = await generateModelPreviewAsync(item.url);
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);
          setPreviewUrl(blobUrl);
        }
      } else {
        const blobUrl = URL.createObjectURL(preview);
        setPreviewUrl(blobUrl);
      }
    };

    getModelPreviewsAsync();
  }, [item.url]);

  const handleShowDeleteDialog = () => {
    setItemToDelete(item);
  };

  const handleCloseDeleteDialog = () => {
    setItemToDelete(null);
  };

  const handleDelete = async () => {
    dispatch(setSaving(true));
    const projects = getStorageItemFromStorageData("projects", userStorageData);
    const projectConfigs = await Promise.all(
      projects.map((p) =>
        getUserJsonAsync(p.url).then((result) => result as Project)
      )
    );

    const projectsToDelete = projectConfigs.filter(
      (p) => p.sequences[0].mainObject?.model.id === item.id
    );

    let deletionTasks = [];

    if (projectsToDelete.length) {
      projectsToDelete.forEach((pC) => {
        const project = projects.find((p) => p.name === pC.name);
        if (project) {
          deletionTasks.push(handleDeleteProjectAsync(project));
        }
      });
    }

    deletionTasks.push(deleteUserBlobAsync(item.url));
    deletionTasks.push(deleteBlobFromIDBAsync(item.url));
    deletionTasks.push(
      deleteUserBlobAsync(`previews/models/${item.name}.glb.webp`)
    );
    deletionTasks.push(
      deleteBlobFromIDBAsync(`previews/models/${item.name}.glb.webp`)
    );

    await Promise.all(deletionTasks);

    dispatch(setSaving(false));
  };

  return (
    <Grid
      item
      sx={{
        position: "relative",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="gradient"
        style={{
          width: "244px",
          height: "144px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      ></div>

      <Button
        style={{
          width: "244px",
          height: "144px",
          borderRadius: "12px",
          opacity: previewUrl ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
        onClick={() => onSelectHandle(item)}
      >
        <img src={previewUrl} width="244px" height="144px" />
      </Button>
      <Box
        sx={{
          width: "244px",
          height: "144px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          opacity: isHovered ? 0.3 : 0.1,
          transition: "opacity 300ms",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          position: "absolute",
          left: "8px",
          top: "8px",
          opacity: isHovered ? 1 : 0,
          transition: "opacity 300ms",
          pointerEvents: "none",
        }}
      >
        <img
          src="assets/svg/UploadModelWhite.svg"
          width={"24px"}
          height={"24px"}
        />
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          right: "8px",
          top: 0,
        }}
      >
        <IconButton
          onClick={handleShowDeleteDialog}
          style={{
            transition: "opacity 300ms",
            marginTop: "8px",
            padding: "0px 0px",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <img
            src="assets/svg/DeleteIconWhite.svg"
            width="20px"
            height="20px"
          />
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <img src="assets/svg/TimeIcon.svg" />
        <Typography
          sx={{
            color: "#373E4E",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontVariantNumeric: "lining-nums tabular-nums",
            fontFeatureSettings: '"clig" off, "liga" off',
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties.lastModified)[0]}
        </Typography>
        <img src="assets/svg/CalendarIcon.svg" style={{ marginLeft: "12px" }} />
        <Typography
          sx={{
            color: "#373E4E",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties.lastModified)[1]}
        </Typography>
      </div>
      {itemToDelete && (
        <ConfirmationDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete this model? You will also lose all projects assosiated with it."
          confirmButtonText="Delete"
          item={itemToDelete}
          onConfirm={handleDelete}
          onCancel={handleCloseDeleteDialog}
        />
      )}
    </Grid>
  );
};

export default ModelItem;
