import { Button, Typography } from "@mui/material";
import { textTemplates } from "../leftPanel/styles/text/TextTemplates";
import { textAnimationTemplates } from "../leftPanel/textAnimations/TextAnimationTempates";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setTimelineCaretTime } from "../../store/timelineSlice";
import { addText, getProject } from "../../store/projectSlice";
import {
  getOriginalValue,
  getProjectDuration,
  normalizeValue,
} from "../../helpers/Helper";
import { iTextTrack } from "../../store/types";
import { v4 as uuidv4 } from "uuid";
import { animationsFps } from "../../settings/GlobalSettings";

const AddTextButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);

  const handleCreateAIText = async () => {
    const textTemplateIndex = Math.floor(Math.random() * textTemplates.length);
    const textTemplate = textTemplates[textTemplateIndex];
    const textAnimationTemplateIndex = Math.floor(
      Math.random() * textAnimationTemplates.length
    );
    const textAnimationTemplate =
      textAnimationTemplates[textAnimationTemplateIndex];
    const animationDurationMinMax: [number, number] = [0.3, 0.9];
    const durationMinMax: [number, number] = [2, 5];
    const projectDurationMinMax: [number, number] = [5, 30];
    const projectDuration = getProjectDuration(project) / animationsFps;
    const normalizedProjectDuration = normalizeValue(
      projectDuration,
      projectDurationMinMax
    );
    const currentAnimationDuration = getOriginalValue(
      normalizedProjectDuration,
      animationDurationMinMax
    );
    const currentDuration = getOriginalValue(
      normalizedProjectDuration,
      durationMinMax
    );

    const resultDuration = currentDuration * (Math.random() + 0.5);
    const text: iTextTrack = {
      id: uuidv4(),
      template: textTemplate,
      animation: {
        template: textAnimationTemplate,
        duration: currentAnimationDuration * (Math.random() + 0.5),
      },
      appearance: {
        text: "Opening",
        normalizedFontSize: 0.2,
        normalizedPosition: {
          x: 0.5,
          y: 0.5,
        },
        normalizedSize: {
          width: 0.8,
          height: 0.7,
        },
      },
      timeline: {
        duration: resultDuration,
        offset: 0,
      },
    };
    dispatch(setTimelineCaretTime(resultDuration / 2));
    dispatch(addText(text));
  };

  return (
    <Button
      sx={{
        background: "#FFF",
        borderRadius: "12px",
        border: "2px solid #4261FF",
        padding: "10px 18px",
        height: "36px",
        width: "calc(100% - 32px)",
        ":hover": {
          background: "#FFF",
          boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
        },
        ":active": {
          background: "#FFF",
          boxShadow: "none",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      onClick={handleCreateAIText}
    >
      <Typography
        sx={{
          color: "#3050F5",
        }}
      >
        Text
      </Typography>
    </Button>
  );
};

export default AddTextButton;
