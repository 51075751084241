import {
  ShotModel,
  RelativeBox,
  Sequence,
  Project,
  projectType,
  StoreItemModel,
  AudioTrack,
  MainObject,
  iTextTrack,
  StorageData,
} from "../store/types";
import { v4 as uuidv4 } from "uuid";
import { textTemplates } from "../components/leftPanel/styles/text/TextTemplates";
import { textAnimationTemplates } from "../components/leftPanel/textAnimations/TextAnimationTempates";
import {
  getStorageItemFromStorageData,
  normalizeValue,
  getOriginalValue,
  getStorageItemFromSharedStorageData,
  getProjectDuration,
  getAnimationsDuration,
  divideTotalInRandomNumbers,
} from "../helpers/Helper";
import { animationsFps } from "../settings/GlobalSettings";
import {
  getSharedStorageDataAsync,
  getUserJsonAsync,
  postUserJsonAsync,
} from "./storage/AzureStorageManager";
import { saveBlobToIDBAsync } from "./storage/DbManager";
import { Vector3, Matrix, Quaternion } from "babylonjs";

export async function getAIProject(
  relativeBox: RelativeBox,
  projectLength?: number
) {
  const project: Project = {
    id: uuidv4(),
    name: "AI Project",
    sequences: [],
    settings: { type: projectType.landscape },
  };

  const numbers = divideTotalInRandomNumbers(projectLength ?? 1800, 180, 480);

  const shotGenerators = [];

  for (let i = 0; i < numbers.length; i++) {
    shotGenerators.push(await getAIShotAsync(relativeBox, numbers[i]));
    project.sequences.push(getAISequence());
  }
  return { project: project, shotGenerators: shotGenerators };
}

export async function getAIShotAsync(
  relativeBox: RelativeBox,
  duration?: number
) {
  const position1 = getRandomPosition(relativeBox);
  const rotation1 = getRotationToBoundPoint(position1, relativeBox);
  const { initialFOV, finalFOV } = getRandomFOVValues();
  let randomDuration = randomInRange(180, 480);
  if (duration) randomDuration = duration;
  let input = [...position1, ...rotation1, initialFOV];
  const pointsCount = calculatePointsCount(randomDuration);
  const outputs = [];
  let prevPosition = position1;
  let accumulatedTime = 0;
  let totalDistance = 0;
  const distances = [];

  for (let i = 0; i < pointsCount; i++) {
    let position,
      rotation,
      relativePosition,
      relativeRotation,
      output,
      isIntersecting;

    do {
      position = getRandomPosition(relativeBox);
      rotation = getRotationToBoundPoint(position, relativeBox);
      const relatives = getRealtiveValues(
        position1,
        position,
        rotation1,
        rotation
      );
      relativePosition = relatives["relativePosition"];
      relativeRotation = relatives["relativeRotation"];

      isIntersecting = isLineIntersectingRelativeBox(
        prevPosition,
        position,
        relativeBox
      );
      console.log("INTERSECT: ", isIntersecting);
    } while (isIntersecting);

    const distanceToPrevPoint = calculateDistance(prevPosition, position);
    totalDistance += distanceToPrevPoint;
    distances.push(distanceToPrevPoint);

    output = [
      ...relativePosition,
      ...relativeRotation,
      initialFOV + (i * (finalFOV - initialFOV)) / pointsCount,
    ];

    outputs.push(output);
    prevPosition = position;
  }

  for (let i = 0; i < pointsCount; i++) {
    const travelTime = (distances[i] / totalDistance) * randomDuration;
    accumulatedTime += travelTime;
    outputs[i].push(accumulatedTime);
  }

  const light1Position = getRandomLightPosition();
  const light2Position = getRandomLightPosition();
  const light1Translation = getRandomLightPosition();
  const light2Translation = getRandomLightPosition();
  const light = [
    ...light1Position,
    ...light1Translation,
    ...light2Position,
    ...light2Translation,
  ];
  return {
    shot: generateAIShotFromArray(input, outputs, light, relativeBox),
    duration: randomDuration,
  };
}

function calculatePointsCount(duration: number): number {
  const durationRanges = [
    { min: 180, max: 300, pointsOptions: [1] },
    { min: 301, max: 420, pointsOptions: [1, 2] },
    { min: 421, max: 480, pointsOptions: [1, 2, 3] },
  ];

  const range = durationRanges.find(
    ({ min, max }) => duration >= min && duration <= max
  );

  if (range) {
    const randomIndex = Math.floor(Math.random() * range.pointsOptions.length);
    return range.pointsOptions[randomIndex];
  }
  return 1;
}

function calculateDistance(point1: number[], point2: number[]): number {
  const deltaX = point2[0] - point1[0];
  const deltaY = point2[1] - point1[1];
  const deltaZ = point2[2] - point1[2];

  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2 + deltaZ ** 2);

  return distance;
}

function getRandomFOVValues(): { initialFOV: number; finalFOV: number } {
  const randomOption = Math.floor(Math.random() * 3); // Randomly choose 0, 1, or 2

  let initialFOV, finalFOV;

  switch (randomOption) {
    case 0:
      // Close up
      initialFOV = randomInRange(0.3, 0.6) || 0; // Default to 0 if undefined
      finalFOV = randomInRange(0.3, 0.6) || 0;
      break;
    case 1:
      // Mid shot
      initialFOV = randomInRange(0.6, 1.0) || 0;
      finalFOV = randomInRange(0.6, 1.0) || 0;
      break;
    case 2:
      // Long shot
      initialFOV = randomInRange(1.0, 1.6) || 0;
      finalFOV = randomInRange(1.0, 1.6) || 0;
      break;
    default:
      // Handle unexpected case
      console.error("Unexpected random option:", randomOption);
      initialFOV = 1;
      finalFOV = 1;
  }

  return { initialFOV, finalFOV };
}

// Function to generate a random number within a range
function randomInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

// Function to generate a random point inside the box
function getRandomPointInBox(
  box: RelativeBox,
  percentageToDecrease: number
): [number, number, number] {
  const adjustedDimensions = [
    box.dimensions[0] - box.dimensions[0] * percentageToDecrease,
    box.dimensions[1] - box.dimensions[1] * percentageToDecrease,
    box.dimensions[2] - box.dimensions[2] * percentageToDecrease,
  ];

  const x = randomInRange(
    box.center[0] - adjustedDimensions[0],
    box.center[0] + adjustedDimensions[0]
  );
  const y = randomInRange(
    box.center[1] - adjustedDimensions[1],
    box.center[1] + adjustedDimensions[1]
  );
  const z = randomInRange(
    box.center[2] - adjustedDimensions[2],
    box.center[2] + adjustedDimensions[2]
  );
  console.log(`Random point in box: (${x}, ${y}, ${z})`);
  return [x, y, z];
}

function getRealtiveValues(
  position1: number[],
  position2: number[],
  rotation1: number[],
  rotation2: number[]
) {
  // Create transformation matrices for both objects
  const transformMatrix1 = Matrix.Compose(
    Vector3.One(),
    Quaternion.FromEulerVector(Vector3.FromArray(rotation1)),
    Vector3.FromArray(position1)
  );
  const transformMatrix2 = Matrix.Compose(
    Vector3.One(),
    Quaternion.FromEulerVector(Vector3.FromArray(rotation2)),
    Vector3.FromArray(position2)
  );
  // Calculate the inverse transformation matrix for the first object
  const inverseTransformMatrix1 = transformMatrix1.invert();

  // Transform the second object's position and rotation to be relative to the first object
  const relativeTransformMatrix = transformMatrix2.multiply(
    inverseTransformMatrix1
  );

  // Extract the relative position
  const relativePosition = relativeTransformMatrix.getTranslation();

  // Extract the relative rotation quaternion
  const relativeRotationQuaternion = Quaternion.FromRotationMatrix(
    relativeTransformMatrix.getRotationMatrix()
  );

  // Convert the quaternion back to Euler angles in radians
  const relativeRotation = relativeRotationQuaternion.toEulerAngles();
  console.log(relativeRotation);

  return {
    relativePosition: relativePosition.asArray(),
    relativeRotation: relativeRotation.asArray(),
  };
}

// Function to calculate Euler rotation to look at a point
function getRotationToBoundPoint(
  position: number[],
  box: RelativeBox
): number[] {
  const randomBoundPoint = getRandomPointInBox(box, 0.2);

  // Direction vector
  const direction = [
    randomBoundPoint[0] - position[0],
    randomBoundPoint[1] - position[1],
    randomBoundPoint[2] - position[2],
  ];
  console.log(
    `Direction vector: (${direction[0]}, ${direction[1]}, ${direction[2]})`
  );

  // Normalize the direction vector
  const length = Math.sqrt(
    direction[0] ** 2 + direction[1] ** 2 + direction[2] ** 2
  );
  const normalizedDirection = direction.map((d) => d / length);
  console.log(
    `Normalized direction: (${normalizedDirection[0]}, ${normalizedDirection[1]}, ${normalizedDirection[2]})`
  );

  // Calculate rotation
  const pitch = Math.asin(-normalizedDirection[1]);
  const yaw = Math.atan2(normalizedDirection[0], normalizedDirection[2]);

  // Convert to degrees
  const rotation = {
    x: pitch,
    y: yaw,
    z: 0, // Assuming no roll is needed
  };

  console.log(`Rotation: ${rotation.x}, ${rotation.y}, ${rotation.z}`);
  return [rotation.x, rotation.y, rotation.z];
}

function bias() {
  return 1;
  // return Math.random() + 1;
}

export function generateAIShotFromArray(
  input: number[],
  output: number[][],
  light: number[],
  relativeBox: RelativeBox
) {
  const shotTemplate: ShotModel = {
    name: uuidv4(),
    groupName: "AI",
    camera: {
      animations: [
        {
          name: "pos",
          property: "position",
          framePerSecond: 60,
          dataType: 1,
          loopBehavior: 1,
          blendingSpeed: 0.01,
          keys: [{ frame: 0, values: [0, 0, 0, [0, 0, 0], [0, 0, 0], 0] }],
          ranges: [],
        },
        {
          name: "rot",
          property: "rotation",
          framePerSecond: 60,
          dataType: 1,
          loopBehavior: 1,
          blendingSpeed: 0.01,
          keys: [
            {
              frame: 0,
              values: [0, 0, 0, [0, 0, 0], [0, 0, 0], 0],
            },
          ],
          ranges: [],
        },
        {
          name: "fov",
          property: "fov",
          framePerSecond: 60,
          dataType: 0,
          loopBehavior: 1,
          blendingSpeed: 0.01,
          keys: [
            {
              frame: 0,
              values: [input[6], 0, 0],
            },
          ],
          ranges: [],
        },
      ],
      transform: {
        position: [input[0], input[1], input[2]],
        rotation: [input[3], input[4], input[5]],
        scaling: [1, 1, 1],
      },
    },
    lights: [
      {
        tags: null,
        shadowAngle: 1.5707963267948966,
        diffuse: [1, 1, 1],
        specular: [1, 1, 1],
        falloffType: 0,
        intensity: 4,
        range: 1.7976931348623157e308,
        intensityMode: 0,
        radius: 0.00001,
        _renderPriority: 0,
        shadowEnabled: true,
        excludeWithLayerMask: 0,
        includeOnlyWithLayerMask: 0,
        lightmapMode: 0,
        position: [light[0], light[1], light[2]],
        name: "8ec25ec4-deac-41fa-90dc-0f3bae14c841",
        id: "8ec25ec4-deac-41fa-90dc-0f3bae14c841",
        state: "",
        uniqueId: 167,
        type: 0,
        parentId: 172,
        animations: [
          {
            name: "pos",
            property: "position",
            framePerSecond: 60,
            dataType: 1,
            loopBehavior: 1,
            blendingSpeed: 0.01,
            keys: [
              { frame: 0, values: [0, 0, 0] },
              {
                frame: output[output.length - 1][7],
                values: [light[3], light[4], light[5]],
              },
            ],
            ranges: [],
          },
        ],
        ranges: [],
        isEnabled: true,
      },
      {
        tags: null,
        shadowAngle: 1.5707963267948966,
        diffuse: [1, 1, 1],
        specular: [1, 1, 1],
        falloffType: 0,
        intensity: 10,
        range: 1.7976931348623157e308,
        intensityMode: 0,
        radius: 0.00001,
        _renderPriority: 0,
        shadowEnabled: true,
        excludeWithLayerMask: 0,
        includeOnlyWithLayerMask: 0,
        lightmapMode: 0,
        position: [light[6], light[7], light[8]],
        name: "5e780f88-6ea8-4204-9f70-01ada2858a6d",
        id: "5e780f88-6ea8-4204-9f70-01ada2858a6d",
        state: "",
        uniqueId: 309,
        type: 0,
        parentId: 314,
        animations: [
          {
            name: "pos",
            property: "position",
            framePerSecond: 60,
            dataType: 1,
            loopBehavior: 1,
            blendingSpeed: 0.01,
            keys: [
              { frame: 0, values: [0, 0, 0] },
              {
                frame: output[output.length - 1][7],
                values: [light[9], light[10], light[11]],
              },
            ],
            ranges: [],
          },
        ],
        ranges: [],
        isEnabled: true,
      },
    ],
    relativeBox: relativeBox,
  };

  // Dynamically populate keys within the 'pos' animation
  for (let i = 0; i < output.length; i++) {
    const array = output[i];
    const last = i === output.length - 1;
    const frame_diff = 30;
    const randomIntValue_pos =
      Math.floor(Math.random() * (2 * frame_diff + 1)) - frame_diff;
    const randomIntValue_rot =
      Math.floor(Math.random() * (2 * frame_diff + 1)) - frame_diff;

    let posValues: any = [array[0], array[1], array[2]];
    if (!last) posValues = [...posValues, ...[[0, 0, 0], [0, 0, 0], 0]];
    let rotValues: any = [array[3], array[4], array[5]];
    if (!last) rotValues = [...rotValues, ...[[0, 0, 0], [0, 0, 0], 0]];

    //pos
    shotTemplate.camera.animations[0].keys.push({
      frame: array[7] + (last ? 0 : randomIntValue_pos),
      values: posValues,
    });
    //rot
    shotTemplate.camera.animations[1].keys.push({
      frame: array[7] + (last ? 0 : randomIntValue_rot),
      values: rotValues,
    });
    //pov
    shotTemplate.camera.animations[2].keys.push({
      frame: array[7],
      values: [array[6], 0, 0],
    });
  }
  return shotTemplate;
}

export function generateAIShot(
  input: number[],
  output: number[],
  light: number[],
  relativeBox: RelativeBox
) {
  const shotTemplate: ShotModel = {
    name: uuidv4(),
    groupName: "AI",
    camera: {
      animations: [
        {
          name: "pos",
          property: "position",
          framePerSecond: 60,
          dataType: 1,
          loopBehavior: 1,
          blendingSpeed: 0.01,
          keys: [
            { frame: 0, values: [0, 0, 0] },
            { frame: output[8], values: [output[0], output[1], output[2]] },
          ],
          ranges: [],
        },
        {
          name: "rot",
          property: "rotation",
          framePerSecond: 60,
          dataType: 1,
          loopBehavior: 1,
          blendingSpeed: 0.01,
          keys: [
            {
              frame: 0,
              values: [0, 0, 0],
            },
            { frame: output[8], values: [output[3], output[4], output[5]] },
          ],
          ranges: [],
        },
        {
          name: "fov",
          property: "fov",
          framePerSecond: 60,
          dataType: 0,
          loopBehavior: 1,
          blendingSpeed: 0.01,
          keys: [
            {
              frame: 0,
              values: [output[6], 0, 0],
            },
            { frame: output[8], values: [output[7], 0, 0] },
          ],
          ranges: [],
        },
      ],
      transform: {
        position: [input[0], input[1], input[2]],
        rotation: [input[3], input[4], input[5]],
        scaling: [1, 1, 1],
      },
    },
    lights: [
      {
        tags: null,
        shadowAngle: 1.5707963267948966,
        diffuse: [1, 1, 1],
        specular: [1, 1, 1],
        falloffType: 0,
        intensity: 4,
        range: 1.7976931348623157e308,
        intensityMode: 0,
        radius: 0.00001,
        _renderPriority: 0,
        shadowEnabled: true,
        excludeWithLayerMask: 0,
        includeOnlyWithLayerMask: 0,
        lightmapMode: 0,
        position: [light[0], light[1], light[2]],
        name: "8ec25ec4-deac-41fa-90dc-0f3bae14c841",
        id: "8ec25ec4-deac-41fa-90dc-0f3bae14c841",
        state: "",
        uniqueId: 167,
        type: 0,
        parentId: 172,
        animations: [
          {
            name: "pos",
            property: "position",
            framePerSecond: 60,
            dataType: 1,
            loopBehavior: 1,
            blendingSpeed: 0.01,
            keys: [
              { frame: 0, values: [0, 0, 0] },
              {
                frame: output[8],
                values: [light[3], light[4], light[5]],
              },
            ],
            ranges: [],
          },
        ],
        ranges: [],
        isEnabled: true,
      },
      {
        tags: null,
        shadowAngle: 1.5707963267948966,
        diffuse: [1, 1, 1],
        specular: [1, 1, 1],
        falloffType: 0,
        intensity: 10,
        range: 1.7976931348623157e308,
        intensityMode: 0,
        radius: 0.00001,
        _renderPriority: 0,
        shadowEnabled: true,
        excludeWithLayerMask: 0,
        includeOnlyWithLayerMask: 0,
        lightmapMode: 0,
        position: [light[6], light[7], light[8]],
        name: "5e780f88-6ea8-4204-9f70-01ada2858a6d",
        id: "5e780f88-6ea8-4204-9f70-01ada2858a6d",
        state: "",
        uniqueId: 309,
        type: 0,
        parentId: 314,
        animations: [
          {
            name: "pos",
            property: "position",
            framePerSecond: 60,
            dataType: 1,
            loopBehavior: 1,
            blendingSpeed: 0.01,
            keys: [
              { frame: 0, values: [0, 0, 0] },
              { frame: output[8], values: [light[9], light[10], light[11]] },
            ],
            ranges: [],
          },
        ],
        ranges: [],
        isEnabled: true,
      },
    ],
    relativeBox: relativeBox,
  };
  return shotTemplate;
}

function getRandomLightPosition() {
  return [
    (Math.random() * 2 - 1) * bias(),
    (Math.random() * 2 - 1 + 0.225) * bias(),
    (Math.random() * 2 - 1) * bias(),
  ];
}

function getRandomPosition(relativeBox: RelativeBox) {
  return [
    getRandomBoundedValue(relativeBox.dimensions[0] + 0.1, 0.6) +
      relativeBox.center[0],
    getRandomBoundedValue(relativeBox.dimensions[1] + 0.1, 0.6) +
      relativeBox.center[1],
    getRandomBoundedValue(relativeBox.dimensions[2] + 0.1, 0.6) +
      relativeBox.center[2],
  ];
}

function getRandomBoundedValue(min: number, max: number) {
  return (Math.random() * max + min / 2) * (Math.random() < 0.5 ? -1 : 1);
}

function isLineIntersectingRelativeBox(
  startPoint: number[],
  endPoint: number[],
  relativeBox: { center: number[]; dimensions: number[] }
): boolean {
  // Check line segment between two points intersects with the relative box

  const percentageToDecrease = 0.3;

  const adjustedDimensions = [
    relativeBox.dimensions[0] +
      relativeBox.dimensions[0] * percentageToDecrease,
    relativeBox.dimensions[1] +
      relativeBox.dimensions[1] * percentageToDecrease,
    relativeBox.dimensions[2] +
      relativeBox.dimensions[2] * percentageToDecrease,
  ];

  const boxMin = [
    relativeBox.center[0] - adjustedDimensions[0] / 2,
    relativeBox.center[1] - adjustedDimensions[1] / 2,
    relativeBox.center[2] - adjustedDimensions[2] / 2,
  ];

  const boxMax = [
    relativeBox.center[0] + adjustedDimensions[0] / 2,
    relativeBox.center[1] + adjustedDimensions[1] / 2,
    relativeBox.center[2] + adjustedDimensions[2] / 2,
  ];

  const offcutMin = [
    Math.min(startPoint[0], endPoint[0]),
    Math.min(startPoint[1], endPoint[1]),
    Math.min(startPoint[2], endPoint[2]),
  ];

  const offcutMax = [
    Math.max(startPoint[0], endPoint[0]),
    Math.max(startPoint[1], endPoint[1]),
    Math.max(startPoint[2], endPoint[2]),
  ];

  // Check for no intersection
  if (
    offcutMax[0] < boxMin[0] ||
    offcutMin[0] > boxMax[0] ||
    offcutMax[1] < boxMin[1] ||
    offcutMin[1] > boxMax[1] ||
    offcutMax[2] < boxMin[2] ||
    offcutMin[2] > boxMax[2]
  ) {
    return false;
  }

  return true;
}

export function getAISequence() {
  const sequence: Sequence = {
    id: uuidv4(),
    name: "AI Generated",
    duration: 0,
    trims: [0, 0],
    environment: {
      model: {
        id: "scenes/space/space.glb",
        name: "space",
        url: "scenes/space/space.glb",
        accessType: "shared",
        properties: {
          createdOn: "2023-07-06T12:47:45.000Z",
          lastModified: "2023-07-06T12:47:45.000Z",
          etag: "0x8DB7E1F325F2F9C",
          contentLength: 196,
          contentType: "application/octet-stream",
          contentEncoding: "",
          contentLanguage: "",
          contentMD5: {
            type: "Buffer",
            data: [
              43, 200, 176, 108, 92, 59, 25, 33, 56, 226, 2, 119, 197, 239, 141,
              6,
            ],
          },
          contentDisposition: "",
          cacheControl: "",
          blobType: "BlockBlob",
          leaseStatus: "unlocked",
          leaseState: "available",
          serverEncrypted: true,
          accessTier: "Hot",
          accessTierInferred: true,
          "Content-CRC64": "",
        },
      },
    },
    camera: { id: uuidv4(), name: "Camera" },
    skybox: {
      model: {
        id: "scenes/space/skyboxes/black gray dark.env",
        name: "black gray dark",
        url: "scenes/space/skyboxes/black gray dark.env",
        accessType: "shared",
        properties: {
          createdOn: "2023-07-07T11:32:43.000Z",
          lastModified: "2023-07-07T11:32:43.000Z",
          etag: "0x8DB7EDDE11DE426",
          contentLength: 280635,
          contentType: "application/octet-stream",
          contentEncoding: "",
          contentLanguage: "",
          contentMD5: {
            type: "Buffer",
            data: [
              52, 74, 216, 17, 240, 34, 7, 120, 252, 196, 170, 37, 254, 13, 20,
              251,
            ],
          },
          contentDisposition: "",
          cacheControl: "",
          blobType: "BlockBlob",
          leaseStatus: "unlocked",
          leaseState: "available",
          serverEncrypted: true,
          accessTier: "Hot",
          accessTierInferred: true,
          "Content-CRC64": "",
        },
      },
      rotationY: 0,
    },
    mainObject: {
      model: {
        id: "models/nike_air_pegasus.glb",
        name: "nike_air_pegasus",
        url: "models/nike_air_pegasus.glb",
        accessType: "shared",
        properties: {
          createdOn: "2023-07-10T10:46:27.000Z",
          lastModified: "2023-07-10T10:46:27.000Z",
          etag: "0x8DB8132E9BDDE52",
          contentLength: 14954276,
          contentType: "application/octet-stream",
          contentEncoding: "",
          contentLanguage: "",
          contentMD5: {
            type: "Buffer",
            data: [
              194, 248, 98, 196, 212, 84, 194, 35, 138, 166, 210, 59, 84, 211,
              18, 165,
            ],
          },
          contentDisposition: "",
          cacheControl: "",
          blobType: "BlockBlob",
          leaseStatus: "unlocked",
          leaseState: "available",
          serverEncrypted: true,
          accessTier: "Hot",
          accessTierInferred: true,
          "Content-CRC64": "",
        },
      },
    },
  };
  return sequence;
}

export function getUnoccupiedName(
  originalName: string,
  storeItemModel: StoreItemModel[]
) {
  let name = originalName;
  if (storeItemModel.some((p) => p.name === originalName)) {
    while (storeItemModel.some((p) => p.name === name)) {
      const regex = /-(\d+)$/;
      const match = name.match(regex);

      if (match) {
        let num = parseInt(match[1]);
        name = name.replace(regex, "") + `-${++num}`;
      } else {
        name += `-1`;
      }
    }
  }
  return name;
}

export async function CreateAIProjectFromModel(
  model: StoreItemModel,
  userStorageData: StorageData
) {
  const projects = getStorageItemFromStorageData("projects", userStorageData);
  const config = await getUserJsonAsync(
    `configs/${model.url.split(".")[0]}.json`
  );
  const relativeBox = config.relativeBox;
  const result = await getAIProject(relativeBox, 1800);
  result.project.name = getUnoccupiedName("AI Project", projects);
  result.project.id = uuidv4();

  await Promise.all(
    result.shotGenerators.map(
      (s) =>
        new Promise<void>(async (resolve) => {
          await saveBlobToIDBAsync(
            new Blob([JSON.stringify(s.shot)], { type: "application/json" }),
            `shots/${s.shot.name}.json`
          );
          postUserJsonAsync(s.shot, `shots/${s.shot.name}.json`);
          resolve();
        })
    )
  );

  const envStorageData = await getSharedStorageDataAsync("ai-env");
  const backgrounds = getStorageItemFromSharedStorageData(envStorageData);

  const backgroundIndex = Math.floor(Math.random() * backgrounds.length);
  result.project.sequences.forEach((s, i) => {
    s.shot = {
      id: result.shotGenerators[i].shot.name,
      name: result.shotGenerators[i].shot.name,
      url: `shots/${result.shotGenerators[i].shot.name}.json`,
      accessType: "user",
      properties: {},
    };
    s.duration = getAnimationsDuration(
      result.shotGenerators[i].shot.camera.animations
    );
    s.skybox!.model = backgrounds[backgroundIndex];
    s.mainObject = {
      sequenceId: s.id,
      model: model,
    } as MainObject;
  });

  const projectDuration = getProjectDuration(result.project) / animationsFps;

  const audioStorageData = await getSharedStorageDataAsync("ai-audios");
  const audios = getStorageItemFromSharedStorageData(audioStorageData);
  const i = Math.floor(Math.random() * audios.length);
  result.project.audio = {
    source: audios[i],
    trims: [0, 30.0 - projectDuration >= 0.0 ? 30.0 - projectDuration : 0],
    duration: 30,
    offset: 0,
  } as AudioTrack;

  const textTemplateIndex = Math.floor(Math.random() * textTemplates.length);
  const textTemplate = textTemplates[textTemplateIndex];
  const textAnimationTemplateIndex = Math.floor(
    Math.random() * textAnimationTemplates.length
  );
  const textAnimationTemplate =
    textAnimationTemplates[textAnimationTemplateIndex];
  const animationDurationMinMax: [number, number] = [0.3, 0.9];
  const durationMinMax: [number, number] = [2, 5];
  const offsetOpeningMinMax: [number, number] = [0, 3];
  const projectDurationMinMax: [number, number] = [5, 30];
  const normalizedProjectDuration = normalizeValue(
    projectDuration,
    projectDurationMinMax
  );
  const currentAnimationDuration = getOriginalValue(
    normalizedProjectDuration,
    animationDurationMinMax
  );
  const currentDuration = getOriginalValue(
    normalizedProjectDuration,
    durationMinMax
  );
  const currentOffsetOpening = getOriginalValue(
    normalizedProjectDuration,
    offsetOpeningMinMax
  );
  const currentOffsetEnding =
    projectDuration -
    currentOffsetOpening * (Math.random() + 0.5) -
    currentDuration;
  const texts: iTextTrack[] = [
    {
      id: uuidv4(),
      template: textTemplate,
      animation: {
        template: textAnimationTemplate,
        duration: currentAnimationDuration * (Math.random() + 0.5),
      },
      appearance: {
        text: "Opening",
        normalizedFontSize: 0.2,
        normalizedPosition: {
          x: 0.5,
          y: 0.5,
        },
        normalizedSize: {
          width: 0.8,
          height: 0.7,
        },
      },
      timeline: {
        duration: currentDuration * (Math.random() + 0.5),
        offset: currentOffsetOpening * (Math.random() + 0.5),
      },
    },
    {
      id: uuidv4(),
      template: textTemplate,
      animation: {
        template: textAnimationTemplate,
        duration: currentAnimationDuration * (Math.random() + 0.5),
      },
      appearance: {
        text: "Ending",
        normalizedFontSize: 0.2,
        normalizedPosition: {
          x: 0.5,
          y: 0.5,
        },
        normalizedSize: {
          width: 0.8,
          height: 0.7,
        },
      },
      timeline: {
        duration: currentDuration * (Math.random() + 0.5),
        offset: currentOffsetEnding,
      },
    },
  ];
  result.project.texts = texts;
  return result.project;
}
