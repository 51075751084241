import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { iTextTrack } from "../../../store/types";
import InOutFreezedAnimator from "./InOutFreezedAnimator";

type TextOverlayItemProps = {
  item: iTextTrack;
  parentSize: { width: number; height: number };
  timelineTextCaret: { id: string; time: number }[];
};

const TextOverlayItem = ({
  item,
  parentSize,
  timelineTextCaret,
}: TextOverlayItemProps) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [fontSize, setFontSize] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(true);
  const [localTime, setLocalTime] = useState(0);

  useEffect(() => {
    const actualSize = {
      width: item.appearance.normalizedSize.width * parentSize.width,
      height: item.appearance.normalizedSize.height * parentSize.height,
    };
    const actualPosition = {
      x:
        item.appearance.normalizedPosition.x * parentSize.width -
        actualSize.width / 2,
      y:
        item.appearance.normalizedPosition.y * parentSize.height -
        actualSize.height / 2,
    };
    const actualFontSize =
      item.appearance.normalizedFontSize * parentSize.height;
    setSize(actualSize);
    setPosition(actualPosition);
    setFontSize(actualFontSize);
  }, [item, parentSize]);

  useEffect(() => {
    const currentCaret = timelineTextCaret.find((c) => c.id === item.id);
    if (currentCaret) {
      setVisible(true);
      setLocalTime(currentCaret.time);
    } else {
      setVisible(false);
      setLocalTime(0);
    }
  }, [timelineTextCaret]);

  return (
    <Rnd
      style={{
        visibility: visible ? "visible" : "hidden",
        border: "2px solid transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      size={{
        width: `${size.width}px`,
        height: `${size.height}px`,
      }}
      position={{ x: position.x, y: position.y }}
      bounds="parent"
    >
      <InOutFreezedAnimator item={item} time={localTime}>
        <Typography
          spellCheck={false}
          sx={{
            ...item.template.css,
            ...item.appearance,
            overflowWrap: "break-word",
            wordWrap: "break-word",
            whiteSpace: "normal",
            wordBreak: "break-all",
            fontSize: `${fontSize}px`,
            outline: "none",
          }}
        >
          {item.appearance.text}
        </Typography>
      </InOutFreezedAnimator>
    </Rnd>
  );
};

export default TextOverlayItem;
