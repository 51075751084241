import { Rnd } from "react-rnd";
import { TimelineItemType, iTextTrack } from "../../../store/types";
import { useDispatch } from "react-redux";
import { setTextAppearanceProps } from "../../../store/projectSlice";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import {
  getTimelineCaretTextTrack,
  getTimelineCaretTime,
  getTimelinePlaying,
  getTimelineSelectedItem,
  setTimelineSelectedItem,
} from "../../../store/timelineSlice";
import { timeoutAsync } from "../../../helpers/Helper";
import { getScene3DView } from "../../../store/sceneSlice";
import EditableTypography from "./EditableTypography";
import InOutAnimator from "./InOutAnimator";

type TextItemProps = {
  item: iTextTrack;
  parentSize: { width: number; height: number };
};

const TextItem = ({ item, parentSize }: TextItemProps) => {
  const dispatch = useDispatch();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [fontSize, setFontSize] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const [selected, setSelected] = useState(false);
  const [hovered, setHovered] = useState(false);
  const timelineTextCaret = useAppSelector(getTimelineCaretTextTrack);
  const timelineCaret = useAppSelector(getTimelineCaretTime);
  const [visible, setVisible] = useState(false);
  const playing = useAppSelector(getTimelinePlaying);
  const abortCotrollerRef = useRef(new AbortController());
  const threeDMode = useAppSelector(getScene3DView);
  const [localTime, setLocalTime] = useState(0);
  const [localPlaying, setLocalPlaying] = useState(false);

  useEffect(() => {
    const actualSize = {
      width: item.appearance.normalizedSize.width * parentSize.width,
      height: item.appearance.normalizedSize.height * parentSize.height,
    };
    const actualPosition = {
      x:
        item.appearance.normalizedPosition.x * parentSize.width -
        actualSize.width / 2,
      y:
        item.appearance.normalizedPosition.y * parentSize.height -
        actualSize.height / 2,
    };
    const actualFontSize =
      item.appearance.normalizedFontSize * parentSize.height;
    setSize(actualSize);
    setPosition(actualPosition);
    setFontSize(actualFontSize);
  }, [item, parentSize]);

  useEffect(() => {
    setSelected(selectedItem?.id === item.id);
  }, [selectedItem?.id]);

  useEffect(() => {
    const currentCaret = timelineTextCaret.find((c) => c.id === item.id);
    if (currentCaret) {
      setVisible(true);
      setLocalTime(currentCaret.time);
    } else {
      setVisible(false);
      setLocalTime(0);
    }
  }, [timelineTextCaret]);

  useEffect(() => {
    if (playing) {
      const playAsync = async (signal: AbortSignal) => {
        const delay = Math.max(0, item.timeline.offset - timelineCaret);
        await timeoutAsync(delay * 1000);
        if (signal.aborted) return;
        const remainDuration =
          delay > 0
            ? item.timeline.duration
            : Math.max(
                0,
                item.timeline.duration + item.timeline.offset - timelineCaret
              );
        if (remainDuration > 0) {
          setVisible(true);
          setLocalPlaying(true);
          await timeoutAsync(remainDuration * 1000);
          if (signal.aborted) return;
          setVisible(false);
        }
      };
      abortCotrollerRef.current.abort();
      abortCotrollerRef.current = new AbortController();
      playAsync(abortCotrollerRef.current.signal);
    } else {
      abortCotrollerRef.current.abort();
      setLocalPlaying(false);
    }
  }, [timelineCaret, playing, item.timeline.offset, item.timeline.duration]);

  const handleDragStop = (_: any, d: { x: number; y: number }) => {
    const normalizedPosition = {
      x: (d.x + size.width / 2) / parentSize.width,
      y: (d.y + size.height / 2) / parentSize.height,
    };
    setPosition(d);
    dispatch(
      setTextAppearanceProps({
        id: item.id,
        data: {
          normalizedPosition,
        },
      })
    );
  };

  const handleResizeStop = (
    _: any,
    __: string,
    ref: HTMLElement,
    ___: { width: number; height: number },
    position: { x: number; y: number }
  ) => {
    const newSize = {
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    };
    const normalizedSize = {
      width: newSize.width / parentSize.width,
      height: newSize.height / parentSize.height,
    };
    const normalizedPosition = {
      x: (position.x + newSize.width / 2) / parentSize.width,
      y: (position.y + newSize.height / 2) / parentSize.height,
    };
    setPosition(position);
    setSize({ width: newSize.width, height: newSize.height });
    dispatch(
      setTextAppearanceProps({
        id: item.id,
        data: {
          normalizedPosition,
          normalizedSize,
        },
      })
    );
  };

  const handleMouseDown = () => {
    dispatch(
      setTimelineSelectedItem({ id: item.id, type: TimelineItemType.text })
    );
  };

  const handleTextChange = (text: string) => {
    dispatch(
      setTextAppearanceProps({
        id: item.id,
        data: {
          text: text,
        },
      })
    );
  };

  return (
    <Rnd
      style={{
        visibility: visible && !threeDMode ? "visible" : "hidden",
        cursor: "grab",
        border: `2px solid ${
          selected ? "white" : hovered ? "#FFF8" : "transparent"
        }`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      size={{
        width: `${size.width}px`,
        height: `${size.height}px`,
      }}
      position={{ x: position.x, y: position.y }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={handleMouseDown}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
      bounds="parent"
      dragAxis="both"
      enableResizing={{
        top: selected,
        right: selected,
        bottom: selected,
        left: selected,
        topRight: selected,
        bottomRight: selected,
        bottomLeft: selected,
        topLeft: selected,
      }}
      resizeHandleStyles={{
        topLeft: innerHandleStyle,
        topRight: innerHandleStyle,
        bottomLeft: innerHandleStyle,
        bottomRight: innerHandleStyle,
      }}
      minWidth={32}
      minHeight={32}
    >
      <InOutAnimator item={item} playing={localPlaying} time={localTime}>
        <EditableTypography
          item={item}
          fontSize={fontSize}
          onTextChange={handleTextChange}
        />
      </InOutAnimator>
    </Rnd>
  );
};

export default TextItem;

const innerHandleStyle: CSSProperties = {
  position: "absolute",
  width: "20px",
  height: "20px",
  borderRadius: "10px",
  backgroundColor: "white",
};
