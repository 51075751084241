import { Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { iTextTrack } from "../../../store/types";

interface EditableTypographyProps {
  item: iTextTrack;
  fontSize: number;
  onTextChange: (text: string) => void;
}

const EditableTypography = ({
  item,
  fontSize,
  onTextChange,
}: EditableTypographyProps) => {
  const [text, setText] = useState<string>(item.appearance.text);
  const typographyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setText(item.appearance.text);
  }, [item]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === " " && document.activeElement === typographyRef.current) {
        e.stopPropagation();
      }
    };

    const typographyElement = typographyRef.current;
    if (typographyElement) {
      typographyElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (typographyElement) {
        typographyElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const onBlurHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLDivElement;
    const newText = target.innerText;
    if (newText !== text) {
      setText(newText);
      onTextChange(newText);
    }
  };

  return (
    <Typography
      ref={typographyRef}
      contentEditable={true}
      suppressContentEditableWarning={true}
      onBlur={onBlurHandler}
      spellCheck={false}
      sx={{
        ...item.template.css,
        ...item.appearance,
        overflowWrap: "break-word",
        wordWrap: "break-word",
        whiteSpace: "normal",
        wordBreak: "break-all",
        fontSize: `${fontSize}px`,
        outline: "none",
      }}
    >
      {text}
    </Typography>
  );
};

export default EditableTypography;
