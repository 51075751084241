import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  copyUserBlobAsync,
  deleteUserBlobAsync,
  getUserBlobAsync,
  postUserBlobAsync,
} from "../../../managers/storage/AzureStorageManager";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setProject, getProject } from "../../../store/projectSlice";
import {
  getOccupiedStorageSize,
  getUserStorageData,
} from "../../../store/storageSlice";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";
import DuplicateItemDialog from "../../dialogs/DuplicateItemDialog";
import {
  getStorageItemFromStorageData,
  handleDeleteProjectAsync,
  preloadBlobsAsync,
  saveProjectAsync,
  sortStoreItemModels,
  timeoutAsync,
} from "../../../helpers/Helper";
import {
  Project,
  Sequence,
  StoreItemModel,
  sortingMethods,
} from "../../../store/types";
import { setSaving, setSceneSwitch } from "../../../store/navigatorSlice";
import {
  increaseCurrentProgress,
  resetProgress,
  setProgressText,
} from "../../../store/progressSlice";
import { getJsonAsync } from "../../../managers/storage/StorageManager";
import { showCreateVideoDialog } from "../../../store/dialogSlice";
import VideosItem from "./VideosItem";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../managers/AppInsights";
import { setLoading } from "../../../store/sceneSlice";
import { setTimelinePlaying } from "../../../store/timelineSlice";
import { deleteBlobFromIDBAsync } from "../../../managers/storage/DbManager";
import { getUnoccupiedName } from "../../../managers/AICreator";
import VideosItemLegacy from "./VideosItemLegacy";
import { drainCreditAsync } from "../../../managers/ClientManager";

const Videos = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const [renders, setRenders] = useState<StoreItemModel[]>();
  const userStorageData = useAppSelector(getUserStorageData);
  const [projects, setProjects] = useState([] as StoreItemModel[]);
  const [itemToDelete, setItemToDelete] = useState<StoreItemModel | null>(null);
  const [itemToRename, setItemToRename] = useState<StoreItemModel | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const selectedItem = useRef<StoreItemModel | null>(null);
  const [imgUrl, setImgUrl] = useState("");
  const [projectsEmpty, setProjectsEmpty] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const projects = getStorageItemFromStorageData("projects", userStorageData);
    setProjects(sortStoreItemModels(projects, sortingMethods.byDate));
    const renders = getStorageItemFromStorageData(
      "renders",
      userStorageData
    ).filter(
      (render) => !projects.some((project) => project.name === render.name)
    );
    setRenders(sortStoreItemModels(renders, sortingMethods.byDate));

    if (projects.length === 0) {
      setImgUrl("assets/images/EmptyRendersImage.webp");
      setProjectsEmpty(true);
    } else setProjectsEmpty(false);
  }, [userStorageData]);

  function handleShowDeleteDialog() {
    setMenuOpen(false);
    setItemToDelete(selectedItem.current);
  }

  function handleCloseDeleteDialog() {
    setItemToDelete(null);
  }

  function handleCloseDeleteAllDialog() {
    setDeleteAllDialogOpen(false);
  }

  function handleShowRenameDialog() {
    setMenuOpen(false);
    setItemToRename(selectedItem.current);
  }

  function handleCloseRenameDialog() {
    setItemToRename(null);
  }

  async function handleSelectProjectAsync(model: StoreItemModel) {
    dispatch(resetProgress());
    dispatch(setProgressText("Setting things up"));
    dispatch(setLoading(true));
    await timeoutAsync(300);

    dispatch(setTimelinePlaying(false));
    const projectBlob = await getJsonAsync(model);

    await preloadBlobsAsync(projectBlob, dispatch, (bytes: number) => {
      dispatch(increaseCurrentProgress(bytes));
    });

    projectBlob.sequences.forEach((s: Sequence) => {
      if (!s.trims) {
        s.trims = [0, 0];
      }
    });

    dispatch(setProject(projectBlob));
    await timeoutAsync(1000);
    dispatch(setSceneSwitch(projectBlob.renderUrl ? "video" : "scene"));
    dispatch(setLoading(false));
  }

  const handleDelete = async (model: StoreItemModel) => {
    dispatch(setSaving(true));
    await handleDeleteProjectAsync(model);
    dispatch(setSaving(false));
  };

  function handleDeleteAll() {
    projects.forEach(async (p) => {
      const project = (await getJsonAsync(p)) as Project;
      project.sequences.forEach(async (s) => {
        if (s.shot) await deleteUserBlobAsync(`shots/${s.shot?.id}.json`);
      });
      deleteUserBlobAsync(p.url);
      deleteUserBlobAsync(`previews/projects/${p.name}.json.webp`);
      deleteBlobFromIDBAsync(p.url);
      deleteBlobFromIDBAsync(`previews/projects/${p.name}.json.webp`);
    });
    dispatch(setProject({}));
  }

  const handleDuplicate = async () => {
    handleCloseMenu();
    if (!selectedItem.current) return;
    dispatch(setSaving(true));
    const name = getUnoccupiedName(selectedItem.current.name, projects);
    copyUserBlobAsync(
      `previews/projects/${selectedItem.current.name}.json.webp`,
      `previews/projects/${name}.json.webp`
    );
    const sourceProject = await getJsonAsync(selectedItem.current);
    const deepClone = JSON.parse(JSON.stringify(sourceProject)) as Project;

    deepClone.name = name;
    deepClone.id = uuidv4();
    for (let i = 0; i < deepClone.sequences.length; i++) {
      const sequence = deepClone.sequences[i];
      if (sequence.shot) {
        const name = uuidv4();
        sequence.shot.id = name;
        sequence.shot.url = `shots/${name}.json`;
        sequence.shot.name = name;
        await copyUserBlobAsync(
          sourceProject.sequences[i].shot?.url,
          `shots/${name}.json`
        );
      }
    }

    await saveProjectAsync(deepClone);
    dispatch(setSaving(false));
  };

  async function handleRename(model: StoreItemModel, name: string) {
    handleCloseRenameDialog();

    const sourceProject = await getJsonAsync(model);
    const deepClone = JSON.parse(JSON.stringify(sourceProject)) as Project;

    deepClone.name = name;
    deepClone.id = uuidv4();
    deepClone.sequences.forEach((s) => {
      s.id = uuidv4();
      if (!s.trims) {
        s.trims = [0, 0];
      }
    });

    if (project.name === model.name) {
      dispatch(setProject(deepClone));
    }

    saveProjectAsync(deepClone);
    deleteUserBlobAsync(model.url);

    const previewUrl = `previews/projects/${sourceProject.name}.json.webp`;
    const blob = await getUserBlobAsync(previewUrl);
    await postUserBlobAsync(
      blob!,
      `previews/projects/${deepClone.name}.json.webp`,
      true
    );
    deleteUserBlobAsync(`previews/projects/${sourceProject.name}.json.webp`);
  }

  const handleMenu = (
    event: React.MouseEvent<HTMLElement>,
    item: StoreItemModel
  ) => {
    selectedItem.current = item;
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const menuItemStyle = {
    borderRadius: "12px",
    transition: "background-color 0.25s",
    padding: "8px 12px",
    ":active": { backgroundColor: "#EDEDED" },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  };

  const handleScroll = (e: any) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {projects.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "calc(100% - 48px)",
              marginLeft: "24px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: 900,
                color: "#373E4E",
              }}
            >
              Videos
            </Typography>

            <Button
              onClick={() => {
                dispatch(showCreateVideoDialog());
              }}
              sx={{
                background: "#3050F5",
                borderRadius: "8px",
                padding: "10px 18px",
                height: "36px",
                width: "156px",
                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#3050F5",
                  boxShadow: "none",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FFF",
                  fontWeight: 700,
                }}
              >
                Create Video
              </Typography>
            </Button>
          </Box>
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              height: "100%",
            }}
          >
            <div
              style={{
                marginTop: "16px",
                overflowY: "auto",
                outline: isScrolled ? "1px solid #E0E3E4" : "none",
              }}
              onScroll={handleScroll}
            >
              <Grid
                container
                spacing={0}
                alignItems={"center"}
                justifyContent={"center"}
                style={{ padding: "16px", gap: "16px" }}
              >
                {projects.map((item) => (
                  <Grid item key={item.url}>
                    <VideosItem
                      item={item}
                      onSelectHandle={() => handleSelectProjectAsync(item)}
                      onMenu={(e) => handleMenu(e, item)}
                    />
                  </Grid>
                ))}
                {renders?.map((item) => (
                  <Grid item key={item.url}>
                    <VideosItemLegacy item={item} />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          {itemToDelete && (
            <ConfirmationDialog
              header="Confirm Deletion"
              description="Are you sure you want to delete it?"
              confirmButtonText="Delete"
              item={itemToDelete}
              onConfirm={handleDelete}
              onCancel={handleCloseDeleteDialog}
            />
          )}
          {deleteAllDialogOpen && (
            <ConfirmationDialog
              header="Confirm Deletion"
              description="Are you sure you want to delete all projects?"
              confirmButtonText="Delete"
              item={{}}
              onConfirm={handleDeleteAll}
              onCancel={handleCloseDeleteAllDialog}
            />
          )}
          {itemToRename && (
            <DuplicateItemDialog
              title="Rename"
              buttonText="Rename"
              item={itemToRename}
              onDuplicate={handleRename}
              onCancel={handleCloseRenameDialog}
            />
          )}
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            sx={{
              ".MuiMenu-paper": { borderRadius: "12px" },
              ".MuiMenu-list": { margin: "8px", padding: "0px" },
            }}
          >
            <MenuItem onClick={handleDuplicate} sx={menuItemStyle}>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <img src="assets/svg/CopyIcon.svg" width="24px" height="24px" />
                <Typography textAlign="center">Duplicate</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => handleShowRenameDialog()}
              sx={menuItemStyle}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <img
                  src="assets/svg/RenameIcon.svg"
                  width="24px"
                  height="24px"
                />
                <Typography textAlign="center">Rename</Typography>
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => handleShowDeleteDialog()}
              sx={menuItemStyle}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <img
                  src="assets/svg/DeleteIcon.svg"
                  width="24px"
                  height="24px"
                />
                <Typography textAlign="center">Delete</Typography>
              </Box>
            </MenuItem>
          </Menu>
        </div>
      )}
      {projectsEmpty && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src={imgUrl} alt="" width="300px" height="256px" />
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: 700,
              marginTop: "24px",
            }}
          >
            No videos yet
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              marginTop: "12px",
            }}
          >
            Your video projects will show up here. Get started by creating your
            first video!
          </Typography>
          <Button
            sx={{
              background: "#3050F5",
              borderRadius: "12px",
              padding: "10px 18px",
              height: "48px",
              width: "156px",
              marginTop: "40px",
              marginBottom: "32px",
              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#3050F5",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={() => {
              dispatch(showCreateVideoDialog());
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFF",
                fontWeight: 800,
              }}
            >
              Create Video
            </Typography>
          </Button>
        </div>
      )}
    </Box>
  );
};

export default withAITracking(reactPlugin, Videos, "Videos", "flex");
