import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Popover,
  Switch,
  SwitchProps,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getShareModelDialogOpen,
  hideShareModelDialog,
} from "../../store/dialogSlice";
import { useEffect, useState } from "react";
import {
  copyUserToSharedBlobAsync,
  deleteSharedBlobAsync,
  postSharedJsonAsync,
  postUserJsonAsync,
} from "../../managers/storage/AzureStorageManager";
import { timeoutAsync } from "../../helpers/Helper";
import { scriberConfig } from "../scene/types";

type ShareModelDialogProps = {
  shareLink: string;
  selectedModel: scriberConfig | null;
};

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#3050F5" : "#3050F5",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ShareModelDialog = ({
  shareLink,
  selectedModel,
}: ShareModelDialogProps) => {
  const dispatch = useAppDispatch();
  const dialogOpen = useAppSelector(getShareModelDialogOpen);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [sharingEnabled, setSharingEnabled] = useState(false);
  const [switchTypographyText, setSwitchTypographyText] =
    useState("Link Disabled");
  const [copyLinkButtonIcon, setCopyLinkButtonIcon] = useState(
    "assets/svg/LinkIcon.svg"
  );
  const [copyLinkButtonOpacity, setCopyLinkButtonOpacity] = useState(1);
  const [copyLinkButtonAwaiting, setCopyLinkButtonAwaiting] = useState(false);
  const [copyLinkButtonText, setCopyLinkButtonText] = useState("Copy Link");
  const [copyCodeButtonIcon, setCopyCodeButtonIcon] = useState(
    "assets/svg/LinkIcon.svg"
  );
  const [copyCodeButtonOpacity, setCopyCodeButtonOpacity] = useState(1);
  const [copyCodeButtonText, setCopyCodeButtonText] = useState("Copy Code");
  const [copyCodeButtonAwaiting, setCopyCodeButtonAwaiting] = useState(false);

  const [loading, setLoading] = useState(false);

  const popoverOpen = Boolean(popoverAnchorEl);
  const embedCode = `<iframe frameborder="0" width="560" height="315" src="${shareLink}" allowfullscreen="true" />`;

  useEffect(() => {
    if (sharingEnabled) {
      setSwitchTypographyText("Sharing Enabled");
    } else {
      setSwitchTypographyText("Sharing Disabled");
    }
  }, [sharingEnabled]);

  useEffect(() => {
    if (!selectedModel) return;
    if (selectedModel.shared !== null && selectedModel.shared !== undefined) {
      setSharingEnabled(selectedModel.shared);
    }
  }, [selectedModel?.shared]);

  const handleShareAsync = async () => {
    if (loading || !selectedModel) return;
    setSharingEnabled(true);
    if (!selectedModel.shared) {
      setLoading(true);
      await copyUserToSharedBlobAsync(
        `scribers/${selectedModel.id}.glb`,
        `scribers/${selectedModel.id}.glb`
      );
      const config = selectedModel;
      config!.shared = true;
      const previewConfig = { rotation: config?.rotation };
      await postSharedJsonAsync(previewConfig, `scribers/${config!.id}.json`);
      await postUserJsonAsync(config, `configs/scribers/${config!.id}.json`);
      setLoading(false);
    }
  };

  const handleUnshareAsync = async () => {
    if (loading || !selectedModel) return;
    setSharingEnabled(false);
    setLoading(true);

    const config = selectedModel;
    config!.shared = false;
    await postUserJsonAsync(config, `configs/scribers/${config!.id}.json`);
    await deleteSharedBlobAsync(`scribers/${selectedModel.id}.glb`);
    await deleteSharedBlobAsync(`scribers/${selectedModel.id}.json`);
    setLoading(false);
  };

  const handleCopyLinkAsync = async () => {
    if (copyLinkButtonAwaiting || loading || !sharingEnabled) return;
    setCopyLinkButtonAwaiting(true);
    setCopyLinkButtonOpacity(0);
    setTimeout(() => {
      setCopyLinkButtonIcon("assets/svg/CheckIconWhite.svg");
      setCopyLinkButtonText("Link Copied");
      setCopyLinkButtonOpacity(1);
    }, 300);
    navigator.clipboard.writeText(shareLink);
    await timeoutAsync(4000);
    setCopyLinkButtonOpacity(0);

    setTimeout(() => {
      setCopyLinkButtonIcon("assets/svg/LinkIcon.svg");
      setCopyLinkButtonText("Copy Link");
      setCopyLinkButtonOpacity(1);
    }, 300);
    setCopyLinkButtonAwaiting(false);
  };

  const handleCopyCodeAsync = async () => {
    if (copyCodeButtonAwaiting) return;

    setCopyCodeButtonAwaiting(true);
    setCopyCodeButtonOpacity(0);

    setTimeout(() => {
      setCopyCodeButtonIcon("assets/svg/CheckIconWhite.svg");
      setCopyCodeButtonText("Code Copied");
      setCopyCodeButtonOpacity(1);
    }, 300);
    navigator.clipboard.writeText(embedCode);
    await timeoutAsync(4000);
    setCopyCodeButtonOpacity(0);

    setTimeout(() => {
      setCopyCodeButtonText("Copy Code");
      setCopyCodeButtonIcon("assets/svg/LinkIcon.svg");
      setCopyCodeButtonOpacity(1);
    }, 300);

    setCopyCodeButtonAwaiting(false);
  };

  const handleEmbed = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading) return;
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePreviewPage = () => {
    if (loading) return;
    window.open(shareLink, "_blank");
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
  };

  const handleCloseDialog = () => {
    dispatch(hideShareModelDialog());
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      sx={{
        ".MuiDialog-paper": {
          backgroundColor: "#FFF",
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "18px" }}>Share your model</Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
            Only people with the link can access it.
          </Typography>

          <Typography
            onClick={handleCopyLinkAsync}
            sx={{
              marginTop: "12px",
              background: sharingEnabled ? "#F1F3F4" : "#e9e9ea",
              padding: "4px 8px",
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: 600,
              width: "412px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: sharingEnabled ? "pointer" : "",
              color: sharingEnabled ? "" : "#FFF",
              transition:
                "color 0.25s ease-in-out, background 0.25s ease-in-out",
            }}
          >
            {shareLink}
          </Typography>

          <Box sx={{ display: "flex", gap: "16px", marginTop: "16px" }}>
            <Button
              onClick={handleCopyLinkAsync}
              disabled={!sharingEnabled}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "132px",
                justifyContent: "space-between",
                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                ":disabled": { background: "#e9e9ea" },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <img
                src={copyLinkButtonIcon}
                width="20px"
                height="20px"
                style={{
                  opacity: copyLinkButtonOpacity,
                  transition: "opacity 0.25s ease-in-out",
                }}
              />
              <Typography
                sx={{
                  width: "100%",
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                  opacity: copyLinkButtonOpacity,
                  transition: "opacity 0.25s ease-in-out",
                }}
              >
                {copyLinkButtonText}
              </Typography>
            </Button>
            <Button
              onClick={(e) => handleEmbed(e)}
              disabled={!sharingEnabled}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "132px",

                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                ":disabled": { background: "#e9e9ea" },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                Embed Model
              </Typography>
            </Button>
            <Button
              onClick={handlePreviewPage}
              disabled={!sharingEnabled}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "132px",

                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                ":disabled": { background: "#e9e9ea" },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                Preview Page
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "0px",
              marginTop: "20px",
              padding: "0px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <IOSSwitch
                sx={{ mr: "8px" }}
                onChange={async (e) => {
                  if (e.target.checked) {
                    handleShareAsync();
                  } else {
                    handleUnshareAsync();
                  }
                }}
                checked={sharingEnabled}
              />
              <Typography>{switchTypographyText}</Typography>
            </Box>
            {loading && <CircularProgress size={"24px"} />}
          </Box>
          <Popover
            open={popoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              ".MuiPopover-paper": {
                borderRadius: "16px",
                marginTop: "12px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "16px",
                gap: "16px",
              }}
            >
              <Typography
                sx={{
                  background: "#F1F3F4",
                  borderRadius: "12px",
                  padding: "16px",
                  width: "300px",
                  fontWeight: 600,
                  userSelect: "text",
                }}
              >
                {embedCode}
              </Typography>
              <Button
                onClick={handleCopyCodeAsync}
                sx={{
                  background: "#3050F5",
                  borderRadius: "12px",
                  padding: "0px 12px",
                  height: "36px",

                  ":hover": {
                    background: "#3050F5",
                    boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                  },
                  ":active": {
                    background: "#2946DB",
                    boxShadow: "none",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
              >
                <img
                  src={copyCodeButtonIcon}
                  width="20px"
                  height="20px"
                  style={{
                    opacity: copyCodeButtonOpacity,
                    transition: "opacity 0.25s ease-in-out",
                  }}
                />
                <Typography
                  sx={{
                    width: "92px",
                    fontSize: 15,
                    color: "#FFF",
                    fontWeight: 600,
                    opacity: copyCodeButtonOpacity,
                    transition: "opacity 0.25s ease-in-out",
                  }}
                >
                  {copyCodeButtonText}
                </Typography>
              </Button>
            </Box>
          </Popover>
        </Box>
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModelDialog;
