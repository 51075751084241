const SelectedElementsIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="10" fill="#3050F5" fillOpacity="0.1" />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2803 12.623L13.127 10.547C12.8799 10.1021 12.7169 9.80918 12.5888 9.5946C12.48 9.41234 12.4353 9.35387 12.4332 9.35066C12.0205 8.89239 11.2949 8.91357 10.9096 9.39514C10.9078 9.39845 10.8665 9.45943 10.7685 9.64775C10.6532 9.86943 10.5076 10.1714 10.2868 10.6299L9.28723 12.7059C9.09967 13.0954 8.97911 13.3464 8.89429 13.5374C8.82298 13.698 8.8046 13.7561 8.80326 13.7599C8.66723 14.3098 9.01479 14.8628 9.56918 14.9788C9.57316 14.9793 9.63347 14.9879 9.8092 14.9933C10.0181 14.9998 10.2965 15 10.7288 15H12.8817C13.3388 15 13.6354 14.9998 13.8574 14.9929C14.0446 14.9871 14.1089 14.9778 14.113 14.9774C14.6838 14.8539 15.0278 14.2692 14.8585 13.7103C14.8569 13.7067 14.8339 13.6459 14.748 13.4794C14.6462 13.2819 14.5023 13.0225 14.2803 12.623ZM9.33382 8.16343C9.1301 8.422 8.91499 8.86876 8.48477 9.7623L7.48523 11.8383C7.12118 12.5944 6.93915 12.9724 6.8649 13.2672C6.44729 14.925 7.49741 16.5961 9.17227 16.939C9.47006 17 9.88965 17 10.7288 17H12.8817C13.77 17 14.2142 17 14.5203 16.9355C16.2474 16.5713 17.2885 14.8021 16.768 13.1154C16.6758 12.8165 16.4601 12.4282 16.0287 11.6517L14.8753 9.57572C14.3937 8.70881 14.1529 8.27535 13.9345 8.02911C12.694 6.63083 10.4906 6.69515 9.33382 8.16343Z"
        fill="#3050F5"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2803 24.623L25.127 22.547C24.8799 22.1021 24.7169 21.8092 24.5888 21.5946C24.48 21.4123 24.4353 21.3539 24.4332 21.3507C24.0205 20.8924 23.2949 20.9136 22.9096 21.3951C22.9078 21.3984 22.8665 21.4594 22.7685 21.6477C22.6532 21.8694 22.5076 22.1714 22.2868 22.6299L21.2872 24.7059C21.0997 25.0954 20.9791 25.3464 20.8943 25.5374C20.823 25.698 20.8046 25.7561 20.8033 25.7599C20.6672 26.3098 21.0148 26.8628 21.5692 26.9788C21.5732 26.9793 21.6335 26.9879 21.8092 26.9933C22.0181 26.9998 22.2965 27 22.7288 27H24.8817C25.3388 27 25.6354 26.9998 25.8574 26.9929C26.0446 26.9871 26.1089 26.9778 26.113 26.9774C26.6838 26.8539 27.0278 26.2692 26.8585 25.7103C26.8569 25.7067 26.8339 25.6459 26.748 25.4794C26.6462 25.2819 26.5023 25.0225 26.2803 24.623ZM21.3338 20.1634C21.1301 20.422 20.915 20.8688 20.4848 21.7623L19.4852 23.8383C19.1212 24.5944 18.9392 24.9724 18.8649 25.2672C18.4473 26.925 19.4974 28.5961 21.1723 28.939C21.4701 29 21.8896 29 22.7288 29H24.8817C25.77 29 26.2142 29 26.5203 28.9355C28.2474 28.5713 29.2885 26.8021 28.768 25.1154C28.6758 24.8165 28.4601 24.4282 28.0287 23.6517L26.8753 21.5757C26.3937 20.7088 26.1529 20.2754 25.9345 20.0291C24.694 18.6308 22.4906 18.6952 21.3338 20.1634Z"
        fill="#3050F5"
      />
      <path
        d="M16 24C16 26.6667 15.5556 28 12 28C8.44444 28 8 26.6667 8 24C8 21.3333 8.5 20 12 20C15.5 20 16 21.3333 16 24Z"
        stroke="#3050F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="24"
        cy="12"
        r="4"
        stroke="#3050F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SelectedElementsIcon;
