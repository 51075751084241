const SelectedModelsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <rect width="36" height="36" rx="10" fill="#3050F5" fillOpacity="0.1" />
      <path
        d="M10.2606 23.7786C9.81079 23.5274 9.47783 23.2412 9.2617 22.9199C9.04557 22.5928 8.9375 22.1254 8.9375 21.5179V14.1928C8.9375 13.7022 9.03388 13.2903 9.22665 12.9574C9.42526 12.6244 9.74069 12.344 10.173 12.1162L16.2538 8.77787C16.8146 8.46827 17.3958 8.31348 17.9975 8.31348C18.605 8.31348 19.1891 8.46827 19.7499 8.77787L25.8308 12.1162C26.2631 12.344 26.5756 12.6244 26.7684 12.9574C26.9611 13.2903 27.0575 13.7022 27.0575 14.1928V21.5179C27.0575 22.1254 26.9494 22.5928 26.7333 22.9199C26.523 23.2412 26.1901 23.5274 25.7344 23.7786L19.1453 27.3798C18.3801 27.7887 17.6178 27.7887 16.8584 27.3798L10.2606 23.7786ZM11.4873 22.0962L16.9723 25.1454V18.5388L10.9002 15.1917V21.1762C10.9002 21.3865 10.944 21.5618 11.0316 21.7019C11.1193 21.8421 11.2711 21.9736 11.4873 22.0962ZM24.5077 22.0962C24.7239 21.9736 24.8757 21.8421 24.9634 21.7019C25.051 21.5618 25.0948 21.3865 25.0948 21.1762V15.1917L19.0227 18.5388V25.1454L24.5077 22.0962ZM17.9975 16.7426L23.982 13.4568L18.8562 10.6354C18.2896 10.3258 17.72 10.3258 17.1476 10.6354L12.0218 13.4568L17.9975 16.7426Z"
        fill="#3050F5"
      />
    </svg>
  );
};

export default SelectedModelsIcon;
