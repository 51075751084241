import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { NavigatorModel, appModes, tabModes } from "./types";

const initialState: NavigatorModel = {
  appMode: appModes.sequencer,
  tabMode: tabModes.home,
  previousTabMode: tabModes.home,
  sceneSwitch: "video",
  saving: false,
};

export const navigatorSlice = createSlice({
  name: "navigator",
  initialState,
  reducers: {
    navigateTo: (state, action) => {
      state.appMode = action.payload;
    },
    switchTabTo: (state, action) => {
      state.previousTabMode = state.tabMode;
      state.tabMode = action.payload;
    },
    setSaving: (state, action) => {
      state.saving = action.payload;
    },
    setSceneSwitch: (state, action) => {
      state.sceneSwitch = action.payload;
    },
  },
});

export const getAppMode = (state: RootState) => state.navigator.appMode;
export const getTabMode = (state: RootState) => state.navigator.tabMode;
export const getPreviousTabMode = (state: RootState) =>
  state.navigator.previousTabMode;
export const getSaving = (state: RootState) => state.navigator.saving;
export const getSceneSwitch = (state: RootState) => state.navigator.sceneSwitch;

export const { navigateTo, switchTabTo, setSaving, setSceneSwitch } =
  navigatorSlice.actions;

export default navigatorSlice.reducer;
