import { Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getPreviewStringAsync } from "../../../managers/storage/StorageManager";
import { StoreItemModel } from "../../../store/types";
import { formatTimestamp } from "../../../helpers/Helper";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { getUserBlobAsync } from "../../../managers/storage/AzureStorageManager";

interface ProjectItemLegacyProps {
  item: StoreItemModel;
}

const VideosItemLegacy = ({ item }: ProjectItemLegacyProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    const getRenderPreviewsAsync = async () => {
      const newModel = JSON.parse(JSON.stringify(item));
      newModel.url = item.url.replace(/\.[^.]*$/, "");
      const preview = await getPreviewStringAsync(newModel);
      if (preview) {
        setPreviewUrl(preview);
      }
    };
    getRenderPreviewsAsync();
  }, [item]);

  const onSelectHandle = async () => {
    const blob = await getUserBlobAsync(item.url);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = item.name + ".mp4";
    link.click();
  };

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          className="gradient"
          style={{
            width: "244px",
            height: "144px",
            borderRadius: "12px",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        ></div>
        <Button
          data-track="ProjectClicked"
          style={{
            width: "244px",
            height: "144px",
            borderRadius: "12px",
            position: "relative",
            padding: 0,
            overflow: "hidden",
            opacity: previewUrl ? 1 : 0,
            background: previewUrl ? `url(${previewUrl}) no-repeat` : "",
            transition: "opacity 0.3s ease-in-out",
          }}
          onClick={() => onSelectHandle()}
        ></Button>
        <Typography
          style={{
            color: "#373E4E",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "24px",
            marginLeft: "2px",
            marginTop: "8px",
          }}
        >
          {item.name}
        </Typography>
      </div>
      <div
        style={{
          transition: "opacity 300ms",
          opacity: isHovered ? 0.3 : 0,
          width: "244px",
          height: "144px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          transition: "opacity 300ms",
          opacity: isHovered ? 1 : 0,
          position: "absolute",
          top: "8px",
          left: "12px",
          display: "flex",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <img src="assets/svg/TimeIconWhite.svg" />
        <Typography
          style={{
            color: "#FFF",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontVariantNumeric: "lining-nums tabular-nums",
            fontFeatureSettings: '"clig" off, "liga" off',
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties?.lastModified)[0]}
        </Typography>
        <img
          src="assets/svg/CalendarIconWhite.svg"
          style={{ marginLeft: "12px" }}
        />
        <Typography
          style={{
            color: "#FFF",
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties?.lastModified)[1]}
        </Typography>
      </div>

      <div
        style={{
          position: "absolute",
          left: "10px",
          bottom: "38px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          pointerEvents: "none",
        }}
      >
        <RestoreRoundedIcon fontSize="large" style={{ color: "white" }} />
      </div>
    </div>
  );
};

export default VideosItemLegacy;
