import * as BABYLON from "babylonjs";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAppMode } from "../../store/navigatorSlice";
import { appModes } from "../../store/types";
import { getBackImageUrl, getIsVertical } from "../../store/renderSlice";
import { timeoutAsync } from "../../helpers/Helper";
import {
  getLoaderState,
  getScene3DView,
  getSceneCanvasSize,
  getSceneLoading,
  setSceneCanvasSize,
} from "../../store/sceneSlice";
import { getProject } from "../../store/projectSlice";
import { postUserBlobAsync } from "../../managers/storage/AzureStorageManager";
import { saveBlobToIDBAsync } from "../../managers/storage/DbManager";
import GradientTypography from "../controls/GradientTypography";
import { getGeneratingText } from "../../store/aiSlice";

type EngineProps = {
  onInit: (
    engine: BABYLON.Engine,
    mainCanvas: HTMLCanvasElement,
    previewCanvas: HTMLCanvasElement
  ) => void;
};

const Engine = ({ onInit }: EngineProps) => {
  const dispatch = useAppDispatch();
  const isVertical = useAppSelector(getIsVertical);
  const generatingText = useAppSelector(getGeneratingText);
  const parentRef = useRef<HTMLDivElement>(null);
  const engineCanvas = useRef({} as HTMLCanvasElement);
  const mainCanvas = useRef({} as HTMLCanvasElement);
  const previewCanvas = useRef({} as HTMLCanvasElement);
  const appMode = useAppSelector(getAppMode);
  const sceneLoading = useAppSelector(getSceneLoading);
  const engine = useRef<BABYLON.Engine>();
  const previewSize = useAppSelector(getSceneCanvasSize);
  const [parentSize, setParentSize] = useState({ width: 0, height: 0 });
  const abortControllerRef = useRef(new AbortController());
  const threeDView = useAppSelector(getScene3DView);
  const [scale, setScale] = useState(1);
  const project = useAppSelector(getProject);
  const loading = useAppSelector(getLoaderState);
  const backImage = useAppSelector(getBackImageUrl);

  useEffect(() => {
    if (!engineCanvas.current) return;

    engine.current = new BABYLON.Engine(engineCanvas.current, false, {
      preserveDrawingBuffer: true,
      adaptToDeviceRatio: true,
      powerPreference: "high-performance",
    });
    engine.current.inputElement = mainCanvas.current;

    const parent = parentRef.current;
    if (!engineCanvas.current || !parent) {
      return;
    }
    engineCanvas.current.width = parent.clientWidth;
    engineCanvas.current.height = parent.clientHeight;

    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length) {
        return;
      }

      const parentEntry = entries[0];
      const parentWidth = parentEntry.contentRect.width;
      const parentHeight = parentEntry.contentRect.height;
      setParentSize({ width: parentWidth, height: parentHeight });
    });

    resizeObserver.observe(parent);
    onInit(engine.current, mainCanvas.current, previewCanvas.current);

    return () => {
      resizeObserver.disconnect();
      engine.current?.dispose();
    };
  }, []);

  useEffect(() => {
    const goAsync = async () => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      let newWidth, newHeight;
      if (isVertical) {
        newWidth = Math.min(parentSize.width, (parentSize.height * 9) / 16);
        newHeight = (newWidth * 16) / 9;
      } else {
        newWidth = Math.min(parentSize.width, (parentSize.height * 16) / 9);
        newHeight = (newWidth * 9) / 16;
      }
      await timeoutAsync(300);
      if (signal.aborted) {
        return;
      }
      dispatch(setSceneCanvasSize({ width: newWidth, height: newHeight }));
      resize();
    };
    goAsync();
  }, [parentSize, isVertical]);

  useEffect(() => {
    if (threeDView) {
      const targetWidth = isVertical ? 200 : 356;
      const targetHeight = isVertical ? 356 : 200;
      const scaleX = targetWidth / previewSize.width;
      const scaleY = targetHeight / previewSize.height;
      setScale(Math.min(scaleX, scaleY));
    } else {
      setScale(1);
    }
  }, [threeDView, previewSize]);

  useEffect(() => {
    return () => {
      const runAsync = async () => {
        await timeoutAsync(100);
        previewCanvas.current.toBlob(async (b) => {
          await saveBlobToIDBAsync(
            b!,
            `previews/projects/${project.name}.json.webp`
          );
          await postUserBlobAsync(
            b!,
            `previews/projects/${project.name}.json.webp`,
            false
          );
        });
      };

      if (loading && project.name && previewCanvas.current) {
        runAsync();
      }
    };
  }, [loading, project.name]);

  const resize = () => {
    engine.current?.resize();
  };

  return (
    <div
      ref={parentRef}
      style={{
        display: appMode === appModes.render ? "none" : "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        className="backgroundGradient"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: threeDView ? 0 : (parentSize.height - previewSize.height) / 2,
          left: threeDView ? 0 : (parentSize.width - previewSize.width) / 2,
          width: threeDView ? "100%" : previewSize.width,
          height: threeDView ? "100%" : previewSize.height,
          opacity: sceneLoading ? 1 : 0,
          pointerEvents: "none",
          zIndex: "100000",
          borderRadius: "16px",
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <div
          style={{
            width: "calc(100% - 4px)",
            height: "calc(100% - 4px)",
            backgroundColor: "#FFFD",
            borderRadius: "14px",
            pointerEvents: "none",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          backgroundColor: "transparent",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: sceneLoading ? 1 : 0,
          pointerEvents: "none",
          zIndex: "1000000",
          borderRadius: "16px",
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        <GradientTypography fontSize="48px" text={`${generatingText}...`} />
      </div>
      <canvas ref={engineCanvas} style={{ display: "none" }} />
      <canvas
        ref={mainCanvas}
        style={{
          outline: "none",
          pointerEvents: threeDView ? "inherit" : "none",
          opacity: threeDView ? 1 : 0,
          borderRadius: "16px",
          position: "absolute",
          transform: `translate(-50%, -50%)`,
          top: "50%",
          left: "50%",
          width: previewSize.width,
          height: previewSize.height,
        }}
      />
      <div
        style={{
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          transition: "transform 0.3s",
          transform: threeDView
            ? `scale(${scale}) translate(calc(50% - ${
                12 / scale
              }px), calc(50% - ${12 / scale}px))`
            : "none",
          transformOrigin: "right bottom",
        }}
      >
        <img
          src={backImage}
          alt=""
          style={{
            borderRadius: `${16 / scale}px`,
            border: `${threeDView ? 2 / scale : 0}px solid white`,
            width: previewSize.width,
            height: previewSize.height,
            transform: threeDView ? "translate(-50%, -50%)" : "none",
            transition: "transform 0.3s",
            position: "absolute", // Ensures the img aligns with the canvas
            objectFit: "cover",
          }}
        />
        <canvas
          ref={previewCanvas}
          style={{
            borderRadius: `${16 / scale}px`,
            border: `${threeDView ? 2 / scale : 0}px solid white`,
            width: previewSize.width,
            height: previewSize.height,
            transform: threeDView ? "translate(-50%, -50%)" : "none",
            transition: "transform 0.3s",
            position: "absolute", // Ensures the canvas aligns with the img
          }}
        />
      </div>
    </div>
  );
};

export default Engine;
