import { Box, Typography } from "@mui/material";
import { isIOS } from "react-device-detect";

const MobileView = () => {
  const appStoreHTML = {
    __html: `<a href="https://apps.apple.com/us/app/lumiere3d/id6468220159?itsct=apps_box_badge&amp;itscg=30200" style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1707350400" alt="Download on the App Store" style="border-radius: 13px; width: 250px; height: 83px;"></a>`,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
      }}
    >
      <img src="assets/images/AppIcon.webp" width="100px" height="100px" />
      {isIOS ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            align="center"
            sx={{
              fontSize: "18px",
              margin: "0px 32px",
              marginTop: "40px",
            }}
          >
            We are sorry, but the website doesn't support this kind of device at
            the moment. Please try our Mobile App
          </Typography>
          <div
            dangerouslySetInnerHTML={appStoreHTML}
            style={{ marginTop: "32px" }}
          />
        </div>
      ) : (
        <Typography
          align="center"
          sx={{
            fontSize: "18px",
            margin: "0px 32px",
            marginTop: "32px",
          }}
        >
          We are sorry, but the application doesn't support this kind of device
          at the moment.
          <br />
          <br /> Please try our app on any{" "}
          <span style={{ fontWeight: 900 }}>desktop</span>,{" "}
          <span style={{ fontWeight: 900 }}>tablet</span> or{" "}
          <span style={{ fontWeight: 900 }}>IOS</span> device
        </Typography>
      )}
    </Box>
  );
};

export default MobileView;
