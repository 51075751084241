import {
  Sequence,
  ShotModel,
  StoreItemModel,
  Transform,
  tabModes,
} from "../../store/types";
import { useAppSelector } from "../../store/hooks";
import {
  getTimelineCaretVideoTrack,
  getTimelinePlaying,
  setTimelinePlaying,
} from "../../store/timelineSlice";
import { useEffect, useRef } from "react";
import { getAnimationsDuration, timeoutAsync } from "../../helpers/Helper";
import { getPreviousTabMode, switchTabTo } from "../../store/navigatorSlice";
import { useDispatch } from "react-redux";
import {
  setAnimatableAnimations,
  setAnimatableTransform,
  setSequenceDuration,
} from "../../store/projectSlice";
import {
  getLoaderState,
  getSceneLoading,
  setAnimatableName,
  setLoading,
  setSceneLoading,
} from "../../store/sceneSlice";
import { LumiereScene } from "./LumiereScene";
import {
  getBlobAsync,
  getJsonAsync,
} from "../../managers/storage/StorageManager";
import {
  increaseCurrentProgress,
  increaseTotalProgress,
} from "../../store/progressSlice";
import { getGeneratingShot } from "../../store/aiSlice";
import { setBackImageUrl } from "../../store/renderSlice";

type SequenceSceneProps = {
  sequence: Sequence;
  engineContext: any;
};

const SequenceScene = ({ sequence, engineContext }: SequenceSceneProps) => {
  const dispatch = useDispatch();
  const playing = useAppSelector(getTimelinePlaying);
  const loading = useAppSelector(getLoaderState);
  const sceneLoading = useAppSelector(getSceneLoading);
  const previousTabMode = useAppSelector(getPreviousTabMode);
  const generatingShot = useAppSelector(getGeneratingShot);
  const lumiereScene = useRef<LumiereScene>();
  const mainObjectTransformRef = useRef<Transform | undefined>();
  const mainCameraTransformRef = useRef<Transform | undefined>();
  const caretVideoTrack = useAppSelector(getTimelineCaretVideoTrack);

  useEffect(() => {
    lumiereScene.current = new LumiereScene(
      engineContext.engine,
      engineContext.mainCanvas,
      engineContext.previewCanvas,
      sequence
    );
    lumiereScene.current.onAnimatableSelect = (node) => {
      dispatch(setAnimatableName(node.name));
    };
    lumiereScene.current.onAnimatableTransform = (transform) => {
      dispatch(setAnimatableTransform(transform));
    };
    dispatch(switchTabTo(tabModes.styles));
  }, [engineContext]);

  useEffect(() => {
    return () => {
      lumiereScene.current?.dispose();
    };
  }, []);

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!sequence.environment?.model.id) return;

      dispatch(increaseTotalProgress(5));
      dispatch(setLoading(true));

      await lumiereScene.current!.setEnvironmentAsync(
        sequence.environment.model
      );

      dispatch(increaseCurrentProgress(5));
      dispatch(setLoading(false));
    };
    asyncWrapper();
  }, [sequence.environment?.model.id]);

  useEffect(() => {
    mainObjectTransformRef.current = sequence.mainObject?.transform;
  }, [sequence.mainObject?.transform]);

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!sequence.mainObject?.model.id) return;
      dispatch(increaseTotalProgress(5));
      dispatch(setLoading(true));

      await lumiereScene.current!.setMainObjectAsync(
        sequence.mainObject.model,
        dispatch,
        mainObjectTransformRef.current
      );
      dispatch(increaseCurrentProgress(5));
      dispatch(setLoading(false));
    };
    asyncWrapper();
  }, [sequence.mainObject?.model]);

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!sequence.skybox?.model?.id) return;

      dispatch(increaseTotalProgress(5));
      if (!sceneLoading) dispatch(setLoading(true));
      else dispatch(setSceneLoading(true));

      await lumiereScene.current!.setSkyboxAsync(sequence.skybox.model);

      dispatch(increaseCurrentProgress(5));
      if (!sceneLoading) dispatch(setLoading(false));
      else {
        dispatch(setSceneLoading(false));
        await timeoutAsync(500);
        dispatch(setTimelinePlaying(true));
      }

      if (!sequence.skybox.model.url.endsWith("env")) {
        const backImage = await getBlobAsync(sequence.skybox.model);
        dispatch(setBackImageUrl(URL.createObjectURL(backImage!)));
      } else {
        dispatch(setBackImageUrl(""));
      }
    };
    asyncWrapper();
  }, [sequence.skybox?.model?.id]);

  useEffect(() => {
    mainCameraTransformRef.current = sequence.camera.transform;
  }, [sequence.camera.transform]);

  useEffect(() => {
    const asyncWrapper = async () => {
      if (!sequence.shot?.id) return;

      dispatch(increaseTotalProgress(5));
      if (!generatingShot) dispatch(setLoading(true));
      else {
        dispatch(setSceneLoading(true));
      }

      await setShotAsync(sequence.shot!);

      dispatch(increaseCurrentProgress(5));
      if (!generatingShot) dispatch(setLoading(false));
      if (sceneLoading) {
        dispatch(setSceneLoading(false));
        await timeoutAsync(500);
        dispatch(setTimelinePlaying(true));
      }
    };
    asyncWrapper();
  }, [sequence.shot?.id]);

  useEffect(() => {
    if (
      previousTabMode !== tabModes.developer ||
      !lumiereScene.current?.mainCamera
    )
      return;
    dispatch(
      setAnimatableAnimations({
        sequenceId: sequence.id,
        animatableId: lumiereScene.current.mainCamera.id,
        animations: lumiereScene.current.mainCamera.animations.map((a) =>
          a.serialize()
        ),
      })
    );
    if (lumiereScene.current.mainObject) {
      dispatch(
        setAnimatableAnimations({
          sequenceId: sequence.id,
          animatableId: lumiereScene.current.mainObject.id,
          animations: lumiereScene.current.mainObject.animations.map((a) =>
            a.serialize()
          ),
        })
      );
    }
    lumiereScene.current.scene.lights.forEach((l) => {
      dispatch(
        setAnimatableAnimations({
          sequenceId: sequence.id,
          animatableId: l.id,
          animations: l.animations.map((a) => a.serialize()),
        })
      );
    });
    dispatch(
      setSequenceDuration({
        sequenceId: sequence.id,
        duration: getAnimationsDuration(
          lumiereScene.current.mainCamera.animations.map((a) => a.serialize())
        ),
      })
    );
  }, [previousTabMode]);

  async function setShotAsync(model: StoreItemModel) {
    const shotModel = (await getJsonAsync(model)) as ShotModel;
    lumiereScene.current!.setShot(shotModel, mainCameraTransformRef.current);
    const duration = getAnimationsDuration(shotModel.camera.animations);
    dispatch(
      setSequenceDuration({ sequenceId: sequence.id, duration: duration })
    );
  }

  useEffect(() => {
    if (loading) {
      lumiereScene.current?.toggleRenderLoop(false);
    } else {
      if (caretVideoTrack.id === sequence.id) {
        lumiereScene.current?.toggleRenderLoop(true);
        if (playing) {
          lumiereScene.current?.playScene(caretVideoTrack.frame);
        } else {
          lumiereScene.current?.scene?.stopAllAnimations();
          lumiereScene.current?.playFrameScene(caretVideoTrack.frame);
        }
      } else {
        lumiereScene.current?.scene?.stopAllAnimations();
        lumiereScene.current?.toggleRenderLoop(false);
      }
    }
  }, [caretVideoTrack, playing, loading]);

  return <></>;
};

export default SequenceScene;
