import { Dialog, DialogContent, Button, Box, Typography } from "@mui/material";

interface WarningDialogProps {
  header: string;
  description: string;
  confirmButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const WarningDialog = ({
  header,
  description,
  confirmButtonText,
  onConfirm,
  onCancel,
}: WarningDialogProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case "Escape":
        event.preventDefault();
        onCancel();
        break;
      case "Enter":
        event.preventDefault();
        handleConfirm();
        break;
    }
  };

  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <Dialog
      open={true}
      onKeyDown={handleKeyDown}
      onClose={onCancel}
      sx={{
        ".MuiDialog-paper": {
          backgroundColor: "#faaf00",
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px", color: "#FFF" }}>
            {header}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              marginTop: "8px",
              whiteSpace: "pre-line",
              color: "#FFF",
            }}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: "20px",
              gap: "8px",
            }}
          >
            <Button
              onClick={handleConfirm}
              sx={{
                background: "#f25829",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "88px",
                gap: "4px",

                ":hover": {
                  background: "#e34d20",
                  boxShadow: "0px 10px 10px 0px rgba(227, 77, 32, 0.24)",
                },
                ":active": {
                  background: "#e34d20",
                  boxShadow: "none",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                {confirmButtonText}
              </Typography>
            </Button>
            <Button
              onClick={onCancel}
              sx={{
                background: "#FFF",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "88px",
                justifyContent: "space-between",
                gap: "4px",

                ":hover": {
                  background: "#FFF",
                  boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: 15,
                  color: "#454E63",
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WarningDialog;
