import { Button, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import {
  getProject,
  setTextAppearanceProps,
} from "../../../../store/projectSlice";
import { getTimelineSelectedItem } from "../../../../store/timelineSlice";
import { TimelineItemType } from "../../../../store/types";

const FontColorPicker = () => {
  const dispatch = useDispatch();
  const project = useAppSelector(getProject);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [color, setColor] = useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (selectedItem?.type === TimelineItemType.text) {
      const text = project.texts?.find((t) => t.id === selectedItem.id);
      if (text) {
        setColor(text.appearance.color ?? text.template.css.color);
      }
    }
  }, [selectedItem?.id, selectedItem?.type, project.texts]);

  function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handlePickerChange(selectedColor: string) {
    setColor(selectedColor);
    dispatch(
      setTextAppearanceProps({
        id: selectedItem?.id,
        data: { color: selectedColor },
      })
    );
  }

  return (
    <div>
      <Button
        onClick={handleButtonClick}
        sx={{
          backgroundColor: color,
          border: "1px solid gray",
          maxHeight: "32px",
          minHeight: "32px",
          maxWidth: "32px",
          minWidth: "32px",
          boxSizing: "border-box",
          borderRadius: "4px",
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div style={{ padding: "16px" }}>
          <HexColorPicker
            color={color}
            onChange={(h) => handlePickerChange(h)}
          />
        </div>
      </Popover>
    </div>
  );
};

export default FontColorPicker;
