import { Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getSequenceById,
  getStorageItemFromSharedStorageData,
  timeoutAsync,
} from "../../helpers/Helper";
import { setProgressText } from "../../store/progressSlice";
import { getProject, setSequenceSkybox } from "../../store/projectSlice";
import {
  getTimelineSelectedItem,
  setTimelinePlaying,
} from "../../store/timelineSlice";
import { setSceneLoading } from "../../store/sceneSlice";
import { getSharedStorageDataAsync } from "../../managers/storage/AzureStorageManager";
import { setGeneratingText } from "../../store/aiSlice";
import {
  drainCreditAsync,
  getUserSettingsAsync,
} from "../../managers/ClientManager";
import {
  proTestNoCreditsString,
  demoStarterNoCreditsString,
} from "../../settings/GlobalStrings";
import { showCustomAlert } from "../../store/dialogSlice";
import {
  getUserLeftCredits,
  getUserSubscriptionLevel,
} from "../../store/userSlice";

const RegenerateBackgroundButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const leftCredits = useAppSelector(getUserLeftCredits);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);

  const handleCreateAIBackground = async () => {
    if (!leftCredits || leftCredits <= 0) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestNoCreditsString
            : demoStarterNoCreditsString
        )
      );
      return;
    }

    drainCreditAsync(dispatch);
    dispatch(setGeneratingText("Generating Background"));
    dispatch(setSceneLoading(true));
    dispatch(setTimelinePlaying(false));
    // const sequence = getSequenceById(project, selectedItem?.id!)!;
    const envStorageData = await getSharedStorageDataAsync("ai-env");
    const backgrounds = getStorageItemFromSharedStorageData(envStorageData);

    let i = Math.floor(Math.random() * backgrounds.length);

    project.sequences.forEach((s) => {
      dispatch(setSequenceSkybox({ sequenceId: s.id, model: backgrounds[i] }));
    });
    // dispatch(
    //   setSequenceSkybox({ sequenceId: sequence.id, model: backgrounds[i] })
    // );
    await timeoutAsync(300);
    dispatch(setSceneLoading(false));
  };

  return (
    <Button
      sx={{
        background: "#FFF",
        borderRadius: "12px",
        border: "2px solid #4261FF",
        padding: "10px 18px",
        height: "36px",
        width: "calc(100% - 32px)",
        ":hover": {
          background: "#FFF",
          boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
        },
        ":active": {
          background: "#FFF",
          boxShadow: "none",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      onClick={handleCreateAIBackground}
    >
      <Typography
        sx={{
          color: "#3050F5",
        }}
      >
        Background
      </Typography>
    </Button>
  );
};

export default RegenerateBackgroundButton;
