import { Box } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { getTabMode } from "../../../store/navigatorSlice";
import { TimelineItemType, tabModes } from "../../../store/types";
import { getEditorViewVisible } from "../../../store/editorSlice";
import SaveShotButton from "./SaveShotButton";
import { getTimelineSelectedItem } from "../../../store/timelineSlice";
import TextHeader from "./text/TextHeader";

const SceneHeader = () => {
  const tabMode = useAppSelector(getTabMode);
  const editorViewVisible = useAppSelector(getEditorViewVisible);
  const selectedItem = useAppSelector(getTimelineSelectedItem);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "48px",
        maxHeight: "48px",
      }}
    >
      {tabMode !== tabModes.home && editorViewVisible && <SaveShotButton />}
      {selectedItem?.type === TimelineItemType.text && (
        <TextHeader key={selectedItem.id} />
      )}
    </Box>
  );
};

export default SceneHeader;
