import { Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { getTimelineSelectedItem } from "../../store/timelineSlice";
import { TimelineItemType } from "../../store/types";
import RegenerateAudioButton from "../controls/RegenerateAudioButton";
import RegenerateBackgroundButton from "../controls/RegenerateBackgroundButton";
import RegenerateProjectButton from "../controls/RegenerateProjectButton";
import RegenerateShotButton from "../controls/RegenerateShotButton";
import RegenerateTextButton from "../controls/RegenerateTextButton";
import TestAIButton from "../controls/TestAIButton";
import FontResizer from "./header/text/FontResizer";
import FontColorPicker from "./header/text/FontColorPicker";
import { getEditorViewVisible } from "../../store/editorSlice";
import { getSceneLoading } from "../../store/sceneSlice";
import RegenerateShotBackgroundButton from "../controls/RegenerateShotBackgroundButton";
import AddTextButton from "../controls/AddTextButton";
import AddBackButton from "../controls/AddBackButton";

const SceneTab = () => {
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const editorViewVisible = useAppSelector(getEditorViewVisible);
  const sceneLoading = useAppSelector(getSceneLoading);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <div
        style={{
          padding: "16px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: "8px",
          borderRadius: "16px",
          border: "2px solid #dee1e3",
          opacity: sceneLoading ? 0.75 : 1,
          transition: "opacity 0.3s ease-in-out",
          pointerEvents: sceneLoading ? "none" : "all",
        }}
      >
        <Typography
          sx={{
            alignSelf: "flex-start",
            marginLeft: "16px",
            fontSize: "20px",
          }}
        >
          Regenerate:
        </Typography>
        <RegenerateProjectButton />
        <RegenerateAudioButton />
        <RegenerateBackgroundButton />
        <Typography
          sx={{
            alignSelf: "flex-start",
            marginLeft: "16px",
            fontSize: "20px",
          }}
        >
          Add:
        </Typography>
        <AddTextButton />
        <AddBackButton />
      </div>
      {(selectedItem?.type === TimelineItemType.threeD ||
        selectedItem?.type === TimelineItemType.text) && (
        <div
          style={{
            padding: "16px 0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "8px",
            borderRadius: "16px",
            border: "2px solid #dee1e3",
            opacity: sceneLoading ? 0.75 : 1,
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: sceneLoading ? "none" : "all",
          }}
        >
          {selectedItem?.type === TimelineItemType.threeD && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "flex-start",
                  marginLeft: "16px",
                  fontSize: "20px",
                }}
              >
                Selected shot:
              </Typography>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <RegenerateShotButton />
                <RegenerateShotBackgroundButton />
                {editorViewVisible && <TestAIButton />}
              </div>
            </div>
          )}
          {selectedItem?.type === TimelineItemType.text && (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  alignSelf: "flex-start",
                  marginLeft: "16px",
                  fontSize: "20px",
                }}
              >
                Selected text:
              </Typography>
              <RegenerateTextButton />
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                }}
              >
                <FontResizer />
                <FontColorPicker />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SceneTab;
