import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ProgressModel } from "./types";

const initialState: ProgressModel = {
  currentDownloadProgress: 0,
  totalDownloadProgress: 0,
  downloadState: "Starting",
};

export const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    increaseCurrentProgress: (state, action) => {
      state.currentDownloadProgress += action.payload;
    },
    increaseTotalProgress: (state, action) => {
      state.totalDownloadProgress += action.payload;
    },
    resetProgress: (state) => {
      state.currentDownloadProgress = 0;
      state.totalDownloadProgress = 0;
    },
    setProgressText: (state, action) => {
      state.downloadState = action.payload;
    },
  },
});

export const getCurrentDownloadProgress = (state: RootState) =>
  state.progress.currentDownloadProgress;
export const getTotalDownloadProgress = (state: RootState) =>
  state.progress.totalDownloadProgress;
export const getDownloadState = (state: RootState) =>
  state.progress.downloadState;

export const {
  resetProgress,
  increaseCurrentProgress,
  increaseTotalProgress,
  setProgressText,
} = progressSlice.actions;

export default progressSlice.reducer;
