import { Box, Menu, MenuItem, Typography } from "@mui/material";
import VideoTrackItem from "./VideoTrackItem";
import { useAppSelector } from "../../../../store/hooks";
import {
  addSequence,
  getProject,
  removeSequence,
  reorderSequences,
  setProjectAudioTrims,
  setSequenceSkybox,
} from "../../../../store/projectSlice";
import {
  getTimelineCaretTime,
  getTimelineCaretVideoTrack,
  getTimelinePlaying,
  setTimelineCaretTime,
  setTimelineCaretVideoTrack,
  setTimelinePlaying,
  setTimelineSelectedItem,
} from "../../../../store/timelineSlice";
import { useEffect, useState } from "react";
import { animationsFps } from "../../../../settings/GlobalSettings";
import { useDispatch } from "react-redux";
import { getProjectDuration, timeoutAsync } from "../../../../helpers/Helper";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import { v4 as uuidv4 } from "uuid";
import { Project, Sequence, TimelineItemType } from "../../../../store/types";

const menuItemStyle = {
  borderRadius: "12px",
  transition: "background-color 0.25s",
  padding: "8px 12px",
  ":active": { backgroundColor: "#EDEDED" },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
};
let animationAbortController = new AbortController();

type VideoTrackProps = { engineContext: any };

function VideoTrack({ engineContext }: VideoTrackProps) {
  const dispatch = useDispatch();
  const project = useAppSelector(getProject);
  const caretTime = useAppSelector(getTimelineCaretTime);
  const playing = useAppSelector(getTimelinePlaying);
  const caretVideoTrack = useAppSelector(getTimelineCaretVideoTrack);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSequenceId, setMenuSequenceId] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null as null | string);

  useEffect(() => {
    dispatch(
      setTimelineSelectedItem({
        id: project.sequences[0].id,
        type: TimelineItemType.threeD,
      })
    );
    dispatch(
      setTimelineCaretVideoTrack({ id: project.sequences[0].id, frame: 0 })
    );
  }, [project.id]);

  useEffect(() => {
    if (!playing) {
      const caretFrame = Math.floor(caretTime * animationsFps);
      let sequenceId = "";
      let sequenceFrame = 0;
      let accDur = 0;
      for (const sequence of project.sequences) {
        const trimmedDuration =
          sequence.duration - sequence.trims[0] - sequence.trims[1];
        if (caretFrame >= trimmedDuration + accDur) {
          accDur += trimmedDuration;
          continue;
        } else {
          sequenceId = sequence.id;
          sequenceFrame = caretFrame - accDur + sequence.trims[0];
          break;
        }
      }
      dispatch(
        setTimelineCaretVideoTrack({ id: sequenceId, frame: sequenceFrame })
      );
    }
  }, [caretTime, playing, project]);

  useEffect(() => {
    if (playing) {
      const playAsync = async (signal: AbortSignal) => {
        const sequence = project.sequences.find(
          (s) => s.id === caretVideoTrack.id
        )!;
        await timeoutAsync(
          ((sequence.duration - sequence.trims[1] - caretVideoTrack.frame) /
            animationsFps) *
            1000
        );
        if (signal.aborted) return;
        const index = project.sequences.findIndex(
          (s) => s.id === caretVideoTrack.id
        );
        const nextSequenceIndex =
          index === project.sequences.length - 1 ? 0 : index + 1;
        if (nextSequenceIndex === 0) {
          dispatch(setTimelinePlaying(false));
          dispatch(setTimelineCaretTime(0));
          return;
        }
        const nextSequence = project.sequences[nextSequenceIndex];
        dispatch(
          setTimelineCaretVideoTrack({
            id: nextSequence.id,
            frame: nextSequence.trims[0],
          })
        );
      };

      animationAbortController.abort();
      animationAbortController = new AbortController();
      playAsync(animationAbortController.signal);
    } else {
      animationAbortController.abort();
    }
  }, [playing, caretVideoTrack, project]);

  const handleDragEnd = (result: any) => {
    dispatch(
      reorderSequences({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  const handleMenu = (
    event: React.MouseEvent<HTMLElement>,
    sequenceId: string
  ) => {
    setMenuSequenceId(sequenceId);
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const handleSyncBackground = () => {
    handleCloseMenu();

    const sequence = project.sequences.find((s) => s.id === menuSequenceId);

    project.sequences.forEach((s) => {
      if (s.id === sequence?.id) return;

      dispatch(
        setSequenceSkybox({ sequenceId: s.id, model: sequence?.skybox?.model })
      );
    });
  };

  const handleDuplicate = async () => {
    handleCloseMenu();
    dispatch(setTimelinePlaying(false));
    await timeoutAsync(100);
    const sequence = project.sequences.find((s) => s.id === menuSequenceId);
    const deepClone = JSON.parse(JSON.stringify(sequence)) as Sequence;
    deepClone.id = uuidv4();
    if (project.audio) {
      const audioDuration =
        (getProjectDuration(project) + deepClone.duration) / animationsFps;
      dispatch(
        setProjectAudioTrims([
          0,
          project.audio.duration - audioDuration >= 0
            ? project.audio.duration - audioDuration
            : 0,
        ])
      );
    }
    dispatch(addSequence(deepClone));
  };

  const handleDelete = async () => {
    handleCloseMenu();

    dispatch(setTimelinePlaying(false));
    await timeoutAsync(100);

    const projectCopy = JSON.parse(JSON.stringify(project)) as Project;

    dispatch(removeSequence(menuSequenceId));

    if (project.audio) {
      const sequenceDuration = projectCopy.sequences.find(
        (s) => s.id === menuSequenceId
      )?.duration;

      if (!sequenceDuration) return;

      dispatch(
        setProjectAudioTrims([
          project.audio.trims[0],
          project.audio.duration -
            (getProjectDuration(projectCopy) - sequenceDuration) /
              animationsFps,
        ])
      );
    }
  };

  const handleCloseDeleteDialog = () => {
    setItemToDelete(null);
  };

  const handleShowDeleteDialog = () => {
    setItemToDelete("sequence");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        height: "64px",
      }}
    >
      <Box sx={{ minWidth: "32px", height: "64px" }}></Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="videoItems" direction="horizontal">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {project.sequences.map((s, i) => (
                <VideoTrackItem
                  key={s.id}
                  sequence={s}
                  index={i}
                  onMenu={handleMenu}
                  engineContext={engineContext}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ minWidth: "32px", height: "64px" }}></Box>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          ".MuiMenu-paper": { borderRadius: "12px" },
          ".MuiMenu-list": { margin: "8px", padding: "0px" },
        }}
      >
        <MenuItem onClick={handleSyncBackground} sx={menuItemStyle}>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/SyncIcon.svg" width="24px" height="24px" />
            <Typography textAlign="center">Sync Background</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleDuplicate} sx={menuItemStyle}>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/CopyIcon.svg" width="24px" height="24px" />
            <Typography textAlign="center">Duplicate</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={handleShowDeleteDialog}
          disabled={project.sequences.length < 2}
          sx={menuItemStyle}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/DeleteIcon.svg" width="24px" height="24px" />
            <Typography textAlign="center">Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>
      {itemToDelete && (
        <ConfirmationDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete it?"
          confirmButtonText="Delete"
          item={itemToDelete}
          onConfirm={handleDelete}
          onCancel={handleCloseDeleteDialog}
        />
      )}
    </Box>
  );
}

export default VideoTrack;
