import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getImageDialogItem, hideImageDialog } from "../../store/dialogSlice";
import { getBlobAsync } from "../../managers/storage/StorageManager";
import heic2any from "heic2any";

const ImageDialog = () => {
  const dispatch = useAppDispatch();
  const imageDialogItem = useAppSelector(getImageDialogItem);
  const [url, setUrl] = useState("");
  const dialogOpen = imageDialogItem ? true : false;

  useEffect(() => {
    const asyncWrapper = async () => {
      let image = await getBlobAsync(imageDialogItem!);

      if (imageDialogItem?.id.toLowerCase().endsWith(".heic")) {
        image = (await heic2any({
          blob: image!,
          toType: "image/png",
          quality: 1,
        })) as Blob;
      }

      setUrl(URL.createObjectURL(image!));
    };
    asyncWrapper();
  }, [imageDialogItem?.id]);

  const handleCloseDialog = () => {
    dispatch(hideImageDialog());
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      maxWidth="lg"
      fullWidth={true}
      sx={{
        overflow: "visible",
        ".MuiDialog-paper": {
          borderRadius: "16px",
          overflow: "visible",
          height: "736px",
          width: "736px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        >
          <CircularProgress
            size={40}
            style={{
              color: "#3050F5",
              opacity: url ? 0 : 1,
              transition: "opacity 0.1s ease-in-out",
            }}
          />
          <img
            src={`${url}`}
            height="720px"
            width="720px"
            style={{
              position: "absolute",
              top: "8px",
              left: "8px",
              objectFit: "contain",
              borderRadius: "16px",
              opacity: url ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        </div>

        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            top: "0px",
            right: "-42px",
            background: "#F1F3F4",
            ":hover": {
              background: "#F1F3F4",
              boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
