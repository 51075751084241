import { Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getPreviousTabMode,
  getTabMode,
  switchTabTo,
} from "../../../../store/navigatorSlice";
import { tabModes } from "../../../../store/types";

const StylesButton = () => {
  const dispatch = useAppDispatch();
  const previousTabMode = useAppSelector(getPreviousTabMode);
  const tabMode = useAppSelector(getTabMode);

  function handleElementsClick() {
    if (tabMode !== tabModes.styles) dispatch(switchTabTo(tabModes.styles));
    else if (previousTabMode === tabModes.textAnimations)
      dispatch(switchTabTo(tabModes.elements));
    else dispatch(switchTabTo(previousTabMode));
  }

  return (
    <Button
      data-track="ElementsClicked"
      className="styles-tab"
      sx={{
        background: tabMode === tabModes.styles ? "#F1F3F4" : "#FFF",
        borderRadius: "12px",
        padding: "4px 16px 4px 8px ",
        minHeight: "36px",
        gap: "4px",
        outline:
          tabMode === tabModes.styles
            ? "1px solid #D0D4D9"
            : "0px solid #D0D4D9",
        outlineOffset: "-1px",
        ":hover": {
          background: tabMode === tabModes.styles ? "#F1F3F4" : "#FFF",
          boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      onClick={handleElementsClick}
    >
      <img src="assets/images/ElementsIcon.png" width="28px" height="28px" />
      <Typography
        sx={{
          fontSize: 15,
        }}
      >
        Styles
      </Typography>
    </Button>
  );
};

export default StylesButton;
