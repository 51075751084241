import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getSelectedScriberProjectId,
  setSelectedScriberProjectId,
} from "../../../store/scriberSlice";
import ScriberProject from "./models/ScriberProject";
import ScriberGallery from "./models/ScriberGallery";
import { useEffect } from "react";

const Models = () => {
  const dispatch = useAppDispatch();
  const selectedScriberProjectId = useAppSelector(getSelectedScriberProjectId);

  useEffect(() => {
    dispatch(setSelectedScriberProjectId(undefined));
  }, []);

  return (
    <div
      style={{
        height: "calc(100% - 80px)",
        position: "absolute",
        top: "64px",
        left: "0px",
        right: "16px",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        borderRadius: "16px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "16px",
          gap: "16px",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          height: "100%",
        }}
      >
        {selectedScriberProjectId ? <ScriberProject /> : <ScriberGallery />}
      </div>
    </div>
  );
};

export default Models;
