import { timeoutAsync } from "../../helpers/Helper";
import { postUserPreviewAsync } from "../storage/AzureStorageManager";
import { saveBlobToIDBAsync } from "../storage/DbManager";
import { previewModel } from "./PreviewGenerator";

const queue: {
  url: string;
  rotation?: number[];
  resolves: ((value: Blob | PromiseLike<Blob> | undefined) => void)[];
}[] = [];

async function startLoop() {
  while (true) {
    await timeoutAsync(900);
    while (queue.length !== 0) {
      const item = queue.shift()!;
      const blob = await previewModel(item.url, item.rotation);
      if (!blob) {
        item.resolves.forEach((resolve) => resolve(undefined));
      } else {
        await postUserPreviewAsync(item.url, blob);
        await saveBlobToIDBAsync(blob, `previews/${item.url}.webp`);
        item.resolves.forEach((resolve) => resolve(blob));
      }
    }
  }
}

startLoop();

export function generateModelPreviewAsync(url: string, rotation?: number[]) {
  console.log("Generating model preview", url);
  return new Promise<Blob | undefined>((resolve) => {
    queue.push({
      url: url,
      rotation: rotation,
      resolves: [resolve],
    });
  });
}
