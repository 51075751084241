import { getEditorViewVisible } from "../../../../store/editorSlice";
import { useAppSelector } from "../../../../store/hooks";
import StylesButton from "./StylesButton";
import FontColorPicker from "./FontColorPicker";
import FontResizer from "./FontResizer";
import TextAnimationButton from "./TextAnimationButton";

const TextHeader = () => {
  const editorViewVisible = useAppSelector(getEditorViewVisible);

  return (
    <div style={{ display: "flex", gap: "16px" }}>
      {editorViewVisible && <StylesButton />}
      {editorViewVisible && <TextAnimationButton />}
    </div>
  );
};

export default TextHeader;
