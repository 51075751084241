const GradientInfinityButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 24 24"
      style={{ animation: "steam 20s linear infinite" }}
    >
      <style>
        {`
@keyframes gradientAnimation {
0%, 100% { stop-color: #fb0094; }
16.6%, 50% { stop-color: #00bbff; }
33.3%, 83.3% { stop-color: #0000ff; }
}
`}
      </style>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
          <stop
            offset="16.6%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
          <stop
            offset="33.3%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
          <stop
            offset="50%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
          <stop
            offset="66.6%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
          <stop
            offset="83.3%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
          <stop
            offset="100%"
            style={{
              animation: "gradientAnimation 20s linear infinite",
            }}
          />
        </linearGradient>
      </defs>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L12 10.66 10.48 12h.01L7.8 14.39c-.64.64-1.49.99-2.4.99-1.87 0-3.39-1.51-3.39-3.38S3.53 8.62 5.4 8.62c.91 0 1.76.35 2.44 1.03l1.13 1 1.51-1.34L9.22 8.2C8.2 7.18 6.84 6.62 5.4 6.62 2.42 6.62 0 9.04 0 12s2.42 5.38 5.4 5.38c1.44 0 2.8-.56 3.77-1.53l2.83-2.5.01.01L13.52 12h-.01l2.69-2.39c.64-.64 1.49-.99 2.4-.99 1.87 0 3.39 1.51 3.39 3.38s-1.52 3.38-3.39 3.38c-.9 0-1.76-.35-2.44-1.03l-1.14-1.01-1.51 1.34 1.27 1.12c1.02 1.01 2.37 1.57 3.82 1.57 2.98 0 5.4-2.41 5.4-5.38s-2.42-5.37-5.4-5.37z"
        fill="url(#gradient)"
      />
    </svg>
  );
};

export default GradientInfinityButton;
