import { ReactNode, useEffect, useState } from "react";
import { iTextTrack } from "../../../store/types";

type InOutAnimatorProps = {
  playing: boolean;
  time: number;
  item: iTextTrack;
  children: ReactNode;
};

const InOutAnimator = ({
  children,
  item,
  playing,
  time,
}: InOutAnimatorProps) => {
  const [delayTimeIn, setDelayTimeIn] = useState(0);
  const [delayTimeOut, setDelayTimeOut] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setDelayTimeIn(-time);
    setDelayTimeOut(item.timeline.duration - time - item.animation.duration);
  }, [time, item.animation.duration, item.timeline.duration]);

  useEffect(() => {
    if (!playing) return;
    setAnimationKey((prevKey) => prevKey + 1);
    setDelayTimeIn(-time);
    setDelayTimeOut(item.timeline.duration - item.animation.duration - time);
    return () => {
      if (!playing) return;
      setAnimationKey((prevKey) => prevKey + 1);
    };
  }, [playing, item.animation.duration, time]);

  return (
    <>
      <div
        key={animationKey}
        style={{
          animationName: item.animation.template
            ? `${item.animation.template.inStyleName}`
            : "",
          animationDuration: `${item.animation.duration}s`,
          animationPlayState: playing ? "running" : "paused",
          animationDelay: `${delayTimeIn}s`,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            animationName: item.animation.template
              ? `${item.animation.template.outStyleName}`
              : "",
            animationDuration: `${item.animation.duration}s`,
            animationPlayState: playing ? "running" : "paused",
            animationDelay: `${delayTimeOut}s`,
            overflow: "hidden",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default InOutAnimator;
