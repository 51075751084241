import { Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import {
  getRelativePositionX,
  getTimeAsString,
} from "../../../../helpers/Helper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getTimelineCaretTime,
  getTimelinePlaying,
  getTimelineScale,
  setTimelineCaretTime,
} from "../../../../store/timelineSlice";

type CaretProps = { duration?: number };

function Caret({ duration }: CaretProps) {
  const dispatch = useAppDispatch();
  const scale = useAppSelector(getTimelineScale);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [scaleHovering, setScaleHovering] = useState(false);
  const [caretHovering, setCaretHovering] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [caretHoverTime, setCaretHoverTime] = useState("");
  const caretTime = useAppSelector(getTimelineCaretTime);
  const [dragging, setDragging] = useState(false);
  const playing = useAppSelector(getTimelinePlaying);
  const timelineContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!duration || !scale) return;
    setCanvasWidth(duration * scale);
  }, [duration, scale]);

  useEffect(() => {
    if (!duration || !scale) return;
    const time = mouseX / scale;
    setCaretHoverTime(getTimeAsString(time));
  }, [mouseX, duration, scale]);

  const handleScaleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const realPosition = getRelativePositionX(e);
    if (dragging) {
      dispatch(setTimelineCaretTime(realPosition / scale));
    }
    setMouseX(realPosition);
  };

  const handleCaretHovering = (v: boolean) => {
    if (!dragging) setCaretHovering(v);
  };

  const handleCaretDragging = (
    dragging: boolean,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (dragging) {
      const realPosition = getRelativePositionX(e);
      dispatch(setTimelineCaretTime(realPosition / scale));
    }
    setCaretHovering(true);
    setDragging(dragging);
  };

  useEffect(() => {
    // Function to handle mouse movement globally
    const handleGlobalMouseMove = (e: MouseEvent) => {
      if (dragging && timelineContainerRef.current) {
        // Get the bounds of the timeline container
        const containerBounds =
          timelineContainerRef.current.getBoundingClientRect();

        // Clamp the mouse position to the bounds of the timeline container
        let realPosition = e.clientX - containerBounds.left;
        realPosition = Math.max(0, realPosition); // Prevent going left of the container
        realPosition = Math.min(containerBounds.width, realPosition); // Prevent going right of the container

        // Dispatch the action and set state with clamped position
        dispatch(setTimelineCaretTime(realPosition / scale));
        setMouseX(realPosition);
      }
    };

    // Function to handle global mouse up
    const handleGlobalMouseUp = () => {
      setDragging(false);
      // Remove global event listeners
      window.removeEventListener("mousemove", handleGlobalMouseMove);
      window.removeEventListener("mouseup", handleGlobalMouseUp);
    };

    if (dragging) {
      // Attach global event listeners
      window.addEventListener("mousemove", handleGlobalMouseMove);
      window.addEventListener("mouseup", handleGlobalMouseUp);
    }

    // Cleanup function to remove event listeners when component unmounts or dragging state changes
    return () => {
      window.removeEventListener("mousemove", handleGlobalMouseMove);
      window.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, [dragging, scale]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        width: `${canvasWidth}px`,
        zIndex: 100,
      }}
    >
      <div style={{ minWidth: "32px", height: "100%" }}></div>
      <div
        ref={timelineContainerRef}
        onMouseEnter={() => setScaleHovering(true)}
        onMouseLeave={() => setScaleHovering(false)}
        onMouseMove={handleScaleMouseMove}
        onMouseOut={handleScaleMouseMove}
        onMouseDown={(e) => handleCaretDragging(true, e)}
        onMouseUp={(e) => handleCaretDragging(false, e)}
        style={{
          minWidth: "100%",
          height: "36px",
          position: "relative",
        }}
      >
        <div
          style={{
            pointerEvents: "none",
            userSelect: "none",
            opacity: scaleHovering && !caretHovering && !dragging ? 1 : 0,
            transition: "opacity 0.3s",
            position: "absolute",
            top: 0,
            left: 0,
            transform: `translateX(${mouseX}px)`,
            zIndex: 10,
          }}
        >
          <div
            style={{
              width: "64px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#2949EE",
              transform: "translateX(-50%)",
              borderRadius: "8px",
            }}
          >
            <Typography
              style={{
                color: "#FFF",
                fontSize: "15px",
                fontFamily: "'Ubuntu', sans-serif",
              }}
            >
              {caretHoverTime}
            </Typography>
          </div>
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: "5px solid #2949EE",
              transform: "translateX(-50%)",
              marginLeft: "0.5px",
            }}
          ></div>
          <div
            style={{
              width: "2px",
              borderRadius: "2px",
              height: "16px",
              backgroundColor: "#ED6C4B",
              marginLeft: "0.5px",
              transform: "translateX(-50%)",
            }}
          />
        </div>
        <div
          onMouseEnter={() => handleCaretHovering(true)}
          onMouseLeave={() => handleCaretHovering(false)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transform: `translateX(${caretTime * scale}px)`,
            cursor: "ew-resize",
          }}
        >
          <div
            style={{
              width: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              transform: "translateX(-50%)",
            }}
          >
            <div
              style={{
                width: "2px",
                height: "172px",
                marginLeft: "1px",
                borderRadius: "1px 1px 0px 0px",
                backgroundColor: "#ED6C4B",
              }}
            />
          </div>
        </div>
        <div
          style={{
            pointerEvents: "none",
            width: "2px",
            height: "132px",
            backgroundColor: "#ED6C4B",
            borderRadius: "2px",
            position: "absolute",
            top: "36px",
            left: "-0.5px",
            transform: `translateX(${mouseX}px)`,
            opacity: scaleHovering && !caretHovering && !dragging ? 1 : 0,
            transition: "opacity 0.3s",
          }}
        />
        <div
          style={{
            pointerEvents: "none",
            width: "2px",
            height: "132px",
            backgroundColor: "#ED6C4B",
            position: "absolute",
            borderRadius: "0px 0px 1px 1px",
            top: "36px",
            left: "-0.5px",
            transform: `translateX(${caretTime * scale}px)`,
          }}
        >
          <div
            style={{
              pointerEvents: "none",
              width: "2px",
              height: "132px",
              backgroundColor: "#ED6C4B",
              borderRadius: "1px",
              left: "-0.5px",
              transition: playing
                ? `transform ${duration! - caretTime}s linear`
                : "none",
              transform: `translateX(${
                playing ? (duration! - caretTime) * scale : 0
              }px)`,
            }}
          />
        </div>
      </div>
      <div style={{ minWidth: "32px", height: "100%" }}></div>
    </div>
  );
}

export default Caret;
