import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  getCurrentTimestampUTC,
  getStorageItemFromStorageData,
  sortStoreItemModels,
  timeoutAsync,
} from "../../../../helpers/Helper";
import {
  deleteUserBlobAsync,
  deleteUserFolderAsync,
  getUserJsonAsync,
  getUserStorageDataAsync,
  postUserJsonAsync,
} from "../../../../managers/storage/AzureStorageManager";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getUserStorageData,
  setUserStorageData,
} from "../../../../store/storageSlice";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import { StoreItemModel, sortingMethods } from "../../../../store/types";
import { setSaving } from "../../../../store/navigatorSlice";
import ScriberGalleryItem from "./ScriberGalleryItem";
import { showCreateModelDialog } from "../../../../store/dialogSlice";
import ScriberGalleryDemoItem from "./ScriberGalleryDemoItem";
import {
  redeemScriberAttemptAsync,
  getUserSettingsAsync,
} from "../../../../managers/ClientManager";

const ScriberGallery = () => {
  const dispatch = useAppDispatch();
  const userStorageData = useAppSelector(getUserStorageData);
  const [models, setModels] = useState([] as StoreItemModel[]);
  const [demoModel, setDemoModel] = useState<StoreItemModel>();
  const [itemToDelete, setItemToDelete] = useState<StoreItemModel | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [projectsEmpty, setProjectsEmpty] = useState(false);
  const selectedItem = useRef<StoreItemModel | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    startLongPolling(abortController.signal);
    return () => abortController.abort();
  }, []);

  async function startLongPolling(signal: AbortSignal) {
    while (!signal.aborted) {
      dispatch(setUserStorageData(await getUserStorageDataAsync()));
      checkTimeoutItems();
      await timeoutAsync(30_000);
    }
  }

  async function checkTimeoutItems() {
    const configs = getStorageItemFromStorageData(
      "configs/scribers",
      userStorageData
    );

    configs.forEach(async (c) => {
      const config = await getUserJsonAsync(c.url);

      if (!config || !config.status) return;

      if (config.status === "processing") {
        const processingTimestamp = config.processingTimestamp;
        const currentTimeUtc = getCurrentTimestampUTC();
        const twoHours = 60 * 60 * 1000 * 2;
        if (currentTimeUtc - processingTimestamp > twoHours) {
          config.status = "error";
          await postUserJsonAsync(config, c.url);
        }
      }

      if (config.status === "queue") {
        const startedDate = config.date;
        const currentTimeUtc = getCurrentTimestampUTC();
        const twentyHours = 60 * 60 * 1000 * 20;

        if (currentTimeUtc - startedDate > twentyHours) {
          config.status = "error";
          await postUserJsonAsync(config, c.url);
        }
      }

      if (config.status === "error" && !config.redeem) {
        config.redeem = true;
        await postUserJsonAsync(
          config,
          configs.find((c) => c.name === config.id)!.url
        );
        await redeemScriberAttemptAsync();
        await getUserSettingsAsync(dispatch);
      }
    });
  }

  useEffect(() => {
    const uploadedModels = getStorageItemFromStorageData(
      "previews/scriber-projects",
      userStorageData
    );
    // if (uploadedModels.length === 0) setProjectsEmpty(true);
    const sorted = sortStoreItemModels(uploadedModels, sortingMethods.byDate);
    setModels(sorted);
    const demoStorage: StoreItemModel = {
      id: "previews/scriber-projects/d8566000-028f-48f3-b837-8e1604bf76cf.jpg",
      name: "d8566000-028f-48f3-b837-8e1604bf76cf",
      url: "previews/scriber-projects/d8566000-028f-48f3-b837-8e1604bf76cf.jpg",
      accessType: "shared",
      properties: {},
    };
    setDemoModel(demoStorage);
  }, [userStorageData]);

  const handleDelete = async (model: StoreItemModel) => {
    dispatch(setSaving(true));
    await deleteUserBlobAsync(model.id);
    await deleteUserFolderAsync(`scriber-projects/${model.name}`);
    dispatch(setSaving(false));
  };

  function handleShowDeleteDialog() {
    setMenuOpen(false);
    setItemToDelete(selectedItem.current);
  }

  function handleCloseDeleteDialog() {
    setItemToDelete(null);
  }

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
    selectedItem.current = null;
  };

  const handleMenu = (
    event: React.MouseEvent<HTMLElement>,
    item: StoreItemModel
  ) => {
    selectedItem.current = item;
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const menuItemStyle = {
    borderRadius: "12px",
    transition: "background-color 0.25s",
    padding: "8px 12px",
    ":active": { backgroundColor: "#EDEDED" },
  };

  const handleScroll = (e: any) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!projectsEmpty ? (
        <div style={{ overflow: "hidden" }}>
          <div
            style={{
              width: "calc(100% - 32px)",
              marginLeft: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "28px",
                fontWeight: 900,
                color: "#373E4E",
              }}
            >
              Models
            </Typography>
            <Button
              onClick={() => {
                dispatch(showCreateModelDialog());
              }}
              sx={{
                background: "#3050F5",
                borderRadius: "8px",
                padding: "10px 18px",
                height: "36px",
                width: "156px",
                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#3050F5",
                  boxShadow: "none",
                },
              }}
            >
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#FFF",
                  fontWeight: 700,
                }}
              >
                Create 3D Model
              </Typography>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              overflow: "hidden",
              height: "100%",
            }}
          >
            <div
              style={{
                marginTop: "16px",
                overflowY: "auto",
                outline: isScrolled ? "1px solid #E0E3E4" : "none",
                height: "calc(100% - 48px)",
              }}
              onScroll={handleScroll}
            >
              <Grid
                container
                spacing={0}
                alignItems={"center"}
                justifyContent={"center"}
                style={{ padding: "16px", gap: "16px" }}
              >
                {demoModel && (
                  <Grid item>
                    <ScriberGalleryDemoItem item={demoModel} />
                  </Grid>
                )}
                {models.map((model) => (
                  <Grid item key={model.id}>
                    <ScriberGalleryItem
                      item={model}
                      onMenu={(e: any) => handleMenu(e, model)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={"assets/images/EmptyUploadsImage.webp"}
            alt=""
            width="300px"
            height="256px"
          />
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: 700,
              marginTop: "24px",
            }}
          >
            No models yet
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              marginTop: "12px",
            }}
          >
            Your model projects will show up here. Get started by uploading your
            first photos!
          </Typography>
          <Button
            sx={{
              background: "#3050F5",
              borderRadius: "12px",
              padding: "10px 18px",
              height: "48px",
              width: "156px",
              marginTop: "40px",
              marginBottom: "32px",
              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#3050F5",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={() => {
              dispatch(showCreateModelDialog());
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFF",
                fontWeight: 800,
              }}
            >
              Create 3D Model
            </Typography>
          </Button>
        </div>
      )}

      {itemToDelete && (
        <ConfirmationDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete it?"
          confirmButtonText="Delete"
          item={itemToDelete}
          onConfirm={handleDelete}
          onCancel={handleCloseDeleteDialog}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          ".MuiMenu-paper": { borderRadius: "12px" },
          ".MuiMenu-list": { margin: "8px", padding: "0px" },
        }}
      >
        <MenuItem
          onClick={() => handleShowDeleteDialog()}
          style={menuItemStyle}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/DeleteIcon.svg" width="24px" height="24px" />
            <Typography textAlign="center">Delete</Typography>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ScriberGallery;
