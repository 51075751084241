import { Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getProject, setSequenceSkybox } from "../../store/projectSlice";
import { ChangeEvent, useRef } from "react";
import {
  getUserStorageDataAsync,
  postUserBlobAsync,
} from "../../managers/storage/AzureStorageManager";
import { getStorageItemFromStorageData } from "../../helpers/Helper";

const AddBackButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files![0];
    if (file) {
      await postUserBlobAsync(file, `customBacks/${file.name}`, false);
      const userData = await getUserStorageDataAsync();
      const storeItems = await getStorageItemFromStorageData(
        "customBacks",
        userData
      );
      const customBackStoreItem = storeItems.find((s) =>
        s.url.endsWith(file.name)
      )!;
      console.log(customBackStoreItem);
      project.sequences.forEach((s) => {
        dispatch(
          setSequenceSkybox({
            sequenceId: s.id,
            model: customBackStoreItem,
          })
        );
      });
    }
  };

  const handleCreateAIText = async () => {
    inputFileRef.current?.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Button
        onClick={handleCreateAIText}
        sx={{
          background: "#4261FF",
          borderRadius: "12px",
          border: "2px solid #4261FF",
          padding: "10px 18px",
          height: "36px",
          width: "calc(100% - 32px)",
          ":hover": {
            background: "#4261FF",
            boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
          },
          ":active": {
            background: "#4261FF",
            boxShadow: "none",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Typography
          sx={{
            color: "#FFF",
          }}
        >
          Upload Background
        </Typography>
      </Button>
    </>
  );
};

export default AddBackButton;
