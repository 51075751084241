import { Button } from "@mui/material";
import { getSequenceById } from "../../../helpers/Helper";
import { postSharedJsonAsync } from "../../../managers/storage/AzureStorageManager";
import { ShotModel } from "../../../store/types";
import { getLumiereSceneById } from "../LumiereScene";
import * as BABYLON from "babylonjs";
import { useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { getTimelineSelectedItem } from "../../../store/timelineSlice";
import ShotDialog from "../../dialogs/ShotDialog";
import { getProject } from "../../../store/projectSlice";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const SaveShotButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [shotDialogOpen, setShotDialogOpen] = useState(false);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const project = useAppSelector(getProject);

  function saveShotAsync() {
    setMenuOpen(false);
    setShotDialogOpen(true);
  }

  function handleCancelShotDialog() {
    setShotDialogOpen(false);
  }

  async function handleSaveShotDialog(
    name: string,
    groupName: string,
    ai: boolean
  ) {
    setShotDialogOpen(false);
    if (!selectedItem?.id) {
      console.log("nothing is selected!");
      return;
    }
    const lumiereScene = getLumiereSceneById(selectedItem.id);
    if (!lumiereScene) {
      console.log("not sequence selected");
      return;
    }
    if (!lumiereScene.targetRelativeBox) {
      console.log("no model!");
      return;
    }
    const cameraParent = lumiereScene.mainCamera
      .parent as BABYLON.TransformNode;
    lumiereScene.scene.lights.forEach(
      (l) =>
        ((l as BABYLON.ShadowLight).position = (
          l.parent as BABYLON.TransformNode
        ).position)
    );
    const shot: ShotModel = {
      name: name,
      groupName: groupName,
      camera: {
        animations: lumiereScene.mainCamera.animations.map((a) =>
          a.serialize()
        ),
        transform: {
          position: cameraParent.position.asArray(),
          rotation: cameraParent.rotation.asArray(),
          scaling: cameraParent.scaling.asArray(),
        },
      },
      lights: lumiereScene.scene.lights.map((l) => l.serialize()),
      relativeBox: lumiereScene.targetRelativeBox,
    };
    lumiereScene.scene.lights.forEach(
      (l) => ((l as BABYLON.ShadowLight).position = BABYLON.Vector3.Zero())
    );

    if (ai) {
      await postSharedJsonAsync(shot, `ai-shots/${groupName}/${name}.json`);
    } else {
      const sequence = getSequenceById(project, selectedItem.id);
      await postSharedJsonAsync(shot, `space/shots/${groupName}/${name}.json`);
    }
    console.log("saved shot successfully!", shot);
  }

  return (
    <>
      <Button
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          minHeight: "36px",
          minWidth: "36px",
          boxShadow: menuOpen
            ? "0px 10px 10px 0px rgba(0, 0, 0, 0.08)"
            : "none",
          ":hover": {
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
        onClick={saveShotAsync}
      >
        <SaveOutlinedIcon />
      </Button>
      <ShotDialog
        open={shotDialogOpen}
        title="Shot"
        onCancel={() => handleCancelShotDialog()}
        onSave={(n, g, ai) => handleSaveShotDialog(n, g, ai)}
      />
    </>
  );
};

export default SaveShotButton;
