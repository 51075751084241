import { Box, Button, Grid, Typography } from "@mui/material";
import {
  TextAnimationTemplate,
  TimelineItemType,
  iTextTrack,
} from "../../../store/types";
import { getTimelineSelectedItem } from "../../../store/timelineSlice";
import { useAppSelector } from "../../../store/hooks";
import { useEffect, useRef, useState } from "react";
import { getProject } from "../../../store/projectSlice";
import InOutPreviewAnimator from "./InOutPreviewAnimator";

interface TextAnimationItemProps {
  template: TextAnimationTemplate;
  onSelect: () => void;
}

const TextAnimationItem = ({ template, onSelect }: TextAnimationItemProps) => {
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const project = useAppSelector(getProject);
  const [selectedTextAnimationId, setSelectedTextAnimationId] = useState("");
  const selectedTextRef = useRef<iTextTrack | undefined>();
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (selectedItem?.type === TimelineItemType.text) {
      selectedTextRef.current = project.texts?.find(
        (t) => t.id === selectedItem?.id
      );
      if (selectedTextRef.current)
        setSelectedTextAnimationId(
          selectedTextRef.current.animation.template.id
        );
    }
  }, [selectedItem, project.texts]);

  return (
    <Grid item sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Button
          data-track="TextAnimationClicked"
          sx={{
            width: "160px",
            height: "92px",
            borderRadius: "12px",
            border:
              template.id === selectedTextAnimationId
                ? "4px solid #F1F3F4"
                : "1px solid gray",
            outline:
              template.id === selectedTextAnimationId
                ? "2px solid #4261FF"
                : "",
            outlineOffset: "-2px",
            overflow: "hidden",
          }}
          onClick={() => onSelect()}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <InOutPreviewAnimator template={template} playing={hover}>
            <Typography
              sx={{
                ...selectedTextRef.current?.template.css,
                ...selectedTextRef.current?.appearance,
                pointerEvents: "none",
                maxWidth: "148px",
                whiteSpace: "nowrap",
                fontSize: 36,
              }}
            >
              Abc
            </Typography>
          </InOutPreviewAnimator>
        </Button>
        <Typography
          sx={{
            maxWidth: "148px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 12,
            marginLeft: "8px",
            marginTop: "4px",
          }}
        >
          {template.id}
        </Typography>
      </Box>
    </Grid>
  );
};

export default TextAnimationItem;
