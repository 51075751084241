import { Button, Popover, Typography } from "@mui/material";
import {
  normalizeValue,
  getOriginalValue,
  preloadBlobsAsync,
  timeoutAsync,
  getStorageItemFromSharedStorageData,
  getProjectDuration,
  getAnimationsDuration,
} from "../../helpers/Helper";
import { getAIProject } from "../../managers/AICreator";
import {
  getSharedStorageDataAsync,
  getUserJsonAsync,
  postUserJsonAsync,
} from "../../managers/storage/AzureStorageManager";
import { saveBlobToIDBAsync } from "../../managers/storage/DbManager";
import { animationsFps } from "../../settings/GlobalSettings";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  resetProgress,
  setProgressText,
  increaseCurrentProgress,
} from "../../store/progressSlice";
import { getProject, setProject } from "../../store/projectSlice";
import { setLoading } from "../../store/sceneSlice";
import { getUserStorageData } from "../../store/storageSlice";
import {
  setTimelineCaretTime,
  setTimelinePlaying,
} from "../../store/timelineSlice";
import { AudioTrack, iTextTrack } from "../../store/types";
import { textTemplates } from "../leftPanel/styles/text/TextTemplates";
import { textAnimationTemplates } from "../leftPanel/textAnimations/TextAnimationTempates";
import { v4 as uuidv4 } from "uuid";
import {
  getUserLeftCredits,
  getUserSubscriptionLevel,
} from "../../store/userSlice";
import {
  proTestNoCreditsString,
  demoStarterNoCreditsString,
} from "../../settings/GlobalStrings";
import { showCustomAlert } from "../../store/dialogSlice";
import { drainCreditAsync } from "../../managers/ClientManager";
import { useState } from "react";
import DownArrow from "../../icons/DownArrow";

const lengths: number[] = [900, 1200, 1500, 1800];

const RegenerateProjectButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const leftCredits = useAppSelector(getUserLeftCredits);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedLength, setSelectedLength] = useState<number>(lengths[1]);

  const handleCreateAISkyboxProject = async () => {
    if (!leftCredits || leftCredits <= 0) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestNoCreditsString
            : demoStarterNoCreditsString
        )
      );
      return;
    }

    dispatch(setTimelinePlaying(false));
    dispatch(setTimelineCaretTime(0));
    dispatch(resetProgress());
    dispatch(setProgressText("Generating"));
    dispatch(setLoading(true));
    drainCreditAsync(dispatch);

    const oldProject = JSON.parse(JSON.stringify(project));
    dispatch(setProject({}));

    const config = await getUserJsonAsync(
      `configs/${
        oldProject.sequences[0].mainObject?.model.url.split(".")[0]
      }.json`
    );
    let relativeBox;

    if (config && config.relativeBox) relativeBox = config.relativeBox;
    else relativeBox = undefined;
    const result = await getAIProject(relativeBox, selectedLength);

    result.project.id = oldProject.id;
    result.project.name = oldProject.name;

    await Promise.all(
      result.shotGenerators.map(
        (s) =>
          new Promise<void>(async (resolve) => {
            await saveBlobToIDBAsync(
              new Blob([JSON.stringify(s.shot)], { type: "application/json" }),
              `shots/${s.shot.name}.json`
            );
            postUserJsonAsync(s.shot, `shots/${s.shot.name}.json`);
            resolve();
          })
      )
    );

    const envStorageData = await getSharedStorageDataAsync("ai-env");
    const backgrounds = getStorageItemFromSharedStorageData(envStorageData);

    let backgroundIndex = Math.floor(Math.random() * backgrounds.length);
    result.project.sequences.forEach((s, i) => {
      s.shot = {
        id: result.shotGenerators[i].shot.name,
        name: result.shotGenerators[i].shot.name,
        url: `shots/${result.shotGenerators[i].shot.name}.json`,
        accessType: "user",
        properties: {},
      };
      s.duration = getAnimationsDuration(
        result.shotGenerators[i].shot.camera.animations
      );

      s.skybox!.model = backgrounds[backgroundIndex];
      s.mainObject = oldProject.sequences[0].mainObject;
    });

    const projectDuration = getProjectDuration(result.project) / animationsFps;
    const audioStorageData = await getSharedStorageDataAsync("ai-audios");
    const audios = getStorageItemFromSharedStorageData(audioStorageData);
    const i = Math.floor(Math.random() * audios.length);
    result.project.audio = {
      source: audios[i],
      trims: [0, 30.0 - projectDuration >= 0.0 ? 30.0 - projectDuration : 0],
      duration: 30.0,
      offset: 0,
    } as AudioTrack;

    const textTemplateIndex = Math.floor(Math.random() * textTemplates.length);
    const textTemplate = textTemplates[textTemplateIndex];
    const textAnimationTemplateIndex = Math.floor(
      Math.random() * textAnimationTemplates.length
    );
    const textAnimationTemplate =
      textAnimationTemplates[textAnimationTemplateIndex];
    const animationDurationMinMax: [number, number] = [0.3, 0.9];
    const durationMinMax: [number, number] = [2, 5];
    const offsetOpeningMinMax: [number, number] = [0, 3];
    const projectDurationMinMax: [number, number] = [5, 30];
    const normalizedProjectDuration = normalizeValue(
      projectDuration,
      projectDurationMinMax
    );
    const currentAnimationDuration = getOriginalValue(
      normalizedProjectDuration,
      animationDurationMinMax
    );
    const currentDuration = getOriginalValue(
      normalizedProjectDuration,
      durationMinMax
    );
    const currentOffsetOpening = getOriginalValue(
      normalizedProjectDuration,
      offsetOpeningMinMax
    );
    const currentOffsetEnding =
      projectDuration -
      currentOffsetOpening * (Math.random() + 0.5) -
      currentDuration;
    const texts: iTextTrack[] = [
      {
        id: uuidv4(),
        template: textTemplate,
        animation: {
          template: textAnimationTemplate,
          duration: currentAnimationDuration * (Math.random() + 0.5),
        },
        appearance: {
          text: "Opening",
          normalizedFontSize: 0.2,
          normalizedPosition: {
            x: 0.5,
            y: 0.5,
          },
          normalizedSize: {
            width: 0.8,
            height: 0.7,
          },
        },
        timeline: {
          duration: currentDuration * (Math.random() + 0.5),
          offset: currentOffsetOpening * (Math.random() + 0.5),
        },
      },
      {
        id: uuidv4(),
        template: textTemplate,
        animation: {
          template: textAnimationTemplate,
          duration: currentAnimationDuration * (Math.random() + 0.5),
        },
        appearance: {
          text: "Ending",
          normalizedFontSize: 0.2,
          normalizedPosition: {
            x: 0.5,
            y: 0.5,
          },
          normalizedSize: {
            width: 0.8,
            height: 0.7,
          },
        },
        timeline: {
          duration: currentDuration * (Math.random() + 0.5),
          offset: currentOffsetEnding,
        },
      },
    ];
    result.project.texts = texts;

    await preloadBlobsAsync(result.project, dispatch, (bytes: number) => {
      dispatch(increaseCurrentProgress(bytes));
    });

    dispatch(setProject(result.project));
    await timeoutAsync(1000);
    dispatch(setLoading(false));
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "calc(100% - 32px)",
        gap: "8px",
      }}
    >
      <Button
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          border: "2px solid #4261FF",
          padding: "10px 18px",
          height: "36px",
          width: "calc(100% - 32px)",
          ":hover": {
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
          },
          ":active": {
            background: "#FFF",
            boxShadow: "none",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
        onClick={handleCreateAISkyboxProject}
      >
        <Typography
          sx={{
            color: "#3050F5",
          }}
        >
          Project
        </Typography>
      </Button>
      <Button
        onClick={handleOpenPopover}
        sx={{
          background: "#F1F3F4",
          borderRadius: "12px",
          padding: "0px 12px",
          width: "100px",
          height: "36px",
          gap: "4px",
          justifyContent: "space-between",
          boxShadow: popoverOpen
            ? "0px 10px 10px 0px rgba(0, 0, 0, 0.08)"
            : "none",
          ":hover": {
            background: "#F1F3F4",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
          }}
        >
          {selectedLength / 60}s
        </Typography>
        <DownArrow />
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          marginTop: "5px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "8px",
          }}
        >
          {lengths.map((l, i) => (
            <Button
              key={l}
              sx={{
                borderRadius: "12px",
                justifyContent: "start",

                background: "#FFF",
                ":hover": {
                  background: "#E3E5E5",
                },
                ":active": {
                  background: "#F1F3F4",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => {
                setSelectedLength(l);
                setPopoverOpen(false);
              }}
            >
              {l / 60}s
            </Button>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default RegenerateProjectButton;
