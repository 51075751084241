import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../store/hooks";
import { getTimelineScale } from "../../../../store/timelineSlice";

type DrawableScaleProps = { duration?: number };
const offset = 32;

function DrawableScale({ duration }: DrawableScaleProps) {
  const currentScale = useAppSelector(getTimelineScale);
  const canvasRef = useRef({} as HTMLCanvasElement);
  const [canvasWidth, setCanvasWidth] = useState(0);

  useEffect(() => {
    if (!duration || !currentScale) return;
    setCanvasWidth((duration * currentScale + offset * 2) * 2);
  }, [duration, currentScale]);

  useEffect(() => {
    if (!duration || !currentScale) return;
    redraw(duration, currentScale);
  }, [canvasWidth]);

  function redraw(duration: number, scale: number) {
    var fractionCount = duration * 10;
    var step = (duration * scale) / fractionCount;
    var ctx = canvasRef.current.getContext("2d")!;
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";
    ctx.textAlign = "center";
    ctx.font = "500 12px 'Red Hat Display'";
    ctx.scale(2, 2);
    ctx.fillStyle = "#8A9CC6";
    ctx.strokeStyle = "#8A9CC6";
    for (var i = 0; i <= fractionCount; i++) {
      ctx.beginPath();
      ctx.moveTo(step * i + offset, 24);
      if (i % 50 === 0) {
        ctx.fillText((i / 10).toString(), step * i + offset, 20);
      } else if (i % 10 === 0) {
        if (step > 4) {
          ctx.fillText((i / 10).toString(), step * i + offset, 20);
        } else {
          ctx.beginPath();
          ctx.ellipse(step * i + offset, 16, 1, 1, 0, 0, 2 * Math.PI);
          ctx.fill();
          ctx.closePath();
        }
      } else if (i % 5 === 0) {
        if (step > 8) {
          ctx.beginPath();
          ctx.ellipse(step * i + offset, 16, 2, 2, 0, 0, 2 * Math.PI);
          ctx.fill();
          ctx.closePath();
        }
      } else {
        if (step > 16) {
          ctx.beginPath();
          ctx.ellipse(step * i + offset, 16, 1, 1, 0, 0, 2 * Math.PI);
          ctx.fill();
          ctx.closePath();
        }
      }
      ctx.stroke();
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "36px",
        position: "relative",
      }}
    >
      <canvas
        ref={canvasRef}
        height="72px"
        width={canvasWidth}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          transform: "scale(0.5, 0.5)",
          transformOrigin: "0 0",
        }}
      />
    </Box>
  );
}

export default DrawableScale;
