import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { RenderModel } from "./types";

const initialState: RenderModel = {
  isVertical: false,
  selectedRenderId: "",
  rendersSelectable: true,
  backImageUrl: "",
};

export const renderSlice = createSlice({
  name: "render",
  initialState,
  reducers: {
    setIsVertical: (state, action) => {
      state.isVertical = action.payload;
    },
    setSelectedRenderId: (state, action) => {
      state.selectedRenderId = action.payload;
    },
    setRendersSelectable: (state, action) => {
      state.rendersSelectable = action.payload;
    },
    setBackImageUrl: (state, action) => {
      state.backImageUrl = action.payload;
    },
  },
});

export const getIsVertical = (state: RootState) => state.render.isVertical;
export const getSelectedRenderId = (state: RootState) =>
  state.render.selectedRenderId;
export const getRendersSelectable = (state: RootState) =>
  state.render.rendersSelectable;
export const getBackImageUrl = (state: RootState) => state.render.backImageUrl;

export const {
  setIsVertical,
  setSelectedRenderId,
  setRendersSelectable,
  setBackImageUrl,
} = renderSlice.actions;

export default renderSlice.reducer;
