import { validateJSON } from "../../helpers/Helper";
import {
  EditorSettings,
  RenderSettings,
  ResolutionMenuItem,
} from "../../store/types";

export function getRenderSettingsFromLocalStorage() {
  const renderSettingsJson = localStorage.getItem("renderSettings");

  if (renderSettingsJson && validateJSON(renderSettingsJson)) {
    const renderSettings = JSON.parse(renderSettingsJson);
    return renderSettings;
  } else {
    const renderSettings = {
      resolution: {
        text: "HD 720p",
        width: 1280,
        height: 720,
        isDemo: true,
      },
      fps: 30,
      orientation: "horizontal",
    };
    saveRenderSettingsToLocalStorage(renderSettings);
    return renderSettings;
  }
}

export function getEditorSettingsFromLocalStorage() {
  const editorSettingsJson = localStorage.getItem("editorSettings");

  if (editorSettingsJson && validateJSON(editorSettingsJson)) {
    const editorSettings = JSON.parse(editorSettingsJson);
    return editorSettings;
  } else {
    const editorSettings = {
      editorViewVisible: true,
    };
    saveEditorSettingsToLocalStorage(editorSettings);
    return editorSettings;
  }
}

export function saveRenderSettingsToLocalStorage(
  renderSettings: RenderSettings
) {
  localStorage.setItem("renderSettings", JSON.stringify(renderSettings));
}

export function saveEditorSettingsToLocalStorage(
  editorSettings: EditorSettings
) {
  localStorage.setItem("editorSettings", JSON.stringify(editorSettings));
}

export function saveRenderResolutionToLocalStorage(
  resolution: ResolutionMenuItem
) {
  let renderSettings = getRenderSettingsFromLocalStorage();
  renderSettings.resolution = resolution;
  saveRenderSettingsToLocalStorage(renderSettings);
}

export function saveRenderFpsToLocalStorage(fps: number) {
  let renderSettings = getRenderSettingsFromLocalStorage();
  renderSettings.fps = fps;
  saveRenderSettingsToLocalStorage(renderSettings);
}

export function saveEditorViewVisibleToLocalStorage(
  editorViewVisible: boolean
) {
  let editorSettings = getEditorSettingsFromLocalStorage();
  editorSettings.editorViewVisible = editorViewVisible;
  saveEditorSettingsToLocalStorage(editorSettings);
}

export function saveRenderOrientationToLocalStorage(orientation: string) {
  let renderSettings = getRenderSettingsFromLocalStorage();
  renderSettings.orientation = orientation;
  saveRenderSettingsToLocalStorage(renderSettings);
}
