import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  getProjectDuration,
  getTimeAsString,
  timeoutAsync,
} from "../../../helpers/Helper";
import { useAppSelector } from "../../../store/hooks";
import {
  getTimelineCaretTime,
  getTimelinePlaying,
} from "../../../store/timelineSlice";
import { getProject } from "../../../store/projectSlice";

type TimerProps = {};

function Timer({}: TimerProps) {
  const caretTime = useAppSelector(getTimelineCaretTime);
  const project = useAppSelector(getProject);
  const [caretTimeString, setCaretTimeString] = useState("");
  const playing = useAppSelector(getTimelinePlaying);
  const abortControllerRef = useRef(new AbortController());
  const [projectDuration, setProjectDuration] = useState("");

  useEffect(() => {
    setCaretTimeString(getTimeAsString(caretTime));
  }, [caretTime]);

  useEffect(() => {
    setProjectDuration(`${(getProjectDuration(project) / 60).toFixed(1)}s`);
  }, [project.sequences]);

  useEffect(() => {
    if (!playing) {
      abortControllerRef.current.abort();
      setCaretTimeString(getTimeAsString(caretTime));
    } else {
      const runAsync = async (signal: AbortSignal) => {
        const timer = Date.now();
        while (true) {
          await timeoutAsync(100);
          if (signal.aborted) return;
          const elapsed = (Date.now() - timer) / 1000;
          setCaretTimeString(getTimeAsString(caretTime + elapsed));
        }
      };
      abortControllerRef.current = new AbortController();
      runAsync(abortControllerRef.current.signal);
    }
  }, [playing]);

  return (
    <Box
      sx={{
        userSelect: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "108px",
        height: "100%",
      }}
    >
      <Typography sx={{ fontFamily: "'Ubuntu', sans-serif" }}>
        {caretTimeString}
        {projectDuration && " / "}
        {projectDuration}
      </Typography>
    </Box>
  );
}

export default Timer;
