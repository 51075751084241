import { Size } from "babylonjs";

export const apiUrl = "https://lumiere-func.azurewebsites.net";
// export const apiUrl = "http://localhost:7071";
export let outputVideoSize = new Size(1280, 720);
export const previewSize = new Size(544, 312);
export let outputVideoFps = 30;
export const animationsFps = 60;
export const DEMO_SCRIBER_ID = "d8566000-028f-48f3-b837-8e1604bf76cf";

export const setVideoSize = (width: number, height: number) => {
  outputVideoSize = new Size(width, height);
};

export const setVideoFps = (fps: number) => {
  outputVideoFps = fps;
};
