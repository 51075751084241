import { RelativeBox, Sequence } from "../../store/types";

export type distance = {
  smooth: number;
  current: number;
};

export const lightMeshMask = 0x10000000;

export type BabylonSequence = {
  sequence: Sequence;
  items: BabylonSequenceItem[];
};

export type BabylonSequenceItem = {
  node: BABYLON.Node;
  animations: BABYLON.Animation[];
};

export interface scriberConfig {
  detail: number;
  featureSensitivity: number;
  id: string;
  date: number;
  status: configStatus;
  errorMsg: string;
  processingTimestamp: number;
  locked: boolean;
  idToken: string;
  mediaPath: string;
  maxResolution: number;
  nodeVersion: number;
  shared: boolean;
  rotation: number[];
  adjusted: boolean;
  subscriptionLevel?: string;
  redeem?: boolean;
  relativeBox?: RelativeBox;
}

export type configStatus = "queue" | "processing" | "done" | "error";
