import { Button, Popover, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { appModes } from "../../../store/types";
import { setScene3DView } from "../../../store/sceneSlice";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import ThreeDRotationRoundedIcon from "@mui/icons-material/ThreeDRotationRounded";
import FormatShapesRoundedIcon from "@mui/icons-material/FormatShapesRounded";
import {
  getSceneSwitch,
  navigateTo,
  setSceneSwitch,
} from "../../../store/navigatorSlice";
import { getProject } from "../../../store/projectSlice";

enum sceneModes {
  video,
  scene,
  threeD,
}

type SceneSwitchButton = {
  sceneMode: sceneModes;
  icon: any;
  value: string;
};

const elementsButtons: SceneSwitchButton[] = [
  {
    sceneMode: sceneModes.video,
    icon: <OndemandVideoRoundedIcon />,
    value: "video",
  },
  {
    sceneMode: sceneModes.scene,
    icon: <FormatShapesRoundedIcon />,
    value: "scene",
  },
  {
    sceneMode: sceneModes.threeD,
    icon: <ThreeDRotationRoundedIcon />,
    value: "threeD",
  },
];

const SceneSwitch = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const sceneSwitch = useAppSelector(getSceneSwitch);
  const [elementsMode, setElementsMode] = useState<sceneModes>(
    sceneModes.video
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (project.renderUrl === undefined) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    switch (sceneSwitch) {
      case "video":
        if (project.renderUrl === undefined) {
          setElementsMode(sceneModes.scene);
          dispatch(setScene3DView(false));
          dispatch(navigateTo(appModes.sequencer));
          return;
        }
        setElementsMode(sceneModes.video);
        dispatch(setScene3DView(false));
        dispatch(navigateTo(appModes.videoPreview));
        break;
      case "scene":
        setElementsMode(sceneModes.scene);
        dispatch(setScene3DView(false));
        dispatch(navigateTo(appModes.sequencer));
        break;
      case "threeD":
        setElementsMode(sceneModes.threeD);
        dispatch(setScene3DView(true));
        dispatch(navigateTo(appModes.sequencer));
        break;
    }
  }, [sceneSwitch]);

  return (
    <div
      style={{
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        border: "1px solid #FFF",
        borderRadius: "12px",
        height: "36px",
        gap: "1px",
      }}
    >
      {elementsButtons.map((b) => (
        <div
          key={b.value}
          onMouseEnter={
            b.sceneMode === sceneModes.video ? handlePopoverOpen : () => {}
          }
          onMouseLeave={
            b.sceneMode === sceneModes.video ? handlePopoverClose : () => {}
          }
        >
          <Button
            key={b.sceneMode}
            onClick={() => {
              dispatch(setSceneSwitch(b.value));
            }}
            disabled={
              b.sceneMode === sceneModes.video &&
              project.renderUrl === undefined
            }
            sx={{
              backgroundColor:
                elementsMode === b.sceneMode ? "#EAEDFE" : "#FFF",
              borderRadius: "12px",
              ":hover": {
                backgroundColor: elementsMode === b.sceneMode ? "#EAEDFE" : "",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            {b.icon}
          </Button>
        </div>
      ))}
      <Popover
        sx={{
          pointerEvents: "none",
          marginTop: "8px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            width: "280px",
            justifyContent: "center",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography align="center" style={{ fontSize: "15px" }}>
            In order to view ths tab you need to render video first
          </Typography>
          <Typography align="center" style={{ fontSize: "15px" }}>
            (click the "Render" button on the right)
          </Typography>
        </div>
      </Popover>
    </div>
  );
};

export default SceneSwitch;
