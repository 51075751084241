import {
  getCachedUser,
  getUserSettingsAsync,
  getUserSubscriptionDataAsync,
} from "./ClientManager";

export function handleAddAndCheckout(
  dispatch: Function,
  subscriptionLevel: string
) {
  // try {
  //   var tags = {
  //     papCookie:
  //       window.PostAffTracker._getAccountId() +
  //       window.PostAffTracker._cmanager.getVisitorIdOrSaleCookieValue(),
  //   };
  //   window.fastspring.builder.push({ tags: tags });
  // } catch (err) {}
  window.fastspring.builder.recognize(getCachedUser());
  window.fastspring.builder.add(subscriptionLevel);
  window.fastspring.builder.checkout();
  window.dataPopupWebhookReceived = async (event: any) => {
    // Handle the event or data received from the webhook here
    console.log("Webhook received:", event);
    await getUserSettingsAsync(dispatch);
    await getUserSubscriptionDataAsync(dispatch);
  };
  window.dataPopupClosed = async (event: any) => {
    // Handle the event or data received from the webhook here
    console.log("Popup closed:", event);
    await getUserSettingsAsync(dispatch);
    await getUserSubscriptionDataAsync(dispatch);
  };
}
