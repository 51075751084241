import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { getIsVertical } from "../../../store/renderSlice";
import { timeoutAsync } from "../../../helpers/Helper";
import { getProject } from "../../../store/projectSlice";
import TextItem from "./TextItem";
import { getAppMode } from "../../../store/navigatorSlice";
import { appModes } from "../../../store/types";
import { getScene3DView } from "../../../store/sceneSlice";

const TextView = () => {
  const isVertical = useAppSelector(getIsVertical);
  const project = useAppSelector(getProject);
  const [parentSize, setParentSize] = useState({ width: 0, height: 0 });
  const [previewSize, setPreviewSize] = useState({ width: 0, height: 0 });
  const parentRef = useRef<HTMLDivElement>(null);
  const previewCanvas = useRef<HTMLDivElement>(null);
  const abortControllerRef = useRef(new AbortController());
  const appMode = useAppSelector(getAppMode);
  const threeDMode = useAppSelector(getScene3DView);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length) {
        return;
      }
      const parentEntry = entries[0];
      const parentWidth = parentEntry.contentRect.width;
      const parentHeight = parentEntry.contentRect.height;
      setParentSize({ width: parentWidth, height: parentHeight });
    });
    resizeObserver.observe(parentRef.current!);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const goAsync = async () => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      let newWidth, newHeight;
      if (isVertical) {
        newWidth = Math.min(parentSize.width, (parentSize.height * 9) / 16);
        newHeight = (newWidth * 16) / 9;
      } else {
        newWidth = Math.min(parentSize.width, (parentSize.height * 16) / 9);
        newHeight = (newWidth * 9) / 16;
      }
      await timeoutAsync(300);
      if (signal.aborted) {
        return;
      }
      setPreviewSize({ width: newWidth, height: newHeight });
    };
    goAsync();
  }, [parentSize, isVertical]);

  return (
    <div
      style={{
        pointerEvents: threeDMode ? "none" : "inherit",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: appMode === appModes.render ? "none" : "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        ref={parentRef}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "calc(100% - 32px)",
          height: "100%",
        }}
      >
        <div
          ref={previewCanvas}
          style={{
            position: "relative",
            width: previewSize.width,
            height: previewSize.height,
          }}
        >
          {project.texts?.map((t) => (
            <TextItem item={t} key={t.id} parentSize={previewSize} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TextView;
