import { Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { getBlobAsync } from "../../../../managers/storage/StorageManager";
import { StoreItemModel } from "../../../../store/types";
import { setSelectedScriberProjectId } from "../../../../store/scriberSlice";
import { useAppDispatch } from "../../../../store/hooks";
import TryRoundedIcon from "@mui/icons-material/TryRounded";

export interface ScriberGalleryDemoItemProps {
  item: StoreItemModel;
}

const ScriberGalleryDemoItem = ({ item }: ScriberGalleryDemoItemProps) => {
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();

  useEffect(() => {
    const asyncWrapper = async () => {
      const preview = await getBlobAsync(item);
      setPreviewUrl(URL.createObjectURL(preview!));
    };
    asyncWrapper();
  }, [item]);

  const handleSelect = async () => {
    dispatch(setSelectedScriberProjectId(item.name));
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="gradient"
        style={{
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <Button
        data-track="ModelClicked"
        style={{
          borderRadius: "12px",
          opacity: previewUrl ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          overflow: "hidden",
          padding: 0,
        }}
        onClick={handleSelect}
      >
        <img
          src={previewUrl}
          style={{ width: "162px", height: "288px", objectFit: "cover" }}
        />
      </Button>
      <div
        style={{
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          opacity: isHovered ? 0.3 : 0.1,
          transition: "opacity 300ms",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <Typography
          style={{
            color: "#373E4E",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          Discover best practices
        </Typography>
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          left: "8px",
          bottom: "40px",
          pointerEvents: "none",
        }}
      >
        <div style={{ display: "flex", alignItems: "end" }}>
          <TryRoundedIcon fontSize="large" style={{ color: "white" }} />
        </div>
      </div>
    </div>
  );
};

export default ScriberGalleryDemoItem;
