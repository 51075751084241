import { Button, Typography } from "@mui/material";
import { textTemplates } from "../leftPanel/styles/text/TextTemplates";
import { textAnimationTemplates } from "../leftPanel/textAnimations/TextAnimationTempates";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getTimelineSelectedItem,
  setTimelineCaretTime,
} from "../../store/timelineSlice";
import {
  getProject,
  setTextAnimationTemplate,
  setTextTemplate,
} from "../../store/projectSlice";

const RegenerateTextButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const selectedItem = useAppSelector(getTimelineSelectedItem);

  const handleCreateAIText = async () => {
    const selectedText = project.texts?.find((t) => t.id === selectedItem?.id);

    const getRandomTemplate = (
      templates: any,
      currentTemplateId: string,
      excludeFirst = false
    ) => {
      let startIndex = excludeFirst ? 1 : 0;
      let randomIndex =
        Math.floor(Math.random() * (templates.length - startIndex)) +
        startIndex;
      if (templates[randomIndex].id === currentTemplateId) {
        randomIndex =
          ((randomIndex + 1 - startIndex) % (templates.length - startIndex)) +
          startIndex;
      }
      return templates[randomIndex];
    };

    const textTemplate = getRandomTemplate(
      textTemplates,
      selectedText?.template.id!
    );
    const textAnimationTemplate = getRandomTemplate(
      textAnimationTemplates,
      selectedText?.template.id!,
      true
    );

    dispatch(setTextTemplate({ template: textTemplate, id: selectedItem?.id }));
    dispatch(
      setTextAnimationTemplate({
        template: textAnimationTemplate,
        id: selectedItem?.id,
      })
    );
    dispatch(
      setTimelineCaretTime(
        selectedText!.timeline.offset + selectedText!.timeline.duration / 2
      )
    );
  };

  return (
    <Button
      sx={{
        background: "#FFF",
        borderRadius: "12px",
        border: "2px solid #4261FF",
        padding: "10px 18px",
        height: "36px",
        width: "calc(100% - 32px)",
        ":hover": {
          background: "#FFF",
          boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
        },
        ":active": {
          background: "#FFF",
          boxShadow: "none",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      onClick={handleCreateAIText}
    >
      <Typography
        sx={{
          color: "#3050F5",
        }}
      >
        Style
      </Typography>
    </Button>
  );
};

export default RegenerateTextButton;
