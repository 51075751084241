export type AccessType = "shared" | "user";

export interface StorageData {
  data: any;
  occupiedStorageSize: number;
}

export type StoreItemModel = {
  id: string;
  name: string;
  url: string;
  accessType: AccessType;
  properties: any;
};

export type ProgressModel = {
  currentDownloadProgress: number;
  totalDownloadProgress: number;
  downloadState: string;
};

export type DialogModel = {
  earlyAccessDialogOpen: boolean;
  settingsDialogOpen: boolean;
  shareModelDialogOpen: boolean;
  createModelDialogOpen: boolean;
  createVideoDialogOpen: boolean;
  createVideoDialogMode: createVideoDialogModes;
  customAlert: string;
  imageDialog: StoreItemModel | undefined;
};

export type OnboardingModel = {
  onboardingVisible: boolean;
  currentOnboardingStep: number;
};

export type EditorModel = {
  editorViewVisible: boolean;
};
export type AIModel = {
  generatingShot: boolean;
  generatingAudio: boolean;
  generatingText: string;
};

export interface TimelineState {
  time: string;
  frame: number;
  playing: boolean;
  sequenceFinish: boolean;
  selectedItem?: { id: string; type: TimelineItemType };
  playerUrl?: { url: string; name: string };
  caretTime: number;
  caretVideoTrack: { id: string; frame: number };
  caretTextTrack: { id: string; time: number }[];
  scale: iScale;
}

export interface iScale {
  minRatio: number;
  maxRatio: number;
  currentScale: number;
}

export interface ScriberState {
  loading: boolean;
  selectedItem?: StoreItemModel;
  inited: boolean;
  scribersSelectable: true;
  selectedScriberProjectId: string;
}

export interface UploadingState {
  uploading: boolean;
  uploadingText: "";
  currentProgress: number;
  totalProgress: number;
}

export interface SceneState {
  loading: number;
  sceneLoading: number;
  progress: number;
  selectedAnimatableName?: string;
  threeDView: boolean;
  canvasSize: { width: number; height: number };
}

export interface Project {
  id: string;
  name: string;
  sequences: Sequence[];
  audio?: AudioTrack;
  texts?: iTextTrack[];
  settings?: ProjectSettings;
  renderUrl?: string;
}

export interface ProjectSettings {
  type: projectType;
}

export enum projectType {
  landscape,
  portrait,
}

export interface iTextTemplate {
  id: string;
  css: any;
}

export interface iTextTrack {
  id: string;
  template: iTextTemplate;
  animation: TextAnimation;
  appearance: {
    text: string;
    color?: string;
    normalizedFontSize: number;
    normalizedPosition: {
      x: number;
      y: number;
    };
    normalizedSize: {
      width: number;
      height: number;
    };
  };
  timeline: {
    duration: number;
    offset: number;
  };
}

export type TextAnimation = {
  template: TextAnimationTemplate;
  duration: number;
};

export type TextAnimationTemplate = {
  id: string;
  inStyleName: string;
  outStyleName: string;
};

export interface AudioTrack {
  id: string;
  source: StoreItemModel;
  duration: number;
  trims: number[];
  offset: number;
}

export interface Sequence {
  id: string;
  name: string;
  duration: number;
  trims: number[];
  camera: Camera;
  environment?: Environment;
  skybox?: Skybox;
  mainObject?: MainObject;
  lights?: Light[];
  shot?: StoreItemModel;
}

export interface Environment {
  model: StoreItemModel;
  selectedChangables?: ChangableEnvironmentOptions[];
}

export interface EnvironmentModel {
  model: StoreItemModel;
  shotGroups: ShotGroup[];
  changableGroups?: ChangableEnvironmentGroup[];
  skyboxes: StoreItemModel[];
}

export interface ChangableEnvironmentGroup {
  id: string;
  name: string;
  selectedOptionId: string;
  options: ChangableEnvironmentOptions[];
}

export interface ChangableEnvironmentOptions {
  id: string;
  name: string;
}

export interface Skybox {
  model: StoreItemModel;
  rotationY: number;
}

export interface MainObject extends Animatable {
  relativeBox?: RelativeBox;
  model: StoreItemModel;
}

export interface Camera extends Animatable {
  id: string;
  name: string;
}

export interface Light extends Animatable {
  id: string;
  name: string;
  light: string;
}

export interface Animatable {
  transform?: Transform;
  animations?: string[];
}

export interface Transform {
  position: number[];
  rotation: number[];
  scaling: number[];
}

export interface ShotGroup {
  id: string;
  name: string;
  shots: StoreItemModel[];
}

export interface RelativeBox {
  dimensions: number[];
  center: number[];
}

export enum appModes {
  scriberPreview,
  sequencer,
  render,
  videoPreview,
}

export enum tabModes {
  home,
  models,
  videos,
  elements,
  developer,
  styles,
  textAnimations,
}

export enum galleryModes {
  none,
  scribedModels,
  uploadedModels,
  sharedModels,
  userAudios,
  sharedAudios,
  sharedTexts,
}

export enum createVideoDialogModes {
  createVideo,
  createModel,
  modelsGallery,
}

export enum sortingMethods {
  byName,
  byDate,
  bySize,
}

export enum audioUploadButtonTypes {
  shared,
  user,
}

export interface NavigatorModel {
  appMode: appModes;
  tabMode: tabModes;
  previousTabMode: tabModes;
  saving: boolean;
  sceneSwitch: string;
}

export interface RenderModel {
  isVertical: boolean;
  selectedRenderId: string;
  rendersSelectable: boolean;
  backImageUrl: string;
}

export interface ShotModel {
  name: string;
  groupName: string;
  camera: {
    animations: any[];
    transform: Transform;
  };
  lights: any[];
  relativeBox: RelativeBox;
}

export interface UserSettings {
  mail?: string;
  subscriptionLevel: string;
  subscriptionId: string;
  subscriptionEndDate: string;
  userRole: string;
  subscriptionLimits?: SubscriptionLevelLimits;
  scriberAvailability?: ItemAvailability;
  videoDownloadAvailability?: ItemAvailability;
  credits?: ItemAvailability;
  createdDateTime?: string;
  displayName?: string;
  hasOnboarded?: boolean;
}

export interface SubscriptionData {
  subscriptionPlans: any;
}

export interface ItemAvailability {
  expDate: string;
  left: number;
}

export interface SubscriptionLevelLimits {
  templatesCount: number;
  musicCount: number;
  scriberProcess: {
    count: number;
    perTime: number;
  };
  scriberPhotos: {
    count: number;
    maxResolution: number;
  };
  videoDownloads: {
    count: number;
    perTime: number;
    watermark: boolean;
  };
  videoSettings: {
    videoFps: number;
    resolution: number;
  };
  storageGb: number;
  scriberPriorityLevel: number;
  modelsDownload: boolean;
}

export interface RenderSettings {
  resolution: ResolutionMenuItem;
  fps: number;
  orientation: string;
}

export interface EditorSettings {
  editorViewVisible: boolean;
}

export interface ResolutionMenuItem {
  text: string;
  width: number;
  height: number;
  isDemo: boolean;
}

export type userRole = "user" | "editor";
export enum TimelineItemType {
  none,
  threeD,
  audio,
  text,
}
