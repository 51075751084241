import { Box, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getHasUserOnboarded } from "../../../store/userSlice";
import { getLoaderState } from "../../../store/sceneSlice";
import Text from "./text/Text";
import { getTimelineSelectedItem } from "../../../store/timelineSlice";
import { getProject } from "../../../store/projectSlice";
import { TimelineItemType, tabModes } from "../../../store/types";
import { switchTabTo } from "../../../store/navigatorSlice";

const Styles = () => {
  const dispatch = useAppDispatch();
  const hasUserOnboarded = useAppSelector(getHasUserOnboarded);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const project = useAppSelector(getProject);
  const projectRef = useRef(project);
  const loading = useAppSelector(getLoaderState);

  useEffect(() => {
    projectRef.current = project;
  }, [project]);

  useEffect(() => {
    if (selectedItem?.type !== TimelineItemType.text) {
      dispatch(switchTabTo(tabModes.elements));
    }
  }, [selectedItem]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {selectedItem?.type === TimelineItemType.text && <Text />}
      {!hasUserOnboarded && loading && (
        <Box
          sx={{
            left: 0,
            top: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "#F1F3F4",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={48} />
        </Box>
      )}
    </Box>
  );
};

export default Styles;
