import { useAppSelector } from "../../../store/hooks";
import {
  getProject,
  setTextAnimationProps,
  setTextAnimationTemplate,
} from "../../../store/projectSlice";
import { Grid, Slider, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { textAnimationTemplates } from "./TextAnimationTempates";
import { TextAnimationTemplate } from "../../../store/types";
import { getTimelineSelectedItem } from "../../../store/timelineSlice";
import TextAnimationItem from "./TextAnimationItem";
import { getTextById } from "../../../helpers/Helper";
import { useEffect, useState } from "react";

const TextAnimations = () => {
  const dispatch = useDispatch();
  const project = useAppSelector(getProject);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!selectedItem?.id) return;
    const selectedText = getTextById(project, selectedItem.id);
    if (!selectedText) return;
    setDuration(selectedText.animation.duration);
  }, [selectedItem]);

  const handleClick = (textAnimationTemplate: TextAnimationTemplate) => {
    dispatch(
      setTextAnimationTemplate({
        id: selectedItem?.id,
        template: textAnimationTemplate,
      })
    );
  };

  const handleDurationChange = () => {
    dispatch(
      setTextAnimationProps({
        id: selectedItem?.id,
        data: { duration: duration },
      })
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          margin: "16px 0px",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
            flexDirection: "column",
            padding: "0px 32px",
            gap: "8px",
          }}
        >
          <Typography>Duration</Typography>
          <Slider
            min={0.1}
            max={3}
            step={0.1}
            value={duration}
            onChange={(_, v) => setDuration(v as number)}
            onChangeCommitted={handleDurationChange}
            valueLabelDisplay="auto"
          />
        </div>
        <Grid
          container
          justifyContent="center"
          sx={{ marginTop: "12px", gap: "12px 8px" }}
        >
          {textAnimationTemplates.map((t) => (
            <TextAnimationItem
              key={t.id}
              template={t}
              onSelect={() => handleClick(t)}
            />
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default TextAnimations;
