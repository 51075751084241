import MainView from "./components/MainView";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./css/scroll.css";
import "./css/textAnimations.css";
import { useEffect } from "react";
import ScriberPreview from "./components/scene/scriber/ScriberPreview";
import { isMobileOnly } from "react-device-detect";
import MobileView from "./components/MobileView";
import { appInsights } from "./managers/AppInsights";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={isMobileOnly ? <MobileView /> : <MainView />} />
      <Route path="/preview" element={<ScriberPreview />} />
    </Routes>
  );
};

type AppProps = {
  instance: PublicClientApplication;
};

function App({ instance }: AppProps) {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isWindows = /Windows/.test(userAgent);

    if (isWindows) {
      document.documentElement.classList.add("is-windows");
    }

    const handleTrackableClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement; // Type casting to HTMLElement
      const trackableElement = target.closest("[data-track]");
      if (trackableElement) {
        const eventName = trackableElement.getAttribute("data-track");
        if (eventName) {
          appInsights.trackEvent({ name: eventName });
        }
      }
    };

    document.body.addEventListener("click", handleTrackableClick);

    return () => {
      document.documentElement.classList.remove("is-windows");
      document.body.removeEventListener("click", handleTrackableClick);
    };
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#454E63",
      },
      secondary: {
        main: "#4261FF",
      },
    },
    typography: {
      allVariants: {
        color: "#454E63",
        textTransform: "none",
        userSelect: "none",
      },
      fontFamily: "Red Hat Display",
      fontWeightRegular: 700,
      fontWeightLight: 700,
      fontWeightMedium: 700,
      fontWeightBold: 700,
    },
  });

  return (
    <MsalProvider instance={instance}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Pages />
        </ThemeProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
