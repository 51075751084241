import { iTextTemplate } from "../../../../store/types";

export const textTemplates: iTextTemplate[] = [
  {
    id: "1",
    css: {
      position: "relative",
      whiteSpace: "nowrap",
      color: "gray",
      "&:after": {
        "--deco-height": "0.3125em",
        content: '""',
        position: "absolute",
        left: "0",
        right: "0",
        bottom: "0",
        height: "var(--deco-height)",
        backgroundImage:
          "url(\"data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%2300FDCF' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A\")",
        backgroundSize: "auto 100%",
        backgroundRepeat: "round",
        backgroundPosition: "0em",
      },
    },
  },
  {
    id: "2",
    css: {
      fontFamily: "'Bebas Neue', cursive",
      backgroundImage: "linear-gradient(gold, gold)",
      backgroundSize: "100% 10px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 0%",
      transition: "background-size .7s, background-position .5s ease-in-out",
    },
  },
  {
    id: "3",
    css: {
      fontFamily: `"Exo 2", sans-serif`,
      color: "#fde9ff",
      fontWeight: 900,
      textTransform: "uppercase",
      textShadow:
        "3px 1px 1px #4af7ff, 2px 2px 1px #165bfb, 4px 2px 1px #4af7ff, 3px 3px 1px #165bfb, 5px 3px 1px #4af7ff, 4px 4px 1px #165bfb, 6px 4px 1px #4af7ff, 5px 5px 1px #165bfb, 7px 5px 1px #4af7ff, 6px 6px 1px #165bfb, 8px 6px 1px #4af7ff, 7px 7px 1px #165bfb, 9px 7px 1px #4af7ff",
    },
  },
  {
    id: "4",
    css: {
      fontFamily: "'Calligraffitti', cursive",
      fontWeight: 700,
      textShadow:
        "0.05em 0.05em 0px #FFB650, 0.1em 0.1em 0px #FFD662, 0.15em 0.15em 0px #FF80BF, 0.2em 0.2em 0px #EF5097, 0.25em 0.25em 0px #6868AC, 0.3em 0.3em 0px #90B1E0",
      letterSpacing: "0.02em",
      color: "#F9f1cc",
    },
  },
  {
    id: "5",
    css: {
      color: "#fcedd8",
      fontFamily: "'Niconne', cursive",
      textShadow: `
      0.03125em 0.03125em 0 #eb452b,
      0.0625em 0.0625em 0 #efa032,
      0.09375em 0.09375em 0 #46b59b,
      0.125em 0.125em 0 #017e7f,
      0.15625em 0.15625em 0 #052939,
      0.1875em 0.1875em 0 #0002,
      0.21875em 0.21875em 0 #0002,
      0.25em 0.25em 0 #0002,
      0.28125em 0.28125em 0 #0002`,
    },
  },
  {
    id: "6",
    css: {
      fontFamily: "'Henny Penny', cursive",
      color: "#FFF",
      margin: "auto",
      letterSpacing: "0.0015em",
      textShadow:
        "0 1px #8da1ff, -1px 0 #c0cbff, -1px 2px #8da1ff, -2px 1px #c0cbff, -2px 3px #8da1ff, -3px 2px #c0cbff, -3px 4px #8da1ff, -4px 3px #c0cbff, -4px 5px #8da1ff, -5px 4px #c0cbff, -5px 6px #8da1ff, -6px 5px #c0cbff, -6px 7px #8da1ff, -7px 6px #c0cbff, -7px 8px #8da1ff, -8px 7px #c0cbff",
      textAlign: "center",
    },
  },
  {
    id: "7",
    css: {
      fontFamily: "'Cinzel Decorative', cursive",
      fontSize: "2em",
      color: "#5e5e5e",
      letterSpacing: "0.1em",
      textTransform: "uppercase",
      textAlign: "center",
      padding: "10px",
    },
  },
  {
    id: "8",
    css: {
      fontFamily: "'Pacifico', cursive",
      fontSize: "1.5em",
      color: "#faf7c8",
      letterSpacing: "0.05em",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
      textAlign: "center",
    },
  },
];
