import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { loginRequest } from "../../authConfig";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { showSettingsDialog } from "../../store/dialogSlice";
import { getUserStorageData } from "../../store/storageSlice";
import { getStorageItemFromStorageData } from "../../helpers/Helper";
import { getBlobAsync } from "../../managers/storage/StorageManager";
import { getUserSubscriptionLevel } from "../../store/userSlice";
import { deleteBlobFromIDBAsync } from "../../managers/storage/DbManager";

const UserIcon = () => {
  const dispatch = useAppDispatch();
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { instance } = useMsal();
  const [url, setUrl] = useState("assets/images/user.webp");
  const userStorageData = useAppSelector(getUserStorageData);

  useEffect(() => {
    const asyncWrapper = async () => {
      let icon = getStorageItemFromStorageData("user/icon", userStorageData);
      if (!icon || icon.length === 0) {
        return;
      }
      let blob = await getBlobAsync(icon[0]);
      if (blob) {
        setUrl(URL.createObjectURL(blob));
      }
    };
    asyncWrapper();
  }, [userStorageData]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    setAnchorElUser(null);
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleSettings = () => {
    setAnchorElUser(null);
    dispatch(showSettingsDialog());
  };

  const handleLogout = async () => {
    await deleteBlobFromIDBAsync("user/icon/icon.png");
    setAnchorElUser(null);
    instance.logoutRedirect();
  };

  return (
    <Box>
      <UnauthenticatedTemplate>
        <IconButton onClick={handleOpenUserMenu}>
          <AccountCircleIcon sx={{ color: "white" }} fontSize="large" />
        </IconButton>
        <Menu
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={handleLogin}>
            <Typography textAlign="center">Login</Typography>
          </MenuItem>
        </Menu>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {subscriptionLevel === "demo" && (
          <IconButton onClick={handleOpenUserMenu} style={{ padding: 0 }}>
            <img
              src={url}
              width="36px"
              height="36px"
              style={{
                objectFit: "cover",
                borderRadius: "36px",
                border: "2px solid #FFF",
              }}
            />
          </IconButton>
        )}
        {subscriptionLevel === "starter" && (
          <IconButton
            onClick={handleOpenUserMenu}
            style={{ padding: "2px", backgroundColor: "#3050F9" }}
          >
            <img
              src={url}
              width="32px"
              height="32px"
              style={{
                objectFit: "cover",
                borderRadius: "32px",
                border: "2px solid #FFF",
              }}
            />
          </IconButton>
        )}
        {(subscriptionLevel === "pro" || subscriptionLevel === "test") && (
          <IconButton
            onClick={handleOpenUserMenu}
            style={{ padding: "2px", backgroundColor: "#3050F9" }}
            className="backgroundGradient"
          >
            <img
              src={url}
              width="32px"
              height="32px"
              style={{
                objectFit: "cover",
                borderRadius: "32px",
                border: "2px solid #FFF",
              }}
            />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
          sx={{
            marginTop: "4px",
            ".MuiMenu-paper": { borderRadius: "12px" },
            ".MuiMenu-list": { margin: "0px 8px" },
          }}
        >
          <MenuItem
            onClick={handleSettings}
            sx={{
              borderRadius: "12px",
              padding: "8px 12px",

              ":active": { backgroundColor: "#EDEDED" },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img
                src="assets/svg/SettingsIcon.svg"
                width="24px"
                height="24px"
              />
              <Typography textAlign="center">Settings</Typography>
            </Box>
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            sx={{
              borderRadius: "12px",
              ":active": { backgroundColor: "#EDEDED" },
              padding: "8px 12px",

              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <img src="assets/svg/LogoutIcon.svg" width="24px" height="24px" />
              <Typography textAlign="center">Log Out</Typography>
            </Box>
          </MenuItem>
        </Menu>
      </AuthenticatedTemplate>
    </Box>
  );
};

export default UserIcon;
