import { Box, IconButton, Slider } from "@mui/material";
import { useAppSelector } from "../../../../store/hooks";
import {
  getTimelineCaretVideoTrack,
  getTimelineScale,
  getTimelineSelectedItem,
  setTimelineSelectedItem,
} from "../../../../store/timelineSlice";
import { Sequence, TimelineItemType } from "../../../../store/types";
import { animationsFps } from "../../../../settings/GlobalSettings";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setSequenceTrims } from "../../../../store/projectSlice";
import { Draggable } from "react-beautiful-dnd";
import { getLumiereSceneById } from "../../../scene/LumiereScene";
import { getSceneCanvasSize } from "../../../../store/sceneSlice";

const minDistance = 600 * 3;

type VideoTrackItemProps = {
  sequence: Sequence;
  engineContext: any;
  index: number;
  onMenu: (event: React.MouseEvent<HTMLElement>, sequenceId: string) => void;
};

function VideoTrackItem({
  sequence,
  engineContext,
  index,
  onMenu,
}: VideoTrackItemProps) {
  const dispatch = useDispatch();
  const canvasSize = useAppSelector(getSceneCanvasSize);
  const scaleRatio = useAppSelector(getTimelineScale);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const caretVideoTrack = useAppSelector(getTimelineCaretVideoTrack);
  const [trims, setTrims] = useState([
    sequence.trims[0],
    sequence.duration - sequence.trims[1],
  ]);
  const selected = useMemo(
    () => selectedItem?.id === sequence.id,
    [selectedItem?.id]
  );
  const [isHovered, setIsHovered] = useState(false);
  const previewCanvas = useRef({} as HTMLCanvasElement);
  const countRef = useRef(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const [scaleX, setScaleX] = useState(1);

  useEffect(() => {
    setTrims([sequence.trims[0], sequence.duration - sequence.trims[1]]);
  }, [sequence.trims, sequence.duration]);

  useEffect(() => {
    if (!engineContext) return;
    const lumiereScene = getLumiereSceneById(sequence.id);
    if (!lumiereScene) return;
    if (caretVideoTrack.id === sequence.id) {
      lumiereScene.engine.registerView(
        previewCanvas.current,
        lumiereScene.mainCamera,
        true
      );
    } else {
      if (countRef.current === 0) {
        lumiereScene.engine.registerView(
          previewCanvas.current,
          lumiereScene.mainCamera,
          true
        );
      } else {
        lumiereScene.engine.unRegisterView(previewCanvas.current);
      }
    }
    countRef.current++;
  }, [engineContext, caretVideoTrack.id]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        const parentHeight = parentRef.current.offsetHeight;
        const scaleX = parentWidth / canvasSize.width;
        const scaleY = parentHeight / canvasSize.height;
        setScaleX(Math.max(scaleX, scaleY));
      }
    });

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleChange = (newValue: number[], activeThumb: number) => {
    if (!selected) {
      dispatch(
        setTimelineSelectedItem({
          id: sequence.id,
          type: TimelineItemType.threeD,
        })
      );
    }

    if (activeThumb === 0) {
      setTrims([
        Math.floor(Math.min(newValue[0], trims[1] - minDistance / scaleRatio)),
        trims[1],
      ]);
    } else {
      setTrims([
        trims[0],
        Math.floor(Math.max(newValue[1], trims[0] + minDistance / scaleRatio)),
      ]);
    }
  };

  const handleChangeCommited = () => {
    dispatch(
      setSequenceTrims({
        sequenceId: sequence.id,
        trims: [trims[0], sequence.duration - trims[1]],
      })
    );
  };

  const handleSelect = () => {
    dispatch(
      setTimelineSelectedItem({
        id: sequence.id,
        type: TimelineItemType.threeD,
      })
    );
  };

  return (
    <Draggable draggableId={sequence.id} index={index}>
      {(provided) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          onClick={handleSelect}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: `${
              scaleRatio * ((trims[1] - trims[0]) / animationsFps)
            }px`,
            maxWidth: `${
              scaleRatio * ((trims[1] - trims[0]) / animationsFps)
            }px`,
            height: "100%",
            position: "relative",
            overflow: "hidden",
            borderRadius: "8px",
          }}
        >
          <div
            ref={parentRef}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <canvas
              ref={previewCanvas}
              style={{
                pointerEvents: "none",
                width: canvasSize.width,
                height: canvasSize.height,
                borderRadius: "inherit",
                transform: `translate(-50%, -50%) scale(${scaleX})`,
                transformOrigin: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: `${scaleRatio * (-trims[0] / animationsFps)}px`,
              right: `${
                scaleRatio * (-(sequence.duration - trims[1]) / animationsFps)
              }px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              minWidth: `${scaleRatio * (sequence.duration / animationsFps)}px`,
              maxWidth: `${scaleRatio * (sequence.duration / animationsFps)}px`,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <Slider
                value={trims}
                onChange={(_, v, at) => handleChange(v as number[], at)}
                onChangeCommitted={handleChangeCommited}
                disableSwap
                min={0}
                max={sequence.duration}
                sx={{
                  color: "transparent",
                  "&.MuiSlider-root": {
                    pointerEvents: "none !important",
                    height: "100%",
                    padding: 0,
                  },
                  ".MuiSlider-thumb[data-index='0']": {
                    pointerEvents: "all !important",
                    opacity: selected ? 1 : 0,
                    transition: "none",
                    boxShadow: "none",
                    borderRadius: 0,
                    height: "100%",
                    width: "24px",
                    backgroundColor: "#4261FF",
                    "&:after": {
                      width: "24px",
                    },
                    "&:before": {
                      boxShadow: "none",
                    },
                  },
                  ".MuiSlider-thumb[data-index='1']": {
                    pointerEvents: "all !important",
                    opacity: selected ? 1 : 0,
                    transition: "none",
                    boxShadow: "none",
                    borderRadius: 0,
                    height: "100%",
                    width: "24px",
                    backgroundColor: "#4261FF",
                    "&:after": {
                      width: "24px",
                    },
                    "&:before": {
                      boxShadow: "none",
                    },
                  },
                }}
              />
            </div>
          </div>

          <div
            style={{
              pointerEvents: "none",
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              boxSizing: "border-box",
              borderWidth: selected ? "3px" : "0px 1px 0px 1px",
              borderColor: selected ? "#4261FF" : "#F1F3F4",
              borderStyle: "solid",
              borderRadius: "8px",
            }}
          />
          <div
            {...provided.dragHandleProps}
            style={{
              position: "absolute",
              top: 0,
              left: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "calc(100% - 24px)",
            }}
          />
          <IconButton
            sx={{
              position: "absolute",
              width: "20px",
              height: "20px",
              top: "8px",
              right: "16px",
              opacity: isHovered ? 1 : 0,
              transition: "opacity 0.3s",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onMenu(e, sequence.id);
            }}
          >
            <img src="assets/svg/MoreIcon.svg" width="20px" height="20px" />
          </IconButton>
          {selected && (
            <>
              <div
                style={{
                  pointerEvents: "none",
                  width: "2px",
                  height: "32px",
                  borderRadius: "2px",
                  position: "absolute",
                  left: "5px",
                  top: "16px",
                  backgroundColor: "white",
                }}
              />
              <div
                style={{
                  pointerEvents: "none",
                  width: "2px",
                  height: "32px",
                  borderRadius: "2px",
                  position: "absolute",
                  right: "5px",
                  top: "16px",
                  backgroundColor: "white",
                }}
              />
            </>
          )}
        </Box>
      )}
    </Draggable>
  );
}

export default VideoTrackItem;
