import { Box } from "@mui/material";
import ReactPlayer from "react-player";
import { useAppSelector } from "../../store/hooks";
import { useEffect, useRef, useState } from "react";
import { getUserBlobAsync } from "../../managers/storage/AzureStorageManager";
import {
  getBlobFromIDBAsync,
  saveBlobToIDBAsync,
} from "../../managers/storage/DbManager";
import { getProject } from "../../store/projectSlice";

const Player = () => {
  const project = useAppSelector(getProject);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [playerSize, setPlayerSize] = useState({ width: 0, height: 0 });
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    const runAsync = async () => {
      if (!project?.renderUrl) return;
      let blob = await getBlobFromIDBAsync(project.renderUrl);
      if (!blob) {
        blob = await getUserBlobAsync(project.renderUrl);
        saveBlobToIDBAsync(blob!, project.renderUrl);
      }
      setVideoUrl(URL.createObjectURL(blob!));
    };
    runAsync();
  }, [project?.renderUrl]);

  function handleReady(p: ReactPlayer) {
    const internalPlayer = p.getInternalPlayer() as HTMLVideoElement;
    const videoAspectRatio =
      internalPlayer.videoWidth / internalPlayer.videoHeight;
    const parentSize = {
      width: wrapperRef.current!.clientWidth - 32,
      height: wrapperRef.current!.clientHeight - 32,
    };
    const playerAspectRatio = parentSize.width / parentSize.height;
    let displayWidth, displayHeight;
    if (videoAspectRatio > playerAspectRatio) {
      displayWidth = parentSize.width;
      displayHeight = displayWidth / videoAspectRatio;
    } else {
      displayHeight = parentSize.height;
      displayWidth = displayHeight * videoAspectRatio;
    }
    setPlayerSize({ width: displayWidth, height: displayHeight });
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100% - 64px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        position: "absolute",
        flexDirection: "column",
        top: "64px",
        left: 0,
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          marginBottom: "32px",
          marginTop: "16px",
          pointerEvents: "all",
          overflow: "hidden",
        }}
      >
        <div
          ref={wrapperRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div style={{ borderRadius: "16px", overflow: "hidden" }}>
            <ReactPlayer
              url={videoUrl}
              onReady={(p) => handleReady(p)}
              width={playerSize.width}
              height={playerSize.height}
              controls
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
            />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Player;
