import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";
import { outputVideoFps, outputVideoSize } from "../settings/GlobalSettings";
import { AudioTrack } from "../store/types";

const chunkSize = 30;
let ffmpeg: FFmpeg;
let hasAudio = false;
let hasWatermark = false;
let cachedVideoFileNames: string[] = [];
let cachedImageFileNames: string[] = [];
let outputFileName = "final_output.mp4";
let audio: {
  name: string;
  delay: number;
  start: number;
  end: number;
  trimmedName: string;
};

export async function loadFfmpegAsync() {
  hasAudio = false;
  cachedVideoFileNames = [];
  cachedImageFileNames = [];
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd";
  ffmpeg = new FFmpeg();
  // ffmpeg.on("log", ({ message }) => {
  //   console.log(message);
  // });
  await ffmpeg.load({
    coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
    wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
  });
}

export async function addImageAsync(
  fileName: string,
  data: string
): Promise<void> {
  await ffmpeg.writeFile(fileName, await fetchFile(data));
  cachedImageFileNames.push(fileName);
  if (cachedImageFileNames.length === chunkSize) {
    await renderChunkAsync();
  }
}

async function renderChunkAsync() {
  const tempVideoFileName = `temp_video_${cachedVideoFileNames.length}.mp4`;
  cachedVideoFileNames.push(tempVideoFileName);
  await ffmpeg.exec([
    "-framerate",
    `${outputVideoFps}`,
    "-pattern_type",
    "glob",
    "-i",
    "*.png",
    "-map",
    "0:v",
    "-vf",
    `scale=${outputVideoSize.width}:${outputVideoSize.height}`,
    "-c:v",
    "libx264",
    "-preset",
    "ultrafast", // Use 'ultrafast' preset for fast encoding and high quality
    "-crf",
    "18", // Lower CRF value (e.g. 18) for better quality
    "-pix_fmt",
    "yuv420p", // Set pixel format to 'yuv420p' for wider compatibility
    tempVideoFileName,
  ]);

  for (const file of cachedImageFileNames) {
    await ffmpeg.deleteFile(file);
  }
  cachedImageFileNames = [];
}

export async function addSoundAsync(data: Blob, audioTrack: AudioTrack) {
  const name = audioTrack.source.url.split("/").pop()!;
  audio = {
    name: name,
    trimmedName: "trimmed_" + name,
    delay: audioTrack.offset * 1000,
    start: audioTrack.trims[0],
    end: audioTrack.duration - audioTrack.trims[1],
  };
  console.log(audio);
  await ffmpeg.writeFile(audio.name, await fetchFile(data));
  await ffmpeg.exec([
    "-i",
    audio.name,
    "-ss",
    `${audio.start}`,
    "-to",
    `${audio.end}`,
    "-c",
    "copy",
    audio.trimmedName,
  ]);
  hasAudio = true;
}

export function disableAudio() {
  hasAudio = false;
}

export function enableWatermark() {
  hasWatermark = true;
}

export async function renderAsync(duration: number) {
  if (cachedImageFileNames.length > 0) {
    await renderChunkAsync();
  }
  const videoListContent = cachedVideoFileNames
    .map((fileName) => `file '${fileName}'`)
    .join("\n");
  await ffmpeg.writeFile(
    "video_list.txt",
    new TextEncoder().encode(videoListContent)
  );
  const settings = hasAudio
    ? [
        "-f",
        "concat",
        "-safe",
        "0",
        "-i",
        "video_list.txt",
        "-i",
        audio.trimmedName, // Add the audio input file
        "-t",
        `${duration}`,
        "-c:v",
        "copy", // Copy the video codec
        "-c:a",
        "aac", // Copy the audio codec
        "-filter_complex",
        `[1:a]adelay=${audio.delay}|${audio.delay}[delayed]`, // Apply delay and trimming to the audio
        "-map",
        "0:v", // Map the video stream from the concatenated video input
        "-map",
        "[delayed]", // Map the filtered audio stream
        outputFileName,
      ]
    : [
        "-f",
        "concat",
        "-safe",
        "0",
        "-i",
        "video_list.txt",
        "-c:v",
        "copy", // Copy the audio codec
        "-map",
        "0:v", // Map the video stream from the concatenated video input
        outputFileName,
      ];
  await ffmpeg.exec([...settings]);

  if (hasWatermark) {
    const response = await fetch("assets/watermark.png");
    const blob = await response.blob();
    await ffmpeg.writeFile("watermark.png", await fetchFile(blob));

    await ffmpeg.exec([
      `-i`,
      outputFileName,
      `-i`,
      `watermark.png`,
      `-filter_complex`,
      "[1]format=rgba,colorchannelmixer=aa=0.1[logo];[logo][0]scale2ref=oh*mdar:ih[logo][video];[video][logo]overlay=(main_w-overlay_w)/2:(main_h-overlay_h)/2",
      "watermarked.mp4",
    ]);
  }

  const outData = await ffmpeg.readFile(
    hasWatermark ? "watermarked.mp4" : outputFileName
  );

  return new Blob([outData], { type: "video/mp4" });
}

export async function disposeAsync() {
  await ffmpeg.terminate();
}
