const DownArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7L8 10L4 7"
        stroke="#353E50"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DownArrow;
