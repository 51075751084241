import { createSlice } from "@reduxjs/toolkit";
import { EditorModel } from "./types";
import { RootState } from "./store";

const initialState: EditorModel = {
  editorViewVisible: true,
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setEditorViewVisible: (state, action) => {
      state.editorViewVisible = action.payload;
    },
  },
});

export const getEditorViewVisible = (state: RootState) =>
  state.user.userRole === "editor" && state.editor.editorViewVisible;

export const { setEditorViewVisible } = editorSlice.actions;

export default editorSlice.reducer;
