import { Box, Button, Slider } from "@mui/material";
import { useAppSelector } from "../../../../store/hooks";
import {
  getTimelineCaretTime,
  getTimelinePlaying,
  getTimelineScale,
  getTimelineSelectedItem,
  setTimelinePlaying,
  setTimelineSelectedItem,
} from "../../../../store/timelineSlice";
import { useEffect, useMemo, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { useDispatch } from "react-redux";
import {
  getProject,
  setProjectAudioTrims,
} from "../../../../store/projectSlice";
import { timeoutAsync } from "../../../../helpers/Helper";
import { getBlobAsync } from "../../../../managers/storage/StorageManager";
import { TimelineItemType } from "../../../../store/types";
import { setSceneLoading } from "../../../../store/sceneSlice";
import {
  getGeneratingAudio,
  setGeneratingAudio,
  setGeneratingText,
} from "../../../../store/aiSlice";

const minDistance = 1;
let playAbortController = new AbortController();

type AudioTrackItemProps = { onDelete: () => void };

function AudioTrackItem({ onDelete }: AudioTrackItemProps) {
  const dispatch = useDispatch();
  const project = useAppSelector(getProject);
  const playing = useAppSelector(getTimelinePlaying);
  const generatingAudio = useAppSelector(getGeneratingAudio);
  const caretTime = useAppSelector(getTimelineCaretTime);
  const wavesurferRef = useRef<WaveSurfer>();
  const scaleRatio = useAppSelector(getTimelineScale);
  const [duration, setDuration] = useState(0);
  const [trims, setTrims] = useState([0, 0]);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const selected = useMemo(
    () => selectedItem?.id === project.audio?.id,
    [selectedItem?.id, project.audio?.id]
  );

  useEffect(() => {
    const playAsync = async (signal: AbortSignal) => {
      const actualOffset = Math.max(project.audio!.offset - caretTime, 0);
      await timeoutAsync(actualOffset * 1000);
      if (signal.aborted) return;
      const caretOffset = Math.max(caretTime - project.audio!.offset, 0);
      const playTime =
        project.audio!.duration -
        project.audio!.trims[0] -
        project.audio!.trims[1] -
        caretOffset;

      if (playTime > 0) {
        wavesurferRef.current?.seekTo(
          (project.audio!.trims[0] + caretOffset) / project.audio!.duration
        );
        wavesurferRef.current?.play();
        await timeoutAsync(playTime * 1000);
        if (signal.aborted) return;
        wavesurferRef.current?.stop();
      }
    };

    if (playing) {
      playAbortController?.abort();
      playAbortController = new AbortController();
      playAsync(playAbortController.signal);
    } else {
      playAbortController?.abort();
      wavesurferRef.current?.stop();
    }
  }, [caretTime, playing, project.audio?.offset, project.audio?.trims]);

  useEffect(() => {
    if (!project.audio) return;
    const loadAsync = async () => {
      if (generatingAudio) {
        dispatch(setSceneLoading(true));
      }
      const blob = await getBlobAsync(project.audio!.source);
      wavesurferRef.current?.destroy();
      wavesurferRef.current = WaveSurfer.create({
        container: "#waveform",
        waveColor: "#B3B3BD",
        progressColor: "#B3B3BD",
        cursorColor: "transparent",
        dragToSeek: false,
        interact: false,
        height: 32,
      });
      await wavesurferRef.current.loadBlob(blob!);
      setTrims([
        project.audio!.trims[0],
        project.audio!.duration - project.audio!.trims[1],
      ]);
      setDuration(project.audio!.duration);

      if (generatingAudio) {
        dispatch(setSceneLoading(false));
        await timeoutAsync(500);
        dispatch(setTimelinePlaying(true));
        dispatch(setGeneratingAudio(false));
      }
    };

    loadAsync();
  }, [project.audio]);

  const handleChange = (newValue: number[], activeThumb: number) => {
    if (activeThumb === 0) {
      setTrims([Math.min(newValue[0], trims[1] - minDistance), trims[1]]);
    } else {
      setTrims([trims[0], Math.max(newValue[1], trims[0] + minDistance)]);
    }
  };

  const handleChangeCommited = () => {
    dispatch(
      setProjectAudioTrims([trims[0], project.audio!.duration - trims[1]])
    );
  };

  const handleThumbMouseDown = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };

  const handleSelect = () => {
    dispatch(
      setTimelineSelectedItem({
        id: project.audio?.id,
        type: TimelineItemType.audio,
      })
    );
  };

  return (
    <Box
      onClick={handleSelect}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: `${scaleRatio * (trims[1] - trims[0])}px`,
        maxWidth: `${scaleRatio * (trims[1] - trims[0])}px`,
        height: "100%",
        position: "relative",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: `${scaleRatio * -trims[0]}px`,
          right: `${scaleRatio * -(duration - trims[1])}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          minWidth: `${scaleRatio * duration}px`,
          maxWidth: `${scaleRatio * duration}px`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            position: "relative",
            backgroundColor: "#D6D8DC",
          }}
        >
          <Slider
            onMouseDown={handleThumbMouseDown}
            value={trims}
            onChange={(_, v, at) => handleChange(v as number[], at)}
            onChangeCommitted={handleChangeCommited}
            disableSwap
            min={0}
            step={0.01}
            max={duration}
            sx={{
              zIndex: 10,
              "&.MuiSlider-root": {
                pointerEvents: "none !important",
                visibility: "hidden",
                height: "100%",
                padding: 0,
              },
              ".MuiSlider-thumb[data-index='0']": {
                pointerEvents: "all !important",
                visibility: selected ? "visible" : "hidden",
                transition: "none",
                boxShadow: "none",
                borderRadius: "0",
                height: "100%",
                backgroundColor: "#4261FF",
                width: "24px",
                "&:after": {
                  width: "24px",
                },
                "&:before": {
                  boxShadow: "none",
                },
              },
              ".MuiSlider-thumb[data-index='1']": {
                pointerEvents: "all !important",
                visibility: selected ? "visible" : "hidden",
                transition: "none",
                boxShadow: "none",
                borderRadius: "0",
                height: "100%",
                backgroundColor: "#4261FF",
                width: "24px",
                "&:after": {
                  width: "24px",
                },
                "&:before": {
                  boxShadow: "none",
                },
              },
            }}
          />
          <div
            style={{
              pointerEvents: "none",
              position: "absolute",
              top: 0,
              left: 0,
              maxHeight: "32px",
              width: `${duration * scaleRatio}px`,
            }}
            id="waveform"
          />
        </div>
      </div>
      <div
        style={{
          pointerEvents: "none",
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          boxSizing: "border-box",
          borderWidth: selected ? "3px" : "0",
          borderColor: selected ? "#4261FF" : "#F1F3F4",
          borderStyle: "solid",
          borderRadius: "8px",
        }}
      />
      {selected && (
        <>
          <div
            style={{
              pointerEvents: "none",
              width: "2px",
              height: "16px",
              borderRadius: "2px",
              position: "absolute",
              left: "5px",
              top: "8px",
              backgroundColor: "white",
              zIndex: 11,
            }}
          />
          <div
            style={{
              pointerEvents: "none",
              width: "2px",
              height: "16px",
              borderRadius: "2px",
              position: "absolute",
              right: "5px",
              top: "8px",
              backgroundColor: "white",
              zIndex: 11,
            }}
          />
        </>
      )}
      <Button
        onClick={onDelete}
        sx={{
          position: "absolute",
          top: "8px",
          right: "16px",
          display: selected ? "inherit" : "none",
          minWidth: "16px",
          padding: 0,
          zIndex: 100,
        }}
      >
        <img src="assets/svg/DeleteIcon.svg" width="16px" height="16px" />
      </Button>
    </Box>
  );
}

export default AudioTrackItem;
