import { useAppSelector } from "../../store/hooks";
import { getLoaderState } from "../../store/sceneSlice";

function TabsLoadingView() {
  const loading = useAppSelector(getLoaderState);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        backgroundColor: "#FFF",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        zIndex: 10000,
        opacity: loading ? 1 : 0,
        transition: "opacity 0.3s ease-in-out",
        pointerEvents: "none",
        borderRadius: "16px",
      }}
    >
      {[1, 2, 3].map((item, index) => {
        return (
          <div key={index}>
            <div
              style={{
                marginTop: "16px",
                marginLeft: "16px",
                backgroundColor: "#F1F3F4",
                width: "100px",
                height: "32px",
                borderRadius: "8px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "16px",
                marginLeft: "16px",
                gap: "16px 8px",
              }}
            >
              {[1, 2, 3, 4].map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: "92px",
                      width: "160px",
                      backgroundColor: "#F1F3F4",
                      borderRadius: "8px",
                    }}
                  ></div>
                );
              })}
            </div>
            <div
              style={{
                marginTop: "16px",
                marginLeft: "16px",
                backgroundColor: "#F1F3F4",
                width: "328px",
                height: "40px",
                borderRadius: "8px",
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}

export default TabsLoadingView;
