import { Box, Grid } from "@mui/material";
import TextItem from "./TextItem";
import { getProject, setTextTemplate } from "../../../../store/projectSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { iTextTemplate } from "../../../../store/types";
import { getTimelineSelectedItem } from "../../../../store/timelineSlice";
import { textTemplates } from "./TextTemplates";

const Text = () => {
  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const project = useAppSelector(getProject);

  const handleTextSelect = (t: iTextTemplate) => {
    const selectedText = project.texts?.find((t) => t.id === selectedItem?.id);
    if (selectedText)
      dispatch(setTextTemplate({ id: selectedText.id, template: t }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Grid
          container
          justifyContent="center"
          sx={{ marginTop: "16px", gap: "12px 8px" }}
        >
          {textTemplates.map((t) => (
            <TextItem
              key={t.id}
              template={t}
              onSelect={() => handleTextSelect(t)}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default Text;
