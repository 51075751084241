import * as BABYLON from "babylonjs";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getAppMode, getTabMode, setSaving } from "../store/navigatorSlice";
import Header from "./Header";
import RenderProgress from "./controls/RenderProgress";
import SequenceScene from "./scene/SequenceScene";
import { getProject } from "../store/projectSlice";
import { appModes, tabModes } from "../store/types";
import { useEffect, useMemo, useState } from "react";
import { saveProjectAsync } from "../helpers/Helper";
import HomeView from "./home/HomeView";
import { selectScriberSelectedItem } from "../store/scriberSlice";
import TimelineView from "./timeline/TimelineView";
import Player from "./player/Player";
import TextView from "./scene/text/TextView";
import VideosView from "./leftPanel/videos/VideosView";
import Models from "./leftPanel/models/Models";
import Engine from "./scene/Engine";
import SceneTab from "./scene/SceneTab";

const SceneView = () => {
  const dispatch = useAppDispatch();
  const appMode = useAppSelector(getAppMode);
  const tabMode = useAppSelector(getTabMode);
  const project = useAppSelector(getProject);
  const selectedScriberItem = useAppSelector(selectScriberSelectedItem);
  const projectString = useMemo(() => JSON.stringify(project), [project]);
  const [engineContext, setEngineContext] = useState<any>();

  useEffect(() => {
    if (!project.id) return;
    const asyncWrapper = async () => {
      dispatch(setSaving(true));
      await saveProjectAsync(project);
      dispatch(setSaving(false));
    };
    asyncWrapper();
  }, [projectString]);

  function handleEngineInit(
    engine: BABYLON.Engine,
    mainCanvas: HTMLCanvasElement,
    previewCanvas: HTMLCanvasElement
  ): void {
    setEngineContext({
      engine: engine,
      mainCanvas: mainCanvas,
      previewCanvas: previewCanvas,
    });
  }

  return (
    <>
      <Header />
      {project.sequences && project.sequences.length > 0 && (
        <div
          style={{
            height: "calc(100% - 80px)",
            position: "absolute",
            top: "64px",
            left: appMode === appModes.render ? "16px" : 0,
            right: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              backgroundColor: "#FFF",
              borderRadius: "16px",
              flexDirection: "column",
              visibility:
                appMode === appModes.videoPreview ? "collapse" : "visible",
            }}
          >
            <div
              style={{
                height: "calc(100% - 16px)",
                width: "100%",
                display: "flex",
                marginTop: "16px",
              }}
            >
              {appMode !== appModes.render && (
                <div
                  style={{
                    width: "224px",
                    marginLeft: "16px",
                  }}
                >
                  <SceneTab />
                </div>
              )}
              <div
                style={{
                  height: "100%",
                  position: "relative",
                  width: "calc(100% - 224px)",
                }}
              >
                {engineContext &&
                  project.sequences.map((s) => (
                    <SequenceScene
                      key={s.id}
                      sequence={s}
                      engineContext={engineContext}
                    />
                  ))}
                <div
                  className="scene"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "calc(100% - 32px)",
                    margin: "0px 16px",
                  }}
                >
                  <Engine onInit={handleEngineInit} />
                </div>
                <TextView />
              </div>
            </div>
            <TimelineView engineContext={engineContext} />
            <RenderProgress />
          </div>
        </div>
      )}
      <div
        style={{
          visibility:
            appMode === appModes.videoPreview ? "visible" : "collapse",
        }}
      >
        <Player />
      </div>
      {tabMode === tabModes.models && <Models />}
      {tabMode === tabModes.videos && <VideosView />}
      {tabMode === tabModes.home && <HomeView />}
    </>
  );
};

export default SceneView;
