import { Button, IconButton, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { getBlobAsync } from "../../../../managers/storage/StorageManager";
import { StoreItemModel } from "../../../../store/types";
import {
  capitalizeFirstLetter,
  formatTimestamp,
  timeoutAsync,
} from "../../../../helpers/Helper";
import { setSelectedScriberProjectId } from "../../../../store/scriberSlice";
import { useAppDispatch } from "../../../../store/hooks";
import {
  getUserJsonAsync,
  getUserStorageDataAsync,
} from "../../../../managers/storage/AzureStorageManager";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import ThreeDRotationRoundedIcon from "@mui/icons-material/ThreeDRotationRounded";
import { setUserStorageData } from "../../../../store/storageSlice";

export interface ModelItemProps {
  item: StoreItemModel;
  onMenu: Function;
}

const ScriberGalleryItem = ({ item, onMenu }: ModelItemProps) => {
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  const [status, setStatus] = useState("none");
  const abortController = useRef(new AbortController());

  useEffect(() => {
    const asyncWrapper = async (signal: AbortSignal) => {
      if (signal.aborted) return;
      const preview = await getBlobAsync(item);
      setPreviewUrl(URL.createObjectURL(preview!));
      const config = await getUserJsonAsync(
        `configs/scribers/${item.name}.json`
      );
      setStatus("none");
      if (config) {
        if (config.status === "done") {
          setStatus("done");
          abortController.current?.abort();
        } else {
          setStatus(config.status);
          await timeoutAsync(10_000);
          asyncWrapper(abortController.current.signal);
        }
      }
    };
    abortController.current?.abort();
    abortController.current = new AbortController();
    asyncWrapper(abortController.current.signal);
    return () => abortController.current?.abort();
  }, [item.url]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="gradient"
        style={{
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <Button
        data-track="ModelClicked"
        style={{
          borderRadius: "12px",
          opacity: previewUrl ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          overflow: "hidden",
          padding: 0,
        }}
        onClick={async () => {
          dispatch(setSelectedScriberProjectId(item.name));
          setUserStorageData(await getUserStorageDataAsync());
        }}
      >
        <img
          src={previewUrl}
          style={{ width: "162px", height: "288px", objectFit: "cover" }}
        />
      </Button>
      <div
        style={{
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          opacity: isHovered ? 0.3 : 0.1,
          transition: "opacity 300ms",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <img src="assets/svg/TimeIcon.svg" />
        <Typography
          style={{
            color: "#373E4E",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontVariantNumeric: "lining-nums tabular-nums",
            fontFeatureSettings: '"clig" off, "liga" off',
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties.lastModified)[0]}
        </Typography>
        <img src="assets/svg/CalendarIcon.svg" style={{ marginLeft: "12px" }} />
        <Typography
          style={{
            color: "#373E4E",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Archivo",
            fontSize: "12px",
            fontWeight: 800,
            lineHeight: "24px",
            marginLeft: "2px",
          }}
        >
          {formatTimestamp(item.properties.lastModified)[1]}
        </Typography>
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          right: "8px",
          top: 0,
        }}
      >
        <IconButton
          onClick={(e) => onMenu(e)}
          style={{
            transition: "opacity 300ms",
            marginTop: "8px",
            padding: "0px 0px",
            opacity: isHovered ? 1 : 0,
          }}
        >
          <img src="assets/svg/MoreIcon.svg" width="20px" height="20px" />
        </IconButton>
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          left: "8px",
          bottom: "40px",
          pointerEvents: "none",
        }}
      >
        {status !== "none" && status !== "done" && (
          <div style={{ display: "flex", alignItems: "end" }}>
            <SettingsSuggestRoundedIcon
              fontSize="large"
              style={{ color: "white" }}
            />
            <Typography style={{ color: "white", fontSize: "12px" }}>
              {capitalizeFirstLetter(status)}...
            </Typography>
          </div>
        )}
        {status === "done" && (
          <ThreeDRotationRoundedIcon
            fontSize="large"
            style={{ color: "white" }}
          />
        )}
      </div>
    </div>
  );
};

export default ScriberGalleryItem;
