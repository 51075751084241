import * as BABYLON from "babylonjs";
import { lightMeshMask } from "../types";

export const createLight = (object: any, scene: BABYLON.Scene) => {
  const light = BABYLON.Light.Parse(object, scene)!;
  const pickableMesh = BABYLON.MeshBuilder.CreateSphere(
    light.name + "_picker",
    { diameter: 0.2 },
    scene
  );
  pickableMesh.visibility = 0.3;
  pickableMesh.parent = light;
  pickableMesh.position = BABYLON.Vector3.Zero();
  pickableMesh.material = createDiffuseEmissiveMaterial(light?.diffuse!, scene);
  pickableMesh.layerMask = lightMeshMask;
  createShadowGenerator(light as BABYLON.IShadowLight);
  const parent = new BABYLON.TransformNode(light!.name, scene);
  parent.id = light!.id;
  light!.parent = parent;
  return light;
};

const createDiffuseEmissiveMaterial = (
  color: BABYLON.Color3,
  scene: BABYLON.Scene
) => {
  const material = new BABYLON.StandardMaterial(
    "diffuseEmissiveMaterial",
    scene
  );
  material.emissiveColor = color;
  material.disableLighting = true; // Disable shading from other light sources
  return material;
};

function createShadowGenerator(light: BABYLON.IShadowLight) {
  light.getShadowGenerator()?.dispose();
  light.shadowEnabled = true;

  const shadowGenerator = new BABYLON.ShadowGenerator(1024, light);
  shadowGenerator.filteringQuality = BABYLON.ShadowGenerator.QUALITY_HIGH;
  shadowGenerator.useContactHardeningShadow = true;
  shadowGenerator.bias = 0.001;
  shadowGenerator.normalBias = 0.001;
  shadowGenerator.setDarkness(0.15);
}
