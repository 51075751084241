import { apiUrl } from "../settings/GlobalSettings";
import { setUserSettings } from "../store/userSlice";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "../authConfig";
import { timeoutAsync } from "../helpers/Helper";
import { appInsights } from "./AppInsights";

let cachedUser: {
  email: string;
  firstName: string;
  lastName: string;
};
let cachedIdToken = "";
let cacheExpirationTime = 0; // Timestamp when the cached token will expire
const msalInstance = new PublicClientApplication(msalConfig);
let attemptCount = 0;

export function getCachedUser() {
  return cachedUser;
}

export async function acquireIdTokenAsync() {
  const result = await msalInstance.acquireTokenSilent({
    scopes: [],
  });

  cachedUser = {
    email: result.account?.username || "", // Use a default value if username is null or undefined
    firstName: result.account?.name ? result.account.name.split(" ")[0] : "", // Use a default value if name is null or undefined
    lastName: result.account?.name ? result.account.name.split(" ")[1] : "", // Use a default value if name is null or undefined
  };

  // Update the cached token and the expiration time
  cachedIdToken = result.idToken;
  cacheExpirationTime = Date.now() + 8 * 60 * 60 * 1000;

  return cachedIdToken;
}

export async function getIdTokenAsync() {
  try {
    while (attemptCount > 0 && !cachedIdToken) {
      await timeoutAsync(100);
      if (attemptCount > 10) break;
    }
    attemptCount++;

    // Check if the cached token is available and not expired + hour
    if (cachedIdToken && cacheExpirationTime > Date.now() + 3600000) {
      return cachedIdToken;
    }

    // If the cached token is not available or expired, try to acquire a new token
    const result = await msalInstance.acquireTokenSilent({
      scopes: [],
    });

    cachedUser = {
      email: result.account?.username || "", // Use a default value if username is null or undefined
      firstName: result.account?.name ? result.account.name.split(" ")[0] : "", // Use a default value if name is null or undefined
      lastName: result.account?.name ? result.account.name.split(" ")[1] : "", // Use a default value if name is null or undefined
    };

    // Update the cached token and the expiration time
    cachedIdToken = result.idToken;
    cacheExpirationTime = Date.now() + 8 * 60 * 60 * 1000;

    return cachedIdToken;
  } catch (err) {
    console.log(err);
    msalInstance
      .loginRedirect(loginRequest)
      .catch((error) => console.log(error));
    await timeoutAsync(5000);
    window.location.reload();
    return "";
  }
}

export async function setSubscriptionLevelAsync(level: string) {
  try {
    const idToken = await getIdTokenAsync();
    await fetch(`${apiUrl}/api/SetSubscriptionLevelV2`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({ subscriptionLevel: level }),
    });
    appInsights.trackEvent(
      { name: "SetSubscriptionLevel" },
      { customProperties: { level: level } }
    );
  } catch (err) {
    throw err;
  }
}

export async function setDisplayNameAsync(displayName: string) {
  try {
    const idToken = await getIdTokenAsync();
    await fetch(`${apiUrl}/api/SetDisplayName`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({ displayName: displayName }),
    });
    appInsights.trackEvent(
      { name: "SetDisplayName" },
      { customProperties: { displayName: displayName } }
    );
  } catch (err) {
    throw err;
  }
}

export async function setHasOnboardedAsync(hasOnboarded: boolean) {
  try {
    const idToken = await getIdTokenAsync();
    await fetch(`${apiUrl}/api/SetHasOnboarded`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + idToken,
      },
      body: JSON.stringify({ hasOnboarded: hasOnboarded }),
    });
    appInsights.trackEvent(
      { name: "SetHasOnboarded" },
      { customProperties: { hasOnboarded: hasOnboarded } }
    );
  } catch (err) {
    throw err;
  }
}

export async function getUserSettingsAsync(dispatch: Function) {
  const idToken = await getIdTokenAsync();
  const response = await fetch(`${apiUrl}/api/GetUserSettingsV2`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
  const result = await response.json();
  dispatch(setUserSettings(result));
}

export async function getUserSubscriptionDataAsync(dispatch: Function) {
  try {
    const idToken = await getIdTokenAsync();
    const response = await fetch(`${apiUrl}/api/GetSubscriptionData`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    });
    if (!response.ok) {
      throw new Error("An error occurred while fetching blobs");
    }
    const result = (await response.json()).message;
    return result;
  } catch (error) {
    console.log(error);
  }
}

export async function cancelSubscriptionAsync() {
  const idToken = await getIdTokenAsync();

  await fetch(`${apiUrl}/api/CancelSubscription`, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
}

export async function canUserScribeAsync() {
  try {
    const localTime = new Date();
    const idToken = await getIdTokenAsync();
    const response = await fetch(`${apiUrl}/api/GetScriberAvailabilityV2`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ localTime: localTime }),
    });
    return response.status === 200;
  } catch (err) {
    return false;
  }
}

export async function getCreditsAvailability() {
  try {
    const localTime = new Date();
    const idToken = await getIdTokenAsync();
    const response = await fetch(`${apiUrl}/api/GetCreditsAvailabilityV2`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ localTime: localTime }),
    });
    return response.status === 200;
  } catch (err) {
    return false;
  }
}

export async function validatePromocodeAsync(promocode: string) {
  try {
    const idToken = await getIdTokenAsync();

    const response = await fetch(`${apiUrl}/api/ValidatePromocode`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ promocode: promocode }),
    });
    return response.status === 200;
  } catch (err) {
    return false;
  }
}

export async function canUserDownloadVideoAsync() {
  try {
    const localTime = new Date();
    const idToken = await getIdTokenAsync();
    const response = await fetch(
      `${apiUrl}/api/GetVideoDownloadAvailabilityV2`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ localTime: localTime }),
      }
    );

    return response.status === 200;
  } catch (err) {
    return false;
  }
}

export async function drainScriberAttemptAsync() {
  const idToken = await getIdTokenAsync();
  await fetch(`${apiUrl}/api/DrainScriberAttemptV2`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
  appInsights.trackEvent({ name: "DrainScriberAttempt" });
}

export async function redeemScriberAttemptAsync() {
  const idToken = await getIdTokenAsync();
  await fetch(`${apiUrl}/api/RedeemScriberAttemptV2`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
  appInsights.trackEvent({ name: "RedeemScriberAttempt" });
}

export async function drainVideoDownloadAttemptAsync(dispatch: Function) {
  const idToken = await getIdTokenAsync();
  await fetch(`${apiUrl}/api/DrainVideoDownloadAttemptV2`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
  appInsights.trackEvent({ name: "DrainVideoDownloadAttempt" });
  await getUserSettingsAsync(dispatch);
}

export async function drainCreditAsync(dispatch: Function) {
  const idToken = await getIdTokenAsync();
  await fetch(`${apiUrl}/api/DrainCredit`, {
    method: "PATCH",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });

  await getUserSettingsAsync(dispatch);

  appInsights.trackEvent({ name: "DrainCredit" });
}

export async function setSubscriptionAsync(dispatch: Function, plan: string) {
  await setSubscriptionLevelAsync(plan);
  await getUserSettingsAsync(dispatch);
}
