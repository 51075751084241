import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { SubscriptionData as SubscriptionState } from "./types";

const initialState: SubscriptionState = {
  subscriptionPlans: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionPlans: (state, action) => {
      state.subscriptionPlans = action.payload;
    },
  },
});

export const getSubscriptionPlans = (state: RootState) =>
  state.subscription.subscriptionPlans;
export const { setSubscriptionPlans } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
