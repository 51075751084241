const LeftArrow = () => {
  return (
    <svg
      width="8"
      height="20"
      viewBox="0 0 8 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_696_6773)">
        <path
          d="M5.88672 15.5972L1.38672 9.59717L5.88672 3.59717"
          stroke="#454E63"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_696_6773">
          <rect
            width="20"
            height="8"
            fill="white"
            transform="translate(8) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftArrow;
