import {
  Box,
  Button,
  List,
  ListItem,
  Popover,
  Typography,
} from "@mui/material";
import DownArrow from "../../icons/DownArrow";
import { useEffect, useRef, useState } from "react";
import {
  outputVideoSize,
  setVideoFps,
  setVideoSize,
} from "../../settings/GlobalSettings";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getUserSubscriptionLevel } from "../../store/userSlice";
import { setIsVertical } from "../../store/renderSlice";
import {
  getRenderSettingsFromLocalStorage,
  saveRenderFpsToLocalStorage,
  saveRenderOrientationToLocalStorage,
  saveRenderResolutionToLocalStorage,
} from "../../managers/storage/LocalStorageManager";
import { ResolutionMenuItem } from "../../store/types";
import GradientCrown from "./GradientCrown";
import GradientTypography from "./GradientTypography";

const resolutionItems: ResolutionMenuItem[] = [
  { text: "HD 720p", width: 1280, height: 720, isDemo: true },
  { text: "Full HD 1080p", width: 1920, height: 1080, isDemo: false },
];

const fullHDItem: ResolutionMenuItem = {
  text: "QuadHD 1440p",
  width: 2560,
  height: 1440,
  isDemo: false,
};

const ResolutionButton = () => {
  const dispatch = useAppDispatch();
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [orientation, setOrientation] = useState("horizontal");
  const [fps, setFps] = useState(30);
  const [selectedResItem, setSelectedResItem] = useState<ResolutionMenuItem>(
    resolutionItems[0]
  );
  const buttonRef = useRef<any>(null);

  useEffect(() => {
    const renderSettings = getRenderSettingsFromLocalStorage();
    setSelectedResItem(renderSettings.resolution);
    setFps(renderSettings.fps);
    setOrientation(renderSettings.orientation);
  }, []);

  useEffect(() => {
    if (subscriptionLevel === "demo") {
      handleResChanged(resolutionItems[0]);
      saveRenderFpsToLocalStorage(30);
      setFps(30);
    }
    if (
      subscriptionLevel === "starter" &&
      (outputVideoSize.width === fullHDItem.width ||
        outputVideoSize.height === fullHDItem.width)
    ) {
      handleResChanged(resolutionItems[1]);
    }
  }, [subscriptionLevel]);

  useEffect(() => {
    if (orientation === "vertical") {
      dispatch(setIsVertical(true));
      setVideoSize(selectedResItem.height, selectedResItem.width);
      return;
    }
    dispatch(setIsVertical(false));
    setVideoSize(selectedResItem.width, selectedResItem.height);
  }, [selectedResItem, orientation]);

  useEffect(() => {
    setVideoFps(fps);
  }, [fps]);

  const handleResChanged = (item: ResolutionMenuItem) => {
    saveRenderResolutionToLocalStorage(item);
    handleCloseResMenu();
    setSelectedResItem(item);
  };

  const handleCloseResMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const handleResMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  return (
    <Box>
      <Button
        data-track="ResolutionMenuClicked"
        onClick={handleResMenu}
        ref={buttonRef}
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          padding: "0px 12px",
          width: "156px",
          height: "36px",
          gap: "4px",
          justifyContent: "space-between",
          boxShadow: menuOpen
            ? "0px 10px 10px 0px rgba(0, 0, 0, 0.08)"
            : "none",
          ":hover": {
            background: "#FFF",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
          }}
        >
          {selectedResItem.text}
        </Typography>
        <DownArrow />
      </Button>
      <Popover
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleCloseResMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "5px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
      >
        <List
          sx={{
            padding: "8px 0px",
          }}
        >
          <Box
            sx={{
              marginLeft: "8px",
              marginBottom: "4px",
              width: "140px",
              height: "36px",
              backgroundColor: "#E3E5E5",
              borderRadius: "12px",
              display: "flex",
            }}
          >
            <Button
              data-track="Resolution30FpsClicked"
              sx={{
                width: "50%",
                borderRadius: "12px",
                border:
                  fps === 30 ? "2px solid rgba(69, 78, 99, 0.50)" : "none",
                backgroundColor: fps === 30 ? "#FFF" : "#E3E5E5",
                ":hover": {
                  backgroundColor: fps === 30 ? "#FFF" : "#E3E5E5",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => {
                saveRenderFpsToLocalStorage(30);
                setFps(30);
              }}
            >
              30 fps
            </Button>
            <Button
              data-track="Resolution60FpsClicked"
              sx={{
                padding: 0,
                justifyContent:
                  subscriptionLevel === "demo" ? "flex-start" : "center",
                width: "50%",
                borderRadius: "12px",
                opacity: subscriptionLevel === "demo" ? 0.2 : 1,
                backgroundColor: fps === 60 ? "#FFF" : "#E3E5E5",
                pointerEvents: subscriptionLevel === "demo" ? "none" : "auto",
                border:
                  fps === 60 ? "2px solid rgba(69, 78, 99, 0.50)" : "none",
                ":hover": {
                  backgroundColor: fps === 60 ? "#FFF" : "#E3E5E5",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => {
                saveRenderFpsToLocalStorage(60);
                setFps(60);
              }}
            >
              <Typography
                sx={{
                  marginLeft: subscriptionLevel === "demo" ? "4px" : "0px",
                  fontSize: "14px",
                }}
              >
                60 fps
              </Typography>

              {subscriptionLevel === "demo" && (
                <img
                  src="assets/images/TransparentCrown.png"
                  height="8px"
                  style={{ marginLeft: "2px", marginBottom: "12px" }}
                />
              )}
            </Button>
          </Box>
          <Box
            sx={{
              marginLeft: "8px",
              marginBottom: "8px",
              width: "140px",
              height: "36px",
              backgroundColor: "#E3E5E5",
              borderRadius: "12px",
              display: "flex",
            }}
          >
            <Button
              data-track="ResolutionHorizontalClicked"
              sx={{
                width: "50%",
                borderRadius: "12px",
                border:
                  orientation === "horizontal"
                    ? "2px solid rgba(69, 78, 99, 0.50)"
                    : "none",
                backgroundColor:
                  orientation === "horizontal" ? "#FFF" : "#E3E5E5",

                ":hover": {
                  backgroundColor:
                    orientation === "horizontal" ? "#FFF" : "#E3E5E5",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => {
                saveRenderOrientationToLocalStorage("horizontal");
                setOrientation("horizontal");
              }}
            >
              <img
                src="assets/images/HorizontalFrame.png"
                width="28px"
                height="22px"
              />
            </Button>
            <Button
              data-track="ResolutionVerticalClicked"
              sx={{
                width: "50%",
                borderRadius: "12px",
                backgroundColor:
                  orientation === "vertical" ? "#FFF" : "#E3E5E5",
                border:
                  orientation === "vertical"
                    ? "2px solid rgba(69, 78, 99, 0.50)"
                    : "none",
                ":hover": {
                  backgroundColor:
                    orientation === "vertical" ? "#FFF" : "#E3E5E5",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => {
                saveRenderOrientationToLocalStorage("vertical");
                setOrientation("vertical");
              }}
            >
              <img
                src="assets/images/VerticalFrame.png"
                height="28px"
                width="22px"
              />
            </Button>
          </Box>
          {resolutionItems.map((item) => (
            <ListItem
              key={item.text}
              sx={{
                padding: "0px 8px",
              }}
            >
              <Button
                data-track={`Resolution${item.text}Clicked`}
                sx={{
                  width: "140px",
                  borderRadius: "12px",
                  justifyContent: "start",
                  pointerEvents:
                    subscriptionLevel === "demo" && !item.isDemo
                      ? "none"
                      : "auto",
                  background:
                    item.text === selectedResItem.text ? "#F1F3F4" : "#FFF",
                  ":hover": {
                    background: "#E3E5E5",
                  },
                  ":active": {
                    background: "#F1F3F4",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
                onClick={() => handleResChanged(item)}
              >
                {item.text}
                {subscriptionLevel === "demo" && !item.isDemo && (
                  <img
                    src="assets/images/TransparentCrown.png"
                    width="10px"
                    height="8px"
                    style={{ alignSelf: "start", marginLeft: "4px" }}
                  />
                )}
              </Button>
            </ListItem>
          ))}
          <ListItem
            key={"Full HD 1080p"}
            sx={{
              padding: "0px 8px",
            }}
          >
            <Button
              sx={{
                width: "140px",
                borderRadius: "12px",
                justifyContent: "start",
                pointerEvents:
                  subscriptionLevel !== "pro" && subscriptionLevel !== "test"
                    ? "none"
                    : "auto",
                background:
                  fullHDItem.text === selectedResItem.text ? "#F1F3F4" : "#FFF",
                ":hover": {
                  background: "#E3E5E5",
                },
                ":active": {
                  background: "#F1F3F4",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => handleResChanged(fullHDItem)}
            >
              {subscriptionLevel !== "test" && subscriptionLevel !== "pro" ? (
                <GradientTypography fontSize="14px" text={fullHDItem.text} />
              ) : (
                `${fullHDItem.text}`
              )}

              {subscriptionLevel !== "test" && subscriptionLevel !== "pro" && (
                <div
                  style={{
                    marginLeft: "4px",
                    height: "100%",
                    alignSelf: "start",
                    display: "flex",
                  }}
                >
                  <GradientCrown size="10px" />
                </div>
              )}
            </Button>
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default ResolutionButton;
