import { Box, LinearProgress } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { getRenderProgress } from "../../store/sceneSlice";

const RenderProgress = () => {
  const progress = useAppSelector(getRenderProgress);
  return (
    <Box
      sx={{
        position: "absolute",
        padding: "16px",
        overflow: "hidden",
        width: "calc(100% - 32px)",
        zIndex: 100,
        display: `${progress === 0 ? "none" : "display"}`,
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{ height: "4px", borderRadius: "2px" }}
      />
    </Box>
  );
};

export default RenderProgress;
