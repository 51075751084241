import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useAppSelector } from "../../../store/hooks";
import { getProject } from "../../../store/projectSlice";
import {
  animationsFps,
  outputVideoSize,
} from "../../../settings/GlobalSettings";
import TextOverlayItem from "./TextOverlayItem";
import html2canvas from "html2canvas";

export interface TextOverlayHandles {
  takeShotAsync: (frame: number) => Promise<string | undefined>;
}

const TextOverlay = forwardRef<TextOverlayHandles, {}>((_, ref) => {
  const project = useAppSelector(getProject);
  const previewCanvas = useRef<HTMLDivElement>(null);
  const drawCanvas = useRef<HTMLCanvasElement>(null);
  const ignoreNextShot = useRef(false);
  const cachedCaretTextIds = useRef("");
  const [timelineTextCaret, setTimelineTextCaret] = useState<
    { id: string; time: number }[]
  >([]);
  const cachedShot = useRef<string | undefined>();

  useImperativeHandle(ref, () => ({
    takeShotAsync: (frame: number) =>
      new Promise<string | undefined>(async (resolve) => {
        if (!project.texts) {
          resolve(cachedShot.current);
          return;
        }
        const caretTexts: { id: string; time: number }[] = [];
        const caretTime = frame / animationsFps;
        for (const text of project.texts) {
          if (
            text.timeline.offset <= caretTime &&
            text.timeline.duration + text.timeline.offset >= caretTime
          ) {
            caretTexts.push({
              id: text.id,
              time: caretTime - text.timeline.offset,
            });
          }
        }

        const ids = JSON.stringify(caretTexts.map((t) => t.id));
        ignoreNextShot.current =
          ids === cachedCaretTextIds.current && ids === "[]";
        cachedCaretTextIds.current = ids;

        setTimelineTextCaret(caretTexts);
        if (ignoreNextShot.current) {
          resolve(cachedShot.current);
          return;
        }
        const ctx = drawCanvas.current!.getContext("2d", {
          willReadFrequently: true,
        });
        ctx!.clearRect(0, 0, outputVideoSize.width, outputVideoSize.height);
        //const startRenderTime = performance.now();
        const canvas = await html2canvas(previewCanvas.current!, {
          backgroundColor: null,
          logging: false,
          width: outputVideoSize.width,
          height: outputVideoSize.height,
          canvas: drawCanvas.current!,
          scale: 1,
          allowTaint: true,
          useCORS: true,
          removeContainer: false,
        });
        document
          .querySelectorAll(".html2canvas-container")
          .forEach((el: any) => {
            const iframe = el.contentWindow;
            if (el) {
              el.src = "about:blank";
              iframe.document.write("");
              iframe.document.clear();
              iframe.close();
              el.remove();
            }
          });
        // const endRenderTime = performance.now();
        // const execRenderTime = endRenderTime - startRenderTime;
        // console.log(`Text render took: ${execRenderTime} milliseconds`);
        const shot = canvas.toDataURL();
        cachedShot.current = shot;
        resolve(cachedShot.current);
      }),
  }));

  return (
    <div
      style={{
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        width: `${outputVideoSize.width}px`,
        height: `${outputVideoSize.height}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <div
        ref={previewCanvas}
        style={{
          position: "relative",
          width: `${outputVideoSize.width}px`,
          height: `${outputVideoSize.height}px`,
        }}
      >
        {project.texts?.map((t) => (
          <TextOverlayItem
            item={t}
            key={t.id}
            parentSize={outputVideoSize}
            timelineTextCaret={timelineTextCaret}
          />
        ))}
      </div>
      <canvas
        ref={drawCanvas}
        width={outputVideoSize.width}
        height={outputVideoSize.height}
        style={{ opacity: 0 }}
      />
    </div>
  );
});

export default TextOverlay;
