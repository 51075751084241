import * as BABYLON from "babylonjs";
import { Camera } from "../../../store/types";
import { lightMeshMask } from "../types";

export const createControlledCamera = (scene: BABYLON.Scene) => {
  const camera = new BABYLON.ArcRotateCamera(
    "controlledCamera",
    Math.PI / 4,
    Math.PI / 4,
    4,
    BABYLON.Vector3.Zero(),
    scene
  );
  camera.lowerRadiusLimit = 0.5;
  camera.upperRadiusLimit = 50;
  camera.minZ = 0.01;
  camera.maxZ = 100;
  camera.attachControl();
  camera.fov = 0.5;
  camera.wheelPrecision = 100;
  camera.layerMask = 0xffffffff;

  const parent = new BABYLON.TransformNode(camera.name, scene);
  parent.id = camera.id;
  camera.parent = parent;

  return camera;
};

export const createMainCamera = (
  sequenceCamera: Camera,
  scene: BABYLON.Scene
) => {
  let camera = new BABYLON.UniversalCamera(
    sequenceCamera.name,
    new BABYLON.Vector3(0, 0, 0),
    scene
  );
  camera.id = sequenceCamera.id;
  camera.minZ = 0.01;
  camera.maxZ = 100;
  camera.fov = 0.7;
  camera.animations = [];
  camera.layerMask = 0xffffffff & ~lightMeshMask;

  BABYLON.SceneLoader.ImportMesh(
    "",
    "/assets/",
    "camera.glb",
    scene,
    (meshes) => {
      const camMesh = meshes[0];
      camMesh.position = new BABYLON.Vector3(-0.04, -0.15, -0.2);
      camMesh.rotation = BABYLON.Vector3.Zero();
      camMesh.scaling = new BABYLON.Vector3(0.1, 0.1, 0.1);
      camMesh.parent = camera;
    }
  );

  const parent = new BABYLON.TransformNode(sequenceCamera.name, scene);
  parent.id = sequenceCamera.id;
  camera.parent = parent;
  return camera;
};
