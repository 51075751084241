import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { StorageData } from "./types";

const initialState: StorageData = { data: {}, occupiedStorageSize: 0 };

export const storageSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    setUserStorageData: (state, action) => {
      state.data = action.payload;
    },
    setOccupiedStorageSize: (state, action) => {
      state.occupiedStorageSize = action.payload;
    },
  },
});

export const getUserStorageData = (state: RootState) => state.storage.data;
export const getOccupiedStorageSize = (state: RootState) =>
  state.storage.occupiedStorageSize;

export const { setUserStorageData, setOccupiedStorageSize } =
  storageSlice.actions;

export default storageSlice.reducer;
