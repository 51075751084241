export const demoStarterLimitReachedString =
  "Storage limit reached. Upgrade your subscription plan or clean up videos, models or images to free it.";
export const proTestLimitReachedString =
  "Storage limit reached. Clean up videos, models or images to free it.";
export const demoStarterNoCreditsString =
  "No credits left. Consider upgrading your subscription plan or waiting until tomorrow.";
export const proTestNoCreditsString =
  "No credits left. They will be replenished tomorrow.";
export const demoStarterImagesCountLimitReachedString =
  "The number of photos you are trying to add exceeds limit of photos per project. Consider upgrading your subscription plan or reducing photo count.";
export const proTestImagesCountLimitReachedString =
  "The number of photos you are trying to add exceeds limit of photos per project. Consider reducing photo count.";
