import { useMsal } from "@azure/msal-react";
import { Button, Typography } from "@mui/material";
import {
  clearUserStorageAsync,
  deleteUserAccountAsync,
} from "../../../managers/storage/AzureStorageManager";
import WarningDialog from "../WarningDialog";
import { useState } from "react";

const DeleteAccountButton = () => {
  const { instance } = useMsal();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDeleteAccount = async () => {
    localStorage.clear();
    sessionStorage.clear();
    await clearUserStorageAsync();
    await deleteUserAccountAsync();
    instance.logoutRedirect();
  };

  const handleCloseDeleteDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Button
        onClick={() => {
          setDialogOpen(true);
        }}
        sx={{
          background: "#faaf00",
          borderRadius: "12px",
          height: "36px",
          width: "220px",
          ":hover": {
            background: "#f5ac02",
            boxShadow: "0px 10px 10px 0px rgba(245, 173, 48, 0.24)",
          },
          ":active": {
            background: "#f5ac02",
            boxShadow: "none",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Typography
          sx={{
            color: "#FFF",
            fontWeight: 600,
          }}
        >
          Delete Account
        </Typography>
      </Button>
      {dialogOpen && (
        <WarningDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete your account? You won't be able to restore any data associated with this account"
          confirmButtonText="Delete"
          onConfirm={handleDeleteAccount}
          onCancel={handleCloseDeleteDialog}
        />
      )}
    </div>
  );
};

export default DeleteAccountButton;
