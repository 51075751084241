import { Button, Grid, Typography } from "@mui/material";
import {
  galleryModes,
  iTextTemplate,
  iTextTrack,
} from "../../../../store/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getTimelineCaretTime } from "../../../../store/timelineSlice";
import { addText } from "../../../../store/projectSlice";
import { v4 as uuidv4 } from "uuid";
import { textTemplates } from "../../styles/text/TextTemplates";
import SharedTextsItem from "./SharedTextsItem";
import { textAnimationTemplates } from "../../textAnimations/TextAnimationTempates";

const SharedTexts = () => {
  const dispatch = useAppDispatch();
  const caret = useAppSelector(getTimelineCaretTime);

  const handleTextSelect = (t: iTextTemplate) => {
    const item: iTextTrack = {
      id: uuidv4(),
      template: t,
      timeline: { duration: 3, offset: caret },
      animation: { duration: 1, template: textAnimationTemplates[0] },
      appearance: {
        text: "Text",
        normalizedFontSize: 0.1,
        normalizedPosition: { x: 0.5, y: 0.5 },
        normalizedSize: { width: 0.8, height: 0.7 },
      },
    };
    dispatch(addText(item));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography style={{ fontSize: "20px", marginLeft: "16px" }}>
          Texts
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: "column",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "16px 0px",
          }}
        >
          <Grid container justifyContent="center" sx={{ gap: "12px 8px" }}>
            {textTemplates.map((t) => (
              <SharedTextsItem
                key={t.id}
                template={t}
                onSelect={() => handleTextSelect(t)}
              />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default SharedTexts;
