import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import { StoreItemModel } from "../../../store/types";
import { getBlobFromIDBAsync } from "../../../managers/storage/DbManager";

export async function createEnvironmentAsync(
  model: StoreItemModel,
  scene: BABYLON.Scene
) {
  const blob = await getBlobFromIDBAsync(model.url);
  const envNode = await loadEnvironmentFileAsync(
    new File([blob!], model.name + ".glb", {
      type: "model/gltf-binary",
    }),
    model,
    scene
  );
  return envNode;
}

const loadEnvironmentFileAsync = async (
  file: File,
  model: StoreItemModel,
  scene: BABYLON.Scene
) => {
  const meshes = await getMeshesFromFileAsync(scene, file);
  if (!meshes) return null;
  const parent = new BABYLON.TransformNode(model.name, scene);
  parent.id = model.id;
  meshes[0].parent = parent;
  return parent;
};

async function getMeshesFromFileAsync(scene: BABYLON.Scene, file: File) {
  try {
    const object = await BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "",
      file,
      scene
    );
    return object.meshes;
  } catch (e) {
    console.log("Bad *.glb: " + file.name, e);
  }
  return null;
}
