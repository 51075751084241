import { Box, Button, CircularProgress, Typography } from "@mui/material";
import UpgradeToProButton from "../controls/UpgradeToProButton";
import {
  canUserDownloadVideoAsync,
  drainVideoDownloadAttemptAsync,
  getUserSettingsAsync,
} from "../../managers/ClientManager";
import { showCustomAlert } from "../../store/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getUserLeftDownloads,
  getUserSubscriptionId,
  getUserSubscriptionLevel,
} from "../../store/userSlice";
import { getProject } from "../../store/projectSlice";
import { getUserBlobAsync } from "../../managers/storage/AzureStorageManager";
import { useEffect, useState } from "react";
import { getBlobFromIDBAsync } from "../../managers/storage/DbManager";

const PlayerHeader = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const subscriptionId = useAppSelector(getUserSubscriptionId);
  const leftDownloads = useAppSelector(getUserLeftDownloads);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const asyncWrapper = async () => {
      const result = await canUserDownloadVideoAsync();
      if (result) {
        await getUserSettingsAsync(dispatch);
      }
    };
    asyncWrapper();
  }, []);

  async function handleDownload() {
    setDownloading(true);
    const canDonwloadVideo = await canUserDownloadVideoAsync();
    if (!canDonwloadVideo) {
      dispatch(showCustomAlert("Oops! no attempts left"));
      setDownloading(false);

      return;
    }
    await drainVideoDownloadAttemptAsync(dispatch);

    let blob = await getBlobFromIDBAsync(project.renderUrl!);
    if (!blob) blob = await getUserBlobAsync(project.renderUrl!);
    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(blob!);
    anchorElement.download = `${project!.name}.mp4`;
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
    setDownloading(false);
  }

  return (
    <Box
      sx={{
        height: "68px",
        width: "calc(100% - 80px)",
        backgroundColor: "#F1F3F4",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        pointerEvents: "all",
      }}
    >
      <Box sx={{ marginLeft: "12px" }}></Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        {subscriptionLevel !== "pro" &&
          subscriptionId !== "apple-subscription" &&
          subscriptionId !== "apple-subscription-cancelled" && (
            <UpgradeToProButton text="Upgrade" />
          )}
        {subscriptionLevel !== "pro" && (
          <div
            style={{
              display: "flex",
              width: "140px",
              justifyContent: "center",
            }}
          >
            <Typography>Downloads left: {leftDownloads!}</Typography>
          </div>
        )}
        <Button
          data-track="DownloadVideoClicked"
          onClick={() => handleDownload()}
          disabled={leftDownloads! <= 0}
          sx={{
            background: "#3050F5",
            borderRadius: "12px",
            padding: "0px 12px",
            height: "36px",
            width: "128px",
            gap: "4px",

            ":hover": {
              background: "#3050F5",
              boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
            },
            ":active": {
              background: "#2946DB",
              boxShadow: "none",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            ":disabled": { background: "#153e8f" },
          }}
        >
          {downloading ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            <Typography
              sx={{
                fontSize: 15,
                color: leftDownloads! <= 0 ? "#a6a9ad" : "#FFF",
                fontWeight: 600,
              }}
            >
              Download
            </Typography>
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default PlayerHeader;
