import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getCreateVideoDialogMode,
  getCreateVideoDialogOpen,
  hideCreateVideoDialog,
  hideCustomAlert,
  setCreateVideoDialogMode,
} from "../../store/dialogSlice";
import { createVideoDialogModes } from "../../store/types";
import CreateModelView from "../controls/CreateModelView";
import CreateVideoView from "../controls/CreateVideoView";
import ScribedModels from "../leftPanel/models/models/ScribedModels";
import { useEffect } from "react";
import CustomAlert from "./CustomAlert";

const CreateVideoDialog = () => {
  const dispatch = useAppDispatch();
  const dialogOpen = useAppSelector(getCreateVideoDialogOpen);
  const mode = useAppSelector(getCreateVideoDialogMode);

  const handleCloseDialog = () => {
    dispatch(hideCreateVideoDialog());
    dispatch(setCreateVideoDialogMode(createVideoDialogModes.createVideo));
  };

  useEffect(() => {
    dispatch(hideCustomAlert());
  }, [dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth={true}
      sx={{
        overflow: "visible",
        ".MuiDialog-paper": {
          backgroundColor: "#FFF",
          borderRadius: "16px",
          overflow: "visible",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "0px",
        }}
      >
        {mode === createVideoDialogModes.createVideo && (
          <div
            style={{
              height: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFF",
              borderRadius: "16px",
            }}
          >
            <CreateVideoView />
          </div>
        )}
        {mode === createVideoDialogModes.modelsGallery && (
          <div
            style={{
              height: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFF",
              borderRadius: "16px",
            }}
          >
            <ScribedModels />
          </div>
        )}
        {mode === createVideoDialogModes.createModel && (
          <div
            style={{
              height: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFF",
              borderRadius: "16px",
            }}
          >
            <CreateModelView />
          </div>
        )}

        <CustomAlert positionTop="24px" positionRight="24px" />

        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            top: "0px",
            right: "-48px",
            background: "#F1F3F4",
            ":hover": {
              background: "#F1F3F4",
              boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default CreateVideoDialog;
