import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Input,
  CircularProgress,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getSettingsDialogOpen,
  hideSettingsDialog,
} from "../../../store/dialogSlice";
import {
  cancelSubscriptionAsync,
  getIdTokenAsync,
  getUserSettingsAsync,
  validatePromocodeAsync,
} from "../../../managers/ClientManager";
import { useEffect, useState } from "react";
import {
  getUserCreatedDateTime,
  getUserDisplayName,
  getUserMail,
  getUserRole,
  getUserSubscriptionEndDate,
  getUserSubscriptionId,
  getUserSubscriptionLevel,
} from "../../../store/userSlice";
import {
  getUserBlobAsync,
  getUserStorageDataAsync,
  postUserBlobAsync,
} from "../../../managers/storage/AzureStorageManager";
import {
  getUserStorageData,
  setUserStorageData,
} from "../../../store/storageSlice";
import Username from "../../controls/Username";
import {
  capitalizeFirstLetter,
  getStorageItemFromStorageData,
  replaceUnderscoresWithSpaces,
  resizeImage,
} from "../../../helpers/Helper";
import EditorSettings from "./EditorSettings";
import StorageProgressBar from "./StorageProgressBar";
import DeleteAccountButton from "./DeleteAccountButton";
import GradientInfinityButton from "./GradientInfinityButton";
import GradientTypography from "../../controls/GradientTypography";
import UpgradeToProButton from "../../controls/UpgradeToProButton";
import { getSubscriptionPlans } from "../../../store/subscriptionSlice";
import AppleIcon from "@mui/icons-material/Apple";
import { saveBlobToIDBAsync } from "../../../managers/storage/DbManager";

enum SettingsTabs {
  settings,
  promocodes,
  editor,
}

interface listItem {
  text: string;
  imageUrl: string;
}

const SettingsDialog = () => {
  const dispatch = useAppDispatch();
  const createdDateTime = useAppSelector(getUserCreatedDateTime);
  const subscriptionId = useAppSelector(getUserSubscriptionId);
  const subscriptionEndDate = useAppSelector(getUserSubscriptionEndDate);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const dialogOpen = useAppSelector(getSettingsDialogOpen);
  const displayName = useAppSelector(getUserDisplayName);
  const userStorageData = useAppSelector(getUserStorageData);
  const userRole = useAppSelector(getUserRole);
  const plans = useAppSelector(getSubscriptionPlans);
  const mail = useAppSelector(getUserMail);
  const [currentTab, setCurrentTab] = useState(SettingsTabs.settings);
  const [url, setUrl] = useState("assets/images/user.webp");
  const [promocodeInputValue, setPromocodeInputValue] = useState("");
  const [redeemStatus, setRedeemStatus] = useState(
    "And press the button below"
  );
  const [color, setColor] = useState("rgba(69, 78, 99, 0.20)");
  const [promocodeImageUrl, setPromocodeImageUrl] = useState(
    "assets/images/PromocodesGray.png"
  );
  const [promocodeInputFocused, setPromocodeInputFocused] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [failureVisible, setFailureVisible] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [currentLimits, setCurrentLimits] = useState<listItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleInputChange = (e: any) => {
    setPromocodeInputValue(e.target.value);
  };

  const handlePromocodeInputFocus = () => {
    if (!successVisible && !failureVisible) {
      setColor("#3050F5");
    }
    setPromocodeInputFocused(true);
  };

  const handlePromocodeInputUnfocus = () => {
    if (!successVisible && !failureVisible) {
      setColor("rgba(69, 78, 99, 0.20)");
    }
    setPromocodeInputFocused(false);
  };

  useEffect(() => {
    setCurrentTab(SettingsTabs.settings);
  }, [dialogOpen]);

  useEffect(() => {
    setPromocodeInputValue("");
    setColor("rgba(69, 78, 99, 0.20)");
    setFailureVisible(false);
    setSuccessVisible(false);
    setPromocodeImageUrl("assets/images/PromocodesGray.webp");
    setRedeemStatus("And press the button below");
  }, [currentTab]);

  const HandleRedeemPromocode = async () => {
    if (promocodeInputValue === "") return;

    const validated = await validatePromocodeAsync(promocodeInputValue);
    if (validated) {
      setColor("#86B668");
      setPromocodeImageUrl("assets/images/PromocodesGreen.webp");
      setFailureVisible(false);
      setSuccessVisible(true);
      setRedeemStatus("You can modify or add new code above");
      setPromocodeInputValue("");
      await getUserSettingsAsync(dispatch);
    } else {
      setColor("#FF584D");
      setFailureVisible(true);
      setSuccessVisible(false);

      setPromocodeImageUrl("assets/images/PromocodesRed.webp");
      setRedeemStatus("Wrong Code, please check it and try again");
    }
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      let icon = getStorageItemFromStorageData("user/icon", userStorageData);
      if (!icon || icon.length === 0) {
        return;
      }
      let blob = await getUserBlobAsync(
        "user/icon/icon.png",
        await getIdTokenAsync()
      );
      if (blob) {
        setUrl(URL.createObjectURL(blob));
      }
    };
    asyncWrapper();
  }, [userStorageData]);

  useEffect(() => {
    if (!subscriptionLevel || !plans) return;
    let limits = [
      {
        text: "Scriber",
        imageUrl: "",
      },
      {
        text: `${plans[subscriptionLevel].scriberProcess.count} Scribed models per day`,
        imageUrl: "assets/images/AppleIcon.png",
      },
      {
        text: `${plans[subscriptionLevel].scriberPhotos.count} Photos per project`,
        imageUrl: "assets/images/PhotoStackIcon.png",
      },
      {
        text:
          subscriptionLevel === "demo"
            ? "No Models downloading"
            : "Download models",
        imageUrl: "assets/images/DownloadIconBlue.png",
      },
      {
        text:
          subscriptionLevel === "demo"
            ? "No Priority processing"
            : subscriptionLevel === "starter"
            ? "Priority processing"
            : "Highest Priority processing",
        imageUrl: "assets/images/RocketIcon.png",
      },
      {
        text: "Video Editor",
        imageUrl: "",
      },
      {
        text: `${plans[subscriptionLevel].credits.count} Creative Credits per day`,
        imageUrl: "assets/images/CreditCardIcon.png",
      },
      {
        text: `${
          subscriptionLevel === "demo"
            ? `${plans[subscriptionLevel].videoDownloads.count}* V`
            : subscriptionLevel === "starter"
            ? `${plans[subscriptionLevel].videoDownloads.count} V`
            : "Unlimited v"
        }ideo downloads per day`,
        imageUrl: "assets/images/FilmRollIcon.png",
      },
      {
        text: `${plans[subscriptionLevel].videoSettings.resolution}p Render resolution`,
        imageUrl: "assets/images/HorizontalPortraitIcon.png",
      },
      {
        text: `${plans[subscriptionLevel].videoSettings.videoFps} Frames per second`,
        imageUrl: "assets/images/CountdownIcon.png",
      },
      {
        text: "Other",
        imageUrl: "",
      },
      {
        text: `${plans[subscriptionLevel].storageGb}GB Storage`,
        imageUrl:
          subscriptionLevel === "demo"
            ? "assets/images/UnfullDatabaseIcon.png"
            : subscriptionLevel === "starter"
            ? "assets/images/MidDatabaseIcon.png"
            : "assets/images/DatabaseIcon.png",
      },
    ];
    setCurrentLimits(limits);
  }, [subscriptionLevel, dialogOpen]);

  const handleCloseDialog = () => {
    dispatch(hideSettingsDialog());
  };

  const handleChangeIcon = () => {
    const input = document.createElement("input");
    input.type = "file";

    input.onchange = async () => {
      await uploadImage(input.files);
    };

    input.click();
  };

  const uploadImage = async (files: any) => {
    const file = files?.[0];
    if (!file) return;
    const resizedBlob = await new Promise((resolve, reject) => {
      resizeImage(file as File, 512, 512, (result: File) => {
        result ? resolve(result) : reject(new Error("Error resizing image"));
      });
    });

    await postUserBlobAsync(resizedBlob as Blob, "user/icon/icon.png", true);
    await saveBlobToIDBAsync(resizedBlob as Blob, "user/icon/icon.png");

    setUrl(URL.createObjectURL(resizedBlob as Blob));
    dispatch(setUserStorageData(await getUserStorageDataAsync()));
  };

  const handleCancelSubscription = async () => {
    setSubscriptionLoading(true);
    await cancelSubscriptionAsync();
    await getUserSettingsAsync(dispatch);
    setSubscriptionLoading(false);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const infoIcon = (
    <img
      src="assets/svg/InfoIconGray.svg"
      width="20px"
      height="20px"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    />
  );

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      maxWidth="xl"
      sx={{
        ".MuiDialog-paper": {
          backgroundColor: "#F1F3F4",
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "8px",
          overflow: "hidden",
        }}
      >
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                backgroundColor: "#FFF",
                minWidth: "300px",
                maxWidth: "300px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "16px",
                outlineOffset: "-1px",
                outline: "1px solid rgba(69, 78, 99, 0.20)",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    marginLeft: "20px",
                    marginTop: "24px",
                  }}
                >
                  Workspace Settings
                </Typography>
                <Box
                  sx={{
                    marginLeft: "20px",
                    marginTop: "16px",
                    display: "flex",
                  }}
                >
                  <img
                    src={url}
                    width="56px"
                    height="56px"
                    style={{
                      objectFit: "cover",
                      borderRadius: "56px",
                    }}
                  />
                  <Box
                    sx={{
                      marginLeft: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>{displayName}</Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "24px",
                        fontWeight: 600,
                        opacity: "0.8",
                      }}
                    >
                      {capitalizeFirstLetter(
                        replaceUnderscoresWithSpaces(subscriptionLevel)
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Button
                  data-track="SettingsTabClicked"
                  onClick={async () => setCurrentTab(SettingsTabs.settings)}
                  sx={{
                    background:
                      currentTab === SettingsTabs.settings
                        ? "rgba(48, 80, 245, 0.10)"
                        : "#FFF",
                    borderRadius: "12px",
                    height: "44px",
                    gap: "8px",
                    margin: "0px 16px ",
                    marginTop: "24px",

                    alignItems: "center",
                    justifyContent: "space-between",

                    ":hover": {
                      background:
                        currentTab === SettingsTabs.settings
                          ? "rgba(48, 80, 245, 0.10)"
                          : "#F1F3F4",
                    },
                    ":active": {
                      background: "rgba(48, 80, 245, 0.10)",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Settings
                  </Typography>
                  <img
                    src="assets/images/SettingsIcon.png"
                    width="20px"
                    height="20px"
                  />
                </Button>
                <Button
                  data-track="PromocodesTabClicked"
                  onClick={async () => setCurrentTab(SettingsTabs.promocodes)}
                  sx={{
                    background:
                      currentTab === SettingsTabs.promocodes
                        ? "rgba(48, 80, 245, 0.10)"
                        : "#FFF",
                    borderRadius: "12px",
                    height: "44px",
                    gap: "8px",
                    margin: "0px 16px ",
                    marginTop: "8px ",

                    alignItems: "center",
                    justifyContent: "space-between",

                    ":hover": {
                      background:
                        currentTab === SettingsTabs.promocodes
                          ? "rgba(48, 80, 245, 0.10)"
                          : "#F1F3F4",
                    },
                    ":active": {
                      background: "rgba(48, 80, 245, 0.10)",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    Promocodes
                  </Typography>
                  <img
                    src="assets/images/ticket.png"
                    width="20px"
                    height="20px"
                    style={{
                      marginBottom: "2px",
                    }}
                  />
                </Button>
                {userRole === "editor" && (
                  <Button
                    onClick={async () => setCurrentTab(SettingsTabs.editor)}
                    sx={{
                      background:
                        currentTab === SettingsTabs.editor
                          ? "rgba(48, 80, 245, 0.10)"
                          : "#FFF",
                      borderRadius: "12px",
                      height: "44px",
                      gap: "8px",
                      margin: "0px 16px ",
                      marginTop: "8px ",

                      alignItems: "center",
                      justifyContent: "space-between",

                      ":hover": {
                        background:
                          currentTab === SettingsTabs.editor
                            ? "rgba(48, 80, 245, 0.10)"
                            : "#F1F3F4",
                      },
                      ":active": {
                        background: "rgba(48, 80, 245, 0.10)",
                      },
                      "& .MuiTouchRipple-root": {
                        display: "none",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      Editor
                    </Typography>
                    <img
                      src="assets/images/SettingsIcon.png"
                      width="20px"
                      height="20px"
                      style={{
                        marginBottom: "2px",
                      }}
                    />
                  </Button>
                )}
              </div>
              <StorageProgressBar />
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: "8px",
              position: "relative",
              width: "676px",
              height: "648px",
            }}
          >
            {currentTab === SettingsTabs.settings && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  justifyContent: "space-between",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "12px",
                    backgroundColor: "#FFF",
                    marginTop: "92px",
                    height: "calc(100% - 92px)",
                    outline: "1px solid rgba(69, 78, 99, 0.20)",
                    outlineOffset: "-1px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      overflow: "hidden",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            marginTop: "32px",
                            marginLeft: "20px",
                            fontSize: "20px",
                            fontWeight: 900,
                          }}
                        >
                          {capitalizeFirstLetter(
                            replaceUnderscoresWithSpaces(subscriptionLevel)
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            marginLeft: "20px",
                            fontSize: "14px",
                            fontWeight: 600,
                            opacity: 0.7,
                          }}
                        >
                          Current plan
                        </Typography>
                      </Box>
                      <div
                        style={{
                          alignSelf: "end",
                          marginRight: "20px",
                        }}
                      >
                        <DeleteAccountButton />
                      </div>
                    </Box>
                    <Divider sx={{ margin: "24px 20px 0px 20px" }}></Divider>
                    {subscriptionLevel !== "test" && (
                      <Box
                        sx={{
                          marginLeft: "20px",
                          display: "flex",
                          width: "calc(100% - 20px)",
                          height: "calc(100% - 16px)",
                        }}
                      >
                        <Box
                          sx={{
                            width: "50%",
                            flexDirection: "column",
                            display: "flex",
                            height: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: "8px",
                              fontWeight: 800,
                              fontSize: "18px",
                            }}
                          >
                            With current plan you have:
                          </Typography>
                          {subscriptionLevel !== "test" &&
                            currentLimits.map((item) => (
                              <Box
                                key={item.text}
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  alignItems: "center",
                                  marginTop:
                                    item.imageUrl === "" ? "12px" : "8px",
                                }}
                              >
                                {item.imageUrl === "" && item.text === "" && (
                                  <div
                                    style={{ width: "24px", height: "24px" }}
                                  ></div>
                                )}

                                {item.imageUrl !== "" && (
                                  <img
                                    src={item.imageUrl}
                                    width="24px"
                                    height="24px"
                                  />
                                )}
                                <Typography
                                  sx={{ fontWeight: item.imageUrl ? 600 : 800 }}
                                >
                                  {item.text}
                                </Typography>
                                {item.text.includes("Credits") && infoIcon}
                              </Box>
                            ))}
                        </Box>
                        <Popover
                          sx={{
                            pointerEvents: "none",
                            marginTop: "8px",
                            ".MuiPopover-paper": {
                              borderRadius: "12px",
                            },
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <div
                            style={{
                              width: "280px",
                              justifyContent: "center",
                              padding: "8px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              align="center"
                              style={{ fontSize: "14px" }}
                            >
                              Creative Credits are used for video creation as
                              well as background, animation and music generation
                              powered by our AI.
                            </Typography>
                          </div>
                        </Popover>
                        <Box
                          sx={{
                            width: "50%",
                            flexDirection: "row-reverse",
                            display: "flex",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "8px",
                              marginRight: "20px",
                              gap: "16px",
                              alignItems: "center",
                            }}
                          >
                            {subscriptionEndDate && (
                              <div
                                style={{
                                  width: "100%",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <Typography
                                  sx={{
                                    gap: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src="assets/svg/CalendarIcon.svg"
                                    width="16px"
                                    height="16px"
                                  />
                                  {subscriptionId &&
                                  subscriptionId !==
                                    "apple-subscription-cancelled" &&
                                  subscriptionId !== "early-access-bonus"
                                    ? "Next Payment"
                                    : "Subscription ends"}
                                  : {subscriptionEndDate}
                                </Typography>
                              </div>
                            )}
                            {subscriptionId &&
                              subscriptionId !== "apple-subscription" &&
                              subscriptionId !==
                                "apple-subscription-cancelled" &&
                              subscriptionId !== "early-access-bonus" &&
                              (subscriptionLevel === "starter" ||
                                subscriptionLevel === "pro") && (
                                <Button
                                  onClick={handleCancelSubscription}
                                  sx={{
                                    background: "#eb4034",
                                    borderRadius: "12px",
                                    height: "36px",
                                    width: "220px",
                                    ":hover": {
                                      background: "#e6382c",
                                      boxShadow:
                                        "0px 10px 10px 0px rgba(230, 56, 44, 0.24)",
                                    },
                                    ":active": {
                                      background: "#e6382c",
                                      boxShadow: "none",
                                    },
                                    "& .MuiTouchRipple-root": {
                                      display: "none",
                                    },
                                  }}
                                >
                                  {subscriptionLoading ? (
                                    <CircularProgress
                                      size={"16px"}
                                      sx={{
                                        marginLeft: "8px",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    <Typography
                                      sx={{
                                        color: "#FFF",
                                        fontWeight: 600,
                                      }}
                                    >
                                      Cancel Subscription
                                    </Typography>
                                  )}
                                </Button>
                              )}

                            {!subscriptionId &&
                              (subscriptionLevel === "starter" ||
                                subscriptionLevel === "pro") && (
                                <UpgradeToProButton text="Renew" />
                              )}
                            {(subscriptionId === "apple-subscription" ||
                              subscriptionId ===
                                "apple-subscription-cancelled") && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "start",
                                }}
                              >
                                <AppleIcon
                                  sx={{ color: "#454E63", fontSize: "20px" }}
                                />
                                <Typography align="center">
                                  You can manage your subscription in mobile app
                                  and app store
                                </Typography>
                              </div>
                            )}
                          </div>
                        </Box>
                      </Box>
                    )}
                    {subscriptionLevel === "test" && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          marginTop: "180px",
                        }}
                      >
                        <div style={{ display: "flex", gap: "16px" }}>
                          <GradientInfinityButton />
                          <GradientTypography
                            fontSize="40px"
                            text="No Limits"
                          />
                        </div>
                      </div>
                    )}
                  </Box>
                  {subscriptionLevel === "demo" && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "4px",
                        right: "12px",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                        * - videos with watermark
                      </Typography>
                    </div>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    left: "28px",
                    width: "calc(100% - 28px)",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={url}
                      width="100px"
                      height="100px"
                      style={{
                        objectFit: "cover",
                        borderRadius: "100px",
                        border: "4px solid #FFF",
                        backgroundColor: "#FFF",
                      }}
                    />
                    <Button
                      data-track="SettingsChangeIconClicked"
                      onClick={handleChangeIcon}
                      sx={{
                        position: "absolute",
                        top: "2px",
                        right: "2px",
                        maxWidth: "28px",
                        minWidth: "28px",
                        maxHeight: "28px",
                        borderRadius: "28px",
                      }}
                    >
                      <img
                        src="assets/images/EditIcon.png"
                        width="28px"
                        height="28px"
                      />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginLeft: "4px",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Username></Username>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {mail && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "24px",
                            fontWeight: 600,
                            opacity: "0.7",
                            marginLeft: "8px",
                            borderBottom: "1px dashed #7D8392",
                            userSelect: "auto",
                          }}
                        >
                          {mail}
                        </Typography>
                      )}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "24px",
                          fontWeight: 600,
                          opacity: "0.7",
                          marginRight: "12px",
                        }}
                      >
                        Created:{" "}
                        {createdDateTime &&
                          new Date(createdDateTime).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {currentTab === SettingsTabs.promocodes && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  flexDirection: "column",
                  marginTop: "32px",
                  alignItems: "center",
                }}
              >
                <img src={promocodeImageUrl} width="300px" height="256px" />
                <Box
                  sx={{
                    height: "28px",
                    width: "244px",
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  {successVisible && (
                    <img
                      src="assets/images/VerifiedIcon.png"
                      width="28px"
                      height="28px"
                    />
                  )}
                  {successVisible && (
                    <Typography sx={{ fontSize: "20px", lineHeight: "24px" }}>
                      Success! Code applied
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    marginTop: "12px",
                    width: "392px",
                    height: "56px",
                    border: promocodeInputFocused ? "2px solid" : "2px solid",
                    borderColor: color,
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Input
                    spellCheck={false}
                    onChange={handleInputChange}
                    value={promocodeInputValue}
                    placeholder="Enter Promocode here"
                    disableUnderline={true}
                    inputProps={{
                      maxLength: 12,
                    }}
                    onFocus={handlePromocodeInputFocus}
                    onBlur={handlePromocodeInputUnfocus}
                    sx={{
                      width: "172px",
                      height: "56px",

                      ".MuiInput-input": {
                        color: "#454E63",
                        caretColor: color,
                        textAlign: "center",
                        "::placeholder": {
                          color: promocodeInputFocused
                            ? "rgba(69, 78, 99, 0.20)"
                            : "#454E63",
                          opacity: 1,
                        },
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: failureVisible ? "396px" : "380px",
                    marginTop: "8px",
                  }}
                >
                  {failureVisible && (
                    <img
                      src="assets/images/RedCrossIcon.png"
                      width="20px"
                      height="20px"
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {redeemStatus}
                  </Typography>
                </Box>
                <Button
                  data-track="SettingsRedeemPromocodeClicked"
                  onClick={HandleRedeemPromocode}
                  sx={{
                    background: "#3050F5",
                    borderRadius: "12px",
                    height: "48px",
                    marginTop: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "396px",
                    ":hover": {
                      background: "#3050F5",
                      boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                    },
                    ":active": {
                      background: "#2946DB",
                      boxShadow: "none",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFF",
                      fontWeight: 600,
                    }}
                  >
                    Redeem
                  </Typography>
                </Button>
              </Box>
            )}
            {currentTab === SettingsTabs.editor && <EditorSettings />}
          </Box>
        </Box>

        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
