import * as BABYLON from "babylonjs";
import "babylonjs-inspector";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createControlledCamera } from "../loaders/CameraLoader";
import {
  setRenderingPipeline,
  updateDepthOfFieldFocusDistance,
} from "../loaders/PipelineLoader";
import { distance } from "../types";
import {
  getSharedBlobAsync,
  getSharedJsonAsync,
  getSharedStorageDataAsync,
} from "../../../managers/storage/AzureStorageManager";
import { useLocation } from "react-router-dom";
import "../../../css/magicButton.css";
import { isMobile } from "react-device-detect";
import SceneProgress from "../../controls/SceneProgress";
import { getLoaderState, setLoading } from "../../../store/sceneSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  increaseCurrentProgress,
  increaseTotalProgress,
  setProgressText,
} from "../../../store/progressSlice";
import {
  getStorageItemFromSharedStorageData,
  getStorageItemFromStorageData,
} from "../../../helpers/Helper";
import { StorageData } from "../../../store/types";

export let scriberScene: BABYLON.Scene;
export let scriberCamera: BABYLON.ArcRotateCamera;
export let scriberCameraPipeline: BABYLON.DefaultRenderingPipeline;
export let scriberCameraDistance: distance = { smooth: 1, current: 1 };
let update = false;
let updateFrames = 0;

const ScriberScene = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getLoaderState);
  const [modelId, setModelId] = useState<string | undefined>();
  const [error, setError] = useState(false);

  const onSceneReady = async (scene: BABYLON.Scene) => {
    scriberScene = scene;
    scriberCamera = createControlledCamera(scriberScene);
    scriberCamera.radius = 3;
    scriberCamera.alpha = Math.PI / 4;
    scriberCamera.beta = Math.PI / 3;
    scriberCameraPipeline = setRenderingPipeline(scriberCamera, scriberScene);
    scriberCamera.onViewMatrixChangedObservable.add(() => {
      update = true;
      updateFrames = 0;
    });
    new BABYLON.HemisphericLight(
      "light",
      new BABYLON.Vector3(0, 0, 0),
      scriberScene
    );
    scriberScene.clearColor = new BABYLON.Color4(1, 1, 1, 1);

    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    if (id) {
      setModelId(id);
    }
  };

  function floatRender() {
    if (update) {
      render();
      updateFrames++;
      if (updateFrames > 30) {
        update = false;
      }
    } else {
      if (engine.frameId % 15 === 0) {
        render();
      }
    }
  }

  function render() {
    scriberScene.render();
    updateDepthOfFieldFocusDistance(
      scriberCamera,
      scriberCameraPipeline,
      scriberCameraDistance,
      scriberScene
    );
  }

  useEffect(() => {
    if (!modelId) {
      return;
    }
    const asyncWrapper = async () => {
      dispatch(setProgressText("Loading"));
      dispatch(setLoading(true));

      const config = await getSharedJsonAsync(`scribers/${modelId}.json`);
      if (!config) setError(true);

      const storageData = await getSharedStorageDataAsync("scribers");
      const scribers = getStorageItemFromSharedStorageData(storageData);
      const scriberSize = scribers.find((i) => i.name === modelId)?.properties
        .contentLength;

      dispatch(increaseTotalProgress(scriberSize));

      const node = await createScriberObjectAsync(
        `scribers/${modelId}.glb`,
        scriberScene,
        dispatch
      );
      if (config.rotation)
        node!.rotationQuaternion = BABYLON.Vector3.FromArray(
          config.rotation
        ).toQuaternion();
      engine.runRenderLoop(() => floatRender());

      dispatch(setLoading(false));
    };

    asyncWrapper();
  }, [modelId]);

  function hancleClick() {
    const url = `https://app.lumiere3d.ai`;
    window.open(url, "_blank");
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        left: 0,
        background: "#f1f3f4",
      }}
    >
      <Scene onSceneReady={onSceneReady} />
      <Box sx={{ position: "absolute", bottom: "16px" }}>
        <Box sx={{ height: "38px", width: "196px" }} className="magicButton" />
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            top: "2px",
            left: "2px",
            height: "34px",
            width: "192px",
            borderRadius: "10px",
            borderWidth: 0,
            backgroundColor: "#FFF",
            ":hover": {
              borderWidth: 0,
              backgroundColor: "#FFFD",
            },
            ":active": {
              borderWidth: 0,
              backgroundColor: "#FFFE",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
          onClick={() => hancleClick()}
        >
          <Typography>Try Lumiere3D</Typography>
        </Button>
      </Box>
      {loading && <SceneProgress />}
      {error && (
        <Box
          sx={{
            position: "absolute",
            background: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            zIndex: "1000",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>
              This link is invalid, but you can always create your own models
              with Lumiere3D
            </Typography>
          </Box>

          <Box
            sx={{
              position: "relative",
              height: "38px",
              width: "196px",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{ height: "38px", width: "196px", position: "absolute" }}
              className="magicButton"
            />
            <Button
              variant="outlined"
              sx={{
                position: "absolute",
                left: "2px",
                top: "2px",
                height: "34px",
                width: "192px",
                borderRadius: "14px",
                borderWidth: 0,
                backgroundColor: "#FFF",
                ":hover": {
                  borderWidth: 0,
                  backgroundColor: "#FFFD",
                },
                ":active": {
                  borderWidth: 0,
                  backgroundColor: "#FFFE",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => hancleClick()}
            >
              <Typography>Try Lumiere3D</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ScriberScene;

async function createScriberObjectAsync(
  path: string,
  scene: BABYLON.Scene,
  dispatch: Function
) {
  const blob = await getSharedBlobAsync(path, (bytes: number) => {
    dispatch(increaseCurrentProgress(bytes));
  });
  const objNode = await loadFileAsync(
    new File([blob!], "preview.glb", {
      type: "model/gltf-binary",
    }),
    scene
  );
  return objNode;
}

const loadFileAsync = async (file: File, scene: BABYLON.Scene) => {
  const meshes = await createObjectFromFileAsync(scene, file);
  if (!meshes) return null;
  const transformNode = normalizeObjectInSpace(meshes, scene);
  const parent = new BABYLON.TransformNode("preview_parent", scene);
  transformNode.parent = parent;
  return transformNode;
};

async function createObjectFromFileAsync(scene: BABYLON.Scene, file: File) {
  try {
    const object = await BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "",
      file,
      scene
    );
    return object.meshes;
  } catch (e) {
    console.log("Bad *.glb: " + file.name, e);
  }
  return null;
}

function normalizeObjectInSpace(
  meshes: BABYLON.AbstractMesh[],
  scene: BABYLON.Scene
) {
  let parent = meshes[0];
  let parentBox = getParentBoundingInfo(meshes);
  let maxSize = new BABYLON.Vector3(
    parentBox.maximum.x - parentBox.minimum.x,
    parentBox.maximum.y - parentBox.minimum.y,
    parentBox.maximum.z - parentBox.minimum.z
  );
  const scale = 1 / Math.max(maxSize.x, maxSize.y, maxSize.z);

  parent.position = new BABYLON.Vector3(
    (parentBox.boundingBox.maximumWorld.x +
      parentBox.boundingBox.minimumWorld.x) /
      2,
    (parentBox.boundingBox.maximumWorld.y +
      parentBox.boundingBox.minimumWorld.y) /
      2,
    (parentBox.boundingBox.maximumWorld.z +
      parentBox.boundingBox.minimumWorld.z) /
      2
  );
  parent.rotation = BABYLON.Vector3.Zero();

  const transform = new BABYLON.TransformNode("preview_node", scene);
  parent.parent = transform;
  transform.scaling.x = scale;
  transform.scaling.y = scale;
  transform.scaling.z = scale;

  return transform;
}

function getParentBoundingInfo(
  meshes: BABYLON.AbstractMesh[]
): BABYLON.BoundingInfo {
  let min = meshes[0].getBoundingInfo().boundingBox.minimumWorld;
  let max = meshes[0].getBoundingInfo().boundingBox.maximumWorld;

  for (let i = 0; i < meshes.length; i++) {
    let meshMin = meshes[i].getBoundingInfo().boundingBox.minimumWorld;
    let meshMax = meshes[i].getBoundingInfo().boundingBox.maximumWorld;

    min = BABYLON.Vector3.Minimize(min, meshMin);
    max = BABYLON.Vector3.Maximize(max, meshMax);
  }

  return new BABYLON.BoundingInfo(min, max);
}

type SceneProps = {
  onSceneReady: (scene: BABYLON.Scene) => void;
};

let engine: BABYLON.Engine;

const Scene = ({ onSceneReady }: SceneProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const reactCanvas = useRef({} as HTMLCanvasElement);

  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    engine = isMobile
      ? new BABYLON.Engine(canvas)
      : new BABYLON.Engine(
          canvas,
          true,
          {
            preserveDrawingBuffer: true,
            stencil: true,
            powerPreference: "high-performance",
            antialias: true,
            premultipliedAlpha: true,
          },
          true
        );
    const scene = new BABYLON.Scene(engine);

    const initScene = (scene: BABYLON.Scene) => {
      onSceneReady(scene);
    };

    if (scene.isReady()) {
      initScene(scene);
    } else {
      scene.onReadyObservable.addOnce((scene) => initScene(scene));
    }

    const resize = () => {
      engine.resize();
    };

    if (window) {
      window.addEventListener("resize", resize);
    }

    const parent = parentRef.current;
    if (!canvas || !parent) {
      return;
    }
    canvas.width = parent.clientWidth;
    canvas.height = parent.clientHeight;

    const observer = new ResizeObserver((_) => {
      resize();
    });
    observer.observe(parent);

    return () => {
      observer.disconnect();
      scene.getEngine().dispose();

      if (window) {
        window.removeEventListener("resize", resize);
      }
    };
  }, []);

  return (
    <div
      ref={parentRef}
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <canvas
        ref={reactCanvas}
        style={{
          outline: "none",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};
