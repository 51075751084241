import * as BABYLON from "babylonjs";
import { distance } from "../types";
import { isMobile } from "react-device-detect";

export function setRenderingPipeline(
  camera: BABYLON.Camera,
  scene: BABYLON.Scene
) {
  const pipeline = new BABYLON.DefaultRenderingPipeline(
    `default_${camera.name}`,
    true,
    scene,
    [camera]
  );
  pipeline.samples = isMobile ? 0 : 64;
  pipeline.fxaaEnabled = true;
  pipeline.imageProcessingEnabled = true;
  pipeline.imageProcessing.toneMappingEnabled = true;
  pipeline.imageProcessing.toneMappingType = 1;
  pipeline.imageProcessing.colorGradingEnabled = true;
  pipeline.depthOfFieldEnabled = true;
  pipeline.depthOfField.focalLength = 20;
  pipeline.depthOfField.fStop = 32;
  pipeline.depthOfField.focusDistance = 1000;
  pipeline.depthOfField.lensSize = 800;
  pipeline.depthOfFieldBlurLevel = 0;
  return pipeline;
}

export function updateDepthOfFieldFocusDistance(
  camera: BABYLON.Camera,
  pipeline: BABYLON.DefaultRenderingPipeline,
  distance: distance,
  scene: BABYLON.Scene
) {
  // if (!camera || !pipeline) return;
  // const origin: BABYLON.Vector3 = camera.globalPosition;
  // const forward: BABYLON.Vector3 = camera.getForwardRay().direction;
  // const ray: BABYLON.Ray = new BABYLON.Ray(origin, forward, 100); // Adjust the maximum raycast distance as needed
  // const hit = scene.pickWithRay(ray);
  // if (hit?.hit) {
  //   distance.current = hit.distance;
  // } else {
  //   distance.current = BABYLON.Vector3.Distance(
  //     camera.globalPosition,
  //     BABYLON.Vector3.Zero()
  //   );
  // }
  // if (Math.abs(distance.smooth - distance.current) < 0.001) return;
  // distance.smooth += (distance.current - distance.smooth) / 8;
  // pipeline.depthOfField.focusDistance = distance.smooth * 1000;
}
