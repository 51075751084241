import { StoreItemModel } from "../../store/types";
import { apiUrl } from "../../settings/GlobalSettings";
import { setUserStorageData } from "../../store/storageSlice";
import { store } from "../../store/store";
import { getIdTokenAsync } from "../ClientManager";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { setSubscriptionPlans } from "../../store/subscriptionSlice";

export async function getSharedStorageDataAsync(path: string) {
  const response = await fetch(
    `${apiUrl}/api/GetSharedResourceFilesV2?path=${path}`
  );
  if (!response.ok) {
    throw new Error("An error occurred while fetching blobs");
  }
  return await response.json();
}

export async function getUserStorageDataAsync() {
  const idToken = await getIdTokenAsync();
  const response = await fetch(`${apiUrl}/api/GetUserResourceFiles`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
  if (!response.ok) {
    throw new Error("An error occurred while fetching blobs");
  }
  return await response.json();
}

export async function getSubscriptionPlansAsync(dispatch: Function) {
  const response = await fetch(`${apiUrl}/api/GetSubscriptionPlans`, {
    method: "GET",
  });
  if (!response.ok) {
    throw new Error("An error occurred while getting subscription plans");
  }
  dispatch(setSubscriptionPlans(await response.json()));
}

export async function clearUserStorageAsync() {
  try {
    const idToken = await getIdTokenAsync();
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };

    const response = await fetch(
      `${apiUrl}/api/DeleteUserStorage`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
    } else {
      console.log(
        data.message || "An error occurred while clearing user storage."
      );
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}

export async function deleteUserAccountAsync() {
  try {
    const idToken = await getIdTokenAsync();
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };

    const response = await fetch(
      `${apiUrl}/api/DeleteUserAccount`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
    } else {
      console.log(
        data.message || "An error occurred while deleting user account."
      );
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}

export async function getSharedJsonAsync(blobPath: string) {
  try {
    const sasUrl = await getSharedBlobSasUrl(blobPath);
    const response = await fetch(sasUrl);
    return await response.json();
  } catch (error) {
    console.log(error);
  }
}

export async function getUserJsonAsync(blobPath: string) {
  try {
    const sasUrl = await getUserBlobSasUrl(blobPath);
    const response = await axios.get(sasUrl);
    return response.data;
  } catch (error) {}
}

export async function getSharedBlobAsync(
  blobPath: string,
  progressCallback?: (bytes: number) => void | undefined
) {
  try {
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressCallback) {
          progressCallback(progressEvent.bytes);
        }
      },
      responseType: "blob",
    };

    const sasUrl = await getSharedBlobSasUrl(blobPath);
    const response = await axios.get(sasUrl, config);

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching blob " + blobPath);
  }
}

export async function getUserBlobAsync(
  blobPath: string,
  idToken?: string,
  progressCallback?: (bytes: number) => void | undefined
) {
  try {
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressCallback) {
          progressCallback(progressEvent.bytes);
        }
      },
      responseType: "blob",
    };

    const sasUrl = await getUserBlobSasUrl(blobPath, idToken);
    const response = await axios.get(sasUrl, config);

    if (response.status !== 200) {
      return undefined;
    }

    return response.data;
  } catch (error) {}
}

export async function postSharedJsonAsync(jsonData: any, blobPath: string) {
  const sasUrl = await getSharedBlobSasUrl(blobPath);
  await fetch(sasUrl, {
    method: "PUT",
    headers: {
      "x-ms-blob-type": "BlockBlob",
    },
    body: JSON.stringify(jsonData),
  });
}

export async function postUserJsonAsync(jsonData: any, blobPath: string) {
  const sasUrl = await getUserBlobSasUrl(blobPath);

  await fetch(sasUrl, {
    method: "PUT",
    headers: {
      "x-ms-blob-type": "BlockBlob",
    },
    body: JSON.stringify(jsonData),
  });

  store.dispatch(setUserStorageData(await getUserStorageDataAsync()));
}

export async function postSharedBlobAsync(
  blob: Blob,
  blobPath: string,
  reportProgress?: (progress: number) => void
) {
  const sasUrl = await getSharedBlobSasUrl(blobPath);

  await new Promise<void>((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", sasUrl, true);

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        reportProgress?.(progress);
      }
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        resolve();
      }
    };

    xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
    xhr.send(blob);
  });
}

export async function postUserBlobAsync(
  blob: Blob,
  blobPath: string,
  fetchStorageData: boolean,
  reportProgress?: (progress: number) => void
) {
  const sasUrl = await getUserBlobSasUrl(blobPath);

  const config = {
    onUploadProgress: (progressEvent: any) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      reportProgress?.(progress);
    },
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": blob.type || "application/octet-stream",
    },
  };

  await axios.put(sasUrl, blob, config);

  if (fetchStorageData) {
    store.dispatch(setUserStorageData(await getUserStorageDataAsync()));
  }
}

export async function deleteSharedBlobAsync(blobPath: string) {
  try {
    const requestOptions: RequestInit = {
      method: "DELETE",
    };

    const response = await fetch(
      `${apiUrl}/api/DeleteSharedBlob?blobPath=${blobPath}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      console.log(data);
    } else {
      console.log(data.message || "An error occurred while deleting the blob.");
    }
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}

export async function deleteUserBlobAsync(blobPath: string) {
  try {
    const idToken = await getIdTokenAsync();
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };

    const response = await fetch(
      `${apiUrl}/api/DeleteUserBlob?blobPath=${blobPath}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
    } else {
      console.log(data.message || "An error occurred while deleting the blob.");
    }

    store.dispatch(setUserStorageData(await getUserStorageDataAsync()));
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}

export async function deleteUserFolderAsync(folderPath: string) {
  try {
    const idToken = await getIdTokenAsync();
    const requestOptions: RequestInit = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };

    const response = await fetch(
      `${apiUrl}/api/DeleteUserFolder?folderPath=${folderPath}`,
      requestOptions
    );
    const data = await response.json();

    if (response.ok) {
      console.log(data);
    } else {
      console.log(
        data.message || "An error occurred while deleting the folder."
      );
    }

    store.dispatch(setUserStorageData(await getUserStorageDataAsync()));
  } catch (err) {
    console.log(`Error: ${err}`);
  }
}

async function getSharedBlobSasUrl(blobPath: string) {
  const urlResponse = await fetch(
    `${apiUrl}/api/GetSharedReadWriteUrl?blobPath=${blobPath}`
  );
  return await urlResponse.text();
}

export async function getUserBlobSasUrl(blobPath: string, idToken?: string) {
  if (!idToken) {
    idToken = await getIdTokenAsync();
  }
  const urlResponse = await fetch(
    `${apiUrl}/api/GetUserReadWriteUrl?blobPath=${blobPath}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    }
  );
  return await urlResponse.text();
}

export async function copyUserBlobAsync(fromPath: string, toPath: string) {
  const idToken = await getIdTokenAsync();
  await fetch(
    `${apiUrl}/api/CopyUserBlob?fromPath=${fromPath}&toPath=${toPath}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    }
  );
}

export async function copyUserToSharedBlobAsync(
  fromPath: string,
  toPath: string
) {
  const idToken = await getIdTokenAsync();
  await fetch(
    `${apiUrl}/api/CopyUserToSharedBlob?fromPath=${fromPath}&toPath=${toPath}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + idToken,
      },
    }
  );
}

export async function getUserPreviewAsync(urlData: string) {
  try {
    const sasUrl = await getUserBlobSasUrl("previews/" + urlData + ".webp");
    const config: AxiosRequestConfig = {
      responseType: "blob",
    };
    const result = await axios.get(sasUrl, config);
    return result.data;
  } catch (err) {}
}

export async function postUserPreviewAsync(urlData: string, blob: Blob) {
  const sasUrl = await getUserBlobSasUrl("previews/" + urlData + ".webp");
  const config = {
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": "image/webp",
    },
  };
  await axios.put(sasUrl, blob, config);
  store.dispatch(setUserStorageData(await getUserStorageDataAsync()));
}

export async function getUserStorageSize() {
  const idToken = await getIdTokenAsync();
  const response = await fetch(`${apiUrl}/api/GetBlobContainerSize`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + idToken,
    },
  });
  return await response.json();
}

export async function setScriberRotation(
  scriberModel: StoreItemModel,
  rotation: number[]
) {
  const configUrl = `configs/scribers/${scriberModel.name}.json`;
  const config = await getUserJsonAsync(configUrl);
  config.rotation = rotation;
  config.adjusted = true;
  await postUserJsonAsync(config, configUrl);
}

export async function getScriberRotation(scriberModel: StoreItemModel) {
  const configUrl = `configs/scribers/${scriberModel.name}.json`;
  const config = await getUserJsonAsync(configUrl);
  return {
    rotation: config?.rotation,
    adjusted: config?.adjusted,
    relativeBox: config?.relativeBox,
  };
}
