import { Button, CircularProgress, Popover, Typography } from "@mui/material";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../managers/AppInsights";
import { useEffect, useRef, useState } from "react";
import { canUserScribeAsync } from "../../managers/ClientManager";
import {
  getUserStorageDataAsync,
  postUserBlobAsync,
} from "../../managers/storage/AzureStorageManager";
import {
  hideCreateModelDialog,
  showCustomAlert,
} from "../../store/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getUserStorageLimit,
  getUserSubscriptionLevel,
  getUserSubscriptionLimits,
} from "../../store/userSlice";
import { v4 as uuidv4 } from "uuid";
import {
  setCurrentProgress,
  setTotalProgress,
  setUploading,
  setUploadingText,
} from "../../store/uploadingSlice";
import DropZone from "./DropZone";
import { resizeImage, timeoutAsync } from "../../helpers/Helper";
import { saveBlobToIDBAsync } from "../../managers/storage/DbManager";
import {
  getOccupiedStorageSize,
  setUserStorageData,
} from "../../store/storageSlice";
import pLimit from "p-limit";
import { setSelectedScriberProjectId } from "../../store/scriberSlice";
import { switchTabTo } from "../../store/navigatorSlice";
import { tabModes } from "../../store/types";
import heic2any from "heic2any";
import {
  demoStarterLimitReachedString,
  proTestLimitReachedString,
} from "../../settings/GlobalStrings";

const CreateModelView = () => {
  const concurrencyLimit = 10;
  const limit = pLimit(concurrencyLimit);
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const userSubscriptionLimits = useAppSelector(getUserSubscriptionLimits);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const occupiedStorageSize = useAppSelector(getOccupiedStorageSize);
  const [loading, setLoading] = useState(false);
  const [storageLimitReached, setStorageLimitReached] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !occupiedStorageSize) return;
      setStorageLimitReached(occupiedStorageSize >= storageLimit);
    };

    asyncWraper();
  }, [occupiedStorageSize, storageLimit]);

  const handleSelectPhotos = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDropAsync = async (files: File[]) => {
    await uploadModel(files);
  };

  const uploadModel = async (files: File[]) => {
    if (!files?.[0]) {
      return;
    }

    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );
      return;
    }

    const minPhotosCount = 1;
    setLoading(true);

    const validation = await validateMultipleFiles(
      files,
      minPhotosCount,
      userSubscriptionLimits!.scriberPhotos.count
    );
    if (!validation.result) {
      dispatch(showCustomAlert(validation.message!));
      setLoading(false);
      return;
    }
    let progress = 0;
    let images = validation.images!;
    const name = uuidv4();

    dispatch(setCurrentProgress(0));
    dispatch(setTotalProgress(images.length));
    dispatch(setUploadingText("Images Uploaded"));
    await timeoutAsync(300);
    dispatch(setUploading(true));

    const uploadImage = async (image: File) => {
      try {
        const imageName = uuidv4();
        const extension = image.name.split(".")[1];
        let resizedBlob: Blob | null = null;
        if (extension.toLowerCase() === "heic") {
          resizedBlob = (await heic2any({
            blob: image,
            toType: "image/jpeg",
            quality: 0.5,
          })) as Blob;
          console.log(resizedBlob);
        } else
          resizedBlob = await new Promise((resolve, reject) => {
            resizeImage(image, 288, 512, (result: File) => {
              result
                ? resolve(result)
                : reject(new Error("Error resizing image"));
            });
          });

        if (!resizedBlob) return;

        const tasks = [
          saveBlobToIDBAsync(
            resizedBlob,
            `scriber-projects/${name}/previews/${imageName}.jpg`
          ),
          postUserBlobAsync(
            resizedBlob,
            `scriber-projects/${name}/previews/${imageName}.jpg`,
            false
          ),
          postUserBlobAsync(
            image,
            `scriber-projects/${name}/images/${imageName}.${extension}`,
            false
          ),
        ];

        await Promise.all(tasks);
        dispatch(setCurrentProgress(++progress));
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    };

    const promises = images.map((image) => limit(() => uploadImage(image)));
    await Promise.all(promises);

    let resizedBlob: Blob | null = null;
    const extension = images[0].name.split(".")[1];

    if (extension.toLowerCase() === "heic") {
      resizedBlob = (await heic2any({
        blob: images[0],
        toType: "image/jpeg",
        quality: 0.5,
      })) as Blob;
      console.log(resizedBlob);
    } else
      resizedBlob = await new Promise((resolve, reject) => {
        resizeImage(images[0], 288, 512, (result: Blob) => {
          result ? resolve(result) : reject(new Error("Error resizing image"));
        });
      });

    if (!resizedBlob) return;

    await saveBlobToIDBAsync(
      resizedBlob,
      `previews/scriber-projects/${name}.jpg`
    );
    await postUserBlobAsync(
      resizedBlob,
      `previews/scriber-projects/${name}.jpg`,
      false
    );
    dispatch(setUserStorageData(await getUserStorageDataAsync()));
    dispatch(hideCreateModelDialog());
    dispatch(switchTabTo(tabModes.models));
    dispatch(setSelectedScriberProjectId(name));
    dispatch(setUploading(false));
    setLoading(false);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <DropZone onModelsReceived={(files) => handleDropAsync(files)}>
      <div
        style={{
          height: "calc(100% - 32px)",
          width: "calc(100% - 32px)",
          marginTop: "16px",
          marginLeft: "16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: "8px",
          border: "1px dashed rgba(55, 62, 78, 0.20)",
        }}
      >
        <img src="assets/svg/ScanIcon.svg" width="48px" height="48px" />
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: 900,
            marginTop: "22px",
            color: "#373E4E",
          }}
        >
          Create 3D model from photos
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            marginTop: "12px",
            color: "#5F6A84",
          }}
        >
          Drag and drop here up to 300 photos to create a model
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              height: "1px",
              width: "212px",
              backgroundColor: "rgba(95, 106, 132, 0.20)",
            }}
          />
          <Typography
            sx={{
              color: "rgba(95, 106, 132, 0.40)",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            OR
          </Typography>
          <div
            style={{
              height: "1px",
              width: "212px",
              backgroundColor: "rgba(95, 106, 132, 0.20)",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "28px",
            alignItems: "center",
            gap: "8px",
            marginLeft: "28px",
          }}
        >
          <Button
            sx={{
              background: "#3050F5",
              borderRadius: "12px",
              padding: "10px 18px",
              height: "48px",
              width: "156px",
              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#3050F5",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={handleSelectPhotos}
          >
            {loading ? (
              <CircularProgress size={"24px"} sx={{ color: "#FFF" }} />
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FFF",
                  fontWeight: 800,
                }}
              >
                Select Photos
              </Typography>
            )}
          </Button>
          <img
            src="assets/svg/InfoIconGray.svg"
            width="20px"
            height="20px"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
        </div>

        <input
          type="file"
          ref={inputRef}
          accept=".jpg,.jpeg,.heic"
          multiple={true}
          onChange={async () => {
            if (inputRef.current && inputRef.current.files) {
              await uploadModel([...inputRef.current.files]);
            }
          }}
          style={{ display: "none" }}
        />
      </div>
      <Popover
        sx={{
          pointerEvents: "none",
          marginLeft: "28px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div
          style={{
            width: "280px",
            height: "280px",
            justifyContent: "center",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <video
            src="assets/videos/Tutorial.mp4"
            autoPlay
            loop
            style={{ borderRadius: "8px" }}
          />
        </div>
      </Popover>
    </DropZone>
  );
};

async function validateMultipleFiles(
  files: File[],
  minPhotosCount: number,
  maxPhotosCount: number
) {
  if (files.length < minPhotosCount) {
    return {
      result: false,
      message: `You selected less than ${minPhotosCount} photos. Please select more and try again.`,
    };
  } else if (files.length > maxPhotosCount) {
    return {
      result: false,
      message: `You've reached the maximum limit of ${maxPhotosCount} photos you can upload. Please select less photos and try again.`,
    };
  }
  for (let file of files) {
    const fileType = file.type.toLowerCase();
    if (
      fileType !== "image/jpeg" &&
      fileType !== "image/jpg" &&
      fileType !== "image/heic"
    ) {
      return {
        result: false,
        message: `Invalid file format. Only JPG, JPEG and HEIC images are allowed.`,
      };
    }
  }
  return {
    result: true,
    images: files,
  };
}

export default withAITracking(
  reactPlugin,
  CreateModelView,
  "CreateModel",
  "flex"
);
