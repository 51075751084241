import { createSlice } from "@reduxjs/toolkit";
import { OnboardingModel } from "./types";
import { RootState } from "./store";

const initialState: OnboardingModel = {
  onboardingVisible: false,
  currentOnboardingStep: -1,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setOnboardingVisible: (state, action) => {
      state.onboardingVisible = action.payload;
    },
    setCurrentOnboardingStep: (state, action) => {
      state.currentOnboardingStep = action.payload;
    },
  },
});

export const getOnboardingVisible = (state: RootState) =>
  state.onboarding.onboardingVisible;

export const getCurrentOnboardingStep = (state: RootState) =>
  state.onboarding.currentOnboardingStep;

export const { setOnboardingVisible, setCurrentOnboardingStep } =
  onboardingSlice.actions;

export default onboardingSlice.reducer;
