import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getAppMode, getTabMode, navigateTo } from "../store/navigatorSlice";
import SceneView from "./SceneView";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import {
  getUserStorageData,
  setOccupiedStorageSize,
  setUserStorageData,
} from "../store/storageSlice";
import {
  getSubscriptionPlansAsync,
  getUserStorageDataAsync,
  getUserStorageSize,
} from "../managers/storage/AzureStorageManager";
import { appModes, tabModes } from "../store/types";
import { getUserSettingsAsync } from "../managers/ClientManager";
import SubscriptionDialog from "./dialogs/SubscriptionDialog";
import SettingsDialog from "./dialogs/settingsDialog/SettingsDialog";
import Opening from "./controls/Opening";
import CustomAlert from "./dialogs/CustomAlert";
import { setEditorViewVisible } from "../store/editorSlice";
import { getEditorSettingsFromLocalStorage } from "../managers/storage/LocalStorageManager";
import BasicTabs from "./leftPanel/BasicTabs";
import UploadingProgress from "./home/UploadingProgress";
import CreateModelDialog from "./dialogs/CreateModelDialog";
import CreateVideoDialog from "./dialogs/CreateVideoDialog";
import { getProject, setProject } from "../store/projectSlice";
import SceneProgress from "./controls/SceneProgress";
import ImageDialog from "./dialogs/ImageDialog";
import {
  getCreateModelDialogOpen,
  getCreateVideoDialogOpen,
  getImageDialogItem,
} from "../store/dialogSlice";
import {
  getBlobFromIDBAsync,
  saveBlobToIDBAsync,
} from "../managers/storage/DbManager";
import { getUserStorageLimit } from "../store/userSlice";
import { selectScriberLoading } from "../store/scriberSlice";

const MainView = () => {
  const appMode = useAppSelector(getAppMode);
  const tabMode = useAppSelector(getTabMode);
  const project = useAppSelector(getProject);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const userStorageData = useAppSelector(getUserStorageData);
  const imageDialogItem = useAppSelector(getImageDialogItem);
  const createModelDialogOpen = useAppSelector(getCreateModelDialogOpen);
  const createVideoDialogOpen = useAppSelector(getCreateVideoDialogOpen);
  const scriberLoading = useAppSelector(selectScriberLoading);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const asyncWrapper = async () => {
      const res = await instance.handleRedirectPromise();
      await getUserSettingsAsync(dispatch);
      await getSubscriptionPlansAsync(dispatch);

      if (!res?.account && instance.getAllAccounts().length === 0) {
        setLoaded(true);
        return;
      }

      const idbBlob = await getBlobFromIDBAsync("UserStorageData");
      let userStorage;
      if (idbBlob) {
        userStorage = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(JSON.parse(reader.result as string));
          };
          reader.readAsText(idbBlob);
        });
        dispatch(setUserStorageData(userStorage));
        setLoaded(true);
      }
      userStorage = await getUserStorageDataAsync();
      const blob = new Blob([JSON.stringify(userStorage)], {
        type: "application/json",
      });
      await saveBlobToIDBAsync(blob, "UserStorageData");
      dispatch(setUserStorageData(userStorage));
      setLoaded(true);
    };

    asyncWrapper();
    dispatch(
      setEditorViewVisible(
        getEditorSettingsFromLocalStorage().editorViewVisible
      )
    );
    dispatch(navigateTo(appModes.sequencer));
  }, []);

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !userStorageData) return;
      const storageSize = await getUserStorageSize();
      if (!storageSize) return;
      dispatch(setOccupiedStorageSize(storageSize.sizeInMB / 1024));
    };

    asyncWraper();
  }, [userStorageData, storageLimit]);

  useEffect(() => {
    switch (tabMode) {
      case tabModes.home:
      case tabModes.models:
      case tabModes.videos:
        dispatch(setProject({}));
        break;
      default:
        break;
    }
  }, [tabMode]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "start",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          backgroundColor: "#F1F3F4",
        }}
      >
        {appMode !== appModes.render && <BasicTabs />}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <SceneView key={project.id} />
          {!scriberLoading && <SceneProgress />}
        </Box>
        <SubscriptionDialog />
        <SettingsDialog />
        <Opening loaded={loaded} />
      </Box>
      {!createModelDialogOpen && !createVideoDialogOpen && (
        <CustomAlert positionTop="56px" positionRight="16px" />
      )}
      <CreateModelDialog />
      <CreateVideoDialog />
      <UploadingProgress />
      {imageDialogItem && <ImageDialog />}
    </Box>
  );
};

export default MainView;
