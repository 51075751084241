import { Box, Typography, Input } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { getStorageItemFromStorageData } from "../../helpers/Helper";
import {
  deleteUserBlobAsync,
  getUserBlobAsync,
  postUserBlobAsync,
} from "../../managers/storage/AzureStorageManager";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getProject, setProjectName } from "../../store/projectSlice";
import { getUserStorageData } from "../../store/storageSlice";
import { StoreItemModel } from "../../store/types";
import { showCustomAlert } from "../../store/dialogSlice";

const INPUT_OFFSET = 2;
const TYPOGRAPHY_WIDTH = 112;

const ProjectRename = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const storageData = useAppSelector(getUserStorageData);
  const [projects, setProjects] = useState([] as StoreItemModel[]);
  const [projectName, setName] = useState("");
  const [inputWidth, setWidth] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const typographyRef = useRef<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const projects = getStorageItemFromStorageData("projects", storageData);
    setProjects(projects);
  }, [storageData]);

  useEffect(() => {
    if (project?.name) {
      setName(project?.name);
    }
  }, [project?.name]);

  useEffect(() => {
    if (!isFocused) {
      setWidth(typographyRef.current?.offsetWidth + INPUT_OFFSET);
    }
  }, [projectName]);

  const handleInputChange = (e: any) => {
    setName(e.target.value);
  };

  const handleInputFocus = () => {
    setWidth(
      typographyRef.current?.offsetWidth + TYPOGRAPHY_WIDTH + INPUT_OFFSET
    );
    setIsFocused(true);
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleInputUnfocus = () => {
    setWidth(typographyRef.current?.offsetWidth + INPUT_OFFSET);
    setIsFocused(false);
    renameProjectAsync();
  };

  const renameProjectAsync = async () => {
    if (projectName === "") {
      dispatch(showCustomAlert("Empty name is not allowed"));
      setName(project.name);
      return;
    }
    if (project.name === projectName) {
      return;
    }
    if (projects.some((p) => p.name === projectName)) {
      dispatch(showCustomAlert("Name already taken"));
      setName(project.name);
      return;
    }

    const name = project.name;
    dispatch(setProjectName(projectName));
    deleteUserBlobAsync(`projects/${name}.json`);

    const previewUrl = `previews/projects/${name}.json.webp`;
    const blob = await getUserBlobAsync(previewUrl);
    await postUserBlobAsync(
      blob!,
      `previews/projects/${projectName}.json.webp`,
      true
    );
    deleteUserBlobAsync(`previews/projects/${name}.json.webp`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      inputRef.current?.blur();
    }
    if (event.key === "Escape") {
      inputRef.current?.blur();
    }
  };

  return (
    <Box
      sx={{
        height: "36px",
        display: "flex",
        alignItems: "center",
        borderRadius: "12px",
        padding: "0px 10px 0px 12px",
        outlineOffset: "-1px",
        outline: isFocused ? "1px solid #4261FF" : "none",
        ":hover": {
          outline: isFocused ? "1px solid #4261FF" : "1px solid #D0D4D9",
        },
      }}
    >
      {!isFocused && (
        <Typography
          sx={{
            marginRight: "4px",
            width: "108px",
          }}
        >
          Project name:
        </Typography>
      )}
      <Typography
        ref={typographyRef}
        sx={{
          position: "absolute",
          opacity: 0,
          zIndex: -100,
          whiteSpace: "pre",
          maxWidth: "280px",
        }}
      >
        {projectName}
      </Typography>
      <Input
        inputRef={inputRef}
        disableUnderline={true}
        type="text"
        onChange={handleInputChange}
        value={projectName}
        onFocus={handleInputFocus}
        onBlur={handleInputUnfocus}
        onKeyDown={handleKeyDown}
        sx={{
          ".MuiInput-input": {
            "&::selection": {
              backgroundColor: "#aeb4d4",
            },
            caretColor: "#4261FF",
            textOverflow: "ellipsis",
            color: "#454E63",
            width: inputWidth,
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export default ProjectRename;
