import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
} from "@mui/material";

interface ShotDialogProps {
  open: boolean;
  title: string;
  onSave: (name: string, groupName: string, ai: boolean) => void;
  onCancel: () => void;
}

const ShotDialog = ({ open, title, onSave, onCancel }: ShotDialogProps) => {
  const [name, setName] = useState("Untitled");
  const [groupName, setGroupName] = useState("Untitled");
  const [ai, setAi] = useState(false);

  useEffect(() => {
    if (!open) {
      setName("Untitled");
      setGroupName("Untitled");
    }
  }, [open]);

  function handleNameChange(name: string) {
    setName(name);
  }

  function handleGroupNameChange(name: string) {
    setGroupName(name);
  }

  function handleSave() {
    if (name.trim() === "") {
      return;
    }
    onSave(name, groupName, ai);
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSave();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onKeyDown={handleKeyDown}
      sx={{
        ".MuiDialog-paper": {
          backgroundColor: "#F1F3F4",
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>Save {title}</Typography>
          <Box sx={{ marginTop: "20px" }}>
            <TextField
              label={`${title} Type`}
              value={groupName}
              onChange={(e) => handleGroupNameChange(e.target.value)}
              required
              error={groupName.trim() === ""}
              helperText={groupName.trim() === "" ? "Name is required" : " "}
              autoFocus
              onFocus={handleFocus}
            />
            <TextField
              sx={{ marginLeft: "8px" }}
              label="Name"
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              required
              error={name.trim() === ""}
              helperText={name.trim() === "" ? "Name is required" : " "}
              onFocus={handleFocus}
            />
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Typography>AI Shot</Typography>
            <Checkbox checked={ai} onChange={() => setAi((v) => !v)} />
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row-reverse",
              gap: "8px",
            }}
          >
            <Button
              onClick={handleSave}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "96px",
                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                ":disabled": { background: "#e9e9ea" },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                Save
              </Typography>
            </Button>
            <Button
              onClick={onCancel}
              sx={{
                background: "#FFF",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "96px",
                justifyContent: "space-between",
                gap: "4px",

                ":hover": {
                  background: "#FFF",
                  boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: 15,
                  color: "#454E63",
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShotDialog;
