import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { UploadingState } from "./types";

const initialState: UploadingState = {
  uploading: false,
  uploadingText: "",
  currentProgress: 0,
  totalProgress: 0,
};

export const uploadingSlice = createSlice({
  name: "uploading",
  initialState,
  reducers: {
    setUploading: (state, action) => {
      state.uploading = action.payload;
    },
    setUploadingText: (state, action) => {
      state.uploadingText = action.payload;
    },
    setCurrentProgress: (state, action) => {
      if (action.payload > state.totalProgress) {
        state.currentProgress = state.totalProgress;
        return;
      }
      state.currentProgress = action.payload;
    },
    setTotalProgress: (state, action) => {
      state.totalProgress = action.payload;
    },
  },
});

export const getUploading = (state: RootState) => state.uploading.uploading;
export const getUploadingText = (state: RootState) =>
  state.uploading.uploadingText;
export const getCurrentProgress = (state: RootState) =>
  state.uploading.currentProgress;
export const getTotalProgress = (state: RootState) =>
  state.uploading.totalProgress;

export const {
  setUploading,
  setUploadingText,
  setCurrentProgress,
  setTotalProgress,
} = uploadingSlice.actions;

export default uploadingSlice.reducer;
