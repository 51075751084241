import { useAppSelector } from "../../../../store/hooks";
import {
  getTimelineScale,
  getTimelineSelectedItem,
  setTimelineCaretTime,
  setTimelineSelectedItem,
} from "../../../../store/timelineSlice";
import { CSSProperties, useMemo } from "react";
import { useDispatch } from "react-redux";
import { TimelineItemType, iTextTrack } from "../../../../store/types";
import { Rnd } from "react-rnd";
import { setTextTimelineProps } from "../../../../store/projectSlice";
import { Button, Typography } from "@mui/material";

type TextTrackItemProps = { item: iTextTrack; onDelete: () => void };

function TextTrackItem({ item, onDelete }: TextTrackItemProps) {
  const dispatch = useDispatch();
  const scaleRatio = useAppSelector(getTimelineScale);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const selected = useMemo(
    () => selectedItem?.id === item.id,
    [selectedItem?.id]
  );

  const handleDragStop = (x: number) => {
    dispatch(
      setTextTimelineProps({ id: item.id, data: { offset: x / scaleRatio } })
    );
  };

  const handleResizeStop = (direction: string, deltaWidth: number) => {
    const newWidth = item.timeline.duration * scaleRatio + deltaWidth;
    if (direction === "left") {
      const newX = item.timeline.offset * scaleRatio - deltaWidth;
      dispatch(
        setTextTimelineProps({
          id: item.id,
          data: {
            offset: newX / scaleRatio,
            duration: newWidth / scaleRatio,
          },
        })
      );
    } else {
      dispatch(
        setTextTimelineProps({
          id: item.id,
          data: { duration: newWidth / scaleRatio },
        })
      );
    }
  };

  return (
    <Rnd
      onClick={() => {
        dispatch(
          setTimelineCaretTime(
            item.timeline.offset + item.timeline.duration / 2
          )
        );

        dispatch(
          setTimelineSelectedItem({ id: item.id, type: TimelineItemType.text })
        );
      }}
      style={{
        cursor: "grab",
        borderRadius: "8px",
        overflow: "hidden",
        zIndex: selected ? 2 : 1,
      }}
      size={{ width: item.timeline.duration * scaleRatio, height: "32px" }}
      position={{ x: item.timeline.offset * scaleRatio, y: 0 }}
      onDragStop={(_, d) => handleDragStop(d.x)}
      onResizeStop={(_, direction, __, delta) =>
        handleResizeStop(direction, delta.width)
      }
      bounds="parent"
      dragAxis="x"
      enableResizing={{
        top: false,
        bottom: false,
        right: selected,
        left: selected,
      }}
      resizeHandleStyles={{
        left: leftResizeHandleStyle,
        right: rightResizeHandleStyle,
      }}
      resizeHandleComponent={{
        left: <span style={innerHandleStyle} />,
        right: <span style={innerHandleStyle} />,
      }}
      minWidth={scaleRatio}
    >
      <div
        style={{
          height: "100%",
          backgroundColor: "#00000018",
          padding: `0px ${selected ? 13 : 16}px`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxSizing: "border-box",
          borderWidth: selected ? "3px" : 0,
          borderColor: selected ? "#4261FF" : "#F1F3F4",
          borderStyle: "solid",
          overflow: "hidden",
        }}
      >
        <Typography
          sx={{ ...item.template.css, ...item.appearance, fontSize: "20px" }}
        >
          {item.appearance.text}
        </Typography>
        <Button
          onClick={onDelete}
          sx={{
            display: selected ? "inherit" : "none",
            minWidth: "16px",
          }}
        >
          <img src="assets/svg/DeleteIcon.svg" width="16px" height="16px" />
        </Button>
      </div>
    </Rnd>
  );
}

export default TextTrackItem;

const leftResizeHandleStyle = {
  minWidth: "12px",
  height: "100%",
  left: 0,
  backgroundColor: "#4261FF",
  cursor: "ew-resize",
};

const rightResizeHandleStyle = {
  minWidth: "12px",
  height: "100%",
  right: 0,
  backgroundColor: "#4261FF",
  cursor: "ew-resize",
};

const innerHandleStyle: CSSProperties = {
  pointerEvents: "none",
  width: "2px",
  height: "16px",
  borderRadius: "2px",
  position: "absolute",
  left: "5px",
  top: "8px",
  backgroundColor: "white",
};
