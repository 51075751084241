import { Box, Button, Grid, Typography } from "@mui/material";
import { TimelineItemType, iTextTemplate } from "../../../../store/types";
import { getTimelineSelectedItem } from "../../../../store/timelineSlice";
import { useAppSelector } from "../../../../store/hooks";
import { useEffect, useState } from "react";
import { getProject } from "../../../../store/projectSlice";

interface TextItemProps {
  template: iTextTemplate;
  onSelect: () => void;
}

const TextItem = ({ template, onSelect }: TextItemProps) => {
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const project = useAppSelector(getProject);
  const [selectedTextId, setSelectedTextId] = useState("");

  useEffect(() => {
    if (selectedItem?.type === TimelineItemType.text) {
      const selectedText = project.texts?.find(
        (t) => t.id === selectedItem?.id
      );
      if (selectedText) setSelectedTextId(selectedText.template.id);
    }
  }, [selectedItem, project.texts]);

  return (
    <Grid item sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Button
          data-track="TextClicked"
          sx={{
            width: "160px",
            height: "92px",
            borderRadius: "12px",
            border:
              template.id === selectedTextId
                ? "4px solid #F1F3F4"
                : "1px solid gray",
            outline: template.id === selectedTextId ? "2px solid #4261FF" : "",
            outlineOffset: "-2px",
          }}
          onClick={() => onSelect()}
        >
          <Typography
            sx={{
              pointerEvents: "none",
              maxWidth: "148px",
              whiteSpace: "nowrap",
              fontSize: 36,
              ...template.css,
            }}
          >
            Abc
          </Typography>
        </Button>
      </Box>
    </Grid>
  );
};

export default TextItem;
