import { TextAnimationTemplate } from "../../../store/types";

export const textAnimationTemplates: TextAnimationTemplate[] = [
  { id: "None", inStyleName: "emptyAnimation", outStyleName: "emptyAnimation" },
  { id: "Fade", inStyleName: "fadeIn", outStyleName: "fadeOut" },
  { id: "Slide", inStyleName: "moveRightIn", outStyleName: "moveRightOut" },
  {
    id: "Elastic Zoom",
    inStyleName: "elasticZoomIn",
    outStyleName: "elasticZoomOut",
  },
  {
    id: "Bounce",
    inStyleName: "bounceIn",
    outStyleName: "bounceIn",
  },
  {
    id: "Rotate In / Out",
    inStyleName: "rotateInClockwise",
    outStyleName: "rotateOutCounterclockwise",
  },
  {
    id: "Scale Pulse",
    inStyleName: "scalePulse",
    outStyleName: "scalePulse",
  },
  {
    id: "Wobble",
    inStyleName: "wobble",
    outStyleName: "wobble",
  },
  {
    id: "Pop In",
    inStyleName: "popIn",
    outStyleName: "none",
  },
  {
    id: "JellyShake",
    inStyleName: "jellyShake",
    outStyleName: "jellyShake",
  },
];
