import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import { StoreItemModel } from "../../../store/types";
import { getBlobFromIDBAsync } from "../../../managers/storage/DbManager";
import {
  increaseCurrentProgress,
  increaseTotalProgress,
  setProgressText,
} from "../../../store/progressSlice";
import { getUserBlobAsync } from "../../../managers/storage/AzureStorageManager";

export async function createMainObjectAsync(
  model: StoreItemModel,
  scene: BABYLON.Scene,
  dispatch: Function,
  rotation?: number[]
) {
  let blob = await getBlobFromIDBAsync(model.url);
  if (!blob) {
    dispatch(setProgressText("Downloading model"));
    dispatch(increaseTotalProgress(model.properties.contentLength));
    blob = await getUserBlobAsync(model.url, undefined, (bytes: number) => {
      dispatch(increaseCurrentProgress(bytes));
    });
  }
  const objNode = await loadFileAsync(
    new File([blob!], "blob.glb", {
      type: "model/gltf-binary",
    }),
    model,
    scene,
    rotation
  );
  return objNode;
}

const loadFileAsync = async (
  file: File,
  model: StoreItemModel,
  scene: BABYLON.Scene,
  rotation?: number[]
) => {
  const rootMesh = await createObjectFromFileAsync(scene, file);
  if (!rootMesh) return null;
  const transformNode = normalizeObjectInSpace(
    rootMesh,
    model,
    scene,
    rotation
  );
  const parent = new BABYLON.TransformNode(model.name, scene);
  parent.id = model.id;
  transformNode.parent = parent;
  return transformNode;
};

async function createObjectFromFileAsync(scene: BABYLON.Scene, file: File) {
  try {
    const object = await BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "",
      file,
      scene
    );
    return object.meshes[0];
  } catch (e) {
    console.log("Bad *.glb: " + file.name, e);
  }
  return null;
}

function normalizeObjectInSpace(
  rootMesh: BABYLON.AbstractMesh,
  model: StoreItemModel,
  scene: BABYLON.Scene,
  rotation?: number[]
) {
  if (rotation) {
    rootMesh.rotation = BABYLON.Vector3.FromArray(rotation);
  } else {
    rootMesh.rotation = BABYLON.Vector3.Zero();
  }

  (rootMesh as BABYLON.Mesh).bakeCurrentTransformIntoVertices();
  (
    rootMesh.getChildMeshes()[0] as BABYLON.Mesh
  ).bakeCurrentTransformIntoVertices();
  rootMesh.getChildMeshes()[0].refreshBoundingInfo();
  rootMesh.refreshBoundingInfo();

  let hierarchyBoundingVectors = rootMesh.getHierarchyBoundingVectors();
  let maxSize = hierarchyBoundingVectors.max.subtract(
    hierarchyBoundingVectors.min
  );
  const scale = 1 / Math.max(maxSize.x, maxSize.y, maxSize.z);
  rootMesh.scaling.x = scale;
  rootMesh.scaling.y = scale;
  rootMesh.scaling.z = scale;

  rootMesh.position = hierarchyBoundingVectors.max
    .add(hierarchyBoundingVectors.min)
    .divide(new BABYLON.Vector3(-2, -2, -2));

  const transform = new BABYLON.TransformNode(model.name, scene);
  rootMesh.parent = transform;

  return transform;
}
