import * as BABYLON from "babylonjs";
import { StoreItemModel } from "../../../store/types";
import { getBlobAsync } from "../../../managers/storage/StorageManager";

export async function createSkybox(
  model: StoreItemModel,
  size: number,
  scene: BABYLON.Scene
) {
  const skyboxBlob = await getBlobAsync(model);
  const blobUrl = URL.createObjectURL(skyboxBlob!);
  const texture = new BABYLON.CubeTexture(
    blobUrl,
    scene,
    null,
    false,
    null,
    null,
    null,
    BABYLON.Constants.TEXTUREFORMAT_RGBA,
    false,
    ".env"
  );
  const skyboxNode = scene.createDefaultSkybox(texture!, true, size, 0.065)!;
  const parentNode = new BABYLON.TransformNode(model.name, scene);
  parentNode.id = model.id;
  skyboxNode.parent = parentNode;
  return parentNode;
}
