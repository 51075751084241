import React, { useEffect, useState, useRef } from "react";

export type DropZoneProps = {
  children: React.ReactNode;
  onModelsReceived: (files: File[]) => void;
};

const DropZone = ({ children, onModelsReceived }: DropZoneProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onModelsReceived(Array.from(e.dataTransfer.files));
    }
  };

  const updateDragState = (e: DragEvent) => {
    if (dropRef.current && dropRef.current.contains(e.target as Node)) {
      setIsDragging(true);
    } else {
      setIsDragging(false);
    }
  };

  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      updateDragState(e);
    };

    const handleDragLeave = (e: DragEvent) => {
      updateDragState(e);
    };

    const handleDragEnd = () => {
      setIsDragging(false); // Ensure to reset the state when the drag ends
    };

    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDragEnd);
    document.addEventListener("dragend", handleDragEnd);

    return () => {
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDragEnd);
      document.removeEventListener("dragend", handleDragEnd);
    };
  }, []);

  return (
    <div
      ref={dropRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {children}
      <div
        onDrop={handleDrop}
        style={{
          display: isDragging ? "block" : "none",
          outline: "1px dashed #3050F5",
          position: "absolute",
          borderRadius: "8px",
          top: "17px",
          left: "17px",
          width: "calc(100% - 32px)",
          height: "calc(100% - 32px)",
          zIndex: 1000,
          pointerEvents: "all",
        }}
      />
    </div>
  );
};

export default DropZone;
