import * as BABYLON from "babylonjs";
import "babylonjs-inspector";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { createControlledCamera } from "../loaders/CameraLoader";
import {
  setRenderingPipeline,
  updateDepthOfFieldFocusDistance,
} from "../loaders/PipelineLoader";
import {
  selectScriberInited,
  selectScriberLoading,
  selectScriberSelectedItem,
  setScriberInited,
  setScriberLoading,
} from "../../../store/scriberSlice";
import { distance } from "../types";
import { navigateTo } from "../../../store/navigatorSlice";
import { RelativeBox, StoreItemModel, appModes } from "../../../store/types";
import {
  getBlobFromIDBAsync,
  saveBlobToIDBAsync,
} from "../../../managers/storage/DbManager";
import {
  getScriberRotation,
  getUserBlobAsync,
  getUserJsonAsync,
  postUserJsonAsync,
  setScriberRotation,
} from "../../../managers/storage/AzureStorageManager";
import {
  increaseCurrentProgress,
  increaseTotalProgress,
  resetProgress,
  setProgressText,
} from "../../../store/progressSlice";
import SceneProgress from "../../controls/SceneProgress";

type SceneProps = {
  onSceneReady: (scene: BABYLON.Scene) => void;
};

let engine: BABYLON.Engine;

const Scene = ({ onSceneReady }: SceneProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const reactCanvas = useRef({} as HTMLCanvasElement);

  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    engine = new BABYLON.Engine(
      canvas,
      true,
      {
        preserveDrawingBuffer: true,
        stencil: true,
        powerPreference: "high-performance",
        antialias: true,
        premultipliedAlpha: true,
      },
      true
    );
    const scene = new BABYLON.Scene(engine);

    const initScene = (scene: BABYLON.Scene) => {
      onSceneReady(scene);
    };

    if (scene.isReady()) {
      initScene(scene);
    } else {
      scene.onReadyObservable.addOnce((scene) => initScene(scene));
    }

    const resize = () => {
      engine.resize();
    };

    if (window) {
      window.addEventListener("resize", resize);
    }

    const parent = parentRef.current;
    if (!canvas || !parent) {
      return;
    }
    canvas.width = parent.clientWidth;
    canvas.height = parent.clientHeight;

    const observer = new ResizeObserver((_) => {
      resize();
    });
    observer.observe(parent);

    return () => {
      observer.disconnect();
      scene.getEngine().dispose();
      console.log("Disposing Engine");

      if (window) {
        window.removeEventListener("resize", resize);
      }
    };
  }, []);

  return (
    <div
      ref={parentRef}
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      <canvas
        ref={reactCanvas}
        style={{
          outline: "none",
          borderRadius: "16px",
          position: "absolute",
          transform: `translate(-50%, ${"-50%"})`,
          top: "50%",
          left: "50%",
          width: "calc(100% - 32px)",
          height: "calc(100% - 32px)",
        }}
      />
    </div>
  );
};

export let scriberScene: BABYLON.Scene;
export let scriberCamera: BABYLON.ArcRotateCamera;
let gizmoManager: BABYLON.GizmoManager;
export let scriberCameraPipeline: BABYLON.DefaultRenderingPipeline;
export let scriberCameraDistance: distance = { smooth: 1, current: 1 };
let update = false;
let updateFrames = 0;

const ScriberAdjustScene = () => {
  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector(selectScriberSelectedItem);
  const inited = useAppSelector(selectScriberInited);
  const [adjusting, setAdjusting] = useState(false);
  const [applying, setApplying] = useState(false);
  const scriberLoading = useAppSelector(selectScriberLoading);

  const onSceneReady = async (scene: BABYLON.Scene) => {
    scriberScene = scene;
    scriberCamera = createControlledCamera(scriberScene);
    scriberCamera.alpha = Math.PI / 4;
    scriberCamera.beta = Math.PI / 3;
    scriberCameraPipeline = setRenderingPipeline(scriberCamera, scriberScene);
    scriberCamera.onViewMatrixChangedObservable.add(() => {
      update = true;
      updateFrames = 0;
    });
    new BABYLON.HemisphericLight(
      "light",
      new BABYLON.Vector3(0, 0, 0),
      scriberScene
    );
    scriberScene.clearColor = new BABYLON.Color4(1, 1, 1, 1);
    dispatch(setScriberInited());
    engine.runRenderLoop(() => floatRender());
  };

  function floatRender() {
    if (update) {
      render();
      updateFrames++;
      if (updateFrames > 30) {
        update = false;
      }
    } else {
      if (engine.frameId % 15 === 0) {
        render();
      }
    }
  }

  function render() {
    scriberScene.render();
    updateDepthOfFieldFocusDistance(
      scriberCamera,
      scriberCameraPipeline,
      scriberCameraDistance,
      scriberScene
    );
  }

  useEffect(() => {
    if (!inited || !selectedItem) {
      return;
    }

    const asyncWrapper = async () => {
      if (scriberScene.getNodeById(selectedItem.name) || scriberLoading) return;

      dispatch(resetProgress());
      dispatch(setProgressText("Loading"));
      dispatch(setScriberLoading(true));

      const scriberRotation = await getScriberRotation(selectedItem);
      const node = await createScriberObjectAsync(
        selectedItem,
        scriberScene,
        dispatch
      );
      if (!node) {
        dispatch(setScriberLoading(false));
        return;
      }
      if (scriberRotation.rotation)
        node.rotationQuaternion = BABYLON.Vector3.FromArray(
          scriberRotation.rotation
        ).toQuaternion();
      const objectParent = new BABYLON.TransformNode(
        "objectParent",
        scriberScene
      );
      node.parent = objectParent;
      setGizmo(objectParent, scriberScene);
      if (!scriberRotation.adjusted) {
        handleAdjustClick();
      }
      dispatch(setScriberLoading(false));
    };

    asyncWrapper();
  }, [inited, selectedItem]);

  function handleAdjustClick() {
    setAdjusting(true);
    createWhiteBoxWithText(scriberScene);
    gizmoManager.rotationGizmoEnabled = true;
    const parent = gizmoManager.gizmos.rotationGizmo
      ?.attachedNode! as BABYLON.TransformNode;
    const child = parent.getChildren()[0] as BABYLON.TransformNode;
    gizmoManager.gizmos.rotationGizmo!.onDragEndObservable.add(() => {
      const globalRotation = child.absoluteRotationQuaternion.clone();
      parent.rotation = new BABYLON.Vector3();
      child.rotationQuaternion = globalRotation;
    });
  }

  async function handleApplyClick() {
    setApplying(true);
    scriberScene.getNodeById("placerParent")?.dispose(false, true);
    const parent = gizmoManager.gizmos.rotationGizmo
      ?.attachedNode! as BABYLON.TransformNode;
    const child = parent.getChildren()[0] as BABYLON.TransformNode;
    const childRotation = child.absoluteRotationQuaternion
      .toEulerAngles()
      .asArray();
    gizmoManager.rotationGizmoEnabled = false;
    await setScriberRotation(selectedItem!, childRotation);

    const bounds = child!.getHierarchyBoundingVectors();
    const path = `configs/${selectedItem?.url.split(".")[0]}.json`;
    const json = await getUserJsonAsync(path);
    json.relativeBox = {
      center: bounds.max
        .add(bounds.min)
        .multiplyByFloats(0.5, 0.5, 0.5)
        .asArray(),
      dimensions: bounds.max
        .subtract(bounds.min)
        .multiplyByFloats(0.5, 0.5, 0.5)
        .asArray(),
    } as RelativeBox;

    await postUserJsonAsync(
      json,
      `configs/${selectedItem?.url.split(".")[0]}.json`
    );
    setApplying(false);
    setAdjusting(false);
  }

  function handleCancelClick() {
    setAdjusting(false);
    scriberScene.getNodeById("placerParent")?.dispose(false, true);
    const parent = gizmoManager.gizmos.rotationGizmo
      ?.attachedNode! as BABYLON.TransformNode;
    if (!parent) return;
    const child = parent.getChildren()[0] as BABYLON.TransformNode;
    child.rotation = BABYLON.Vector3.Zero();
    gizmoManager.rotationGizmoEnabled = false;
  }

  useEffect(() => {
    return () => {
      dispatch(navigateTo(appModes.sequencer));
    };
  }, []);

  const handleShowFront = () => {
    spinTo(Math.PI / 2, Math.PI / 2);
  };

  const handleShowRight = () => {
    spinTo(0, Math.PI / 2);
  };

  const handleShowTop = () => {
    spinTo(Math.PI / 2, 0);
  };

  function spinTo(alpha: number, beta: number) {
    var ease = new BABYLON.CubicEase();
    ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);
    BABYLON.Animation.CreateAndStartAnimation(
      "spin",
      scriberCamera,
      "alpha",
      60,
      30,
      scriberCamera.alpha,
      alpha,
      0,
      ease
    );
    BABYLON.Animation.CreateAndStartAnimation(
      "spin",
      scriberCamera,
      "beta",
      60,
      30,
      scriberCamera.beta,
      beta,
      0,
      ease
    );
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {scriberLoading && (
        <div
          style={{
            position: "absolute",
            top: "16px",
            left: "16px",
            width: "calc(100% - 32px)",
            height: "calc(100% - 32px)",
            borderRadius: "16px",
          }}
        >
          <SceneProgress />
        </div>
      )}
      <Scene onSceneReady={onSceneReady} />
      {adjusting && (
        <div
          style={{
            position: "absolute",
            top: "32px",
            gap: "8px",
            display: "flex",
          }}
        >
          <IconButton
            style={{ borderRadius: "12px", border: "1px solid #00000040" }}
            onClick={handleShowRight}
          >
            <Typography>RIGHT</Typography>
          </IconButton>
          <IconButton
            style={{ borderRadius: "12px", border: "1px solid #00000040" }}
            onClick={handleShowTop}
          >
            <Typography>TOP</Typography>
          </IconButton>
          <IconButton
            style={{ borderRadius: "12px", border: "1px solid #00000040" }}
            onClick={handleShowFront}
          >
            <Typography>FRONT</Typography>
          </IconButton>
        </div>
      )}
      {adjusting && (
        <div
          style={{
            position: "absolute",
            left: "32px",
            bottom: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Typography>Adjust model rotation dragging color curves</Typography>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          right: "32px",
          bottom: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {!adjusting && (
          <Button
            data-track="AdjustModelClicked"
            sx={{
              background: "#FFF",
              borderRadius: "12px",
              padding: "4px 16px 4px 16px ",
              minHeight: "36px",
              gap: "8px",
              outline: "0px solid #D0D4D9",
              outlineOffset: "-1px",
              ":hover": {
                background: "#FFF",
                boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={() => handleAdjustClick()}
          >
            <Typography
              style={{
                fontSize: 15,
              }}
            >
              Adjust Rotation
            </Typography>
          </Button>
        )}
        {adjusting && (
          <Button
            data-track="CancelAdjustModelClicked"
            sx={{
              background: "#FFF",
              borderRadius: "12px",
              padding: "4px 16px 4px 16px ",
              minHeight: "36px",
              gap: "8px",
              outline: "0px solid #D0D4D9",
              outlineOffset: "-1px",
              ":hover": {
                background: "#FFF",
                boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={() => handleCancelClick()}
          >
            <Typography
              style={{
                fontSize: 15,
              }}
            >
              Cancel
            </Typography>
          </Button>
        )}
        {adjusting && (
          <Button
            data-track="ApplyAdjustModelClicked"
            onClick={() => handleApplyClick()}
            disabled={applying}
            sx={{
              background: "#3050F5",
              borderRadius: "12px",
              padding: "0px 12px",
              height: "36px",
              gap: "4px",

              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#2946DB",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            {applying ? (
              <CircularProgress size={24} style={{ color: "white" }} />
            ) : (
              <Typography
                style={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                Apply
              </Typography>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ScriberAdjustScene;

function createWhiteBoxWithText(scene: BABYLON.Scene) {
  // Dimensions of the box
  const width = 1;
  const height = 1;
  const depth = 1;

  // Create dynamic texture options
  const dynamicTextureOptions = {
    width: 512,
    height: 512,
    fontSize: 100,
    fontFamily: "Arial",
    color: "black",
    background: "white",
  };

  // Function to create a plane with text
  function createPlaneWithText(
    name: string,
    size: BABYLON.Vector3,
    position: BABYLON.Vector3,
    rotation: BABYLON.Vector3
  ) {
    const plane = BABYLON.MeshBuilder.CreatePlane(
      name,
      { size: size.y },
      scene
    );
    plane.position = position;

    // Set the rotation of the plane
    plane.rotation = rotation;

    // Create dynamic texture
    const dynamicTexture = new BABYLON.DynamicTexture(
      `${name}-texture`,
      dynamicTextureOptions,
      scene
    );

    // Create material with dynamic texture
    const material = new BABYLON.StandardMaterial(`${name}-material`, scene);
    material.emissiveTexture = dynamicTexture;
    material.alpha = 0.5;
    material.disableLighting = true;
    plane.material = material;

    // Write the name on the texture
    dynamicTexture.drawText(
      name,
      null,
      null,
      "bold 64px Arial",
      "white",
      "black",
      true
    );

    return plane;
  }

  const parent = new BABYLON.TransformNode("placerParent", scene);
  // Create planes and set text on each one
  const frontPlane = createPlaneWithText(
    "Back",
    new BABYLON.Vector3(width, height, 0),
    new BABYLON.Vector3(0, 0, depth / 2),
    new BABYLON.Vector3(0, Math.PI, 0)
  );
  frontPlane.parent = parent;
  const backPlane = createPlaneWithText(
    "Front",
    new BABYLON.Vector3(width, height, 0),
    new BABYLON.Vector3(0, 0, -depth / 2),
    new BABYLON.Vector3(0, 0, 0)
  );
  backPlane.parent = parent;
  const leftPlane = createPlaneWithText(
    "Right",
    new BABYLON.Vector3(depth, height, 0),
    new BABYLON.Vector3(-width / 2, 0, 0),
    new BABYLON.Vector3(0, Math.PI / 2, 0)
  );
  leftPlane.parent = parent;
  const rightPlane = createPlaneWithText(
    "Left",
    new BABYLON.Vector3(depth, height, 0),
    new BABYLON.Vector3(width / 2, 0, 0),
    new BABYLON.Vector3(0, -Math.PI / 2, 0)
  );
  rightPlane.parent = parent;
  const topPlane = createPlaneWithText(
    "Top",
    new BABYLON.Vector3(width, 0, depth),
    new BABYLON.Vector3(0, height / 2, 0),
    new BABYLON.Vector3(Math.PI / 2, 0, 0)
  );
  topPlane.parent = parent;
  const bottomPlane = createPlaneWithText(
    "Bottom",
    new BABYLON.Vector3(width, 0, depth),
    new BABYLON.Vector3(0, -height / 2, 0),
    new BABYLON.Vector3(-Math.PI / 2, 0, 0)
  );
  bottomPlane.parent = parent;
  parent.rotation = new BABYLON.Vector3(0, Math.PI, 0);
}

function setGizmo(node: BABYLON.TransformNode, scene: BABYLON.Scene) {
  gizmoManager = new BABYLON.GizmoManager(scene);
  gizmoManager.attachToNode(node);
}

async function createScriberObjectAsync(
  model: StoreItemModel,
  scene: BABYLON.Scene,
  dispatch: Function
) {
  let blob = await getBlobFromIDBAsync(model.url);
  if (!blob) {
    dispatch(setProgressText("Downloading model"));
    dispatch(increaseTotalProgress(model.properties.contentLength));
    blob = await getUserBlobAsync(model.url, undefined, (bytes: number) => {
      dispatch(increaseCurrentProgress(bytes));
    });
    if (blob) await saveBlobToIDBAsync(blob, model.id);
  }
  const objNode = await loadFileAsync(
    new File([blob!], model.name + ".glb", {
      type: "model/gltf-binary",
    }),
    model,
    scene
  );
  return objNode;
}

const loadFileAsync = async (
  file: File,
  model: StoreItemModel,
  scene: BABYLON.Scene
) => {
  const meshes = await createObjectFromFileAsync(scene, file);
  if (!meshes) return null;
  const transformNode = normalizeObjectInSpace(meshes, model, scene);
  const parent = new BABYLON.TransformNode(model.name, scene);
  parent.id = model.id;
  transformNode.parent = parent;
  return transformNode;
};

async function createObjectFromFileAsync(scene: BABYLON.Scene, file: File) {
  try {
    const object = await BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "",
      file,
      scene
    );
    return object.meshes;
  } catch (e) {
    console.log("Bad *.glb: " + file.name, e);
  }
  return null;
}

function normalizeObjectInSpace(
  meshes: BABYLON.AbstractMesh[],
  model: StoreItemModel,
  scene: BABYLON.Scene
) {
  let parent = meshes[0];
  let parentBox = getParentBoundingInfo(meshes);
  let maxSize = new BABYLON.Vector3(
    parentBox.boundingBox.maximumWorld.x - parentBox.boundingBox.minimumWorld.x,
    parentBox.boundingBox.maximumWorld.y - parentBox.boundingBox.minimumWorld.y,
    parentBox.boundingBox.maximumWorld.z - parentBox.boundingBox.minimumWorld.z
  );
  const scale = 1 / Math.max(maxSize.x, maxSize.y, maxSize.z);

  parent.position = new BABYLON.Vector3(
    (parentBox.boundingBox.maximumWorld.x +
      parentBox.boundingBox.minimumWorld.x) /
      2,
    -(
      parentBox.boundingBox.maximumWorld.y +
      parentBox.boundingBox.minimumWorld.y
    ) / 2,
    (parentBox.boundingBox.maximumWorld.z +
      parentBox.boundingBox.minimumWorld.z) /
      2
  );
  parent.rotation = BABYLON.Vector3.Zero();
  const transform = new BABYLON.TransformNode(model.name, scene);
  parent.parent = transform;
  transform.scaling.x = scale;
  transform.scaling.y = scale;
  transform.scaling.z = scale;

  return transform;
}

function getParentBoundingInfo(
  meshes: BABYLON.AbstractMesh[]
): BABYLON.BoundingInfo {
  let min = meshes[0].getBoundingInfo().boundingBox.minimumWorld;
  let max = meshes[0].getBoundingInfo().boundingBox.maximumWorld;

  for (let i = 0; i < meshes.length; i++) {
    let meshMin = meshes[i].getBoundingInfo().boundingBox.minimumWorld;
    let meshMax = meshes[i].getBoundingInfo().boundingBox.maximumWorld;

    min = BABYLON.Vector3.Minimize(min, meshMin);
    max = BABYLON.Vector3.Maximize(max, meshMax);
  }

  return new BABYLON.BoundingInfo(min, max);
}
