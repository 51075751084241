import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../managers/AppInsights";
import SharedTexts from "./SharedTexts/SharedTexts";

function Elements() {
  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      <div
        style={{
          marginTop: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <SharedTexts />
      </div>
    </div>
  );
}

export default withAITracking(reactPlugin, Elements, "Elements", "flex");
