import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

export var reactPlugin = new ReactPlugin();
var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
  autoCapture: true,
};
export var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=ab67be97-8c1b-4332-821e-d5d235a77c3a;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
    disableTelemetry: true,
  },
});
// appInsights.addTelemetryInitializer((envelope) => {
//   console.log(envelope);
// });
appInsights.loadAppInsights();
