import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { useState } from "react";

interface DuplicateItemDialogProps {
  title: string;
  buttonText: string;
  item: any;
  onDuplicate: (item: any, name: string) => void;
  onCancel: () => void;
}

const DuplicateItemDialog = ({
  title,
  buttonText,
  item,
  onDuplicate,
  onCancel,
}: DuplicateItemDialogProps) => {
  const [name, setName] = useState(item.name);

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    switch (event.key) {
      case "Escape":
        event.preventDefault();
        onCancel();
        break;
      case "Enter":
        event.preventDefault();
        handleDuplicate();
        break;
    }
  }

  function handleDuplicate() {
    if (name.trim() === "") {
      return;
    }
    onDuplicate(item, name);
    setName("");
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
  }

  return (
    <Dialog
      open={true}
      onKeyDown={handleKeyDown}
      onClose={onCancel}
      sx={{
        ".MuiDialog-paper": {
          backgroundColor: "#F1F3F4",
          borderRadius: "16px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>{title}</Typography>
          <TextField
            sx={{ marginTop: "20px" }}
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            error={name.trim() === ""}
            helperText={name.trim() === "" ? "Name is required" : " "}
            autoFocus
            onFocus={handleFocus}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <Button
              onClick={onCancel}
              sx={{
                background: "#FFF",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "96px",
                justifyContent: "space-between",
                gap: "4px",

                ":hover": {
                  background: "#FFF",
                  boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: 15,
                  color: "#454E63",
                }}
              >
                Cancel
              </Typography>
            </Button>
            <Button
              onClick={handleDuplicate}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                width: "96px",
                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                ":disabled": { background: "#e9e9ea" },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                {buttonText}
              </Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateItemDialog;
