import { ReactNode, useEffect, useState } from "react";
import { iTextTrack } from "../../../store/types";

type InOutFreezedAnimatorProps = {
  time: number;
  item: iTextTrack;
  children: ReactNode;
};

const InOutFreezedAnimator = ({
  children,
  item,
  time,
}: InOutFreezedAnimatorProps) => {
  const [delayTimeIn, setDelayTimeIn] = useState(0);
  const [delayTimeOut, setDelayTimeOut] = useState(0);

  useEffect(() => {
    setDelayTimeIn(-time);
    setDelayTimeOut(item.timeline.duration - time - item.animation.duration);
  }, [time, item.animation.duration, item.timeline.duration]);

  return (
    <div
      style={{
        animationName: item.animation.template
          ? `${item.animation.template.inStyleName}`
          : "",
        animationDuration: `${item.animation.duration}s`,
        animationPlayState: "paused",
        animationDelay: `${delayTimeIn}s`,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          animationName: item.animation.template
            ? `${item.animation.template.outStyleName}`
            : "",
          animationDuration: `${item.animation.duration}s`,
          animationPlayState: "paused",
          animationDelay: `${delayTimeOut}s`,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default InOutFreezedAnimator;
