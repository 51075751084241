import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  capitalizeFirstLetter,
  getCurrentTimestampUTC,
  getStorageItemFromSharedStorageData,
  getStorageItemFromStorageData,
  preloadBlobsAsync,
  resizeImage,
  timeoutAsync,
} from "../../../../helpers/Helper";
import {
  deleteUserBlobAsync,
  getSharedBlobAsync,
  getSharedJsonAsync,
  getSharedStorageDataAsync,
  getUserJsonAsync,
  getUserStorageDataAsync,
  postUserBlobAsync,
  postUserJsonAsync,
} from "../../../../managers/storage/AzureStorageManager";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getOccupiedStorageSize,
  getUserStorageData,
  setUserStorageData,
} from "../../../../store/storageSlice";
import {
  deleteBlobFromIDBAsync,
  saveBlobToIDBAsync,
} from "../../../../managers/storage/DbManager";
import { StoreItemModel, tabModes } from "../../../../store/types";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "../../../../managers/AppInsights";
import ScriberProjectItem from "./ScriberProjectItem";
import {
  getSelectedScriberProjectId,
  selectScriberLoading,
  setScriberItem,
  setSelectedScriberProjectId,
} from "../../../../store/scriberSlice";
import LeftArrow from "../../../../icons/LeftArrow";
import {
  acquireIdTokenAsync,
  canUserScribeAsync,
  drainCreditAsync,
  drainScriberAttemptAsync,
  getCreditsAvailability,
  getIdTokenAsync,
  getUserSettingsAsync,
} from "../../../../managers/ClientManager";
import { DEMO_SCRIBER_ID, apiUrl } from "../../../../settings/GlobalSettings";
import {
  getUserLeftCredits,
  getUserLeftScribes,
  getUserProjectPhotosLimit,
  getUserScriberLimit,
  getUserStorageLimit,
  getUserSubscriptionLevel,
  getUserSubscriptionLimits,
} from "../../../../store/userSlice";
import { v4 as uuidv4 } from "uuid";
import {
  setCurrentProgress,
  setTotalProgress,
  setUploading,
  setUploadingText,
} from "../../../../store/uploadingSlice";
import ScriberScene from "../../../scene/scriber/ScriberAdjustScene";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import {
  hideCreateVideoDialog,
  showCustomAlert,
} from "../../../../store/dialogSlice";
import {
  resetProgress,
  setProgressText,
  increaseCurrentProgress,
} from "../../../../store/progressSlice";
import { setProject } from "../../../../store/projectSlice";
import { setLoading } from "../../../../store/sceneSlice";
import { setTimelinePlaying } from "../../../../store/timelineSlice";
import { CreateAIProjectFromModel } from "../../../../managers/AICreator";
import {
  demoStarterImagesCountLimitReachedString,
  demoStarterLimitReachedString,
  demoStarterNoCreditsString,
  proTestImagesCountLimitReachedString,
  proTestLimitReachedString,
  proTestNoCreditsString,
} from "../../../../settings/GlobalStrings";
import { setSaving, switchTabTo } from "../../../../store/navigatorSlice";
import { getBlobAsync } from "../../../../managers/storage/StorageManager";

let demoDownloading = false;

const ScriberProject = () => {
  const dispatch = useAppDispatch();
  const userStorageData = useAppSelector(getUserStorageData);
  const selectedScriberProjectId = useAppSelector(getSelectedScriberProjectId);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const occupiedStorageSize = useAppSelector(getOccupiedStorageSize);
  const leftScribes = useAppSelector(getUserLeftScribes);
  const scriberLimit = useAppSelector(getUserScriberLimit);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const userSubscriptionLimits = useAppSelector(getUserSubscriptionLimits);
  const userProjectPhotosLimit = useAppSelector(getUserProjectPhotosLimit);
  const [imageItems, setImageItems] = useState<StoreItemModel[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [config, setConfig] = useState<any>();
  const [storageLimitReached, setStorageLimitReached] = useState(false);
  const abortController = useRef(new AbortController());
  const [state, setState] = useState("none");
  const scriberStoreItemRef = useRef<StoreItemModel>();
  const [buttonLoading, setButtonLoading] = useState(false);
  const scriberLoading = useAppSelector(selectScriberLoading);

  useEffect(() => {
    const asyncWrapper = async () => {
      const result = await canUserScribeAsync();
      if (result) {
        await getUserSettingsAsync(dispatch);
      }
    };
    asyncWrapper();
  }, []);

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !occupiedStorageSize) return;
      setStorageLimitReached(occupiedStorageSize >= storageLimit);
    };

    asyncWraper();
  }, [occupiedStorageSize, storageLimit]);

  useEffect(() => {
    if (selectedScriberProjectId === DEMO_SCRIBER_ID) {
      const runAsync = async () => {
        const previewsStorageData = await getSharedStorageDataAsync(
          `scriber-projects/${selectedScriberProjectId}/previews`
        );
        const storateItems =
          getStorageItemFromSharedStorageData(previewsStorageData);
        setImageItems(storateItems);
      };
      runAsync();
    } else {
      setImageItems(
        getStorageItemFromStorageData(
          `scriber-projects/${selectedScriberProjectId}/previews`,
          userStorageData
        )
      );
    }
    const scriberStoreModel = getStorageItemFromStorageData(
      `scribers`,
      userStorageData
    ).find((c) => c.name === selectedScriberProjectId);
    const configStoreItem = getStorageItemFromStorageData(
      `configs/scribers`,
      userStorageData
    ).find((c) => c.name === selectedScriberProjectId);
    setState(configStoreItem ? "preview3d" : "photos");
    if (scriberStoreModel) {
      scriberStoreItemRef.current = scriberStoreModel;
      dispatch(setScriberItem(scriberStoreModel));
    }

    const runAsync = async (signal: AbortSignal) => {
      if (signal.aborted) return;

      if (configStoreItem) {
        const config = await getUserJsonAsync(
          `configs/scribers/${selectedScriberProjectId}.json`
        );
        setConfig(config);
        if (config?.status !== "done") {
          await timeoutAsync(10_000);
          runAsync(abortController.current.signal);
        }
      }
    };
    abortController.current?.abort();
    abortController.current = new AbortController();
    runAsync(abortController.current.signal);
    return () => abortController.current?.abort();
  }, [userStorageData, selectedScriberProjectId]);

  const handleDeleteImage = async (model: StoreItemModel) => {
    if (selectedScriberProjectId === DEMO_SCRIBER_ID) return;
    setImageItems(imageItems.filter((i) => i.name !== model.name));
    deleteUserBlobAsync(model.url);
    deleteBlobFromIDBAsync(model.url);

    const images = getStorageItemFromStorageData(
      `scriber-projects/${selectedScriberProjectId}/images`,
      userStorageData
    );
    const fullImage = images.find((i) => i.name === model.name);

    if (fullImage) {
      deleteUserBlobAsync(fullImage.url);
      deleteBlobFromIDBAsync(fullImage.url);
    }
  };

  const handleAddImages = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadImages = async (images: File[]) => {
    if (!images?.[0]) return;

    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );
      return;
    }

    if (
      userProjectPhotosLimit &&
      images.length + imageItems.length > userProjectPhotosLimit
    ) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "demo" || subscriptionLevel === "starter"
            ? demoStarterImagesCountLimitReachedString
            : proTestImagesCountLimitReachedString
        )
      );
      return;
    }

    let progress = 0;
    dispatch(setCurrentProgress(0));
    dispatch(setTotalProgress(images.length));
    dispatch(setUploadingText("Images Uploaded"));
    await timeoutAsync(300);
    dispatch(setUploading(true));

    const promises = images.map(async (image) => {
      const imageName = uuidv4();

      const resizedBlob: Blob = await new Promise((resolve, reject) => {
        resizeImage(image, 288, 512, (result: Blob) => {
          result ? resolve(result) : reject(new Error("Error resizing image"));
        });
      });

      const tasks = [
        saveBlobToIDBAsync(
          resizedBlob,
          `scriber-projects/${selectedScriberProjectId}/previews/${imageName}.webp`
        ),
        postUserBlobAsync(
          resizedBlob,
          `scriber-projects/${selectedScriberProjectId}/previews/${imageName}.webp`,
          false
        ),
        postUserBlobAsync(
          image,
          `scriber-projects/${selectedScriberProjectId}/images/${imageName}.${
            image.name.split(".")[1]
          }`,
          false
        ),
      ];

      await Promise.all(tasks);
      dispatch(setCurrentProgress(++progress));
    });
    await Promise.all(promises);

    dispatch(setUserStorageData(await getUserStorageDataAsync()));
    dispatch(setUploading(false));
  };

  const handleCreateModelAsync = async () => {
    if (selectedScriberProjectId === DEMO_SCRIBER_ID) {
      const config = await getUserJsonAsync(
        `configs/scribers/${DEMO_SCRIBER_ID}.json`
      );
      if (config) return;
      if (demoDownloading) return;
      setButtonLoading(true);
      demoDownloading = true;
      const scriberBlob = await getSharedBlobAsync(
        `scribers/${DEMO_SCRIBER_ID}.glb`
      );
      await postUserBlobAsync(
        scriberBlob,
        `scribers/${DEMO_SCRIBER_ID}.glb`,
        false
      );
      const configBlob = await getSharedJsonAsync(
        `configs/scribers/${DEMO_SCRIBER_ID}.json`
      );
      await postUserJsonAsync(
        configBlob,
        `configs/scribers/${DEMO_SCRIBER_ID}.json`
      );
      demoDownloading = false;

      setButtonLoading(false);
      return;
    }

    setButtonLoading(true);
    if (!(await canUserScribeAsync())) {
      dispatch(showCustomAlert("Oops! no attempts left"));

      setButtonLoading(false);
      return;
    }
    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );

      setButtonLoading(false);
      return;
    }

    if (imageItems.length < 100) {
      dispatch(
        showCustomAlert(
          "Unfortunately, we aren't able to create model from less than 100 photos."
        )
      );

      setButtonLoading(false);
      return;
    }

    const idToken = await acquireIdTokenAsync();

    const json = {
      detail: 4,
      featureSensitivity: 0,
      id: selectedScriberProjectId,
      date: getCurrentTimestampUTC(),
      status: "queue",
      errorMsg: "",
      locked: false,
      idToken: idToken,
      mediaPath: `scriber-projects/${selectedScriberProjectId}/images`,
      maxResolution: userSubscriptionLimits!.scriberPhotos.maxResolution,
      nodeVersion: 1,
      shared: false,
      rotation: [0, 0, 0],
      adjusted: false,
      processingTimestamp: 0,
      subscriptionLevel: subscriptionLevel,
    };

    await postUserJsonAsync(
      json,
      `configs/scribers/${selectedScriberProjectId}.json`
    );

    await fetch(`${apiUrl}/api/ScriberQueue`, {
      method: "POST",
      body: JSON.stringify(json),
    });

    await drainScriberAttemptAsync();
    await getUserSettingsAsync(dispatch);

    appInsights.trackEvent(
      { name: "Scribe Uploaded" },
      { customProperties: json }
    );

    setButtonLoading(false);
  };

  const handleBack = () => {
    if (config) {
      setState((prev) => {
        if (prev === "preview3d") {
          dispatch(setSelectedScriberProjectId(""));
          return "none";
        }
        return "preview3d";
      });
    } else {
      dispatch(setSelectedScriberProjectId(""));
    }
  };

  async function handleDownload() {
    if (!scriberStoreItemRef.current) return;
    dispatch(setSaving(true));

    const fileName = scriberStoreItemRef.current.url.split("/").pop();
    const blob = await getBlobAsync(scriberStoreItemRef.current);
    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(blob!);
    anchorElement.download = fileName!;
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
    dispatch(setSaving(false));
  }

  const handleCreateAIVideo = async () => {
    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );
      return;
    }

    if (!(await getCreditsAvailability())) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestNoCreditsString
            : demoStarterNoCreditsString
        )
      );
      return;
    }

    dispatch(hideCreateVideoDialog());
    dispatch(setTimelinePlaying(false));
    dispatch(resetProgress());
    dispatch(setProgressText("Generating"));
    dispatch(setLoading(true));
    await timeoutAsync(300);
    dispatch(switchTabTo(tabModes.home));
    await timeoutAsync(1000);

    await drainCreditAsync(dispatch);

    const project = await CreateAIProjectFromModel(
      scriberStoreItemRef.current!,
      userStorageData
    );

    await preloadBlobsAsync(project, dispatch, (bytes: number) => {
      dispatch(increaseCurrentProgress(bytes));
    });
    dispatch(setProject(project));
    await timeoutAsync(1000);
    dispatch(setLoading(false));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: "0px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          style={{
            background: "#F1F3F4",
            borderRadius: "12px",
            width: "48px",
            height: "36px",
            marginTop: "2px",
            marginRight: "16px",
            gap: "8px",
            opacity: scriberLoading ? "0.5" : "1",
            transition: "opacity 0.3s ease-in-out",
            pointerEvents: scriberLoading ? "none" : "all",
          }}
          onClick={handleBack}
        >
          <LeftArrow />
        </Button>
        {state === "preview3d" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 900,
                  color: "#373E4E",
                }}
              >
                Preview
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <Button
                onClick={() => setState("photos")}
                sx={{
                  background: "#FFF",
                  borderRadius: "8px",
                  border: "2px solid #4261FF",
                  padding: "10px 18px",
                  height: "36px",
                  width: "156px",
                  opacity: scriberLoading ? "0.5" : "1",
                  transition: "opacity 0.3s ease-in-out",
                  pointerEvents: scriberLoading ? "none" : "all",

                  ":hover": {
                    background: "#FFF",
                    boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                  },
                  ":active": {
                    background: "#FFF",
                    boxShadow: "none",
                  },
                  "& .MuiTouchRipple-root": {
                    display: "none",
                  },
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#3050F5",
                    fontWeight: 700,
                  }}
                >
                  Photos
                </Typography>
              </Button>
              {subscriptionLevel !== "demo" && (
                <Button
                  disabled={
                    config?.status === "processing" ||
                    config?.status === "queue" ||
                    config?.status === "error"
                  }
                  onClick={handleDownload}
                  sx={{
                    background: "#3050F5",
                    borderRadius: "8px",
                    padding: "10px 18px",
                    height: "36px",
                    width: "156px",
                    opacity: scriberLoading ? "0.5" : "1",
                    transition: "opacity 0.3s ease-in-out",
                    pointerEvents: scriberLoading ? "none" : "all",
                    ":hover": {
                      background: "#3050F5",
                      boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                    },
                    ":active": {
                      background: "#3050F5",
                      boxShadow: "none",
                    },
                    ":disabled": {
                      background: "#b0b0c0",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#FFF",
                      fontWeight: 700,
                    }}
                  >
                    Download Model
                  </Typography>
                </Button>
              )}
              <Button
                disabled={
                  !config?.relativeBox ||
                  config?.status === "processing" ||
                  config?.status === "queue" ||
                  config?.status === "error"
                }
                onClick={handleCreateAIVideo}
                sx={{
                  background: "#3050F5",
                  borderRadius: "8px",
                  padding: "10px 18px",
                  height: "36px",
                  width: "156px",
                  opacity: scriberLoading ? "0.5" : "1",
                  transition: "opacity 0.3s ease-in-out",
                  pointerEvents: scriberLoading ? "none" : "all",
                  ":hover": {
                    background: "#3050F5",
                    boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                  },
                  ":active": {
                    background: "#3050F5",
                    boxShadow: "none",
                  },
                  ":disabled": {
                    background: "#b0b0c0",
                  },
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#FFF",
                    fontWeight: 700,
                  }}
                >
                  Create Video
                </Typography>
              </Button>
            </div>
          </div>
        )}
        {state === "photos" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: 900,
                  color: "#373E4E",
                }}
              >
                {`Images (${imageItems.length} / ${userProjectPhotosLimit})`}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "16px",
              }}
            >
              <Typography>
                Left: {leftScribes} of {scriberLimit}
                <span style={{ color: "rgba(69, 78, 99, 0.50)" }}>/day</span>
              </Typography>
              {selectedScriberProjectId !== DEMO_SCRIBER_ID && (
                <Button
                  onClick={handleAddImages}
                  sx={{
                    background: "#FFF",
                    borderRadius: "8px",
                    border: "2px solid #4261FF",
                    padding: "10px 18px",
                    height: "36px",
                    width: "156px",
                    ":hover": {
                      background: "#FFF",
                      boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                    },
                    ":active": {
                      background: "#FFF",
                      boxShadow: "none",
                    },
                    "& .MuiTouchRipple-root": {
                      display: "none",
                    },
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#3050F5",
                      fontWeight: 700,
                    }}
                  >
                    Add Image(s)
                  </Typography>
                </Button>
              )}
              <Button
                disabled={
                  buttonLoading ||
                  config?.status === "processing" ||
                  config?.status === "queue"
                }
                onClick={handleCreateModelAsync}
                sx={{
                  background: "#3050F5",
                  borderRadius: "8px",
                  padding: "10px 18px",
                  height: "36px",
                  width: "156px",
                  ":hover": {
                    background: "#3050F5",
                    boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                  },
                  ":active": {
                    background: "#3050F5",
                    boxShadow: "none",
                  },
                  ":disabled": {
                    background: "#b0b0c0",
                  },
                }}
              >
                {!buttonLoading && (
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#FFF",
                      fontWeight: 700,
                    }}
                  >
                    Create 3D Model
                  </Typography>
                )}
                {buttonLoading && (
                  <CircularProgress size="16px" style={{ color: "white" }} />
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", overflow: "hidden", height: "100%" }}>
        {state === "preview3d" && (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            {config?.status === "done" && <ScriberScene />}
            {config && config.status !== "done" && (
              <div
                style={{
                  width: "calc(100% - 30px)",
                  height: "calc(100% - 30px)",
                  top: "15px",
                  left: "15px",
                  backgroundColor: "white",
                  borderRadius: "16px",
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SettingsSuggestRoundedIcon
                  style={{ color: "#e1e3e4", fontSize: "256px" }}
                />
                <Typography style={{ color: "#e1e3e4", fontSize: "40px" }}>
                  {capitalizeFirstLetter(config.status)}...
                </Typography>
              </div>
            )}
          </div>
        )}
        {state === "photos" && (
          <div
            style={{
              width: "100%",
              padding: "0px 16px",
              marginTop: "16px",
              overflowY: "auto",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                gap: "12px",
                marginBottom: "16px",
              }}
            >
              {imageItems.map((model) => (
                <ScriberProjectItem
                  key={model.id}
                  item={model}
                  demo={selectedScriberProjectId === DEMO_SCRIBER_ID}
                  onDelete={() => handleDeleteImage(model)}
                />
              ))}
            </Grid>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={inputRef}
        accept=".jpg,.jpeg,.heif,.heic"
        multiple={true}
        onChange={async () => {
          if (inputRef.current && inputRef.current.files) {
            await uploadImages([...inputRef.current.files]);
            inputRef.current.value = "";
          }
        }}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default withAITracking(
  reactPlugin,
  ScriberProject,
  "UploadedModels",
  "flex"
);
