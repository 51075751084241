import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../store/hooks";
import { switchTabTo } from "../../../../store/navigatorSlice";
import { getProject, removeText } from "../../../../store/projectSlice";
import {
  getTimelineCaretTime,
  getTimelinePlaying,
  getTimelineScale,
  setTimelineCaretTextTrack,
} from "../../../../store/timelineSlice";
import { iTextTrack, tabModes } from "../../../../store/types";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import TextTrackItem from "./TextTrackItem";

type TextTrackProps = { duration?: number };

function TextTrack({ duration }: TextTrackProps) {
  const dispatch = useDispatch();
  const project = useAppSelector(getProject);
  const caretTime = useAppSelector(getTimelineCaretTime);
  const playing = useAppSelector(getTimelinePlaying);
  const scaleRatio = useAppSelector(getTimelineScale);
  const [itemToDelete, setItemToDelete] = useState<iTextTrack | undefined>();

  useEffect(() => {
    if (!project.texts) return;
    if (!playing) {
      const caretTexts = [];
      for (const text of project.texts) {
        if (
          text.timeline.offset <= caretTime &&
          text.timeline.duration + text.timeline.offset >= caretTime
        ) {
          caretTexts.push({
            id: text.id,
            time: caretTime - text.timeline.offset,
          });
        }
      }
      dispatch(setTimelineCaretTextTrack(caretTexts));
    }
  }, [caretTime, playing, project]);

  const handleCloseDeleteDialog = () => {
    setItemToDelete(undefined);
  };

  const handleShowDeleteDialog = (t: iTextTrack) => {
    setItemToDelete(t);
  };

  const handleDelete = () => {
    dispatch(removeText(itemToDelete?.id));
  };

  const handleAddText = () => {
    dispatch(switchTabTo(tabModes.elements));
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
        height: "32px",
      }}
    >
      <Box sx={{ minWidth: "32px", height: "32px" }}></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          minWidth: `${duration! * scaleRatio}px`,
          height: "32px",
        }}
      >
        {project.texts && project.texts.length > 0 ? (
          project.texts?.map((t) => (
            <TextTrackItem
              key={t.id}
              item={t}
              onDelete={() => handleShowDeleteDialog(t)}
            />
          ))
        ) : (
          <Button
            onClick={handleAddText}
            sx={{
              backgroundColor: "#FFF",
              marginBottom: "8px",
              borderRadius: "8px",
              gap: "14px",
              ":hover": {
                backgroundColor: "#e6e6e6",
              },
              ":active": {
                backgroundColor: "#cfcfcf",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            Add Text
            <img src="assets/svg/Union.svg" width="12px" height="12px" />
          </Button>
        )}
      </Box>
      <Box sx={{ minWidth: "32px", height: "32px" }}></Box>
      {itemToDelete && (
        <ConfirmationDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete it?"
          confirmButtonText="Delete"
          item="text"
          onConfirm={handleDelete}
          onCancel={handleCloseDeleteDialog}
        />
      )}
    </Box>
  );
}

export default TextTrack;
