import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { TimelineState } from "./types";

const initialState: TimelineState = {
  time: "",
  frame: 0,
  playing: false,
  sequenceFinish: false,
  scale: { minRatio: 0, maxRatio: 600, currentScale: 0 },
  caretTime: 0,
  caretVideoTrack: { id: "", frame: 0 },
  caretTextTrack: [],
};

export const timelineSlice = createSlice({
  name: "timeline",
  initialState,
  reducers: {
    setTimelineTime: (state, action) => {
      state.time = action.payload;
    },
    setTimelineFrame: (state, action) => {
      state.frame = action.payload;
    },
    setTimelineSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setTimelinePlaying: (state, action) => {
      state.playing = action.payload;
    },
    setTimelineSequenceFinished: (state) => {
      state.sequenceFinish = !state.sequenceFinish;
    },
    setPlayerUrl: (state, action) => {
      state.playerUrl = action.payload;
    },
    setTimelineMinRatio: (state, action) => {
      state.scale.minRatio = action.payload;
    },
    setTimelineScale: (state, action) => {
      state.scale.currentScale = action.payload;
    },
    setTimelineCaretTime: (state, action) => {
      state.caretTime = action.payload;
    },
    setTimelineCaretVideoTrack: (state, action) => {
      state.caretVideoTrack = action.payload;
    },
    setTimelineCaretTextTrack: (state, action) => {
      state.caretTextTrack = action.payload;
    },
  },
});

export const getTimelineTime = (state: RootState) => state.timeline.time;
export const getTimelineFrame = (state: RootState) => state.timeline.frame;
export const getTimelineSelectedItem = (state: RootState) =>
  state.timeline.selectedItem;
export const getTimelinePlaying = (state: RootState) => state.timeline.playing;
export const getTimelineSequenceFinish = (state: RootState) =>
  state.timeline.sequenceFinish;
export const getPlayerUrl = (state: RootState) => state.timeline.playerUrl;
export const getTimelineScale = (state: RootState) => {
  let ratio = 1;
  switch (state.timeline.scale.currentScale) {
    case 0.25:
      ratio = 2;
      break;
    case 0.5:
      ratio = 4;
      break;
    case 0.75:
      ratio = 8;
      break;
  }
  if (state.timeline.scale.currentScale === 1) {
    return state.timeline.scale.maxRatio;
  } else {
    const result = state.timeline.scale.minRatio * ratio;
    return result > state.timeline.scale.maxRatio
      ? state.timeline.scale.maxRatio
      : result;
  }
};
export const getTimelineCaretTime = (state: RootState) =>
  state.timeline.caretTime;
export const getTimelineCaretVideoTrack = (state: RootState) =>
  state.timeline.caretVideoTrack;
export const getTimelineCaretTextTrack = (state: RootState) =>
  state.timeline.caretTextTrack;

export const {
  setTimelineTime,
  setTimelineFrame,
  setTimelineSelectedItem,
  setTimelinePlaying,
  setTimelineSequenceFinished,
  setPlayerUrl,
  setTimelineMinRatio,
  setTimelineScale,
  setTimelineCaretTime,
  setTimelineCaretVideoTrack,
  setTimelineCaretTextTrack,
} = timelineSlice.actions;

export default timelineSlice.reducer;
