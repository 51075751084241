import { Button, Popover, Typography } from "@mui/material";
import DownArrow from "../../icons/DownArrow";
import { useState } from "react";
import {
  capitalizeFirstLetter,
  getProjectDuration,
  getStorageItemFromSharedStorageData,
  timeoutAsync,
} from "../../helpers/Helper";
import { getProject, setProjectAudio } from "../../store/projectSlice";
import { setSceneLoading } from "../../store/sceneSlice";
import { setTimelinePlaying } from "../../store/timelineSlice";
import { AudioTrack } from "../../store/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getSharedStorageDataAsync } from "../../managers/storage/AzureStorageManager";
import { animationsFps } from "../../settings/GlobalSettings";
import { setGeneratingAudio, setGeneratingText } from "../../store/aiSlice";
import {
  drainCreditAsync,
  getUserSettingsAsync,
} from "../../managers/ClientManager";
import {
  proTestNoCreditsString,
  demoStarterNoCreditsString,
} from "../../settings/GlobalStrings";
import { showCustomAlert } from "../../store/dialogSlice";
import {
  getUserLeftCredits,
  getUserSubscriptionLevel,
} from "../../store/userSlice";

const genres: string[] = [
  "random",
  "pop",
  "rock",
  "folk",
  "electro",
  "acoustic",
  "jazz",
];

const RegenerateAudioButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const leftCredits = useAppSelector(getUserLeftCredits);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState<string>(genres[0]);

  const handleCreateAIAudio = async () => {
    if (!leftCredits || leftCredits <= 0) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestNoCreditsString
            : demoStarterNoCreditsString
        )
      );
      return;
    }

    dispatch(setTimelinePlaying(false));
    dispatch(setGeneratingText("Generating Audio"));
    dispatch(setGeneratingAudio(true));
    dispatch(setSceneLoading(true));
    drainCreditAsync(dispatch);

    const audioStorageData = await getSharedStorageDataAsync(
      selectedGenre === "random" ? "ai-audios" : `ai-audios/${selectedGenre}`
    );
    const audios = getStorageItemFromSharedStorageData(audioStorageData);
    const i = Math.floor(Math.random() * audios.length);
    const projectDuration = getProjectDuration(project) / animationsFps;
    dispatch(
      setProjectAudio({
        source: audios[i],
        trims: [0, 30.0 - projectDuration >= 0.0 ? 30.0 - projectDuration : 0],
        duration: 30,
        offset: 0,
      } as AudioTrack)
    );
    await timeoutAsync(500);
    dispatch(setSceneLoading(false));
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "calc(100% - 32px)",
        gap: "8px",
      }}
    >
      <Button
        sx={{
          background: "#FFF",
          borderRadius: "12px",
          border: "2px solid #4261FF",
          padding: "10px 18px",
          height: "36px",
          ":hover": {
            background: "#FFF",
            boxShadow: "0px 8px 8px 0px rgba(48, 80, 245, 0.24)",
          },
          ":active": {
            background: "#FFF",
            boxShadow: "none",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
        onClick={handleCreateAIAudio}
      >
        <Typography
          sx={{
            color: "#3050F5",
          }}
        >
          Music
        </Typography>
      </Button>
      <Button
        onClick={handleOpenPopover}
        sx={{
          background: "#F1F3F4",
          borderRadius: "12px",
          padding: "0px 12px",
          height: "36px",
          width: "calc(100% - 32px)",
          gap: "4px",
          justifyContent: "space-between",
          boxShadow: popoverOpen
            ? "0px 10px 10px 0px rgba(0, 0, 0, 0.08)"
            : "none",
          ":hover": {
            background: "#F1F3F4",
            boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
          }}
        >
          {capitalizeFirstLetter(selectedGenre)}
        </Typography>
        <DownArrow />
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          marginTop: "5px",
          ".MuiPopover-paper": {
            borderRadius: "12px",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            padding: "8px",
          }}
        >
          {genres.map((g, i) => (
            <Button
              key={g}
              sx={{
                borderRadius: "12px",
                justifyContent: "start",

                background: "#FFF",
                ":hover": {
                  background: "#E3E5E5",
                },
                ":active": {
                  background: "#F1F3F4",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
              onClick={() => {
                setSelectedGenre(g);
                setPopoverOpen(false);
              }}
            >
              {capitalizeFirstLetter(g)}
            </Button>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default RegenerateAudioButton;
