import { Box, Button, CircularProgress, Typography } from "@mui/material";
import {
  animationsFps,
  outputVideoFps,
  outputVideoSize,
  previewSize,
} from "../../../settings/GlobalSettings";
import { useAppSelector } from "../../../store/hooks";
import { getProject, setProjectRenderUrl } from "../../../store/projectSlice";
import * as BABYLON from "babylonjs";
import * as FFMPEG from "../../../managers/VideoManager";
import {
  getIntWithZeros,
  getProjectDuration,
  timeoutAsync,
} from "../../../helpers/Helper";
import { useDispatch } from "react-redux";
import {
  getAppMode,
  navigateTo,
  switchTabTo,
} from "../../../store/navigatorSlice";
import { setProgress } from "../../../store/sceneSlice";
import { postUserBlobAsync } from "../../../managers/storage/AzureStorageManager";
import {
  getTimelineCaretVideoTrack,
  getTimelineFrame,
  getTimelineSelectedItem,
  setTimelinePlaying,
} from "../../../store/timelineSlice";
import { useEffect, useRef, useState } from "react";
import { appModes } from "../../../store/types";
import { getLumiereSceneById } from "../LumiereScene";
import {
  getUserStorageLimit,
  getUserSubscriptionLevel,
} from "../../../store/userSlice";
import ResolutionButton from "../../controls/ResolutionButton";
import { getOccupiedStorageSize } from "../../../store/storageSlice";
import TextOverlay, { TextOverlayHandles } from "./TextOverlay";
import { getEditorViewVisible } from "../../../store/editorSlice";
import { getBlobFromIDBAsync } from "../../../managers/storage/DbManager";
import { showCustomAlert } from "../../../store/dialogSlice";
import {
  demoStarterLimitReachedString,
  proTestLimitReachedString,
} from "../../../settings/GlobalStrings";
import { getBackImageUrl } from "../../../store/renderSlice";

const RenderPanel = () => {
  const dispatch = useDispatch();
  const project = useAppSelector(getProject);
  const appMode = useAppSelector(getAppMode);
  const currentFrame = useAppSelector(getTimelineFrame);
  const selectedItem = useAppSelector(getTimelineSelectedItem);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const editorViewVisible = useAppSelector(getEditorViewVisible);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const occupiedStorageSize = useAppSelector(getOccupiedStorageSize);
  const [storageLimitReached, setStorageLimitReached] = useState(false);
  const [preview, setPreview] = useState<string | undefined>();
  const [textPreview, setTextPreview] = useState<string | undefined>();
  const caretVideoTrack = useAppSelector(getTimelineCaretVideoTrack);
  const abortControllerRef = useRef(new AbortController());
  const textOverlayRef = useRef<TextOverlayHandles | null>(null);
  let cachedProgress: number;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { willReadFrequently: true })!;
  const baseImage = new Image();
  const overlayImage = new Image();
  const backImageUrl = useAppSelector(getBackImageUrl);

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !occupiedStorageSize) return;
      setStorageLimitReached(occupiedStorageSize >= storageLimit);
    };

    asyncWraper();
  }, [occupiedStorageSize, storageLimit]);

  async function addBackgroundAndSetPreview(
    data: string,
    backImageUrl: string,
    outputVideoSize: { width: number; height: number }
  ) {
    // Create a new canvas element
    const canvas = document.createElement("canvas");
    canvas.width = outputVideoSize.width;
    canvas.height = outputVideoSize.height;
    const ctx = canvas.getContext("2d")!;

    // Load the background image
    const backgroundImage = new Image();
    backgroundImage.src = backImageUrl;
    await new Promise((resolve) => (backgroundImage.onload = resolve));

    // Draw the background image with 'cover' effect
    const hRatio = canvas.width / backgroundImage.width;
    const vRatio = canvas.height / backgroundImage.height;
    const ratio = Math.max(hRatio, vRatio);
    const centerShift_x = (canvas.width - backgroundImage.width * ratio) / 2;
    const centerShift_y = (canvas.height - backgroundImage.height * ratio) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      backgroundImage,
      0,
      0,
      backgroundImage.width,
      backgroundImage.height,
      centerShift_x,
      centerShift_y,
      backgroundImage.width * ratio,
      backgroundImage.height * ratio
    );

    // Draw the screenshot on top of the background
    const image = new Image();
    image.src = data;
    await new Promise((resolve) => (image.onload = resolve));
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to a data URL
    const finalImage = canvas.toDataURL("image/png");

    return finalImage;
  }

  const renderAsync = async () => {
    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );
      return;
    }
    dispatch(setTimelinePlaying(false));
    await timeoutAsync(300);
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    dispatch(navigateTo(appModes.render));
    getLumiereSceneById(caretVideoTrack.id)?.toggleRenderLoop(false);
    const watermarked = subscriptionLevel === "demo";
    await FFMPEG.loadFfmpegAsync();
    let frameCounter: number = 0;
    let previewData: string;
    const projectDuration = getProjectDuration(project);
    const previewFrame = Math.round(projectDuration / 3);
    const frameInc = outputVideoFps === 30 ? 2 : 1;
    const hasText = project.texts && project.texts.length > 0;
    for (const sequence of project.sequences) {
      await timeoutAsync(400);
      const lumiereScene = getLumiereSceneById(sequence.id)!;
      lumiereScene.engine.setSize(
        outputVideoSize.width,
        outputVideoSize.height
      );
      lumiereScene.engine.runRenderLoop(() => {});
      await timeoutAsync(400);
      for (
        let i = sequence.trims[0];
        i < sequence.duration - sequence.trims[1];
        i += frameInc
      ) {
        // const startTime = performance.now();
        //babylon render
        lumiereScene.playFrameScene(i);
        lumiereScene.render();
        await timeoutAsync(40);
        let data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(
          lumiereScene.engine,
          lumiereScene.mainCamera,
          {
            width: outputVideoSize.width,
            height: outputVideoSize.height,
          }
        );
        if (backImageUrl) {
          data = await addBackgroundAndSetPreview(
            data,
            backImageUrl,
            outputVideoSize
          );
        }
        setPreview(data);
        //text overlay render
        if (hasText) {
          const textOverlayData = await textOverlayRef.current!.takeShotAsync(
            frameCounter
          );
          setTextPreview(textOverlayData);
          data = await overlayImagesAsync(data, textOverlayData!);
        }
        //naming
        const fileName = `img${getIntWithZeros(frameCounter)}.png`;
        if (frameCounter === previewFrame) {
          previewData =
            await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(
              lumiereScene.engine,
              lumiereScene.mainCamera,
              { width: previewSize.width, height: previewSize.height }
            );
        }
        await FFMPEG.addImageAsync(fileName, data!);
        //progress
        setTotalProgress(Math.floor((frameCounter / projectDuration) * 100));
        if (signal.aborted) {
          lumiereScene.engine.stopRenderLoop();
          setPreview(undefined);
          FFMPEG.disposeAsync();
          finishRender();
          return;
        }
        frameCounter += frameInc;
        // const endTime = performance.now();
        // const execTime = endTime - startTime;
        // console.log(`All shot took: ${execTime} milliseconds`);
      }
      lumiereScene.engine.stopRenderLoop();
    }
    setPreview(undefined);
    if (project.audio) {
      let blob = await getBlobFromIDBAsync(project.audio.source.url);
      await FFMPEG.addSoundAsync(blob!, project.audio);
    } else {
      FFMPEG.disableAudio();
    }
    if (watermarked) {
      FFMPEG.enableWatermark();
    }
    const videoBlob = await FFMPEG.renderAsync(projectDuration / animationsFps);
    await FFMPEG.disposeAsync();

    if (!videoBlob) {
      return;
    }

    await postUserBlobAsync(videoBlob!, `renders/${project.name}.mp4`, true);
    dispatch(setProjectRenderUrl(`renders/${project.name}.mp4`));
    dispatch(switchTabTo(appModes.videoPreview));
    finishRender();
  };

  const finishRender = () => {
    setTextPreview(undefined);
    dispatch(navigateTo(appModes.sequencer));
    setTotalProgress(0);
    getLumiereSceneById(caretVideoTrack.id)?.toggleRenderLoop(true);
  };

  const setTotalProgress = (progress: number) => {
    if (progress !== cachedProgress) {
      cachedProgress = progress;
      dispatch(setProgress(progress));
    }
  };

  const overlayImagesAsync = (
    baseData: string,
    overlayData: string
  ): Promise<string> => {
    return new Promise((resolve) => {
      let loadedCount = 0;

      const checkAndDraw = () => {
        loadedCount++;
        if (loadedCount === 2) {
          canvas.width = baseImage.width;
          canvas.height = baseImage.height;
          ctx.clearRect(0, 0, canvas.width, canvas.height); // clear canvas
          ctx.drawImage(baseImage, 0, 0);
          ctx.drawImage(overlayImage, 0, 0);

          resolve(canvas.toDataURL());
        }
      };
      baseImage.onload = checkAndDraw;
      overlayImage.onload = checkAndDraw;
      baseImage.src = baseData;
      overlayImage.src = overlayData;
    });
  };

  async function renderFrameAsync() {
    if (!selectedItem?.id) return;
    const lumiereScene = getLumiereSceneById(selectedItem.id);
    if (!lumiereScene) return;
    dispatch(navigateTo(appModes.render));
    await timeoutAsync(400);
    lumiereScene.engine.setSize(outputVideoSize.width, outputVideoSize.height);
    lumiereScene.engine.runRenderLoop(() => {});
    await timeoutAsync(400);

    lumiereScene.playFrameScene(currentFrame);
    lumiereScene.render();
    await timeoutAsync(40);

    const data = await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(
      lumiereScene.engine,
      lumiereScene.mainCamera,
      {
        width: outputVideoSize.width,
        height: outputVideoSize.height,
      }
    );

    lumiereScene.engine.stopRenderLoop();
    const anchorElement = document.createElement("a");
    anchorElement.href = data;
    anchorElement.download = "screenshot.png";
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
    dispatch(navigateTo(appModes.sequencer));
  }

  return (
    <>
      {appMode === appModes.sequencer && project.sequences && (
        <Box
          className="render-panel"
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <ResolutionButton />
          {editorViewVisible && (
            <Button
              data-track="ScreenshotClicked"
              onClick={() => renderFrameAsync()}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                Screenshot
              </Typography>
            </Button>
          )}
          <Button
            data-track="RenderClicked"
            onClick={renderAsync}
            sx={{
              background: "#3050F5",
              borderRadius: "12px",
              padding: "0px 12px",
              height: "36px",
              gap: "4px",

              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#2946DB",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                color: "#FFF",
                fontWeight: 600,
              }}
            >
              Render
            </Typography>
          </Button>
        </Box>
      )}
      {appMode === appModes.render && (
        <>
          <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
            <Button
              data-track="RenderCancelClicked"
              onClick={() => abortControllerRef.current.abort()}
              sx={{
                background: "#3050F5",
                borderRadius: "12px",
                padding: "0px 12px",
                height: "36px",
                gap: "4px",

                ":hover": {
                  background: "#3050F5",
                  boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
                },
                ":active": {
                  background: "#2946DB",
                  boxShadow: "none",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  color: "#FFF",
                  fontWeight: 600,
                }}
              >
                Cancel
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              pointerEvents: "none",
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {preview ? (
              <Box
                sx={{
                  width: "75%",
                  height: "75%",
                  position: "relative",
                }}
              >
                <img
                  src={preview}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
                <img
                  src={textPreview}
                  style={{
                    display: textPreview ? "inherit" : "none",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            ) : (
              <CircularProgress />
            )}
            <TextOverlay ref={textOverlayRef} />
          </Box>
        </>
      )}
    </>
  );
};

export default RenderPanel;
