import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import { timeoutAsync } from "../../helpers/Helper";
import {
  getUserBlobAsync,
  getUserJsonAsync,
  postUserJsonAsync,
} from "../storage/AzureStorageManager";
import { previewSize } from "../../settings/GlobalSettings";
import { getBlobFromIDBAsync } from "../storage/DbManager";
import { RelativeBox } from "../../store/types";
import { scriberConfig } from "../../components/scene/types";

let canvas: HTMLCanvasElement;
let engine: BABYLON.Engine;
let scene: BABYLON.Scene;
let camera: BABYLON.ArcRotateCamera;

function initPreviewGenerator() {
  canvas = document.createElement("canvas");
  canvas.width = previewSize.width;
  canvas.height = previewSize.height;
  engine = new BABYLON.Engine(
    canvas.getContext("webgl2") as WebGL2RenderingContext,
    true
  );
  engine.setSize(previewSize.width, previewSize.height);
  scene = new BABYLON.Scene(engine);
  scene.clearColor = new BABYLON.Color4(1, 1, 1, 1);
  scene.stopAllAnimations();

  camera = new BABYLON.ArcRotateCamera(
    "camera",
    Math.PI / 4,
    Math.PI / 4,
    2,
    BABYLON.Vector3.Zero(),
    scene
  );
  camera.lowerRadiusLimit = 0.5;
  camera.upperRadiusLimit = 50;
  camera.minZ = 0.01;
  camera.maxZ = 100;
  camera.fov = 0.5;
}

export async function previewModel(url: string, rotation?: number[]) {
  initPreviewGenerator();
  engine.runRenderLoop(() => scene.render());
  let bl = await getBlobFromIDBAsync(url);
  if (!bl) {
    bl = await getUserBlobAsync(url);
  }
  const model = await loadFileAsync(
    new File([bl!], "model.glb", {
      type: "model/gltf-binary",
    })
  );
  if (rotation) model!.rotation = BABYLON.Vector3.FromArray(rotation);
  const bounds = model!.getHierarchyBoundingVectors();
  if (url.split("/")[0] === "scribers") {
    const path = `configs/${url.split(".")[0]}.json`;
    const json = await getUserJsonAsync(path);
    json.relativeBox = {
      center: bounds.max
        .add(bounds.min)
        .multiplyByFloats(0.5, 0.5, 0.5)
        .asArray(),
      dimensions: bounds.max
        .subtract(bounds.min)
        .multiplyByFloats(0.5, 0.5, 0.5)
        .asArray(),
    } as RelativeBox;

    await postUserJsonAsync(json, `configs/${url.split(".")[0]}.json`);
  } else {
    await postUserJsonAsync(
      {
        relativeBox: {
          center: bounds.max
            .add(bounds.min)
            .multiplyByFloats(0.5, 0.5, 0.5)
            .asArray(),
          dimensions: bounds.max
            .subtract(bounds.min)
            .multiplyByFloats(0.5, 0.5, 0.5)
            .asArray(),
        } as RelativeBox,
      },
      `configs/${url.split(".")[0]}.json`
    );
  }

  camera.setTarget(
    bounds.max.add(bounds.min).multiply(new BABYLON.Vector3(0.5, 0.5, 0.5))
  );
  const light = new BABYLON.HemisphericLight(
    "hemi",
    new BABYLON.Vector3(0.5, -1, 0),
    scene
  );
  light.intensity = 2;

  await timeoutAsync(300);
  const blob = await new Promise<Blob>((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob!);
      },
      "image/webp",
      0.7
    );
  });
  scene?.dispose();
  engine?.dispose();
  return blob;
}

async function createObjectFromFileAsync(scene: BABYLON.Scene, file: File) {
  try {
    const object = await BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "",
      file,
      scene
    );
    return object.meshes;
  } catch (e) {
    console.log("Bad *.glb", e);
  }
  return null;
}

const loadFileAsync = async (file: File) => {
  const meshes = await createObjectFromFileAsync(scene, file);
  if (!meshes) return null;
  await timeoutAsync(150);
  let transformNode = normalizeObjectInSpace(meshes);
  return transformNode;
};

function normalizeObjectInSpace(meshes: BABYLON.AbstractMesh[]) {
  let parent = meshes[0];
  let parentBox = getParentBoundingInfo(meshes);
  let maxSize = new BABYLON.Vector3(
    parentBox.maximum.x - parentBox.minimum.x,
    parentBox.maximum.y - parentBox.minimum.y,
    parentBox.maximum.z - parentBox.minimum.z
  );
  const scale = 1 / Math.max(maxSize.x, maxSize.y, maxSize.z);

  parent.position = new BABYLON.Vector3(
    (parentBox.boundingBox.maximumWorld.x +
      parentBox.boundingBox.minimumWorld.x) /
      2,
    -parentBox.boundingBox.minimumWorld.y,
    (parentBox.boundingBox.maximumWorld.z +
      parentBox.boundingBox.minimumWorld.z) /
      2
  );
  parent.rotation = BABYLON.Vector3.Zero();

  const transform = new BABYLON.TransformNode("root_transform");
  parent.parent = transform;
  transform.scaling.x = scale;
  transform.scaling.y = scale;
  transform.scaling.z = scale;

  return transform;
}

function getParentBoundingInfo(meshes: BABYLON.AbstractMesh[]) {
  let min = meshes[0].getBoundingInfo().boundingBox.minimumWorld;
  let max = meshes[0].getBoundingInfo().boundingBox.maximumWorld;

  for (let i = 0; i < meshes.length; i++) {
    let meshMin = meshes[i].getBoundingInfo().boundingBox.minimumWorld;
    let meshMax = meshes[i].getBoundingInfo().boundingBox.maximumWorld;

    min = BABYLON.Vector3.Minimize(min, meshMin);
    max = BABYLON.Vector3.Maximize(max, meshMax);
  }

  return new BABYLON.BoundingInfo(min, max);
}
