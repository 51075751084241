import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import {
  getCurrentDownloadProgress,
  getDownloadState,
  getTotalDownloadProgress,
} from "../../store/progressSlice";
import { useEffect, useState } from "react";
import { getLoaderState } from "../../store/sceneSlice";
import { selectScriberLoading } from "../../store/scriberSlice";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        opacity: props.value === 0 ? 0 : 1,
        transform: "opacity 0.3s 0.15s",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        style={{ position: "absolute", color: "lightgray" }}
        size={60}
      />
      <CircularProgress
        size={60}
        variant="determinate"
        value={props.value}
        sx={{
          "& .MuiCircularProgress-svg": {
            borderRadius: "50%",
          },
          "& .MuiCircularProgress-circle": {
            strokeLinecap: "round",
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const SceneProgress = () => {
  const currentDownloadProgress = useAppSelector(getCurrentDownloadProgress);
  const totalDownloadProgress = useAppSelector(getTotalDownloadProgress);
  const downloadState = useAppSelector(getDownloadState);
  const scriberLoading = useAppSelector(selectScriberLoading);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const loading = useAppSelector(getLoaderState);

  useEffect(() => {
    if (currentDownloadProgress && totalDownloadProgress) {
      setDownloadProgress(
        Math.round((currentDownloadProgress / totalDownloadProgress) * 100)
      );
    } else {
      setDownloadProgress(0);
    }
  }, [currentDownloadProgress, totalDownloadProgress]);

  return (
    <Box
      sx={{
        backgroundColor: "#F1F3F4",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
        borderRadius: "16px",
        opacity: loading || scriberLoading ? 1 : 0,
        pointerEvents: loading || scriberLoading ? "all" : "none",
        transition: "opacity 0.3s ease-in-out",
      }}
    >
      <Box
        sx={{
          height: "256px",
          width: "256px",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <CircularProgressWithLabel value={downloadProgress} />
        <Typography
          sx={{
            transform: `translateY(${downloadProgress > 0 ? 0 : -36}px)`,
            transition: "transform 0.2s",
          }}
        >
          {downloadState}...
        </Typography>
      </Box>
    </Box>
  );
};

export default SceneProgress;
