import { Button, Grid, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { StoreItemModel } from "../../../../store/types";
import { getBlobAsync } from "../../../../managers/storage/StorageManager";
import {
  getStorageItemFromSharedStorageData,
  getStorageItemFromStorageData,
} from "../../../../helpers/Helper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getUserStorageData } from "../../../../store/storageSlice";
import { showImageDialog } from "../../../../store/dialogSlice";
import { getSelectedScriberProjectId } from "../../../../store/scriberSlice";
import { getSharedStorageDataAsync } from "../../../../managers/storage/AzureStorageManager";

export interface ModelItemProps {
  item: StoreItemModel;
  onDelete: Function;
  demo?: boolean;
}

const ScriberProjectItem = ({ item, onDelete, demo }: ModelItemProps) => {
  const dispatch = useAppDispatch();
  const userStorageData = useAppSelector(getUserStorageData);
  const selectedScriberProjectId = useAppSelector(getSelectedScriberProjectId);
  const [isHovered, setIsHovered] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();

  useEffect(() => {
    const asyncWrapper = async () => {
      const preview = await getBlobAsync(item);
      setPreviewUrl(URL.createObjectURL(preview!));
    };
    asyncWrapper();
  }, [item]);

  const handleImageClick = () => {
    if (demo) {
      const runAsync = async () => {
        const previewsStorageData = await getSharedStorageDataAsync(
          `scriber-projects/${selectedScriberProjectId}/images`
        );
        const storateItems =
          getStorageItemFromSharedStorageData(previewsStorageData);
        const image = storateItems.find((i) => i.name === item.name);
        dispatch(showImageDialog(image));
      };
      runAsync();
    } else {
      const projectItems = getStorageItemFromStorageData(
        `scriber-projects/${selectedScriberProjectId}/images`,
        userStorageData
      );
      const image = projectItems.find((i) => i.name === item.name);
      dispatch(showImageDialog(image));
    }
  };

  return (
    <Grid
      item
      style={{
        position: "relative",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="gradient"
        style={{
          width: "144px",
          height: "244px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      ></div>
      <Button
        data-track="ModelClicked"
        style={{
          width: "144px",
          height: "244px",
          borderRadius: "12px",
          opacity: previewUrl ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
          padding: 0,
          overflow: "hidden",
        }}
        onClick={() => handleImageClick()}
      >
        <img
          src={previewUrl}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Button>
      <div
        style={{
          width: "144px",
          height: "244px",
          borderRadius: "12px",
          position: "absolute",
          left: 0,
          top: 0,
          background: "black",
          opacity: isHovered ? 0.3 : 0.1,
          transition: "opacity 300ms",
          pointerEvents: "none",
        }}
      />
      {!demo && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            right: "8px",
            top: 0,
          }}
        >
          <IconButton
            onClick={() => onDelete()}
            style={{
              transition: "opacity 300ms",
              marginTop: "8px",
              padding: "0px 0px",
              opacity: isHovered ? 1 : 0,
            }}
          >
            <img
              src="assets/svg/DeleteIconWhite.svg"
              width="24px"
              height="24px"
            />
          </IconButton>
        </div>
      )}
    </Grid>
  );
};

export default ScriberProjectItem;
