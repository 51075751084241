import { configureStore } from "@reduxjs/toolkit";
import projectSlice from "./projectSlice";
import navigatorReducer from "./navigatorSlice";
import sceneSlice from "./sceneSlice";
import timelineSlice from "./timelineSlice";
import storageSlice from "./storageSlice";
import scriberSlice from "./scriberSlice";
import progressSlice from "./progressSlice";
import userSlice from "./userSlice";
import dialogSlice from "./dialogSlice";
import renderSlice from "./renderSlice";
import onboardingSlice from "./onboardingSlice";
import editorSlice from "./editorSlice";
import aiSlice from "./aiSlice";
import uploadingSlice from "./uploadingSlice";
import subscriptionSlice from "./subscriptionSlice";

export const store = configureStore({
  reducer: {
    project: projectSlice,
    navigator: navigatorReducer,
    scene: sceneSlice,
    timeline: timelineSlice,
    storage: storageSlice,
    scriber: scriberSlice,
    progress: progressSlice,
    user: userSlice,
    dialog: dialogSlice,
    render: renderSlice,
    onboarding: onboardingSlice,
    editor: editorSlice,
    ai: aiSlice,
    uploading: uploadingSlice,
    subscription: subscriptionSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
