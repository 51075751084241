import { Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getSequenceById, timeoutAsync } from "../../helpers/Helper";
// import { getTestNetworkOutputAsync } from "../../managers/AICreator";
import { setSequenceDuration, getProject } from "../../store/projectSlice";
import {
  setTimelinePlaying,
  setTimelineCaretTime,
  getTimelineSelectedItem,
} from "../../store/timelineSlice";
import { getLumiereSceneById } from "../scene/LumiereScene";
import { getUserJsonAsync } from "../../managers/storage/AzureStorageManager";

const TestAIButton = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getProject);
  const selectedItem = useAppSelector(getTimelineSelectedItem);

  const handleTestNetworkAsync = async () => {
    dispatch(setTimelinePlaying(false));

    const config = await getUserJsonAsync(
      `configs/${project.sequences[0].mainObject?.model.url.split(".")[0]}.json`
    );
    let relativeBox;

    if (config && config.relativeBox) relativeBox = config.relativeBox;
    else relativeBox = undefined;

    // const { shot, duration } = await getTestNetworkOutputAsync(relativeBox);
    const sequence = getSequenceById(project, selectedItem?.id!)!;
    const lumiereScene = getLumiereSceneById(sequence.id);
    // lumiereScene!.setShot(shot);
    // dispatch(
    //   setSequenceDuration({ sequenceId: sequence.id, duration: duration })
    // );
    let time = 0;
    for (let i = 0; i < project.sequences.length; i++) {
      if (project.sequences[i].id === sequence.id) {
        break;
      }
      time += project.sequences[i].duration;
    }
    dispatch(setTimelineCaretTime(time / 60));
    await timeoutAsync(300);
    dispatch(setTimelinePlaying(true));
  };

  return (
    <Button
      sx={{
        background: "#FFF",
        borderRadius: "12px",
        border: "2px solid #4261FF",
        padding: "10px 18px",
        height: "36px",
        width: "calc(100% - 32px)",
        ":hover": {
          background: "#FFF",
          boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
        },
        ":active": {
          background: "#FFF",
          boxShadow: "none",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      onClick={handleTestNetworkAsync}
    >
      <Typography
        sx={{
          color: "#3050F5",
        }}
      >
        Test AI
      </Typography>
    </Button>
  );
};

export default TestAIButton;
