const SelectedVideosIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="10" fill="#3050F5" fillOpacity="0.1" />
      <path
        d="M24.5686 14.8022C25.3269 14.4134 26.1075 14.0102 26.5482 13.7552C28.3098 12.7124 29.4998 13.6814 29.4998 15.7985V20.2014C29.4998 22.3185 28.3098 23.2876 26.5482 22.2448C26.1004 21.9857 25.3135 21.5837 24.5547 21.1894"
        stroke="#3050F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8333 18C24.8551 19.1515 24.7495 20.3018 24.5183 21.43C23.7833 24.7784 22.5 26.1667 16.6667 26.1667C9.66667 26.1667 8.5 23.845 8.5 18C8.5 12.05 9.66667 9.83337 16.6667 9.83337C22.5 9.83337 23.7367 11.14 24.495 14.4184C24.7418 15.5958 24.8553 16.7972 24.8333 18V18Z"
        stroke="#3050F5"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default SelectedVideosIcon;
