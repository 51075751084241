import * as BABYLON from "babylonjs";
import {
  createControlledCamera,
  createMainCamera,
} from "./loaders/CameraLoader";
import { GizmoLoader, GizmoType } from "./loaders/GizmoLoader";
import {
  setRenderingPipeline,
  updateDepthOfFieldFocusDistance,
} from "./loaders/PipelineLoader";
import { distance } from "./types";
import {
  RelativeBox,
  Sequence,
  ShotModel,
  StoreItemModel,
  Transform,
} from "../../store/types";
import { createLight } from "./loaders/LightLoader";
import { createMainObjectAsync } from "./loaders/ObjectLoader";
import { createEnvironmentAsync } from "./loaders/EnvironmentLoader";
import { createSkybox } from "./loaders/SkyboxLoader";
import { getScriberRotation } from "../../managers/storage/AzureStorageManager";

let lumiereScenes: LumiereScene[] = [];
export const getLumiereSceneById = (id: string) =>
  lumiereScenes.find((l) => l.id === id);

export class LumiereScene {
  public onAnimatableSelect: (node: BABYLON.Node) => void = () => {};
  public onAnimatableTransform: (transform: {
    sequenceId: string;
    animatableId: string;
    transform: Transform;
  }) => void = () => {};
  public readonly id: string;
  public readonly engine: BABYLON.Engine;
  public readonly scene: BABYLON.Scene;
  public readonly controlledCamera: BABYLON.ArcRotateCamera;
  private readonly controlledCameraPipeline: BABYLON.DefaultRenderingPipeline;
  private readonly controlledCameraDistance: distance = {
    smooth: 1,
    current: 1,
  };
  private readonly highlightLayer: BABYLON.HighlightLayer;
  public readonly mainCamera: BABYLON.UniversalCamera;
  private readonly mainCameraPipeline: BABYLON.DefaultRenderingPipeline;
  private readonly mainCameraDistance: distance = { smooth: 1, current: 1 };
  private readonly gizmoLoader: GizmoLoader;
  public mainObject: BABYLON.TransformNode | undefined;
  public environment: BABYLON.TransformNode | undefined;
  public skybox: BABYLON.TransformNode | undefined;
  private skyboxSize = 100;
  private hasMoved = false;
  private readonly mainCanvas: HTMLCanvasElement;
  public readonly previewCanvas: HTMLCanvasElement;
  public targetRelativeBox: RelativeBox | undefined;
  private shotModel: ShotModel | undefined;
  private rendering = false;
  private cachedCameraTransform: Transform | undefined;
  private renderLoopFunction: () => void;

  constructor(
    engine: BABYLON.Engine,
    main: HTMLCanvasElement,
    preview: HTMLCanvasElement,
    sequence: Sequence
  ) {
    this.id = sequence.id;
    this.engine = engine;
    this.mainCanvas = main;
    this.previewCanvas = preview;
    this.scene = new BABYLON.Scene(this.engine);
    this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0); // Set transparent background color
    this.scene.fogMode = BABYLON.Scene.FOGMODE_EXP2;
    this.scene.fogColor = BABYLON.Color3.Black();
    this.scene.fogDensity = 0;
    this.scene.autoClear = true;
    this.scene.autoClearDepthAndStencil = true;

    this.mainCamera = createMainCamera(sequence.camera, this.scene);
    this.mainCameraPipeline = setRenderingPipeline(this.mainCamera, this.scene);

    this.controlledCamera = createControlledCamera(this.scene);
    this.controlledCameraPipeline = setRenderingPipeline(
      this.controlledCamera,
      this.scene
    );
    this.highlightLayer = new BABYLON.HighlightLayer("hl", this.scene, {
      camera: this.controlledCamera,
    });

    this.gizmoLoader = new GizmoLoader(this.scene, this.controlledCamera);
    this.scene.onPointerObservable.add((pointerInfo) =>
      this.pickAnimatable(pointerInfo)
    );
    this.renderLoopFunction = () => this.render();
    lumiereScenes.push(this);
  }

  public async setEnvironmentAsync(model: StoreItemModel) {
    this.environment = (await createEnvironmentAsync(
      model,
      this.scene
    )) as BABYLON.TransformNode;
    this.updateShadows(this.environment);
    const skyNode = this.scene.getTransformNodeById("skybox");
    if (skyNode) this.skyboxSize = skyNode.scaling.x;
  }

  public async setSkyboxAsync(model: StoreItemModel) {
    if (model.url.endsWith("env")) {
      this.scene.environmentTexture?.dispose();
      this.skybox?.dispose(false, true);
      this.skybox = (await createSkybox(
        model,
        this.skyboxSize,
        this.scene
      )) as BABYLON.TransformNode;
    } else {
      this.skybox?.setEnabled(false);
    }
  }

  public async setMainObjectAsync(
    model: StoreItemModel,
    dispatch: Function,
    mainObjectTransform?: Transform
  ) {
    this.mainObject?.parent?.dispose(false, true);
    let scriberRotation;
    if (model.url.startsWith("scribers")) {
      scriberRotation = await getScriberRotation(model);
    }
    this.mainObject = (await createMainObjectAsync(
      model,
      this.scene,
      dispatch,
      scriberRotation?.rotation
    )) as BABYLON.TransformNode;

    if (mainObjectTransform) {
      const parent = this.mainObject.parent as BABYLON.TransformNode;
      parent.position = BABYLON.Vector3.FromArray(mainObjectTransform.position);
      parent.rotation = BABYLON.Vector3.FromArray(mainObjectTransform.rotation);
      parent.scaling = BABYLON.Vector3.FromArray(mainObjectTransform.scaling);
    }

    this.updateShadows(this.mainObject);
    this.targetRelativeBox = this.getRelativeBox(this.mainObject);
    if (this.shotModel)
      await this.setShot(this.shotModel, this.cachedCameraTransform);

    this.controlledCamera.setTarget(
      BABYLON.Vector3.FromArray(this.targetRelativeBox.center).multiply(
        new BABYLON.Vector3(1, 0.5, 1)
      )
    );
  }

  public setShot(shotModel: ShotModel, mainCameraTransform?: Transform) {
    this.shotModel = shotModel;
    this.cachedCameraTransform = mainCameraTransform;
    const shotCopy = JSON.parse(JSON.stringify(this.shotModel));
    this.clearShot();
    this.setCameraTransform(shotCopy);
    this.setCameraAnimations(shotCopy);
    this.setLights(shotCopy);
    if (this.environment) {
      this.updateShadows(this.environment);
    }
    if (this.mainObject) {
      this.updateShadows(this.mainObject);
    }
  }

  public playScene(firstFrame: number) {
    if (this.mainCamera?.animations) {
      this.playAnimation(this.mainCamera, firstFrame, this.scene!);
    }
    if (this.mainObject?.animations) {
      this.playAnimation(this.mainObject, firstFrame, this.scene);
    }
    this.scene.lights.forEach((l) => {
      if (l.animations) {
        this.playAnimation(l, firstFrame, this.scene);
      }
    });
  }

  public playFrameScene(sequenceFrame: number) {
    if (this.mainCamera?.animations) {
      this.setAnimation(this.mainCamera, sequenceFrame, this.scene!);
    }
    if (this.mainObject?.animations) {
      this.setAnimation(this.mainObject, sequenceFrame, this.scene!);
    }
    this.scene.lights.forEach((l) => {
      if (l.animations) {
        this.setAnimation(l, sequenceFrame, this.scene!);
      }
    });
  }

  public toggleRenderLoop(rendering: boolean) {
    if (this.rendering === rendering) return;
    this.rendering = rendering;
    if (this.rendering) {
      this.startRenderLoop();
    } else {
      this.stopRenderLoop();
    }
  }

  private startRenderLoop() {
    this.engine.unRegisterView(this.mainCanvas);
    this.engine.unRegisterView(this.previewCanvas);
    this.engine.registerView(this.mainCanvas, this.controlledCamera, true);
    this.engine.registerView(this.previewCanvas, this.mainCamera, true);
    this.engine.runRenderLoop(this.renderLoopFunction);
  }

  private stopRenderLoop() {
    this.engine.stopRenderLoop(this.renderLoopFunction);
  }

  public render() {
    updateDepthOfFieldFocusDistance(
      this.mainCamera,
      this.mainCameraPipeline,
      this.mainCameraDistance,
      this.scene
    );
    updateDepthOfFieldFocusDistance(
      this.controlledCamera,
      this.controlledCameraPipeline,
      this.controlledCameraDistance,
      this.scene
    );
    this.scene.render();
  }

  public async takeSnapShotAsync(width: number, height: number) {
    return await BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(
      this.engine,
      this.controlledCamera,
      {
        width: width,
        height: height,
      },
      "image/jpeg"
    );
  }

  public dispose() {
    this.scene.dispose();
    lumiereScenes = lumiereScenes.filter((l) => l.id !== this.id);
  }

  private pickAnimatable(pointerInfo: BABYLON.PointerInfo) {
    if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERDOWN) {
      this.hasMoved = false;
    } else if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERMOVE) {
      this.hasMoved = true;
    } else if (pointerInfo.type === BABYLON.PointerEventTypes.POINTERUP) {
      if (this.hasMoved) return;
      let result = this.scene.pick(
        this.scene.pointerX,
        this.scene.pointerY,
        undefined,
        undefined,
        this.controlledCamera
      );
      this.highlightLayer.removeAllMeshes();
      if (result.pickedMesh) {
        const rootParent = this.findRootParent(result.pickedMesh);
        if (rootParent.id === this.environment?.id) return;

        this.onAnimatableSelect(rootParent);
        this.highlightMeshes(rootParent);

        this.gizmoLoader?.showGizmo(
          rootParent,
          GizmoType.All,
          rootParent.id === this.mainCamera.id,
          () => {
            const transform = {
              position: rootParent.position.asArray(),
              rotation: rootParent.rotation.asArray(),
              scaling: rootParent.scaling.asArray(),
            };
            this.onAnimatableTransform({
              sequenceId: this.id,
              animatableId: rootParent.id,
              transform: transform,
            });
          }
        );
      }
    }
  }

  private setLights(shotModel: ShotModel) {
    shotModel.lights.forEach((l) => {
      const light = createLight(l, this.scene) as BABYLON.ShadowLight;
      const lightParent = light.parent as BABYLON.TransformNode;
      lightParent.position = light.position;
      light.position = BABYLON.Vector3.Zero();
      light.animations = l.animations.map((a: any) =>
        BABYLON.Animation.Parse(a)
      );
    });
  }

  private setCameraAnimations(shotModel: ShotModel) {
    const cameraAnimations = shotModel.camera.animations.map((a) =>
      BABYLON.Animation.Parse(a)
    );
    this.mainCamera.animations = cameraAnimations ?? [];
  }

  private setCameraTransform(shotModel: ShotModel) {
    const cameraParent = this.mainCamera.parent as BABYLON.TransformNode;
    if (cameraParent) {
      cameraParent.position = BABYLON.Vector3.FromArray(
        this.cachedCameraTransform?.position ??
          shotModel.camera.transform.position ?? [0, 0, 0]
      );
      cameraParent.rotation = BABYLON.Vector3.FromArray(
        this.cachedCameraTransform?.rotation ??
          shotModel.camera.transform.rotation ?? [0, 0, 0]
      );
    }
    this.mainCamera.rotation = BABYLON.Vector3.Zero();
    this.mainCamera.position = BABYLON.Vector3.Zero();
  }

  private clearShot() {
    this.mainCamera.animations = [];
    [...this.scene.lights].forEach((l) => {
      l.parent?.dispose(false, true);
    });
  }

  public highlightMeshes(node: BABYLON.Node) {
    if (node instanceof BABYLON.Mesh) {
      this.highlightLayer.addMesh(node, new BABYLON.Color3(0.098, 0.463, 0.82));
    }
    node.getChildren().forEach((childNode) => {
      this.highlightMeshes(childNode);
    });
  }

  private playAnimation(
    node: BABYLON.Node,
    sequenceFrame: number,
    scene: BABYLON.Scene
  ) {
    const duration = this.getBabylonAnimationsDuration(node.animations);
    const limitFrame = sequenceFrame > duration ? duration : sequenceFrame;
    scene.beginDirectAnimation(node, node.animations, limitFrame, duration);
  }

  private setAnimation(
    node: BABYLON.Node,
    sequenceFrame: number,
    scene: BABYLON.Scene
  ) {
    const duration = this.getBabylonAnimationsDuration(node.animations);
    const limitFrame = sequenceFrame > duration ? duration : sequenceFrame;
    scene.beginDirectAnimation(node, node.animations, limitFrame, limitFrame);
  }

  private getBabylonAnimationsDuration(animations: BABYLON.Animation[]) {
    return Math.max(
      ...animations.flatMap((a) => a.getKeys().map((k) => k.frame)),
      0
    );
  }

  private findRootParent(node: BABYLON.Node): BABYLON.TransformNode {
    return (
      node.parent ? this.findRootParent(node.parent) : node
    ) as BABYLON.TransformNode;
  }

  private updateShadows(node: BABYLON.Node) {
    if (node instanceof BABYLON.AbstractMesh) {
      this.addMeshShadowing(node);
    }

    const children = node?.getChildren();
    if (children) {
      for (const child of children) {
        this.updateShadows(child);
      }
    }
  }

  private addMeshShadowing(mesh: BABYLON.AbstractMesh) {
    mesh.receiveShadows = true;
    this.scene.lights?.forEach((light) => {
      const gen = light.getShadowGenerator() as BABYLON.ShadowGenerator;
      gen?.addShadowCaster(mesh, true);
    });
  }

  private getRelativeBox(node: BABYLON.TransformNode) {
    const hierarchyBoundingVectors = (
      node.parent as BABYLON.TransformNode
    ).getHierarchyBoundingVectors(true);
    return {
      center: hierarchyBoundingVectors.max
        .add(hierarchyBoundingVectors.min)
        .multiplyByFloats(0.5, 0.5, 0.5)
        .asArray(),
      dimensions: hierarchyBoundingVectors.max
        .subtract(hierarchyBoundingVectors.min)
        .multiplyByFloats(0.5, 0.5, 0.5)
        .asArray(),
    } as RelativeBox;
  }
}
