import { Checkbox } from "@mui/material";

type PlayProps = {
  playing: boolean;
  onPlay: (playing: boolean) => void;
};

const Play = ({ playing, onPlay }: PlayProps) => {
  return (
    <Checkbox
      sx={{
        backgroundColor: "white",
        width: `32px`,
        height: `32px`,
        ":hover": {
          backgroundColor: "white",
          boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.08)",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
      icon={<img src="assets/svg/PlayIcon.svg" width="36px" height="36px" />}
      checkedIcon={
        <img src="assets/svg/PauseIcon.svg" width="36px" height="36px" />
      }
      checked={playing}
      onChange={(_, v) => onPlay(v)}
    />
  );
};

export default Play;
