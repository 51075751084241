import * as BABYLON from "babylonjs";

enum GizmoType {
  All,
  Position,
  Rotation,
  Scale,
}

class GizmoLoader {
  private lightGizmo: BABYLON.LightGizmo | undefined;
  private cameraGizmo: BABYLON.CameraGizmo | undefined;
  private scaleGizmo: BABYLON.ScaleGizmo | undefined;
  private planeDragGizmo: BABYLON.PlaneDragGizmo | undefined;
  private gizmoManager: BABYLON.GizmoManager;
  private gizmoLayer: BABYLON.UtilityLayerRenderer;

  constructor(scene: BABYLON.Scene, camera: BABYLON.ArcRotateCamera) {
    this.gizmoLayer = new BABYLON.UtilityLayerRenderer(scene);
    this.gizmoLayer.setRenderCamera(camera);
    this.gizmoManager = new BABYLON.GizmoManager(scene, 1, this.gizmoLayer);
  }

  private removeDragEndObservables(): void {
    const gizmos = this.gizmoManager.gizmos;
    if (gizmos.positionGizmo) {
      const positionGizmo = gizmos.positionGizmo;
      positionGizmo.xGizmo.dragBehavior.onDragEndObservable.clear();
      positionGizmo.yGizmo.dragBehavior.onDragEndObservable.clear();
      positionGizmo.zGizmo.dragBehavior.onDragEndObservable.clear();
    }
    if (gizmos.rotationGizmo) {
      const rotationGizmo = gizmos.rotationGizmo;
      rotationGizmo.xGizmo.dragBehavior.onDragEndObservable.clear();
      rotationGizmo.yGizmo.dragBehavior.onDragEndObservable.clear();
      rotationGizmo.zGizmo.dragBehavior.onDragEndObservable.clear();
    }
  }

  public hideGizmo(): void {
    this.removeDragEndObservables();
    this.gizmoManager?.attachToNode(null);
    this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.planeDragGizmo?.dispose();
    this.scaleGizmo?.dispose();
    this.lightGizmo?.dispose();
    this.cameraGizmo?.dispose();
  }

  public showGizmo(
    node: BABYLON.Node,
    type: GizmoType,
    isCamera: boolean,
    onDragEnd: () => void
  ): void {
    switch (type) {
      case GizmoType.All:
        this.showAllGizmo(node, isCamera, onDragEnd);
        break;
      case GizmoType.Position:
        this.showPositionGizmo(node, onDragEnd);
        break;
      case GizmoType.Rotation:
        this.showRotationGizmo(node, onDragEnd);
        break;
      case GizmoType.Scale:
        if (isCamera) break;
        this.showScaleGizmo(node, onDragEnd);
        break;
    }
  }

  private showAllGizmo(
    node: BABYLON.Node,
    isCamera: boolean,
    onDragEnd: () => void
  ): void {
    if (isCamera) this.showCameraGizmo(node);
    else this.showObjectGizmo(node, onDragEnd);

    this.addPositionDragEndObservables(onDragEnd);
    this.addRotationDragEndObservables(onDragEnd);
  }

  private showPositionGizmo(node: BABYLON.Node, onDragEnd: () => void): void {
    this.hideGizmo();
    this.gizmoManager.attachToNode(node);
    this.gizmoManager.positionGizmoEnabled = true;

    this.planeDragGizmo = new BABYLON.PlaneDragGizmo(
      new BABYLON.Vector3(0, 5, 0),
      BABYLON.Color3.White(),
      this.gizmoLayer
    );
    this.planeDragGizmo.attachedNode = node;

    this.addPositionDragEndObservables(onDragEnd);
  }

  private showRotationGizmo(node: BABYLON.Node, onDragEnd: () => void): void {
    this.hideGizmo();
    this.gizmoManager.attachToNode(node);
    this.gizmoManager.rotationGizmoEnabled = true;

    this.addRotationDragEndObservables(onDragEnd);
  }

  private showScaleGizmo(node: BABYLON.Node, onDragEnd: () => void): void {
    this.hideGizmo();
    this.createScaleDragGizmo(node, onDragEnd);
  }

  private showCameraGizmo(node: BABYLON.Node): void {
    this.hideGizmo();
    this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = true;

    this.gizmoManager.attachToNode(node);
  }

  private showObjectGizmo(node: BABYLON.Node, onDragEnd: () => void): void {
    this.hideGizmo();
    this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = true;

    this.createScaleDragGizmo(node, onDragEnd);
    this.gizmoManager.attachToNode(node);
  }

  private createScaleDragGizmo(
    node: BABYLON.Node,
    onDragEnd: () => void
  ): void {
    this.scaleGizmo = new BABYLON.ScaleGizmo(
      this.gizmoLayer,
      2,
      this.gizmoManager
    );
    this.scaleGizmo.attachedNode = node;
    this.scaleGizmo.scaleRatio = 2;
    this.scaleGizmo.sensitivity = 2;
    this.scaleGizmo.xGizmo.isEnabled = false;
    this.scaleGizmo.yGizmo.isEnabled = false;
    this.scaleGizmo.zGizmo.isEnabled = false;
    this.scaleGizmo.uniformScaleGizmo.dragBehavior.onDragEndObservable.add(
      onDragEnd
    );
  }

  private addPositionDragEndObservables(onDragEnd: () => void): void {
    const positionGizmo = this.gizmoManager.gizmos.positionGizmo;
    if (positionGizmo) {
      positionGizmo.xGizmo.dragBehavior.onDragEndObservable.add(onDragEnd);
      positionGizmo.yGizmo.dragBehavior.onDragEndObservable.add(onDragEnd);
      positionGizmo.zGizmo.dragBehavior.onDragEndObservable.add(onDragEnd);
    }
  }

  private addRotationDragEndObservables(onDragEnd: () => void): void {
    const rotationGizmo = this.gizmoManager.gizmos.rotationGizmo;
    if (rotationGizmo) {
      rotationGizmo.xGizmo.dragBehavior.onDragEndObservable.add(onDragEnd);
      rotationGizmo.yGizmo.dragBehavior.onDragEndObservable.add(onDragEnd);
      rotationGizmo.zGizmo.dragBehavior.onDragEndObservable.add(onDragEnd);
    }
  }
}

export { GizmoLoader, GizmoType };
