import { Button, Grid, Typography } from "@mui/material";
import { iTextTemplate } from "../../../../store/types";
import { getTimelineSelectedItem } from "../../../../store/timelineSlice";
import { useAppSelector } from "../../../../store/hooks";

interface SharedTextsItemProps {
  template: iTextTemplate;
  onSelect: () => void;
}

const SharedTextsItem = ({ template, onSelect }: SharedTextsItemProps) => {
  const selectedItem = useAppSelector(getTimelineSelectedItem);

  return (
    <Grid item sx={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Button
          data-track="TextClicked"
          sx={{
            width: "160px",
            height: "92px",
            borderRadius: "12px",
            border:
              template.id === selectedItem?.id
                ? "4px solid #F1F3F4"
                : "1px solid gray",
            outline:
              template.id === selectedItem?.id ? "2px solid #4261FF" : "",
            outlineOffset: "-2px",
          }}
          onClick={() => onSelect()}
        >
          <Typography
            sx={{
              pointerEvents: "none",
              maxWidth: "148px",
              whiteSpace: "nowrap",
              fontSize: 36,
              ...template.css,
            }}
          >
            Abc
          </Typography>
        </Button>
      </div>
    </Grid>
  );
};

export default SharedTextsItem;
