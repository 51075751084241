type GradientCrownProps = {
  size: string;
};

const GradientCrown = ({ size }: GradientCrownProps) => {
  return (
    <div
      className="backgroundGradient"
      style={{
        maskImage: "url('assets/svg/Crown.svg')",
        width: size,
        height: size,
        maskSize: "contain",
        maskPosition: "center",
        maskRepeat: "no-repeat",
      }}
    />
  );
};

export default GradientCrown;
