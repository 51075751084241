import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCurrentTimestampUTC,
  getStorageItemFromStorageData,
  handleDeleteProjectAsync,
  preloadBlobsAsync,
  sortStoreItemModels,
  timeoutAsync,
} from "../../../../helpers/Helper";
import { useAppSelector } from "../../../../store/hooks";
import {
  getOccupiedStorageSize,
  getUserStorageData,
  setUserStorageData,
} from "../../../../store/storageSlice";
import {
  sortingMethods,
  createVideoDialogModes,
  StoreItemModel,
  Project,
} from "../../../../store/types";
import ScriberItem from "./ScriberItem";
import {
  getCreateVideoDialogOpen,
  hideCreateVideoDialog,
  setCreateVideoDialogMode,
  showCreateModelDialog,
  showCustomAlert,
  showShareModelDialog,
} from "../../../../store/dialogSlice";
import LeftArrow from "../../../../icons/LeftArrow";
import ModelItem from "./ModelItem";
import { CreateAIProjectFromModel } from "../../../../managers/AICreator";
import {
  resetProgress,
  setProgressText,
  increaseCurrentProgress,
} from "../../../../store/progressSlice";
import { setProject } from "../../../../store/projectSlice";
import { setLoading } from "../../../../store/sceneSlice";
import { setTimelinePlaying } from "../../../../store/timelineSlice";
import { setSaving, setSceneSwitch } from "../../../../store/navigatorSlice";
import {
  deleteSharedBlobAsync,
  deleteUserBlobAsync,
  getUserBlobAsync,
  getUserJsonAsync,
  getUserStorageDataAsync,
  getUserStorageSize,
  postUserJsonAsync,
} from "../../../../managers/storage/AzureStorageManager";
import { deleteBlobFromIDBAsync } from "../../../../managers/storage/DbManager";
import { scriberConfig } from "../../../scene/types";
import ConfirmationDialog from "../../../dialogs/ConfirmationDialog";
import ShareModelDialog from "../../../dialogs/ShareModelDialog";
import {
  getUserLeftCredits,
  getUserStorageLimit,
  getUserSubscriptionLevel,
} from "../../../../store/userSlice";
import { getBlobAsync } from "../../../../managers/storage/StorageManager";
import {
  demoStarterLimitReachedString,
  demoStarterNoCreditsString,
  proTestLimitReachedString,
  proTestNoCreditsString,
} from "../../../../settings/GlobalStrings";
import {
  redeemScriberAttemptAsync,
  getUserSettingsAsync,
  drainCreditAsync,
} from "../../../../managers/ClientManager";

const ScribedModels = () => {
  const dispatch = useDispatch();
  const userStorageData = useAppSelector(getUserStorageData);
  const subscriptionLevel = useAppSelector(getUserSubscriptionLevel);
  const dialogOpen = useAppSelector(getCreateVideoDialogOpen);
  const storageLimit = useAppSelector(getUserStorageLimit);
  const occupiedStorageSize = useAppSelector(getOccupiedStorageSize);
  const leftCredits = useAppSelector(getUserLeftCredits);
  const [configsEx, setConfigsEx] = useState<any[]>([]);
  const [uploadedModels, setUploadedModels] = useState([] as StoreItemModel[]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<scriberConfig | null>(null);
  const [shareUrl, setShareUrl] = useState("");
  const [storageLimitReached, setStorageLimitReached] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const selectedItem = useRef<scriberConfig | null>(null);

  useEffect(() => {
    const abortController = new AbortController();
    startLongPolling(abortController.signal);
    return () => abortController.abort();
  }, []);

  async function startLongPolling(signal: AbortSignal) {
    while (!signal.aborted) {
      dispatch(setUserStorageData(await getUserStorageDataAsync()));
      checkTimeoutItems();
      await timeoutAsync(30_000);
    }
  }

  async function checkTimeoutItems() {
    const configs = getStorageItemFromStorageData(
      "configs/scribers",
      userStorageData
    );

    configs.forEach(async (c) => {
      const config = await getUserJsonAsync(c.url);

      if (!config || !config.status) return;

      if (config.status === "processing") {
        const processingTimestamp = config.processingTimestamp;
        const currentTimeUtc = getCurrentTimestampUTC();
        const twoHours = 60 * 60 * 1000 * 2;
        if (currentTimeUtc - processingTimestamp > twoHours) {
          config.status = "error";
          await postUserJsonAsync(config, c.url);
        }
      }

      if (config.status === "queue") {
        const startedDate = config.date;
        const currentTimeUtc = getCurrentTimestampUTC();
        const twentyHours = 60 * 60 * 1000 * 20;

        if (currentTimeUtc - startedDate > twentyHours) {
          config.status = "error";
          await postUserJsonAsync(config, c.url);
        }
      }

      if (config.status === "error" && !config.redeem) {
        config.redeem = true;
        await postUserJsonAsync(
          config,
          configs.find((c) => c.name === config.id)!.url
        );
        await redeemScriberAttemptAsync();
        await getUserSettingsAsync(dispatch);
      }
    });
  }

  useEffect(() => {
    const asyncWraper = async () => {
      if (!storageLimit || !occupiedStorageSize) return;
      setStorageLimitReached(occupiedStorageSize >= storageLimit);
    };

    asyncWraper();
  }, [occupiedStorageSize, storageLimit]);

  useEffect(() => {
    const scribers = getStorageItemFromStorageData("scribers", userStorageData);
    const configs = getStorageItemFromStorageData(
      "configs/scribers",
      userStorageData
    );
    const configsEx = sortStoreItemModels(configs, sortingMethods.byDate).map(
      (config) => {
        const scribeStore = scribers.find(
          (scriber) => scriber.name === config.name
        );
        return { config: config, scribe: scribeStore };
      }
    );

    const uploadedModels = getStorageItemFromStorageData(
      "models",
      userStorageData
    );
    setConfigsEx(configsEx);
    setUploadedModels(
      sortStoreItemModels(uploadedModels, sortingMethods.byDate)
    );
  }, [userStorageData]);

  const handleSelectModelAsync = async (model: StoreItemModel) => {
    if (storageLimitReached) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestLimitReachedString
            : demoStarterLimitReachedString
        )
      );
      return;
    }

    if (!leftCredits || leftCredits <= 0) {
      dispatch(
        showCustomAlert(
          subscriptionLevel === "pro" || subscriptionLevel === "test"
            ? proTestNoCreditsString
            : demoStarterNoCreditsString
        )
      );
      return;
    }

    dispatch(setSceneSwitch("scene"));
    dispatch(hideCreateVideoDialog());
    dispatch(setTimelinePlaying(false));
    dispatch(resetProgress());
    dispatch(setProgressText("Generating"));
    dispatch(setLoading(true));

    drainCreditAsync(dispatch);

    const project = await CreateAIProjectFromModel(model, userStorageData);

    await preloadBlobsAsync(project, dispatch, (bytes: number) => {
      dispatch(increaseCurrentProgress(bytes));
    });
    dispatch(setProject(project));
    await timeoutAsync(1000);
    dispatch(setLoading(false));
  };

  const handleMenu = (
    event: React.MouseEvent<HTMLElement>,
    scriber: scriberConfig
  ) => {
    selectedItem.current = scriber;
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const menuItemStyle = {
    borderRadius: "12px",
    transition: "background-color 0.25s",
    padding: "8px 12px",
    ":active": { backgroundColor: "#EDEDED" },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  };

  function handleCloseDeleteDialog() {
    setItemToDelete(null);
  }

  async function handleDelete(scriber: scriberConfig) {
    dispatch(setSaving(true));

    const projects = getStorageItemFromStorageData("projects", userStorageData);
    const projectConfigs = await Promise.all(
      projects.map((p) =>
        getUserJsonAsync(p.url).then((result) => result as Project)
      )
    );

    const projectsToDelete = projectConfigs.filter(
      (p) => p.sequences[0].mainObject?.model.name === scriber.id
    );

    let deletionTasks = [];

    if (projectsToDelete.length) {
      projectsToDelete.forEach((pC) => {
        const project = projects.find((p) => p.name === pC.name);
        if (project) {
          deletionTasks.push(handleDeleteProjectAsync(project));
        }
      });
    }
    deletionTasks.push(
      deleteUserBlobAsync(`configs/scribers/${scriber.id}.json`)
    );
    deletionTasks.push(deleteUserBlobAsync(`scribers/${scriber.id}.glb`));
    deletionTasks.push(deleteSharedBlobAsync(`scribers/${scriber.id}.glb`));
    deletionTasks.push(deleteSharedBlobAsync(`scribers/${scriber.id}.json`));
    deletionTasks.push(
      deleteUserBlobAsync(`previews/scribers/${scriber.id}.glb.webp`)
    );
    deletionTasks.push(
      deleteBlobFromIDBAsync(`configs/scribers/${scriber.id}.json`)
    );
    deletionTasks.push(deleteBlobFromIDBAsync(`scribers/${scriber.id}.glb`));
    deletionTasks.push(
      deleteBlobFromIDBAsync(`previews/scribers/${scriber.id}.glb.webp`)
    );

    await Promise.all(deletionTasks);

    dispatch(setSaving(false));
  }

  function handleShowDeleteDialog() {
    setMenuOpen(false);
    setItemToDelete(selectedItem.current);
  }

  async function handleDownload() {
    handleCloseMenu();
    if (!selectedItem.current?.id) return;
    const scribers = getStorageItemFromStorageData("scribers", userStorageData);
    const scriberItem = scribers.find(
      (s) => s.name === selectedItem.current?.id
    );
    if (!scriberItem) return;
    dispatch(setSaving(true));

    const fileName = scriberItem.url.split("/").pop();
    const blob = await getBlobAsync(scriberItem);
    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(blob!);
    anchorElement.download = fileName!;
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
    dispatch(setSaving(false));
  }

  async function handleShare() {
    handleCloseMenu();
    setShareUrl(
      `https://app.lumiere3d.ai/preview?id=${selectedItem.current?.id}`
    );
    dispatch(showShareModelDialog());
  }

  const handleScroll = (e: any) => {
    const atTop = e.target.scrollTop === 0;
    setIsScrolled(!atTop);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {configsEx.length > 0 && !dialogOpen && (
        <Box
          sx={{
            width: "calc(100% - 32px)",
            marginLeft: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: 900,
              color: "#373E4E",
            }}
          >
            Models
          </Typography>
          <Button
            onClick={() => {
              dispatch(showCreateModelDialog());
            }}
            sx={{
              background: "#3050F5",
              borderRadius: "8px",
              padding: "10px 18px",
              height: "36px",
              width: "156px",
              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#3050F5",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFF",
                fontWeight: 700,
              }}
            >
              Create 3D Model
            </Typography>
          </Button>
        </Box>
      )}
      {dialogOpen && (
        <Box
          sx={{
            width: "calc(100% - 32px)",
            marginLeft: "16px",
            display: "flex",
            marginTop: "16px",
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              background: "#FFF",
              borderRadius: "12px",
              width: "144px",
              height: "32px",
              marginTop: "2px",
              gap: "8px",
            }}
            onClick={() => {
              dispatch(
                setCreateVideoDialogMode(createVideoDialogModes.createVideo)
              );
            }}
          >
            <LeftArrow />
            <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
              Select Model
            </Typography>
          </Button>
        </Box>
      )}
      <div
        style={{
          marginTop: "16px",
          overflowY: "auto",
          outline: isScrolled ? "1px solid #E0E3E4" : "none",
        }}
        onScroll={handleScroll}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            gap: "16px",
            padding: "16px",
          }}
        >
          {uploadedModels.map((model) => (
            <ModelItem
              key={model.id}
              item={model}
              onSelectHandle={handleSelectModelAsync}
            />
          ))}
          {configsEx.map((configsEx) => (
            <ScriberItem
              key={configsEx.config.id}
              configStore={configsEx.config}
              item={configsEx.scribe}
              onSelectHandle={handleSelectModelAsync}
              onMenu={handleMenu}
            />
          ))}
        </Grid>
      </div>
      {configsEx.length === 0 && uploadedModels.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            src="assets/images/EmptyUploadsImage.webp"
            alt=""
            width="300px"
            height="256px"
          />
          <Typography
            sx={{
              fontSize: "28px",
              fontWeight: 700,
              marginTop: "24px",
            }}
          >
            No 3D models yet
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              marginTop: "12px",
            }}
          >
            Your models will show up here. Get started by creating your first
            model!
          </Typography>
          <Button
            sx={{
              background: "#3050F5",
              borderRadius: "12px",
              padding: "10px 18px",
              height: "48px",
              width: "156px",
              marginTop: "40px",
              marginBottom: "32px",
              ":hover": {
                background: "#3050F5",
                boxShadow: "0px 10px 10px 0px rgba(48, 80, 245, 0.24)",
              },
              ":active": {
                background: "#3050F5",
                boxShadow: "none",
              },
              "& .MuiTouchRipple-root": {
                display: "none",
              },
            }}
            onClick={() => {
              dispatch(showCreateModelDialog());
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFF",
                fontWeight: 800,
              }}
            >
              Create 3d Model
            </Typography>
          </Button>
        </div>
      )}
      {itemToDelete && (
        <ConfirmationDialog
          header="Confirm Deletion"
          description="Are you sure you want to delete this model? You will also lose all projects assosiated with it."
          confirmButtonText="Delete"
          item={itemToDelete}
          onConfirm={handleDelete}
          onCancel={handleCloseDeleteDialog}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        keepMounted
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          ".MuiMenu-paper": { borderRadius: "12px" },
          ".MuiMenu-list": { margin: "8px", padding: "0px" },
        }}
      >
        <MenuItem
          onClick={() => handleShare()}
          disabled={selectedItem.current?.status !== "done"}
          sx={menuItemStyle}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/ShareIcon2.svg" width="24px" height="24px" />
            <Typography textAlign="center">Share</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={handleDownload}
          disabled={
            selectedItem.current?.status !== "done" ||
            subscriptionLevel === "demo"
          }
          sx={menuItemStyle}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/DownloadIcon.svg" width="24px" height="24px" />
            <Typography textAlign="center">Download</Typography>
            {subscriptionLevel === "demo" && (
              <img
                src="assets/images/TransparentCrown.png"
                height="8px"
                style={{ marginBottom: "8px" }}
              />
            )}
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => handleShowDeleteDialog()}
          disabled={
            selectedItem.current?.status !== "done" &&
            selectedItem.current?.status !== "error"
          }
          sx={menuItemStyle}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="assets/svg/DeleteIcon.svg" width="24px" height="24px" />
            <Typography textAlign="center">Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>
      <ShareModelDialog
        shareLink={shareUrl}
        selectedModel={selectedItem.current!}
      />
    </Box>
  );
};

export default ScribedModels;
